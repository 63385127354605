import React, { useContext } from 'react';

import { containsErrors } from '../../../utils/validate';

import _ from 'lodash';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import SecurityAnswerDialog from './SecurityAnswerDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { Typography } from '@material-ui/core';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { INVALID_REDEMPTION_EMAILS } from '../../../constants';

const SECURITY_QUESTIONS = lang => [
    loc('redemption14', lang),
    loc('redemption15', lang),
    loc('redemption16', lang),
    loc('redemption17', lang),
    loc('redemption18', lang)
];

const InteracETransfer = ({
    redemptionType,
    customer,
    charity,
    theme,
    redemptionForm,
    formErrors,
    handleChange,
    handleBack,
    handleNext,
    etransfername,
    securityAnswerDialogOpen,
    handleSecurityAnswerDialog,
    securityQuestions,
    onClose,
    emailVerificationRequired,
    emailVerificationRequiredForETransfer
}) => {
    const { lang } = useContext(LocalizationContext);
    const handleSecurityAnswerConfirmed = () => {
        handleSecurityAnswerDialog(false);
        handleNext();
    };

    const emailOptions = [
        customer.email,
        ..._.get(customer, 'authorizedEmails', [])
            .filter(authorization => authorization.authorized)
            .map(authorization => authorization.email)
    ].filter(address => !_.some(INVALID_REDEMPTION_EMAILS, invalidEmail => address.includes(invalidEmail)));
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption8', lang)} iconName="account_balance_wallet" />
                {_.isEmpty(emailOptions) && redemptionType !== 'charity' ? (
                    <FormControl fullWidth style={{ marginBottom: theme.spacing.unit }}>
                        <TextField
                            data-cy="redemption-dialog-email"
                            name="email"
                            label={loc('dashboardCharityInfo4', lang)}
                            variant="outlined"
                            value={redemptionForm.email}
                            helperText={
                                formErrors['email'].fail && !_.isEmpty(redemptionForm.email)
                                    ? formErrors['email'].reason
                                    : loc('privateRelayText', lang)
                            }
                            error={formErrors['email'].fail && !_.isEmpty(redemptionForm.email)}
                            style={{ marginTop: theme.spacing.unit }}
                            onChange={handleChange}
                        />
                    </FormControl>
                ) : (
                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                        <InputLabel variant="outlined" htmlFor="question">
                            {loc('dashboardCharityInfo4', lang)}
                        </InputLabel>
                        <Select
                            data-cy="redemption-dialog-email-select"
                            value={redemptionForm.email}
                            onChange={handleChange}
                            inputProps={{
                                name: 'email',
                                id: 'email'
                            }}
                            disabled={emailOptions.length <= 1 || redemptionType === 'charity'}
                            input={<OutlinedInput labelWidth={122} name="email" id="email" className="select-icon" />}
                        >
                            {redemptionType === 'customer' ? (
                                emailOptions.map(email => {
                                    return (
                                        <MenuItem
                                            data-cy={'redemption-dialog-email-' + email}
                                            key={email}
                                            value={email}
                                        >
                                            {email}
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem
                                    data-cy={'redemption-dialog-email-' + _.get(charity, 'redemptionEmail', '')}
                                    key={charity.redemptionEmail}
                                    value={charity.redemptionEmail}
                                >
                                    {charity.redemptionEmail}
                                </MenuItem>
                            )}
                        </Select>
                        <FormHelperText style={{ marginBottom: theme.spacing.unit, paddingLeft: theme.spacing.unit }}>
                            {loc('emailAuthorization4', lang)}
                            <Link
                                color="primary"
                                style={{ color: '#0000EE' }}
                                to={`/customers/${customer._id}/${
                                    redemptionType === 'charity' ? 'charity' : ''
                                }profile`}
                                onClick={onClose}
                            >
                                {loc('emailAuthorization5', lang)}
                            </Link>
                        </FormHelperText>
                    </FormControl>
                )}
                <CustomFormTitle titleText={loc('redemption8a', lang)} iconName="lock_open" />

                <DialogContentText
                    style={{
                        marginTop: theme.spacing.unit
                    }}
                >
                    {loc('redemption11', lang)}
                </DialogContentText>
                <DialogContentText
                    style={{
                        marginTop: theme.spacing.unit,
                        color: theme.palette.error.main,
                        fontWeight: 500
                    }}
                >
                    {loc('redemption12', lang)}
                </DialogContentText>

                <FormControl
                    data-cy="redemption-dialog-security-question"
                    fullWidth
                    style={{ marginTop: theme.spacing.unit }}
                    error={formErrors['question'].fail}
                >
                    <InputLabel variant="outlined" htmlFor="question">
                        {loc('redemption13', lang)}
                    </InputLabel>
                    <Select
                        value={redemptionForm.question}
                        onChange={handleChange}
                        inputProps={{
                            name: 'question',
                            id: 'security-question'
                        }}
                        input={<OutlinedInput labelWidth={122} name="question" id="question" className="select-icon" />}
                    >
                        {(_.isNil(securityQuestions) || _.isEmpty(securityQuestions)
                            ? SECURITY_QUESTIONS(lang)
                            : _.get(securityQuestions, lang, [])
                        ).map((question, index) => {
                            return (
                                <MenuItem
                                    data-cy={index + '-redemption-dialog-question'}
                                    key={question}
                                    value={question}
                                >
                                    {question}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {formErrors['question'].fail && (
                        <FormHelperText style={{ paddingLeft: theme.spacing.unit }}>
                            {formErrors['question'].reason}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        data-cy="redemption-dialog-passphrase"
                        name="passphrase"
                        label={loc('redemption19', lang)}
                        variant="outlined"
                        value={redemptionForm.passphrase}
                        helperText={formErrors['passphrase'].reason}
                        error={formErrors['passphrase'].fail}
                        style={{ marginTop: theme.spacing.unit }}
                        onChange={handleChange}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={handleBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    disabled={
                        containsErrors(formErrors) ||
                        (!_.get(customer, 'verification.email.verified', false) &&
                            emailVerificationRequired &&
                            emailVerificationRequiredForETransfer)
                    }
                    onClick={() => handleSecurityAnswerDialog(true)}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <SecurityAnswerDialog
                securityAnswerDialogOpen={securityAnswerDialogOpen}
                handleSecurityAnswerDialog={handleSecurityAnswerDialog}
                handleSecurityAnswerConfirmed={handleSecurityAnswerConfirmed}
                securityAnswer={redemptionForm.passphrase}
            />
        </>
    );
};

export default InteracETransfer;
