import React, { useState } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { convertToImportName, DriverCommentMarkdownHelpertext, formatAsCurrency, getReceiverName } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    InputAdornment,
    Typography,
    colors,
    ListItem,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    List,
    FormControlLabel,
    Switch,
    DialogContentText
} from '@material-ui/core';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import { useEffect } from 'react';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { _user } from 'std';
import { DRIVER_PAYMENT_MODELS } from 'constants.js';
import FakeLink from 'components/FakeLink/FakeLink';

function ApproveRequestDialog(props) {
    const {
        open,
        theme,
        selectedRequest,
        onClose,
        http,
        afterSubmitSuccess,
        handleView,
        transporters,
        receivers,
        subCommodities,
        commodities,
        fees
    } = props;

    const [date, setDate] = useState(moment());
    const [isPreview, setIsPreview] = useState(false);
    const [comment, setComment] = useState(getDefaultCommentForRequest(selectedRequest, subCommodities, commodities));
    const [transporter, setTransporter] = useState(null);
    const [receiverId, setReceiverId] = useState(null);
    const [receiver, setReceiver] = useState(null);
    const [stopPayAmount, setStopPayAmount] = useState(null);
    const [stopDuration, setStopDuration] = useState(null);

    const [driversSelectable, setDriversSelectable] = useState([]);
    const customer = _.get(selectedRequest, 'customer');

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    useEffect(() => {
        setComment(getDefaultCommentForRequest(selectedRequest, subCommodities, commodities));
        setTransporter(_.get(selectedRequest, 'collector._id', null));
        setReceiverId(null);
    }, [selectedRequest]);

    useEffect(() => {
        setReceiver(_.find(receivers, r => r._id.toString() === receiverId));
    }, [receiverId]);

    useEffect(() => {
        if (_.isNil(transporter)) {
            setReceiverId(null);
            setDriversSelectable([]);
        } else {
            const driverList = _.filter(
                receivers,
                d => !d.banned && !d.disabled && _.get(d, 'collector._id', '').toString() === transporter.toString()
            );
            if (!driverList.includes(receiverId)) {
                setReceiverId(null);
            }
            setDriversSelectable(driverList);
        }
    }, [transporter]);

    const handleApproveRequest = async () => {
        const resCommodityRequest = await http.post(
            `/commodityRequests/${selectedRequest._id}/approve`,
            {
                date,
                transporter,
                receiver: receiverId,
                comment,
                stopPayAmount,
                stopDuration
            },
            true
        );
        if (resCommodityRequest.ok) {
            afterSubmitSuccess();
        }
    };

    const showOOSBountyInput =
        !_.isNil(receiver) && !_.isEmpty(receiver) && _user.getPaymentModel(receiver) === DRIVER_PAYMENT_MODELS[0]
            ? false
            : true;

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle style={{ paddingBottom: 14 }}>Confirm Commodity Request</DialogTitle>
            <DialogContent>
                <CustomFormTitle
                    titleText="Subscriptions"
                    iconName="event_repeat"
                    style={{ marginBottom: theme.spacing.unit, marginTop: theme.spacing.unit }}
                />
                {/* <DialogContentText>The following subscriptions will be applied:</DialogContentText> */}
                <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                    <Typography variant="body1">
                        <FakeLink
                            theme={theme}
                            text={`${_.get(customer, 'name.first', 'N/A')} ${_.get(customer, 'name.last', '')}`}
                            onClickEvent={() => handleView(customer._id)}
                            color={theme.palette.primary.main}
                            data-cy="view-customer"
                        />{' '}
                        has requested:
                        <div style={{ display: 'flex' }}>
                            {_.get(selectedRequest, 'commodities', []).map((item, i) => {
                                const commodity = _.find(
                                    item.isSubCommodity ? subCommodities : commodities,
                                    c => c._id.toString() === item._id.toString()
                                );
                                if (_.isNil(commodity)) {
                                    return <></>;
                                }
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            verticalAlign: 'middle',
                                            whiteSpace: 'noWrap'
                                        }}
                                    >
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(
                                                    allIcons,
                                                    _.startsWith(commodity.iconName, 'mdi')
                                                        ? commodity.iconName
                                                        : convertToImportName(commodity.iconName)
                                                )}
                                                size={1}
                                                color={commodity.color}
                                            />
                                        </span>
                                        <Typography variant="body2" style={{ marginRight: theme.spacing.unit }}>
                                            {_.get(commodity, item.isSubCommodity ? 'name.en' : 'skutype', 'Item')} x{' '}
                                            {item.amount}
                                            {i < _.get(selectedRequest, 'commodities', []).length - 1 && ', '}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>
                    </Typography>
                </div>
                <Typography variant="body1">The following subscriptions will be applied:</Typography>
                <List
                    style={{
                        margin: 0
                    }}
                >
                    {_.get(selectedRequest, 'commodities', []).map((item, i) => {
                        const commodity = _.find(
                            item.isSubCommodity ? subCommodities : commodities,
                            c => c._id.toString() === item._id.toString()
                        );
                        if (_.isNil(commodity)) {
                            return <></>;
                        }
                        const fee = _.find(fees, fee => fee._id.toString() === commodity.subscriptionFee.toString());
                        if (_.isNil(fee)) {
                            return <></>;
                        }
                        return (
                            <ListItem
                                style={{
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: theme.shape.borderRadius,
                                    whiteSpace: 'normal',
                                    marginBottom: theme.spacing.unit
                                }}
                            >
                                <Avatar
                                    style={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white'
                                        // fontSize: '15px'
                                    }}
                                >
                                    {item.amount}x
                                </Avatar>
                                <ListItemText
                                    primary={
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                display: 'block',
                                                paddingRight: 'inherit'
                                            }}
                                        >
                                            {fee.description} (
                                            {commodity.subscriptionFeeRecurrence > 0
                                                ? `Every ${commodity.subscriptionFeeRecurrence} Days`
                                                : 'One Time Fee'}
                                            )
                                        </span>
                                    }
                                    secondary={
                                        <span
                                            style={{
                                                display: 'block',
                                                paddingRight: 'inherit'
                                            }}
                                        >
                                            {_.get(selectedRequest, 'location.description', 'N/A')}
                                        </span>
                                    }
                                    style={{
                                        whiteSpace: 'normal',
                                        paddingRight: theme.spacing.unit * 3,
                                        position: 'relative'
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2 }}>
                                        {formatAsCurrency(fee.amount)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>

                <CustomFormTitle
                    titleText="Book Out of System Stop"
                    iconName="local_shipping"
                    style={{ marginBottom: theme.spacing.unit }}
                />
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={12}>
                        <FormControl fullWidth style={formControlStyle}>
                            <DatePicker
                                variant="outlined"
                                timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                value={date}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>date_range</Icon>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={date => setDate(date)}
                                disablePast
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            data-cy="create-edit-trip-dialog-collector-select"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel htmlFor="transporterCollector" variant="outlined" shrink>
                                Transporter
                            </InputLabel>
                            <Select
                                value={transporter}
                                onChange={e => {
                                    if (e.target.value !== transporter) {
                                        setReceiverId(null);
                                    }
                                    setTransporter(e.target.value);
                                }}
                                inputProps={{ id: 'transporterCollector', name: 'transporterCollector' }}
                                input={
                                    <OutlinedInput
                                        name="transporterCollector"
                                        id="transporterCollector"
                                        labelWidth={'Transporter'.length * theme.spacing.unit}
                                        notched
                                    />
                                }
                            >
                                {transporters.map(collector => {
                                    return (
                                        <MenuItem
                                            key={collector._id}
                                            value={collector._id}
                                            data-cy={`create-edit-trip-transporterCollector-${collector.name
                                                .replace(/\s*\W*\s+/g, '-')
                                                .toLowerCase()}-li`}
                                        >
                                            {collector.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {_.isEmpty(transporter) && (
                            <Typography
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    color: theme.palette.error.main,
                                    marginLeft: 5
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit / 2,
                                        lineHeight: 1.25,
                                        color: theme.palette.error.main
                                    }}
                                >
                                    warning
                                </Icon>{' '}
                                No transporter selected!
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            data-cy="create-edit-trip-dialog-driver-select"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel htmlFor="driver" variant="outlined" shrink>
                                Driver
                            </InputLabel>
                            <Select
                                disabled={_.isEmpty(transporter)}
                                value={receiverId}
                                onChange={e => {
                                    setReceiverId(e.target.value);
                                }}
                                inputProps={{ id: 'driver', name: 'driver' }}
                                input={
                                    <OutlinedInput
                                        name="driver"
                                        id="driver"
                                        labelWidth={'Driver'.length * theme.spacing.unit}
                                        notched
                                    />
                                }
                            >
                                {driversSelectable.map(driver => {
                                    return (
                                        <MenuItem
                                            key={driver._id}
                                            value={driver._id}
                                            data-cy={`${getReceiverName(driver)
                                                .replace(/\s/g, '-')
                                                .toLowerCase()}-create-edit-trip-driver-li`}
                                        >
                                            {getReceiverName(driver)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {_.isEmpty(receiverId) && (
                            <Typography
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    color: colors.amber[700],
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit / 2,
                                        lineHeight: 1.25,
                                        color: colors.amber[700]
                                    }}
                                >
                                    warning_amber
                                </Icon>{' '}
                                No driver selected
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {showOOSBountyInput ? (
                            <FormControl fullWidth>
                                <TextField
                                    data-cy="oos-stop-pay-amount"
                                    variant="outlined"
                                    name="stopPayAmount"
                                    value={_.isNil(stopPayAmount) ? '' : stopPayAmount / 100}
                                    type="number"
                                    onChange={e => setStopPayAmount(e.target.value * 100)}
                                    label="Pay (Dollars)"
                                    error={_.isNil(stopPayAmount) || stopPayAmount < 0}
                                    helperText={
                                        _.isNil(stopPayAmount)
                                            ? 'Required'
                                            : stopPayAmount < 0
                                            ? 'Cannot be negative'
                                            : null
                                    }
                                />
                            </FormControl>
                        ) : (
                            <FormControl fullWidth>
                                <TextField
                                    data-cy="oos-stop-pay-amount-hourly"
                                    variant="outlined"
                                    name="stopPayAmount"
                                    value="Hourly"
                                    label="Pay (Dollars)"
                                    disabled
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                data-cy="oos-stop-duration"
                                name="stopDuration"
                                label="Est. Duration (Min)"
                                value={stopDuration / 60}
                                onChange={e => setStopDuration(e.target.value * 60)}
                                type="number"
                                error={_.isNil(stopDuration) || stopDuration < 0}
                                helperText={
                                    _.isNil(stopDuration) ? 'Required' : stopDuration < 0 ? 'Cannot be negative' : null
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                multiline
                                label={'Directions'}
                                variant="outlined"
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{ 'data-cy': `bottle-drive-comment-input` }}
                                FormHelperTextProps={{ 'data-cy': `bottle-drive-comment-input-helper-txt` }}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Switch checked={isPreview} onChange={() => setIsPreview(!isPreview)} />}
                            label="Preview Markdown"
                        />
                        {isPreview && <DriverCommentMarkdownHelpertext source={comment} theme={theme} />}
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Close
                </Button>
                <Button
                    data-cy="bottle-drive-submit-btn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={handleApproveRequest}
                    disabled={
                        _.isNil(transporter) ||
                        _.isNil(stopDuration) ||
                        stopDuration < 0 ||
                        (showOOSBountyInput && (_.isNil(stopPayAmount) || stopPayAmount < 0))
                    }
                >
                    Approve Request
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ApproveRequestDialog);

function getDefaultCommentForRequest(request, subCommodities, commodities) {
    let string = 'Drop off for customer:';
    _.get(request, 'commodities', []).forEach(item => {
        const commodity = _.find(
            item.isSubCommodity ? subCommodities : commodities,
            c => c._id.toString() === item._id.toString()
        );
        if (!_.isNil(commodity)) {
            string += `\n* ${_.get(commodity, item.isSubCommodity ? 'name.en' : 'skutype', 'Item')} x ${item.amount}`;
        }
    });
    return string;
}
