export const keysUser = [
    'phone',
    'email',
    'nameFirst',
    'nameLast',
    'password',
    'repeatPassword',
    'location',
    'locationType',
    'unitNumber',
    'securityQuestion',
    'securityAnswer',
    'POBox',
    'schemeID',
    'requestedCommodities'
];

export const keysExistingUser = ['existingEmail'];

export const keysCharity = [
    'charityName',
    'registeredCharityName',
    'charityRegistrationNumber',
    'redemptionEmail',
    'website',
    'mission',
    'subdivisions',
    'location',
    'hasCloseDate',
    'closeDate',
    'charitySchemeID'
];

export const keysCommercial = ['businessName', 'registeredBusinessName', 'businessNumber', 'businessPhone'];

export const keysBottleDrive = ['bottleDriveName', 'bottleDriveCharity'];

export const keysValidated = {
    0: keysUser,
    1: keysUser.concat(keysCharity),
    5: keysUser.concat(keysCharity),
    3: keysUser.concat(keysBottleDrive),
    4: keysUser
};

export const trimmedKeys = ['email', 'nameFirst', 'nameLast', 'website', 'redemptionEmail'];
