import React from 'react';

const HouseIllustration = ({ theme }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 832 501.325">
            <g transform="translate(-32.84 15.098) scale(.86773)">
                <ellipse cx="516.969" cy="527.844" rx="479.167" ry="32.5" fill="#3f3d56" />
                <path fill="#3f3d56" d="M286.141 177.432h8.903v351.653h-8.903z" />
                <path
                    d="M377.205 222.246c.566 122.964-85.946 223.048-85.946 223.048s-87.43-99.282-87.997-222.246C202.695 100.083 289.207 0 289.207 0s87.431 99.282 87.998 222.246z"
                    fill={theme.palette.primary[500]}
                />
                <path fill="#f2f2f2" d="M225.5 383.5h689v146h-689z" />
                <path fill="#3f3d56" d="M933.5 399.5h-730l75-157h599z" />
                <path fill="#f2f2f2" d="M390.5 252.5h278v92h-278z" />
                <path fill="#3f3d56" d="M668.5 177.5h-260l-44 92h330z" />
                <path fill="#ccc" d="M415 412.5h219.5v117H415zM252.5 416.5h117v113h-117z" />
                <path fill="#3f3d56" d="M672.5 423.5H733v106h-60.5zM179.905 297.059h5.875v232.054h-5.875z" />
                <path
                    d="M239.997 326.631c.374 81.144-56.714 147.188-56.714 147.188s-57.695-65.515-58.07-146.659c-.373-81.143 56.715-147.187 56.715-147.187s57.695 65.515 58.07 146.658z"
                    fill={theme.palette.primary[500]}
                />
                <path
                    d="M239.997 326.631c.374 81.144-56.714 147.188-56.714 147.188s-57.695-65.515-58.07-146.659c-.373-81.143 56.715-147.187 56.715-147.187s57.695 65.515 58.07 146.658z"
                    opacity=".1"
                />
                <path fill="#3f3d56" d="M103.533 297.059h5.875v232.054h-5.875z" />
                <path
                    d="M163.625 326.631C164 407.775 106.91 473.82 106.91 473.82S49.215 408.304 48.842 327.16c-.374-81.143 56.714-147.187 56.714-147.187s57.695 65.515 58.07 146.658z"
                    fill={theme.palette.primary[500]}
                />
                <path
                    fill="#3f3d56"
                    d="M798.5 429.5H828V457h-29.5zM838 429.5h29.5V457H838zM798.5 467H828v28.5h-29.5zM838 467h29.5v28.5H838zM416 277.674h23.087v21.522H416zM446.913 277.674H470v21.522h-23.087zM416 307.022h23.087v22.304H416zM446.913 307.022H470v22.304h-23.087zM589 277.674h23.087v21.522H589zM619.913 277.674H643v21.522h-23.087zM589 307.022h23.087v22.304H589zM619.913 307.022H643v22.304h-23.087zM503 277.674h23.087v21.522H503zM533.913 277.674H557v21.522h-23.087zM503 307.022h23.087v22.304H503zM533.913 307.022H557v22.304h-23.087z"
                />
                <path
                    fill="#f2f2f2"
                    d="M686 434.239h14.536v13.551H686zM705.464 434.239H720v13.551h-14.536zM686 452.717h14.536v14.043H686zM705.464 452.717H720v14.043h-14.536z"
                />
                <circle cx="725" cy="484" r="4" fill="#f2f2f2" />
                <path
                    fill="#f2f2f2"
                    d="M415 425h220v2H415zM415 434h220v2H415zM415 443h220v2H415zM415 452h220v2H415zM415 461h220v2H415zM415 470h220v2H415zM415 479h220v2H415zM415 488h220v2H415zM415 497h220v2H415zM415 506h220v2H415zM415 515h220v2H415zM252.5 425h117v2h-117zM252.5 434h117v2h-117zM252.5 443h117v2h-117zM252.5 452h117v2h-117zM252.5 461h117v2h-117zM252.5 470h117v2h-117zM252.5 479h117v2h-117zM252.5 488h117v2h-117zM252.5 497h117v2h-117zM252.5 506h117v2h-117zM252.5 515h117v2h-117z"
                />
            </g>
        </svg>
    );
};

export default HouseIllustration;
