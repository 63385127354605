import React, { useContext, useState, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    colors,
    Hidden,
    Icon,
    IconButton,
    InputAdornment,
    Grid,
    Paper,
    Toolbar,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Select,
    MenuItem
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { downloadObjectAsCSV, deviceHelper, formatAsCurrency } from 'utils/misc';

import {
    issueTaxReceipts,
    getTaxableBulksByCustomer,
    generateNonIssuedTableData,
    generateDeferredTableData,
    generateTableData
} from 'helpers/charityTaxReceiptsHelper';

import Badge from 'components/Badge/Badge';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import useGetJSON from 'utils/hooks/useGetJSON';
import GoogleContext from 'utils/contexts/GoogleContext';

import { addPostalCodes } from 'helpers/charityDonationsHelper';

// TODO: do not use dev express anymore
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    DataTypeProvider
} from '@devexpress/dx-react-grid';
import { Grid as TableGrid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

const TODAY = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);

const CURRENT_YEAR = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .year();

const CURRENT_YEAR_END = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .endOf('year');

function CharityTaxReceipts(props) {
    const { theme, bulks, charity, isSystemAdmin, reloadCharity } = props;

    const {
        loading: defaultMinTaxReceiptAmountLoading,
        error: defaultMinTaxReceiptAmountError,
        data: { defaultMinTaxReceiptAmount = 0 }
    } = useGetJSON(`/system/minTaxReceiptAmount`);

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { placesService, google } = useContext(GoogleContext);
    const { lang } = useContext(LocalizationContext);

    const [loading, setLoading] = useState(false);

    const [activeBulks, setActiveBulks] = useState(bulks);
    const [tableData, setTableData] = useState({ rows: [], download: [] });

    const [help1DialogOpen, setHelp1DialogOpen] = useState(false);
    const [help2DialogOpen, setHelp2DialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [sendEmails, setSendEmails] = useState(true);
    const [minTaxReceiptAmount, setMinTaxReceiptAmount] = useState(
        _.get(charity, 'minTaxReceiptAmount', defaultMinTaxReceiptAmount)
    );

    // Gets the default end of tax year for the charity. Shows prompt to set end of tax year if null
    const [charityEndOfTaxYear, setCharityEndOfTaxYear] = useState(charity.taxReceiptsEndOfYear);

    let endOfTaxYear = moment(charityEndOfTaxYear || CURRENT_YEAR_END)
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .set('year', CURRENT_YEAR)
        .endOf('day');

    // If the last tax periods creep into this year but has already passed, set end of current tax year to next year
    if (moment(endOfTaxYear).isBefore(TODAY)) {
        endOfTaxYear = moment(endOfTaxYear).set('year', CURRENT_YEAR + 1);
    }

    const lastTaxableYear = moment(endOfTaxYear).year();

    const [endDate, setEndDate] = useState(endOfTaxYear);
    const [redemptionYear, setRedemptionYear] = useState(lastTaxableYear);

    const [taxData, setTaxData] = useState(getTaxableBulksByCustomer(charity, activeBulks, endDate, lastTaxableYear));
    const [nonIssuedTableData, setNonIssuedTableData] = useState(
        generateNonIssuedTableData(taxData, charity, minTaxReceiptAmount, lang)
    );
    const [deferredTableData, setDeferredTableData] = useState(
        generateDeferredTableData(taxData, charity, minTaxReceiptAmount)
    );

    const handleHelp1Dialog = state => {
        setHelp1DialogOpen(state);
    };

    const handleHelp2Dialog = state => {
        setHelp2DialogOpen(state);
    };

    const handleConfirmDialog = state => {
        setConfirmDialogOpen(state);
    };

    const handleRefreshTaxData = () => {
        const updatedTaxData = getTaxableBulksByCustomer(charity, activeBulks, endDate, lastTaxableYear);

        setTaxData(updatedTaxData);
        setNonIssuedTableData(generateNonIssuedTableData(updatedTaxData, charity, minTaxReceiptAmount, lang));
        setDeferredTableData(generateDeferredTableData(taxData, charity, minTaxReceiptAmount, lang));
    };

    const handleYearChange = e => {
        setRedemptionYear(e.target.value);
        setEndDate(moment(endDate).set('year', e.target.value));
    };

    const handleViewCustomer = customer_id => {
        window.open(`/customers/${customer_id}/history`, '_blank');
    };

    const refreshBulks = async () => {
        const updatedData = await http.getJSON(`/charities/bulks/${charity._id}`);
        const updatedBulks = _.get(updatedData, 'data.bulks', []);

        setActiveBulks(updatedBulks);
    };

    const handleSaveYearEndTaxDate = async () => {
        await http.post(`/charities/${charity._id}/setTaxReceiptsEndOfYear`, { date: endDate });

        await reloadCharity();
        await refreshBulks();

        setCharityEndOfTaxYear(endDate);
    };

    const handleTaxReceiptIssuance = async () => {
        setLoading(true);

        await issueTaxReceipts(http, charity, taxData, redemptionYear, endDate, sendEmails);

        await reloadCharity();
        await refreshBulks();

        setLoading(false);
    };

    const acceptTaxReceiptIssuance = () => {
        handleTaxReceiptIssuance();
        setConfirmDialogOpen(false);
    };

    const handleDownloadCSV = async () => {
        setLoading(true);

        let data = tableData.downloadAlternate;
        // let data = tableData.rows;
        data = await addPostalCodes(http, google, placesService, data);

        let date = locDate(moment(), 'YYYY_MM_DD', lang);

        const fileName = `tax_receipts_${date}`;
        try {
            await downloadObjectAsCSV(data, fileName);
            if (deviceHelper.isNativeApp()) {
                onSnackbar(loc('charitySnackbar15', lang, { name: fileName }));
            }
        } catch (err) {
            onSnackbar(loc('charitySnackbar16', lang), 'error');
        }

        setLoading(false);
    };

    useEffect(() => {
        async function reload() {
            await refreshBulks();
        }

        reload();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const tableData = await generateTableData(http, charity, lang);
            try {
                setTableData(tableData);
            } catch (err) {
                console.error('Error loading tax receipt table');
            }
        }
        fetchData();
    }, [activeBulks, lang]);

    useEffect(() => {
        handleRefreshTaxData();
    }, [activeBulks, endDate, lastTaxableYear, charityEndOfTaxYear, lang]);

    useEffect(() => {
        setMinTaxReceiptAmount(_.get(charity, 'minTaxReceiptAmount', defaultMinTaxReceiptAmount));
    }, [defaultMinTaxReceiptAmount]);

    const Cell = theme => props => {
        const { row, column } = props;
        const customer_id = _.get(row, '_id');
        const column_name = _.get(column, 'name');

        if (isSystemAdmin && customer_id && column_name === 'name') {
            return (
                <Table.Cell {...props} style={{ cursor: 'pointer' }} onClick={() => handleViewCustomer(customer_id)} />
            );
        } else if (row.disabled) {
            return <Table.Cell {...props} style={{ color: theme.palette.text.disabled }} />;
        } else {
            return <Table.Cell {...props} />;
        }
    };

    // Create icon columns for table rows and filter by date
    const filteredIssuedRows = _.filter(tableData.rows, row => {
        return (
            moment(row.cutOffMomentObject).isBefore(endDate) &&
            moment(row.cutOffMomentObject).isAfter(moment(endDate).subtract(1, 'years'))
        );
    });

    const filteredNonIssuedRows = _.filter(nonIssuedTableData.rows, row => {
        return (
            moment(row.cutOffMomentObject).isBefore(endDate) &&
            moment(row.cutOffMomentObject).isAfter(moment(endDate).subtract(1, 'years'))
        );
    });

    const filteredDeferredRows = _.filter(deferredTableData.rows, row => {
        return (
            moment(row.cutOffMomentObject).isBefore(endDate) &&
            moment(row.cutOffMomentObject).isAfter(moment(endDate).subtract(1, 'years'))
        );
    });

    const tableRows = filteredNonIssuedRows.concat(filteredIssuedRows).concat(filteredDeferredRows);

    for (let i = 0; i < tableRows.length; i++) {
        const isIssued = tableRows[i].date;
        const isDeferred = tableRows[i].isDeferred;
        tableRows[i].issued = (
            <Badge
                style={{
                    backgroundColor: isIssued ? colors.green[500] : isDeferred ? colors.yellow[800] : colors.red[500],
                    color: '#fff'
                }}
            >
                {isIssued
                    ? loc('taxReceipts20', lang)
                    : isDeferred
                    ? loc('taxReceipts16', lang)
                    : loc('taxReceipts21', lang)}
            </Badge>
        );
    }

    // Create string for tax receipt unissued years
    let yearsToIssue = '';
    for (const year of taxData.keys()) {
        if (year === lastTaxableYear) continue;
        if (_.get(taxData.get(year), 'taxableCount', 0) > 0) {
            yearsToIssue += year.toString() + ', ';
        }
    }

    if (yearsToIssue.length > 0) {
        yearsToIssue = yearsToIssue.slice(0, yearsToIssue.length - 2);
    }

    const DateFormatter = ({ value }) => (!_.isNil(value) ? locDate(value, 'MMM Do YYYY', lang) : '');

    const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

    const integratedSortingColumnExtensions = [
        { columnName: 'amount', compare: compareCurrencies },
        { columnName: 'startDate', compare: compareDates },
        { columnName: 'cutOffDate', compare: compareDates },
        { columnName: 'date', compare: compareDates },
        { columnName: 'firstDonation', compare: compareDates },
        { columnName: 'lastDonation', compare: compareDates }
    ];

    return (
        <>
            <Grid container spacing={theme.spacing.unit * 4} style={{ width: '100%', margin: 0 }}>
                <Grid item xs={12}>
                    <Paper
                        id="issue-taxreceipts"
                        data-cy="issue-taxreceipts"
                        style={{ padding: theme.spacing.unit * 2 }}
                    >
                        <Typography variant="h6">{loc('taxReceipts1', lang)}</Typography>
                        {yearsToIssue !== '' && (
                            <Typography style={{ color: colors.red[500], marginTop: theme.spacing.unit * 2 }}>
                                {loc('taxReceipts5', lang, { yearsToIssue })}.
                            </Typography>
                        )}
                        <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                            {loc('taxReceipts6', lang, {
                                minTaxReceiptAmount: formatAsCurrency(minTaxReceiptAmount, lang)
                            })}
                        </Typography>
                        <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                            {loc('taxReceipts7', lang)}
                            <strong>{locDate(endOfTaxYear, 'MMMM Do', lang)}</strong>
                            {loc('taxReceipts7', lang, {
                                brand: process.env.REACT_APP_BRAND_NAME
                            })}
                        </Typography>
                        {charityEndOfTaxYear ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        marginTop: theme.spacing.unit * 2
                                    }}
                                >
                                    <Select
                                        data-cy="tax-receipt-year-select"
                                        value={redemptionYear}
                                        onChange={handleYearChange}
                                    >
                                        <MenuItem value="" disabled>
                                            {loc('taxReceipts22', lang)}
                                        </MenuItem>
                                        {_.range(2018, lastTaxableYear + 1).map(year => (
                                            <MenuItem data-cy={`tax-receipt-year-${year}`} key={year} value={year}>
                                                {/*taxData.get(year).taxableCount > 0 ? (
                                                    <Icon style={{ color: colors.red[500] }}>clear</Icon>
                                                ) : (
                                                    <Icon style={{ color: colors.green[500] }}>check</Icon>
                                                )*/}
                                                <Icon style={{ color: colors.green[500] }}>check</Icon>
                                                {_.filter(tableData.rows, row => year === row.receiptYear).length}
                                                <Icon style={{ color: colors.red[500] }}>clear</Icon>
                                                {_.get(taxData.get(year), 'taxableCount', 0)}{' '}
                                                <Icon style={{ color: colors.yellow[800] }}>arrow_forward</Icon>
                                                {
                                                    _.filter(deferredTableData.rows, row => year === row.receiptYear)
                                                        .length
                                                }{' '}
                                                <span
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        color: theme.palette.primary.main
                                                    }}
                                                >
                                                    {locDate(
                                                        moment(endDate)
                                                            .set('year', year - 1)
                                                            .add(1, 'days'),
                                                        'MMMM YYYY',
                                                        lang
                                                    )}{' '}
                                                    - {locDate(moment(endDate).set('year', year), 'MMMM YYYY', lang)}{' '}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        data-cy="issue-tax-receipts-button"
                                        disabled={
                                            loading ||
                                            lastTaxableYear === redemptionYear ||
                                            _.get(taxData.get(redemptionYear), 'taxableCount', 0) <= 0
                                        }
                                        style={{ margin: theme.spacing.unit * 2 }}
                                        onClick={() => handleConfirmDialog(true)}
                                    >
                                        {!loading ? (
                                            lastTaxableYear === redemptionYear ? (
                                                loc('taxReceipts8', lang)
                                            ) : (
                                                loc('taxReceipts2', lang, {
                                                    count: _.get(taxData.get(redemptionYear), 'taxableCount', 0)
                                                })
                                            )
                                        ) : (
                                            <CircularProgress size={22} />
                                        )}
                                    </Button>
                                </div>

                                {lastTaxableYear === redemptionYear && (
                                    <Typography style={{ marginTop: theme.spacing.unit * 2 }}>
                                        <strong>
                                            {loc('taxReceipts9', lang, {
                                                endOfTaxYear: locDate(endOfTaxYear, 'MMMM Do', lang),
                                                brand: process.env.REACT_APP_BRAND_NAME
                                            })}
                                        </strong>
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography style={{ color: colors.red[500], marginTop: theme.spacing.unit * 2 }}>
                                    {loc('taxReceipts19', lang)}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        marginTop: theme.spacing.unit * 2
                                    }}
                                >
                                    <DatePicker
                                        disabled={loading} // TODO: implement loading
                                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                        data-cy="taxreceipt-date-picker" // TODO: fill it out
                                        label={loc('labels13', lang)}
                                        name="taxYearEndDate"
                                        format="MMMM Do, YYYY"
                                        value={endDate}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>date_range</Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                        style={{ margin: theme.spacing.unit * 2 }}
                                        onChange={date => setEndDate(date.endOf('day'))}
                                    />
                                    <Button
                                        data-cy="confirm-tax-receipt-issual-date"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        style={{ marginLeft: theme.spacing.unit * 2 }}
                                        onClick={handleSaveYearEndTaxDate}
                                    >
                                        {loc('labels14', lang)}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper data-cy="issued-tax-receipts-table">
                        <Toolbar
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingRight: theme.spacing.unit * 2
                            }}
                        >
                            <Hidden smUp>
                                <div style={{ width: '100%', paddingTop: theme.spacing.unit * 2 }} />
                            </Hidden>
                            <Typography variant="h6">
                                {tableRows.length > 0
                                    ? `${loc('taxReceipts10', lang, {
                                          year: redemptionYear
                                      })} - ${loc('taxReceipts11', lang, {
                                          count: filteredIssuedRows.length
                                      })} -${' '}
                                ${loc('taxReceipts12', lang, {
                                    count: filteredNonIssuedRows.length
                                })}`
                                    : loc('taxReceipts17', lang)}

                                {
                                    <IconButton onClick={() => handleHelp1Dialog(true)}>
                                        <Icon>help</Icon>
                                    </IconButton>
                                }
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Button
                                    color="primary"
                                    disabled={_.isEmpty(tableData.rows)}
                                    onClick={handleDownloadCSV}
                                    style={{ margin: theme.spacing.unit }}
                                >
                                    {loc('taxReceipts15', lang)}
                                </Button>
                            </div>
                        </Toolbar>
                        <TableGrid
                            rows={tableRows}
                            columns={[
                                { title: loc('taxReceipts20', lang), name: 'issued' },
                                { title: loc('taxReceiptCol1', lang), name: 'name' },
                                { title: loc('taxReceiptCol15', lang), name: 'registeredBusinessName' },
                                { title: loc('taxReceiptCol2', lang), name: 'email' },
                                { title: loc('taxReceiptCol3', lang), name: 'phone' },
                                { title: loc('taxReceiptCol4', lang), name: 'address' },
                                { title: loc('taxReceiptCol5', lang), name: 'donations' },
                                { title: loc('taxReceiptCol6', lang), name: 'amount' },
                                // { title: 'Cluster', name: 'cluster' },
                                { title: loc('taxReceiptCol7', lang), name: 'firstDonation' },
                                { title: loc('taxReceiptCol8', lang), name: 'lastDonation' },
                                { title: loc('taxReceiptCol9', lang), name: 'date' },
                                { title: loc('taxReceiptCol10', lang), name: 'receiptYear' }
                            ]}
                        >
                            <DateTypeProvider for={['date', 'firstDonation', 'lastDonation']} />
                            <SortingState defaultSorting={[{ columnName: 'lastDonation', direction: 'desc' }]} />
                            <PagingState defaultCurrentPage={0} />
                            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                            <IntegratedPaging />
                            <Table cellComponent={Cell(theme)} />
                            <TableHeaderRow showSortingControls />
                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                        </TableGrid>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog open={help1DialogOpen} fullWidth onClose={() => handleHelp1Dialog(false)}>
                <DialogTitle>{loc('taxReceipts13', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('taxReceipts14', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleHelp1Dialog(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={help2DialogOpen} fullWidth onClose={() => handleHelp2Dialog(false)}>
                <DialogTitle>{loc('taxReceipts13', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('taxReceipts18', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleHelp2Dialog(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmDialogOpen} fullWidth onClose={() => handleConfirmDialog(false)}>
                <DialogTitle>{loc('taxReceipts1', lang)}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('taxReceipts3', lang, { charity: charity.name })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => handleConfirmDialog(false)}>
                        {loc('close', lang)}
                    </Button>
                    <Button data-cy="issue-tax-receipt-confirm" color="primary" onClick={acceptTaxReceiptIssuance}>
                        {loc('taxReceipts4', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(CharityTaxReceipts);

function compareCurrencies(a, b) {
    const priorityA = parseFloat(a.substr(1).replace(/,/g, ''), 10);
    const priorityB = parseFloat(b.substr(1).replace(/,/g, ''), 10);
    if (priorityA === priorityB) {
        return 0;
    } else {
        return priorityA < priorityB ? -1 : 1;
    }
}

function compareDates(a, b) {
    if (a === b) {
        return 0;
    } else {
        return a < b ? -1 : 1;
    }
}
