import React, { useContext } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { LinearProgress, Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const AutoRedemptionSummary = ({ handleBack, handleSave, loading, method, data, usePreviousBankingInfo }) => {
    const { lang } = useContext(LocalizationContext);

    const renderSummary = (method, data) => {
        switch (method) {
            case 'CASH':
                return (
                    <>
                        <Typography variant="h6">Collector</Typography>
                        <Typography variant="body1">{data.collector}</Typography>
                    </>
                );
            case 'AUS_DIRECT_BANKING':
                return usePreviousBankingInfo ? (
                    <>
                        <Typography variant="h6">Account Number</Typography>
                        <Typography variant="body1">{data.accountNumber}</Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h6">BSB Number</Typography>
                        <Typography variant="body1">{data.bsbNumber}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Account Number
                        </Typography>
                        <Typography variant="body1">{data.accountNumber}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Account Name
                        </Typography>
                        <Typography variant="body1">{data.accountName}</Typography>
                    </>
                );
            case 'INTERAC_ETRANSFERS':
                return (
                    <>
                        <Typography variant="h6">Security Question</Typography>
                        <Typography variant="body1">{data.question}</Typography>
                    </>
                );
            case 'CHEQUE':
                return (
                    <>
                        <Typography variant="h6">Account Name</Typography>
                        <Typography variant="body1">{data.accountName}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Street Address
                        </Typography>
                        <Typography variant="body1">{data.street}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            City
                        </Typography>
                        <Typography variant="body1">{data.city}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Postal Code
                        </Typography>
                        <Typography variant="body1">{data.postalCode}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Province
                        </Typography>
                        <Typography variant="body1">{data.province}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Country
                        </Typography>
                        <Typography variant="body1">{data.country}</Typography>
                    </>
                );
            case 'BANK_TRANSFER':
                return usePreviousBankingInfo ? (
                    <>
                        <Typography variant="h6">Account Number</Typography>
                        <Typography variant="body1">{data.accountNumber}</Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h6">Account Number</Typography>
                        <Typography variant="body1">{data.accountNumber}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Transit Number
                        </Typography>
                        <Typography variant="body1">{data.transitNumber}</Typography>

                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                            Institution Name
                        </Typography>
                        <Typography variant="body1">{data.institutionNumber}</Typography>
                    </>
                );
        }
    };
    return (
        <>
            <DialogContent>
                <Typography variant="body2">
                    {
                        'The following settings will be used to automatically redeem all subsequent orders as soon as they are processed:'
                    }
                </Typography>
                <div style={{ padding: '24px', margin: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, marginRight: '16px' }}>
                            <Typography variant="h6">Redemption Method</Typography>
                            <Typography variant="body1">{method}</Typography>
                        </div>

                        <div style={{ flex: 1 }}>{renderSummary(method, data)}</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={handleBack}>
                    {loc('back', lang)}
                </Button>
                <Button data-cy="auto-redemption-dialog-accept" color="primary" onClick={handleSave}>
                    {'Accept'}
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </>
    );
};

export default AutoRedemptionSummary;
