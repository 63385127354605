import React, { useEffect, useContext, useState, useMemo } from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    Typography,
    Icon,
    IconButton,
    Checkbox,
    List,
    ListItem,
    TextField,
    MenuItem,
    colors
} from '@material-ui/core';

import ServiceFeesForm from '../Forms/CollectorForms/ServiceFeesForm';

import useTaxGroups from 'utils/hooks/useTaxGroups';

import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { _commodity } from 'std';

function ServiceFeesDialog({
    open,
    collector,
    commodities,
    collectorRates,
    serviceFees = [],
    onClose,
    onSubmit,
    theme
}) {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const [serviceFeeIdsSelected, setServiceFeeIdsSelected] = useState(
        _.get(collector, 'configuration.serviceFees', []).map(serviceFee => serviceFee.serviceFeeId)
    );

    const [serviceFeeEditDialogOpen, setServiceFeeEditDialogOpen] = useState(false);
    const [serviceFeeSelectedI, setServiceFeeSelectedI] = useState(null);

    const { taxGroups, taxGroupsLoading } = useTaxGroups();

    const handleAddServiceFeeDialog = () => {
        setServiceFeeEditDialogOpen(true);
        setServiceFeeSelectedI(null);
    };

    const handleEditServiceFeeDialog = index => {
        setServiceFeeEditDialogOpen(true);
        setServiceFeeSelectedI(index);
    };

    /*const handleAddServiceFee = serviceFee => {
        setServiceFees([...serviceFees, serviceFee]);
        setServiceFeeEditDialogOpen(false);
    };

    const handleEditServiceFee = newServiceFee => {
        let serviceFeesCopy = _.cloneDeep(serviceFees);
        serviceFeesCopy[serviceFeeSelectedI] = newServiceFee;
        setServiceFees(serviceFeesCopy);
        setServiceFeeEditDialogOpen(false);
    };

    const handleRemoveServiceFee = index => {
        let serviceFeesCopy = _.cloneDeep(serviceFees);
        serviceFeesCopy.splice(index, 1);
        setServiceFees(serviceFeesCopy);
    };*/

    const handleToggleServiceFee = (serviceFee, selected) => {
        if (selected) {
            setServiceFeeIdsSelected([...serviceFeeIdsSelected, _.get(serviceFee, '_id')]);
        } else {
            setServiceFeeIdsSelected(
                serviceFeeIdsSelected.filter(serviceFeeId => serviceFeeId !== _.get(serviceFee, '_id'))
            );
        }
    };

    const handleSubmit = () => {
        const serviceFeesSelected = _.filter(
            serviceFees,
            serviceFee => serviceFeeIdsSelected.indexOf(serviceFee._id) !== -1
        );

        onSubmit(
            serviceFeesSelected.map(serviceFee => {
                const serviceFeeObj = { ...serviceFee };
                delete serviceFeeObj._id;
                serviceFeeObj.serviceFeeId = serviceFee._id;
                return serviceFeeObj;
            })
        );
    };

    const serviceFeesGroupedByCommodity = useMemo(() => {
        const serviceFeesEnabled = _.filter(serviceFees, sf => !_.get(sf, 'disabled', false));
        return _.groupBy(serviceFeesEnabled, 'skuType');
    }, [serviceFees]);

    const handleSetServiceFeeForCommodity = (serviceFeeId, skuType) => {
        //remove any service fees that have the same commodity
        const updatedServiceFeeIds = _.filter(serviceFeeIdsSelected, sFId => {
            const serviceFee = _.find(serviceFees, { _id: sFId });
            return _.get(serviceFee, 'skuType') !== skuType;
        });

        if (!_.isEmpty(serviceFeeId)) {
            updatedServiceFeeIds.push(serviceFeeId);
        }

        setServiceFeeIdsSelected(updatedServiceFeeIds);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Update service fees</DialogTitle>
            <DialogContent>
                {Object.keys(serviceFeesGroupedByCommodity).map(skuType => {
                    const serviceFeesForCommodity = serviceFeesGroupedByCommodity[skuType];
                    const serviceFeeForCommodity = _.find(serviceFeesForCommodity, serviceFee => {
                        return serviceFeeIdsSelected.indexOf(serviceFee._id) !== -1;
                    });
                    const commodity = _.find(commodities, { skuType });
                    return (
                        <TextField
                            key={skuType}
                            margin="normal"
                            value={_.get(serviceFeeForCommodity, '_id', '')}
                            onChange={e => handleSetServiceFeeForCommodity(e.target.value, skuType)}
                            label={_commodity.getSkuType(commodity)}
                            variant="outlined"
                            fullWidth
                            select
                        >
                            <MenuItem value={''}>
                                <ListItem
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography variant="h6">
                                        <i>None</i>
                                    </Typography>
                                </ListItem>
                            </MenuItem>
                            {serviceFeesForCommodity.map(serviceFee => {
                                const taxGroup = _.find(taxGroups, {
                                    _id: serviceFee.tax
                                });
                                const taxes = _.get(taxGroup, 'taxes', []);
                                return (
                                    <MenuItem
                                        value={serviceFee._id}
                                        key={serviceFee._id}
                                        style={{
                                            height: '100%'
                                        }}
                                    >
                                        <span>
                                            <Typography variant="h6">{serviceFee.description}</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ marginRight: theme.spacing.unit * 2 }}>
                                                    {_.get(serviceFee, 'type', '')}: {getTypePayoutText(serviceFee)} -{' '}
                                                    {serviceFee.skuType}
                                                </Typography>
                                                {taxes.length > 0 && (
                                                    <Typography>
                                                        Taxes:{' '}
                                                        {taxes
                                                            .map(
                                                                tax =>
                                                                    `${tax.name} (${_.round(
                                                                        tax.configuration.value * 100,
                                                                        5
                                                                    )}%)`
                                                            )
                                                            .join(', ')}
                                                    </Typography>
                                                )}
                                            </div>
                                        </span>
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    );
                })}
                {/*<List dense style={{ margin: theme.spacing.unit }}>
                    {serviceFees.map((serviceFee, index) => {
                        const taxGroup = _.find(taxGroups, {
                            _id: serviceFee.tax
                        });
                        const taxes = _.get(taxGroup, 'taxes', []);
                        const checked = serviceFeeIdsSelected.indexOf(serviceFee._id) !== -1;

                        return (
                            <ListItem
                                button
                                onClick={() => handleToggleServiceFee(serviceFee, !checked)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                key={index}
                            >
                                <Checkbox checked={checked} disableRipple />
                                <span style={{ flex: 1 }}>
                                    <Typography variant="h6">{serviceFee.description}</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ marginRight: theme.spacing.unit * 2 }}>
                                            {_.get(serviceFee, 'type', '')}: {getTypePayoutText(serviceFee)} -{' '}
                                            {serviceFee.skuType}
                                        </Typography>
                                        {taxes.length > 0 && (
                                            <Typography>
                                                Taxes:{' '}
                                                {taxes
                                                    .map(
                                                        tax =>
                                                            `${tax.name} (${_.round(
                                                                tax.configuration.value * 100,
                                                                5
                                                            )}%)`
                                                    )
                                                    .join(', ')}
                                            </Typography>
                                        )}
                                    </div>
                                </span>
                            </ListItem>
                        );
                    })}
                </List>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ServiceFeesDialog);

function getTypePayoutText(serviceFee) {
    if (_.get(serviceFee, 'type', '') === 'Percent Fee') {
        return `${serviceFee.value * 100}%`;
    } else if (_.get(serviceFee, 'type', '') === 'Count Split') {
        return `${_.get(serviceFee, 'split.customer', 0)}¢/${_.get(serviceFee, 'split.charity', 0)}¢/${_.get(
            serviceFee,
            'split.collector',
            0
        )}¢/${_.get(serviceFee, 'split.processor', 0)}¢`;
    }
}
