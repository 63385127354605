import { locDate } from 'localizations/localizationHandler';
import _ from 'lodash';
import moment from 'moment-timezone';

import { getCustomerName, formatAsCurrency, formatAsPhoneNumber, formatAsAddress } from 'utils/misc';

export function getTaxableBulksByYear(bulks, cutOffDate, lastTaxableYear) {
    // gets taxable bulks by year (with amounts)
    const taxableBulksByYear = new Map();

    for (let i = 2018; i <= lastTaxableYear; i++) {
        taxableBulksByYear.set(i, { totalValue: 0, bulks: [] });
    }
    bulks.forEach(bulk => {
        // Get data for bulks
        const dateRedeemed = getDonationDateForBulk(bulk);
        const taxReceiptRequested = _.get(bulk, 'taxReceipt.requested');
        const taxReceiptIssued = _.get(bulk, 'taxReceipt.issued');
        const bulkCompletedYear = moment(dateRedeemed)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .year();
        const compareDate = moment(cutOffDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .set('year', bulkCompletedYear);

        // verify that the tax year is the current tax year
        let taxYear;
        if (
            moment(dateRedeemed)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .isAfter(compareDate)
        )
            taxYear = bulkCompletedYear + 1;
        else taxYear = bulkCompletedYear;

        if (!taxReceiptIssued && taxReceiptRequested && taxYear <= lastTaxableYear) {
            const yearTaxObj = taxableBulksByYear.get(taxYear);

            const ledgerLines = _.get(bulk, 'ledger.lines', []);
            const bulksTotalValue = getLedgerTotal(ledgerLines);

            yearTaxObj.totalValue += bulksTotalValue;
            yearTaxObj.bulks.push(bulk);
        }
    });

    return taxableBulksByYear;
}

// Better name would be getTaxableCustomerBulksByCharityRedemptions
export function getTaxableBulksByCustomer(charity, bulks, cutOffDate, lastTaxableYear) {
    const taxableBulksByYearByCustomer = new Map();

    for (let i = 2018; i <= lastTaxableYear; i++) {
        taxableBulksByYearByCustomer.set(i, { customerTaxMap: new Map(), taxableCount: 0 });
    }
    bulks.forEach(bulk => {
        // Get data for bulks
        const dateRedeemed = getDonationDateForBulk(bulk);
        // const taxReceiptRequested = _.get(bulk, 'taxReceipt.requested');
        const taxReceiptRequested = _.get(bulk, 'owner.receiveTaxReceipts');
        const taxReceiptIssued = _.get(bulk, 'taxReceipt.issued');
        const bulkCustomerId = _.get(bulk, 'owner._id');
        const bulkCompletedYear = moment(dateRedeemed)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .year();
        const compareDate = moment(cutOffDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .set('year', bulkCompletedYear);

        // verify that the tax year is the current tax year
        let taxYear;
        if (
            moment(dateRedeemed)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .isAfter(compareDate)
        )
            taxYear = bulkCompletedYear + 1;
        else taxYear = bulkCompletedYear;

        if (!taxReceiptIssued && taxReceiptRequested && taxYear <= lastTaxableYear) {
            const customerTaxMap = taxableBulksByYearByCustomer.get(taxYear).customerTaxMap;

            const ledgerLines = _.get(bulk, 'ledger.lines', []);
            const bulksTotalValue = getLedgerTotal(ledgerLines);

            if (customerTaxMap.has(bulkCustomerId)) {
                const customerTaxData = customerTaxMap.get(bulkCustomerId);
                customerTaxData.totalValue += bulksTotalValue;
                customerTaxData.bulks.push(bulk);
            } else {
                const customerTaxData = { totalValue: bulksTotalValue, bulks: [] };
                customerTaxData.bulks.push(bulk);
                customerTaxMap.set(bulkCustomerId, customerTaxData);
            }
        }
    });

    // Handles rolling over bulks to next year if they aren't above the cut off amount (20$)
    for (const year of taxableBulksByYearByCustomer.keys()) {
        const yearData = taxableBulksByYearByCustomer.get(year);

        const customerTaxMap = yearData.customerTaxMap;

        for (const customerId of customerTaxMap.keys()) {
            const customer = customerTaxMap.get(customerId);

            const rollOverYear = year + 1;

            if (customer.totalValue >= _.get(charity, 'minTaxReceiptAmount', 2000)) {
                yearData.taxableCount++;
                continue;
            }

            if (rollOverYear > lastTaxableYear) {
                continue;
            }

            const rollOverYearMap = taxableBulksByYearByCustomer.get(rollOverYear).customerTaxMap;

            if (rollOverYearMap.has(customerId)) {
                const rollOverTaxDataForCustomer = rollOverYearMap.get(customerId);
                rollOverTaxDataForCustomer.totalValue += customer.totalValue;
                rollOverTaxDataForCustomer.bulks = customer.bulks.concat(rollOverTaxDataForCustomer.bulks);
            } else {
                const rollOverTaxDataForCustomer = { totalValue: customer.totalValue, bulks: customer.bulks };
                rollOverYearMap.set(customerId, rollOverTaxDataForCustomer);
            }

            customer.bulks = [];
            customer.totalValue = 0;
        }
    }

    return taxableBulksByYearByCustomer;
}

export async function issueTaxReceipts(http, charity, taxData, year, cutOffDate, sendEmails) {
    if (!taxData.has(year)) return;

    const customerTaxMap = taxData.get(year).customerTaxMap;

    const customer_ids = customerTaxMap.keys();
    const dateIssued = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);
    const charityTaxAmountMinimum = _.get(charity, 'minTaxReceiptAmount', 2000);

    const toIssue = [];

    for (const customer_id of customer_ids) {
        const customerTaxableAmount = customerTaxMap.get(customer_id).totalValue;
        const customerTaxableBulks = customerTaxMap.get(customer_id).bulks;

        // extra check - shouldn't happen
        if (customerTaxableAmount < charityTaxAmountMinimum || customerTaxableBulks.length <= 0) {
            continue;
        }

        const customer = _.get(customerTaxableBulks[0], 'owner');

        if (!customer) continue;

        const taxReceiptData = {
            customer: customer._id,
            charity: charity._id,
            amount: customerTaxableAmount,
            issued: true,
            dateIssued: dateIssued,
            bulks: customerTaxableBulks.map(bulk => bulk._id),
            startDate: moment(cutOffDate)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .set('year', year - 1)
                .add(1, 'days'),
            cutOffDate: cutOffDate
            // cluster: _.get(charity, 'taxReceiptCutOffDates', []).length
        };

        toIssue.push(taxReceiptData);
    }

    // Add issual date to charity issual dates
    await http.post(`/charities/${charity._id}/addCutOffDate`, { date: cutOffDate });

    // Creates tax receipts and updates associated bulks
    await http.post(`/charityTaxReceipt/${charity._id}/createMultipleTaxReceipts`, { toIssue, sendEmails });
}

export async function generateTableData(http, charity, lang) {
    const rows = [];
    const download = [];
    const downloadAlternate = [];

    const charityEndOfTaxYear = _.get(charity, 'taxReceiptsEndOfYear');

    const res = await http.getJSON(`/charityTaxReceipt/${charity._id}/byCharity`);
    if (!res.ok) return { rows, download };

    const receipts = res.data.receipts;

    receipts.forEach(receipt => {
        // Basic customer data
        const customerData = {
            _id: receipt.customer._id,
            name: getCustomerName(receipt.customer),
            registeredBusinessName: _.get(receipt.customer, 'registeredBusinessName', 'N/A'),
            address: formatAsAddress(receipt.customer.location, true) || 'N/A',
            place_id: _.get(receipt, 'customer.location.place_id'),
            postal_code: _.get(receipt, 'customer.location.postalCode'),
            phone: formatAsPhoneNumber(receipt.customer.phone),
            email: receipt.customer.email
        };

        let startDate = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);
        let endDate = moment(new Date(2018, 0, 1)).tz(process.env.REACT_APP_REGION_TIMEZONE);

        receipt.bulks.forEach(bulk => {
            // Get dates for first and last bulk for receipt
            const dateRedeemed = getDonationDateForBulk(bulk) || new Date();
            const momentRedeemed = moment(dateRedeemed).tz(process.env.REACT_APP_REGION_TIMEZONE);

            if (moment(momentRedeemed).isBefore(startDate)) {
                startDate = momentRedeemed;
            }
            if (moment(momentRedeemed).isAfter(endDate)) {
                endDate = momentRedeemed;
            }

            // Bulk breakdown of tax receipts issued
            const downloadData = {
                ...customerData,
                amount: formatAsCurrency(getLedgerTotal(_.get(bulk, 'ledger.lines', []))),
                donationDate: moment(dateRedeemed)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('MMM Do YYYY'),
                issueDate: moment(receipt.dateIssued)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('MMM Do YYYY')
            };

            download.push(downloadData);
        });

        let receiptYear = endDate.year();
        if (
            charityEndOfTaxYear &&
            moment(charityEndOfTaxYear)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .set('year', receiptYear)
                .isBefore(endDate)
        ) {
            receiptYear++;
        }

        // Rolled up tax receipt download
        const alternateDownloadData = {
            ...customerData,
            // cluster: receipt.cluster,
            donations: receipt.bulks.length,
            amount: formatAsCurrency(receipt.amount),
            firstDonation: locDate(startDate, 'MMM Do YYYY', lang),
            lastDonation: locDate(endDate, 'MMM Do YYYY', lang),
            date: locDate(receipt.dateIssued, 'MMM Do YYYY', lang),
            receiptYear
        };

        // Data to be displayed on table
        const rowData = {
            ...customerData,
            // cluster: receipt.cluster,
            donations: receipt.bulks.length,
            amount: formatAsCurrency(receipt.amount),
            cutOffMomentObject: moment(endDate),
            firstDonation: startDate.toISOString(),
            lastDonation: endDate.toISOString(),
            /*startDate: moment(receipt.startDate)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('MMM Do YYYY'),
            cutOffDate: moment(receipt.cutOffDate)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('MMM Do YYYY'),*/
            date: receipt.dateIssued,
            receiptYear
        };

        downloadAlternate.push(alternateDownloadData);
        rows.push(rowData);
    });

    return { rows, download, downloadAlternate };
}

export function generateNonIssuedTableData(taxData, charity, minTaxReceiptAmount, lang) {
    const rows = [];

    const charityEndOfTaxYear = _.get(charity, 'taxReceiptsEndOfYear');

    for (const year of taxData.keys()) {
        const yearData = taxData.get(year);

        const customerTaxMap = yearData.customerTaxMap;

        for (const customerId of customerTaxMap.keys()) {
            const customer = customerTaxMap.get(customerId);

            if (customer.totalValue < minTaxReceiptAmount || customer.bulks.length <= 0) {
                continue;
            }

            const customerDataFromBulk = customer.bulks[0].owner;

            const customerData = {
                _id: customerDataFromBulk._id,
                name: getCustomerName(customerDataFromBulk),
                registeredBusinessName: _.get(customerDataFromBulk, 'registeredBusinessName', 'N/A'),
                address: formatAsAddress(customerDataFromBulk.location, true) || 'N/A',
                place_id: _.get(customerDataFromBulk, 'location.place_id'),
                postal_code: _.get(customerDataFromBulk, 'location.postalCode'),
                phone: formatAsPhoneNumber(customerDataFromBulk.phone),
                email: customerDataFromBulk.email
            };

            let startDate = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);
            let endDate = moment(new Date(2018, 0, 1)).tz(process.env.REACT_APP_REGION_TIMEZONE);

            customer.bulks.forEach(bulk => {
                // Get dates for first and last bulk for receipt
                const dateRedeemed = getDonationDateForBulk(bulk) || new Date();
                const momentRedeemed = moment(dateRedeemed);

                if (moment(momentRedeemed).isBefore(startDate)) {
                    startDate = momentRedeemed;
                }
                if (moment(momentRedeemed).isAfter(endDate)) {
                    endDate = momentRedeemed;
                }
            });

            let receiptYear = endDate.year();
            if (
                charityEndOfTaxYear &&
                moment(charityEndOfTaxYear)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .set('year', receiptYear)
                    .isBefore(endDate)
            ) {
                receiptYear++;
            }

            const rowData = {
                ...customerData,
                donations: customer.bulks.length,
                amount: formatAsCurrency(customer.totalValue),
                cutOffMomentObject: moment(endDate),
                firstDonation: startDate.toISOString(),
                lastDonation: endDate.toISOString(),
                receiptYear
            };

            rows.push(rowData);
        }
    }

    return { rows };
}

export function generateDeferredTableData(taxData, charity, minTaxReceiptAmount, lang) {
    const rows = [];

    const charityEndOfTaxYear = _.get(charity, 'taxReceiptsEndOfYear');

    for (const year of taxData.keys()) {
        const yearData = taxData.get(year);

        const customerTaxMap = yearData.customerTaxMap;

        for (const customerId of customerTaxMap.keys()) {
            const customer = customerTaxMap.get(customerId);

            if (customer.totalValue >= minTaxReceiptAmount || customer.bulks.length <= 0) {
                continue;
            }

            const customerDataFromBulk = customer.bulks[0].owner;

            const customerData = {
                _id: customerDataFromBulk._id,
                name: getCustomerName(customerDataFromBulk),
                registeredBusinessName: _.get(customerDataFromBulk, 'registeredBusinessName', 'N/A'),
                address: formatAsAddress(customerDataFromBulk.location, true) || 'N/A',
                place_id: _.get(customerDataFromBulk, 'location.place_id'),
                postal_code: _.get(customerDataFromBulk, 'location.postalCode'),
                phone: formatAsPhoneNumber(customerDataFromBulk.phone),
                email: customerDataFromBulk.email
            };

            let startDate = moment().tz(process.env.REACT_APP_REGION_TIMEZONE);
            let endDate = moment(new Date(2018, 0, 1)).tz(process.env.REACT_APP_REGION_TIMEZONE);

            customer.bulks.forEach(bulk => {
                // Get dates for first and last bulk for receipt
                const dateRedeemed = getDonationDateForBulk(bulk) || new Date();
                const momentRedeemed = moment(dateRedeemed);

                if (moment(momentRedeemed).isBefore(startDate)) {
                    startDate = momentRedeemed;
                }
                if (moment(momentRedeemed).isAfter(endDate)) {
                    endDate = momentRedeemed;
                }
            });

            let receiptYear = endDate.year();
            if (
                charityEndOfTaxYear &&
                moment(charityEndOfTaxYear)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .set('year', receiptYear)
                    .isBefore(endDate)
            ) {
                receiptYear++;
            }

            const rowData = {
                ...customerData,
                donations: customer.bulks.length,
                amount: formatAsCurrency(customer.totalValue),
                cutOffMomentObject: moment(endDate),
                firstDonation: startDate.toISOString(),
                lastDonation: endDate.toISOString(),
                receiptYear,
                isDeferred: true
            };

            rows.push(rowData);
        }
    }

    return { rows };
}

function getDonationDateForBulk(bulk) {
    const dateManuallyDonated = _.get(bulk, 'dateManuallyDonated');
    const dateCompleted = _.get(bulk, 'dateCompleted');

    return dateManuallyDonated || dateCompleted;
}

function getLedgerTotal(ledger) {
    let sum = 0;

    ledger.forEach(line => {
        sum += _.get(line, 'amount', 0);
    });

    return Math.abs(sum);
}
