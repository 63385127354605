import React from 'react';

import _ from 'lodash';

import * as terms from 'localizations/terms';

import * as colors from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import { getQuickDropName, isMXDRegion } from 'utils/misc';

// TODO this should all be changed with localizations
function generateHelperTextArray({ data, theme, lang }) {
    let quickDropType = getQuickDropName(lang);

    return [
        {
            topic: 'location',
            title: loc('pickupDialog8', lang),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialog9', lang, {
                        brand: process.env.REACT_APP_BRAND_NAME
                    })}
                </Typography>
            )
        },
        {
            topic: 'locationUnchangeable',
            title: 'Location',
            body: <Typography variant="body1">{loc('helperText1', lang)}</Typography>
        },
        {
            topic: 'unitNumber',
            title: loc('pickupDialog7', lang),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialog10', lang, { condoName: terms.CONDO_NAME.toLowerCase() })}
                </Typography>
            )
        },
        {
            topic: 'buzzerCode',
            title: loc('pickupDialogSelect3', lang),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialogSelect4', lang, { name: terms.CONDO_NAME.toLowerCase() })}
                </Typography>
            )
        },
        {
            topic: 'date',
            title: 'Date',
            body: <Typography variant="body1">{loc('helperText2', lang)}</Typography>
        },
        {
            topic: 'recurring',
            title: 'Recurring pickups',
            body: <Typography variant="body1">{loc('helperText3', lang)}</Typography>
        },
        {
            topic: 'clothingBags',
            title: 'Clothing',
            body: generateRows('clothing', theme, lang)
        },
        {
            topic: 'recyclingBags',
            title: 'Beverage containers',
            body: (
                <>
                    <Typography variant="body1" style={{ marginBottom: theme.spacing.unit * 2 }}>
                        {loc('helperText4', lang)}
                    </Typography>
                    <>{generateRows('recycling', theme, lang)}</>
                </>
            )
        },
        // {
        //     topic: 'recyclingBags',
        //     title: 'Beverage containers',
        //     body: generateRows('recycling', theme)
        // },
        {
            topic: 'electronicsItems',
            title: 'Electronics',
            body: generateRows('electronics', theme, lang)
        },
        {
            topic: 'clothing-disabled',
            title: 'Clothing donations',
            body: <Typography variant="body1">{loc('helperText5', lang)}</Typography>
        },
        {
            topic: 'comment',
            title: loc('specialInstructions', lang),
            body: <Typography variant="body1">{loc('pickupDialog43', lang)}</Typography>
        },
        {
            topic: 'residential',
            title: loc('appServiceType2', lang),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialog3', lang)}{' '}
                    <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                        {data.uniqueID}
                    </strong>
                </Typography>
            )
        },
        {
            topic: 'condo',
            title: loc('pickupDialogSelect1', lang, { name: terms.CONDO_NAME }),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialogSelect2', lang, { name: terms.CONDO_NAME })}
                    <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                        {data.uniqueID}
                    </strong>
                </Typography>
            )
        },
        {
            topic: 'commercial',
            title: loc('pickupDialogSelect9', lang),
            body: (
                <Typography variant="body1">
                    {loc('pickupDialogSelect7', lang)}{' '}
                    {!isMXDRegion() && (
                        <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                            {data.uniqueID}
                        </strong>
                    )}
                </Typography>
            )
        },
        {
            topic: 'dropandgo',
            title: quickDropType,
            body: (
                <Typography variant="body1">
                    {loc('pickupDialogDrop2', lang, { type: quickDropType })}{' '}
                    {!isMXDRegion() && (
                        <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                            {data.uniqueID}
                        </strong>
                    )}
                </Typography>
            )
        },
        {
            topic: 'walkin',
            title: loc('rvm', lang),
            body: <Typography variant="body1">{loc('pickupDialogDrop48', lang)} </Typography>
        },
        {
            topic: 'bottleDrive',
            title: loc('pickupDialogBottleDrive1', lang),
            body: <Typography variant="body1">{loc('pickupDialogBottleDrive2', lang)}</Typography>
        },
        {
            topic: 'businessName',
            title: loc('pickupDialogBottleDrive8', lang),
            body: <Typography variant="body1">{loc('pickupDialogBottleDrive9', lang)}</Typography>
        },
        {
            topic: 'organizationName',
            title: `${terms.ORGANIZATION_NAME} name`,
            body: (
                <Typography variant="body1">
                    {loc('helperText6', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                </Typography>
            )
        },
        {
            topic: 'fundraising',
            title: 'Fundraising',
            body: (
                <Typography variant="body1">
                    {loc('helperText7', lang, {
                        organization: terms.ORGANIZATION_NAME.toLowerCase(),
                        brand: process.env.REACT_APP_BRAND_NAME
                    })}
                </Typography>
            )
        },
        {
            topic: 'multiLocationPickups',
            title: loc('managePickups6', lang),
            body: (
                <Typography variant="body1">
                    {loc('managePickups7a', lang)}{' '}
                    <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                        {loc('managePickups7email', lang)}
                    </a>{' '}
                    {loc('managePickups7b', lang)}
                </Typography>
            )
        },
        {
            topic: 'accountTypeRestricted',
            title: loc('accountType', lang),
            body: <Typography variant="body1">{loc('accountSettings31', lang)}</Typography>
        }
    ];
}

export default generateHelperTextArray;

function generateRows(rowsFilePath, theme, lang) {
    const rows = require('./AllowableItems/' +
        rowsFilePath +
        '-rows-' +
        process.env.REACT_APP_REGION_EXT.toLowerCase() +
        '.json');
    return (
        <div>
            <span style={{ display: 'inline-flex', marginBottom: theme.spacing.unit }}>
                <Typography variant="caption">{loc('accepted', lang)}</Typography>
                <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 4 }}>
                    {loc('description', lang)}
                </Typography>
            </span>
            <List>
                {rows.map((row, index) => (
                    <span key={row.desc}>
                        <Divider />
                        <ListItem style={{ paddingLeft: 0 }}>
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                    {row.accepted ? (
                                        <Icon style={{ color: colors.green[500] }}>check</Icon>
                                    ) : (
                                        <Icon style={{ color: colors.red[500] }}>close</Icon>
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                style={{ paddingLeft: theme.spacing.unit * 6 }}
                                primary={row.desc}
                                secondary={_.get(row, 'secondary', '')}
                            />
                        </ListItem>
                    </span>
                ))}
            </List>
        </div>
    );
}
