import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Device } from '@capacitor/device';

import { Typography, Button, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import googleLogoWhite from 'icons/g-logo-white.png';
import googleLogoEXP from 'icons/g-logo-exp.png';
import googleLogoSTD from 'icons/g-logo-std.png';
import googleLogoAUS from 'icons/g-logo-aus.png';
import googleLogoCON from 'icons/g-logo-con.png';
import googleLogoSAR from 'icons/g-logo-rec.png';
import appleLogoWhite from 'icons/a-logo-white.svg';
import appleLogoEXP from 'icons/a-logo-exp.svg';
import appleLogoSTD from 'icons/a-logo-std.svg';
import appleLogoAUS from 'icons/a-logo-aus.svg';
import appleLogoCON from 'icons/a-logo-con.svg';
import appleLogoSAR from 'icons/a-logo-rec.svg';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

function AlternativeRegistrations({
    theme,
    registerFacebook,
    registerGoogle,
    registerApple,
    registerExpress,
    style,
    signUp
}) {
    const { lang } = useContext(LocalizationContext);

    const [showApple, setShowApple] = useState(true);

    useEffect(() => {
        const getIsAndroid = async () => {
            const deviceInfo = await Device.getInfo();
            if (deviceInfo.operatingSystem === 'android') {
                setShowApple(false);
            }
        };

        getIsAndroid();
    }, []);

    const isDark = theme.palette.type === 'dark';
    const buttonStyle = {
        height: 50,
        borderRadius: 15,
        marginTop: theme.spacing.unit
    };

    const iconStyle = {
        height: '24px',
        width: '24px',
        margin: theme.spacing.unit / 3
    };

    const textStyle = {
        marginLeft: theme.spacing.unit,
        color: theme.palette.primary.main,
        textTransform: 'none'
    };

    let appleLogo = appleLogoWhite;
    let googleLogo = googleLogoWhite;
    switch (process.env.REACT_APP_REGION_EXT_DEMO || process.env.REACT_APP_REGION_EXT) {
        case 'AUS':
            appleLogo = appleLogoAUS;
            googleLogo = googleLogoAUS;
            break;
        case 'STD':
            appleLogo = appleLogoSTD;
            googleLogo = googleLogoSTD;
            break;
        case 'EXP':
            appleLogo = appleLogoEXP;
            googleLogo = googleLogoEXP;
            break;
        case 'CON':
            appleLogo = appleLogoCON;
            googleLogo = googleLogoCON;
            break;
        case 'SAR':
            appleLogo = appleLogoSAR;
            googleLogo = googleLogoSAR;
            break;
        default:
            appleLogo = appleLogoWhite;
            googleLogo = googleLogoWhite;
            break;
    }
    return (
        <div style={style}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around'
                }}
                data-cy="alternative-registration-buttons"
            >
                {/* {process.env.REACT_APP_REGION_EXT === 'EXP' && (
                    <Button
                        style={{ ...buttonStyle, backgroundColor: isDark ? theme.palette.background.paper : '#faa31d' }}
                        onClick={registerExpress}
                    >
                        <img style={{ width: '24px', height: '24px' }} src={expressIcon} alt="Express Logo" />
                        <Typography variant="button" style={textStyle}>
                            {signUp && loc('registrationOAuth9', lang, { platform: 'EXPRESS' })}
                            {!signUp && loc('registrationOAuth10', lang, { platform: 'EXPRESS' })}
                        </Typography>
                    </Button>
                )} */}
                {/* <Button
                    style={{ ...buttonStyle, backgroundColor: isDark ? theme.palette.background.paper : '#4487EF' }}
                    onClick={registerGoogle}
                >
                    <img src={googleLogoWhite} alt="F Logo" style={iconStyle} />
                    <Typography variant="button" style={textStyle}>
                        {signUp && loc('registrationOAuth7', lang, { platform: 'Google' })}
                        {!signUp && loc('registrationOAuth8', lang, { platform: 'Google' })}
                    </Typography>
                </Button>

                {showApple && (
                    <Button
                        style={{
                            ...buttonStyle,
                            backgroundColor: isDark ? theme.palette.background.paper : theme.palette.text.primary
                        }}
                        onClick={registerApple}
                    >
                        <img src={appleLogoWhite} alt="F Logo" style={iconStyle} />
                        <Typography variant="button" style={textStyle}>
                            {signUp && loc('registrationOAuth7', lang, { platform: 'Apple' })}
                            {!signUp && loc('registrationOAuth8', lang, { platform: 'Apple' })}
                        </Typography>
                    </Button>
                )} */}

                <Button style={{ ...buttonStyle }} onClick={registerGoogle} variant="outlined" color="primary">
                    <img src={googleLogo} alt="F Logo" style={iconStyle} />
                    <Typography variant="button" style={textStyle}>
                        {signUp && loc('registrationOAuth7', lang, { platform: 'Google' })}
                        {!signUp && loc('registrationOAuth8', lang, { platform: 'Google' })}
                    </Typography>
                </Button>

                {showApple && (
                    <Button
                        style={{
                            ...buttonStyle
                        }}
                        onClick={registerApple}
                        variant="outlined"
                        color="primary"
                    >
                        <img src={appleLogo} alt="F Logo" style={iconStyle} />
                        <Typography variant="button" style={textStyle}>
                            {signUp && loc('registrationOAuth7', lang, { platform: 'Apple' })}
                            {!signUp && loc('registrationOAuth8', lang, { platform: 'Apple' })}
                        </Typography>
                    </Button>
                )}
            </div>
        </div>
    );
}

AlternativeRegistrations.propTypes = {
    registerFacebook: PropTypes.func,
    registerGoogle: PropTypes.func
};

export default withTheme()(AlternativeRegistrations);
