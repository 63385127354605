import React, { useContext } from 'react';

import { formatAsCurrency } from '../../../utils/misc';

import * as colors from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { LinearProgress, Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Summary = ({
    theme,
    balance,
    redemptionFee,
    handleInfoDialog,
    handleConfirmationDialog,
    handleBack,
    disablingAccount,
    deletingAccount,
    confirmationText,
    handleRedeem,
    loading,
    showTimeFrame,
    timeFrameText
}) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <DialogContent>
                <Typography variant="body2">{confirmationText}</Typography>
                <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ fontSize: theme.typography.fontSize }}>Balance</TableCell>
                            <TableCell align="right" style={{ fontSize: theme.typography.fontSize }}>
                                {formatAsCurrency(balance, lang)}
                            </TableCell>
                        </TableRow>
                        {redemptionFee > 0 && (
                            <TableRow>
                                <TableCell
                                    style={{
                                        fontSize: theme.typography.fontSize,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {loc('redemption26', lang)}{' '}
                                    <IconButton onClick={() => handleInfoDialog(true)}>
                                        <Icon>info</Icon>
                                    </IconButton>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    style={{
                                        color: colors.red[500],
                                        fontSize: theme.typography.fontSize
                                    }}
                                >
                                    {formatAsCurrency(-redemptionFee, lang)}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow style={{ backgroundColor: theme.palette.background.default }}>
                            <TableCell style={{ fontSize: theme.typography.fontSize }}>
                                {loc('redemption27', lang)}
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: theme.typography.fontSize }}>
                                {formatAsCurrency(balance - redemptionFee, lang)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {balance - redemptionFee < 0 ? (
                    <Typography style={{ marginTop: 10, flex: 1, textAlign: 'center', color: colors.red[500] }}>
                        {loc('redemption53', lang)}
                    </Typography>
                ) : null}
                {showTimeFrame && (
                    <Typography style={{ marginTop: 10, flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
                        {timeFrameText}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={handleBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="redemption-dialog-redeem"
                    color="primary"
                    onClick={handleRedeem}
                    disabled={balance - redemptionFee < 0 || loading}
                >
                    {disablingAccount
                        ? loc('cashOutAndDisable', lang)
                        : deletingAccount
                        ? loc('cashOutAndDelete', lang)
                        : loc('cashOut', lang)}
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </>
    );
};

export default Summary;
