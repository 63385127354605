import React, { useState } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { convertToImportName } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody
} from '@material-ui/core';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';
import { _commodity } from 'std';

function RemovePickupWarningDialog(props) {
    const {
        open,
        theme,
        pickups,
        checkedPickups,
        handleCheckbox,
        handleAllCheckbox,
        onSubmit,
        onClose,
        allTrackedSubCommodities,
        allTrackedCommodities,
        formatDate,
        customer,
        fees
    } = props;
    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
            <DialogContent>
                <Typography variant="h6">
                    Mark {checkedPickups.length > 0 && `${checkedPickups.length} `}Pickups as Resolved
                </Typography>
                <Table size="small" padding="dense">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={checkedPickups.length > 0 && checkedPickups.length === pickups.length}
                                    onChange={handleAllCheckbox}
                                    data-cy={`clear-pickup-warning-select-all-checkbox`}
                                />
                            </TableCell>
                            {['Date', 'Address', 'Assets', 'Subscriptions'].map((label, i) => {
                                return (
                                    <TableCell key={i} padding={'default'}>
                                        {label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pickups.map(p => {
                            const subscriptions = {};
                            if (!_.isNil(_.get(customer, 'recurringFees'))) {
                                _.get(customer, 'recurringFees', [])
                                    .filter(
                                        fee =>
                                            fee.address === _.get(p, 'location.description', '') ||
                                            _.isNil(fee.address) ||
                                            _.isEmpty(fee.address)
                                    ) //also filter by commodity subscriptions?
                                    .forEach(fee => {
                                        _.set(
                                            subscriptions,
                                            _.get(fee, 'fee', 'Fee').trim(),
                                            _.get(subscriptions, _.get(fee, 'fee', 'Fee').trim(), 0) + 1
                                        );
                                    });
                            }
                            const reducedPayload = _.cloneDeep(p.payload);
                            if (!_.isNil(reducedPayload)) {
                                _commodity.removeSubPayloadsFromPayload(reducedPayload, _.get(p, 'subPayloads', []));
                            }

                            return (
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={checkedPickups.includes(p._id)}
                                            onChange={handleCheckbox(p._id)}
                                            data-cy={`clear-pickup-warning-select-${p._id}-checkbox`}
                                        />
                                    </TableCell>
                                    <TableCell padding="checkbox">{formatDate(p.date, timezone)}</TableCell>
                                    <TableCell padding="checkbox">{_.get(p, 'location.description', 'N/A')}</TableCell>
                                    <TableCell padding="checkbox">
                                        {_.keys(reducedPayload || {}).map(sku => {
                                            const commodity = _.find(
                                                allTrackedCommodities,
                                                commodity => commodity.skuType.toString() === sku.toString()
                                            );
                                            if (_.isNil(commodity) || reducedPayload[sku] === 0) {
                                                return <></>;
                                            }
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(
                                                                allIcons,
                                                                _.startsWith(commodity.iconName, 'mdi')
                                                                    ? commodity.iconName
                                                                    : convertToImportName(commodity.iconName)
                                                            )}
                                                            size={1}
                                                            color={commodity.color}
                                                        />
                                                    </span>
                                                    {_.get(commodity, 'payloadInputName.en', 'N/A')} x{' '}
                                                    {reducedPayload[sku]}
                                                </div>
                                            );
                                        })}
                                        {(_.get(p, 'subPayloads', []) || []).map(item => {
                                            const subCommodity = _.find(
                                                allTrackedSubCommodities,
                                                subCommodity => subCommodity._id === item.subCommodity
                                            );
                                            if (_.isNil(subCommodity) || item.amount === 0) {
                                                return <></>;
                                            }
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(
                                                                allIcons,
                                                                _.startsWith(subCommodity.iconName, 'mdi')
                                                                    ? subCommodity.iconName
                                                                    : convertToImportName(subCommodity.iconName)
                                                            )}
                                                            size={1}
                                                            color={subCommodity.color}
                                                        />
                                                    </span>
                                                    {_.get(subCommodity, 'name.en', 'N/A')} x {item.amount}
                                                </div>
                                            );
                                        })}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        {_.keys(subscriptions).map(fee_id => (
                                            <div style={{ display: 'flex', verticalAlign: 'center' }}>
                                                {_.get(
                                                    _.find(fees, f => f._id.toString() === fee_id.toString()),
                                                    'description',
                                                    'Fee'
                                                )}
                                                {subscriptions[fee_id] > 1 ? ` x ${subscriptions[fee_id]}` : ''}
                                            </div>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onClose}>
                    Nevermind
                </Button>
                <Button
                    color="primary"
                    size="small"
                    disabled={checkedPickups.length === 0}
                    onClick={onSubmit}
                    data-cy="clear-pickup-warnings-dialog-confirm"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(RemovePickupWarningDialog);
