import React from 'react';

const ExpIcon = ({ theme, width, height }) => {
    const fillColor = typeof theme.palette.linkColor === 'object' ? 'rgba(0, 0, 0, 0.54)' : theme.palette.linkColor;
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38.6 26.53"
            version="1.1"
            width={width}
            height={height}
            style={{}}
        >
            <defs />
            <g>
                <polygon
                    fill={fillColor}
                    points="12.8 13.26 2.98 13.26 2.41 10.43 30.64 10.43 29.46 16.1 23.12 16.1 22.7 18.07 15.75 18.07 16.17 16.1 12.2 16.1 12.8 13.26"
                />
                <path
                    fill={fillColor}
                    d="M22.93,18.36h-7.53l.42-1.97h-3.97l.6-2.84H2.75l-.69-3.4h28.93l-1.3,6.24h-6.35l-.42,1.97ZM16.1,17.79h6.37l.42-1.97h6.34l1.06-5.11H2.76l.46,2.27h9.93l-.6,2.84h3.97l-.42,1.97Z"
                />
            </g>
            <g>
                <polygon
                    fill={fillColor}
                    points="4.46 20.57 5.03 23.41 10.66 23.41 10.06 26.25 33.66 26.25 34.87 20.57 4.46 20.57"
                />
                <path
                    fill={fillColor}
                    d="M33.89,26.53H9.71l.6-2.84h-5.51l-.69-3.4h31.11l-1.33,6.24ZM10.41,25.96h23.02l1.09-5.11H4.8l.46,2.27h5.75l-.6,2.84Z"
                />
            </g>
            <g>
                <polygon
                    fill={fillColor}
                    points=".35 .28 .92 3.12 14.96 3.12 14.36 5.96 18.32 5.96 17.9 7.93 24.85 7.93 25.27 5.96 37.07 5.96 38.25 .28 .35 .28"
                />
                <path
                    fill={fillColor}
                    d="M25.08,8.21h-7.53l.42-1.97h-3.96l.6-2.84H.69L0,0h38.6l-1.3,6.24h-11.81l-.42,1.97ZM18.25,7.65h6.37l.42-1.97h11.8l1.06-5.11H.69l.46,2.27h14.16l-.6,2.84h3.96l-.42,1.97Z"
                />
            </g>
        </svg>
    );
};

export default ExpIcon;
