import React, { memo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk, _user, _commodity } from 'std';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { withTheme } from '@material-ui/core/styles';

const SearchLabelCard = memo(({ data, index, style }) => {
    const { theme, items } = data;
    const labelInfo = items[index];
    let infoText = _.get(labelInfo, 'label', '');
    const dateText = moment(_.get(labelInfo, 'printedDate')).format('MMM DD h:mm a');
    const locationText = _.get(labelInfo, 'location', '');
    if (dateText !== 'Invalid date') {
        infoText += ` - ${dateText}`;
    }
    if (locationText !== '') {
        infoText += ` | ${locationText}`;
    }

    return (
        <div style={{ ...style, padding: '3px' }}>
            <Card
                data-cy={`labelcard-${labelInfo.label}`}
                elevation={0}
                style={{
                    margin: theme.spacing.unit,
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    maxHeight: '100%'
                }}
            >
                <Typography
                    variant="caption"
                    style={{
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        padding: 5
                    }}
                    noWrap
                >
                    {infoText}
                </Typography>
            </Card>
        </div>
    );
});

export default withTheme()(SearchLabelCard);
