import React, { useContext } from 'react';
import _ from 'lodash';
import QRCode from 'qrcode.react';

import { Typography, Paper, Icon, colors, withTheme, Button, IconButton, Tooltip, Grid } from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as terms from 'localizations/terms';

import CustomWrapper from '../../containers/Operators/BulkCounter/CustomWrapper';
import CustomFormTitle from '../MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useState } from 'react';

const imageFileTypes = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif'
];

function IntroTaskDisplay(props) {
    const {
        countComplete,
        countCompletable,
        minNumberTasksToBeFeatured,
        currentTask,
        showPromoText,
        theme,
        url,
        highResImage,
        handleImageDialog,
        tab,
        setTab
    } = props;

    const { lang } = useContext(LocalizationContext);

    let progressPaperArray = [];
    const [imageError, setImageError] = useState(false);

    for (let i = 0; i < Math.min(countComplete, minNumberTasksToBeFeatured); i++) {
        progressPaperArray.push(
            <Paper
                key={i}
                style={{
                    height: 30,
                    flexGrow: 1,
                    margin: theme.spacing.unit,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: colors.green[100]
                }}
            >
                <Icon style={{ color: colors.green[600] }}>
                    {i === minNumberTasksToBeFeatured - 1 ? 'star' : 'check_circle'}
                </Icon>
            </Paper>
        );
    }

    for (let i = 0; i < minNumberTasksToBeFeatured - countComplete; i++) {
        progressPaperArray.push(
            <Paper
                key={i + countComplete}
                style={{
                    height: 30,
                    flexGrow: 1,
                    margin: theme.spacing.unit,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: colors.grey[100]
                }}
            >
                <Icon style={{ color: colors.grey[600] }}>
                    {i === minNumberTasksToBeFeatured - countComplete - 1 ? 'star' : 'check_circle'}
                </Icon>
            </Paper>
        );
    }

    const handleDownloadQRCode = () => {
        const canvas = document.getElementById('charity-qr-code');
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'CharityQRCode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownloadImage = async filename => {
        let res = await props.http.download(`/system/downloadImage/${filename}`, filename);
    };

    return (
        <div style={{ margin: theme.spacing.unit }}>
            <Typography variant="h4" align="center" style={{ margin: theme.spacing.unit * 4 }}>
                {currentTask[lang].title}
            </Typography>
            {tab === 'main' && (
                <>
                    <Grid container spacing={theme.spacing.unit} style={{ marginBottom: theme.spacing.unit }}>
                        <Grid item xs="12" sm="4">
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                data-cy="intro-task-display-how-it-works-tab"
                                onClick={() => setTab('howItWorks')}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>quiz</Icon> How It Works
                            </Button>
                        </Grid>
                        <Grid item xs="12" sm="4">
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                data-cy="intro-task-display-share-qr-tab"
                                onClick={() => setTab('shareQR')}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>qr_code</Icon> Share QR
                            </Button>
                        </Grid>
                        <Grid item xs="12" sm="4">
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                data-cy="intro-task-display-logo-tab"
                                onClick={() => setTab('logo')}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>image</Icon> Logo
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: theme.spacing.unit }}>
                        <CustomFormTitle
                            titleText={loc('dashboardGrowthInfo5', lang, {
                                countComplete,
                                countCompletable
                            })}
                            iconName="check_circle"
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>{progressPaperArray}</div>
                </>
            )}

            {tab === 'howItWorks' && (
                <>
                    <Typography variant="body1">
                        {loc('dashboardGrowthInfo4', lang, { countCompletable })}
                        {/* {process.env.REACT_APP_REGION_EXT === 'STD' && (
                    <>Ranging from things like adding your donation link to your website to ordering custom bags!</>
                )}{' '} */}
                    </Typography>
                    <div style={{ marginTop: theme.spacing.unit * 2 }}>
                        <CustomWrapper
                            title={loc('dashboardGrowthInfo6', lang, { organization: terms.ORGANIZATION_NAME })}
                        >
                            <div style={{ padding: theme.spacing.unit }}>
                                <Typography variant="body1">
                                    {loc('dashboardGrowthInfo7a', lang, {
                                        organization: terms.ORGANIZATION_NAME,
                                        brand: process.env.REACT_APP_BRAND_NAME
                                    })}
                                    {showPromoText &&
                                        <b>
                                            {' '}
                                            {loc('dashboardGrowthInfo7b', lang, {
                                                organization: terms.ORGANIZATION_NAME
                                            })}
                                        </b>
                                    }
                                </Typography>
                            </div>
                        </CustomWrapper>
                    </div>
                </>
            )}
            {tab === 'shareQR' && (
                <CustomWrapper
                    title={loc('dashboardGrowthInfo8', lang)}
                    outerDivStyle={{ minWidth: '280px', height: '200px' }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <QRCode id="charity-qr-code" value={`${process.env.REACT_APP_ORIGIN_URL}/${url}`} />
                        <div style={{ position: 'absolute', bottom: 0, right: '10px' }}>
                            <Tooltip title={loc('save', lang)}>
                                <IconButton size="small" onClick={handleDownloadQRCode}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </CustomWrapper>
            )}
            {tab === 'logo' && (
                <CustomWrapper
                    title={loc('dashboardGrowthInfo9', lang)}
                    outerDivStyle={{ minWidth: '280px', height: '200px' }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {highResImage ? (
                            <>
                                {!imageError && imageFileTypes.includes(highResImage.split('.').pop()) ? (
                                    <img
                                        src={highResImage}
                                        alt="charity-high-resolution-logo"
                                        onError={() => setImageError(true)}
                                        style={{
                                            maxWidth: '128px',
                                            maxHeight: '128px'
                                        }}
                                    />
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                            check_circle
                                        </Icon>
                                        <Typography
                                            style={{
                                                color: colors.green[500],
                                                margin: theme.spacing.unit * 2,
                                                textAlign: 'center'
                                            }}
                                            variant="subtitle2"
                                        >
                                            {loc('dashboardGrowthInfo12', lang)}
                                            <br />( {loc('dashboardGrowthInfo13', lang)})
                                        </Typography>
                                    </div>
                                )}
                                <div style={{ position: 'absolute', bottom: 0, right: '10px' }}>
                                    <Tooltip title={loc('save', lang)}>
                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                e.preventDefault();
                                                handleDownloadImage(highResImage.split('/').pop());
                                            }}
                                        >
                                            <SaveAltIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={loc('tooltip1', lang)}>
                                        <IconButton
                                            size="small"
                                            onClick={handleImageDialog}
                                            data-cy={'intro-task-display-high-res-image'}
                                        >
                                            <PublishIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                        ) : (
                            <Button
                                data-cy={'intro-task-display-high-res-image'}
                                onClick={handleImageDialog}
                                variant="contained"
                                color={'secondary'}
                            >
                                {loc('dashboardGrowthInfo10', lang)}
                            </Button>
                        )}
                    </div>
                </CustomWrapper>
            )}
        </div>
    );
}

export default withTheme()(IntroTaskDisplay);
