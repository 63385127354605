import React, { useContext } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import {
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    withTheme,
    Collapse,
    MenuItem,
    Typography,
    TextField
} from '@material-ui/core';

import { getTextInput, getSelect, getSwitch } from '../../helperFunctions';
import { _commodity } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ServiceFeesForm(props) {
    const { open, loading, onClose, serviceFee, taxGroups, commodities, rates, onSubmit, theme } = props;
    const skuTypes = commodities.map(commodity => _commodity.getSkuType(commodity));

    const { lang } = useContext(LocalizationContext);

    const formik = useFormik({
        initialValues: {
            _id: _.get(serviceFee, '_id', null),
            disabled: _.get(serviceFee, 'disabled', false),
            default: _.get(serviceFee, 'default', false),
            type: _.get(serviceFee, 'type', 'Percent Fee'),
            description: _.get(serviceFee, 'description', ''),
            value: !_.isNil(_.get(serviceFee, 'value')) ? serviceFee.value * 100 : '',
            sku: _.get(serviceFee, 'sku', 0),
            split: {
                customer: _.get(serviceFee, 'split.customer', 0),
                charity: _.get(serviceFee, 'split.charity', 0),
                collector: _.get(serviceFee, 'split.collector', 0),
                processor: _.get(serviceFee, 'split.processor', 0)
                //driver: _.get(serviceFee, 'split.driver', 0)
            },
            skuType: _.get(serviceFee, 'skuType', skuTypes[0]),
            tax: _.get(serviceFee, 'tax')
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            default: Yup.boolean(),
            description: Yup.string().required('You must enter a value'),
            value: Yup.number().when('type', {
                is: value => value === 'Percent Fee',
                then: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be a positive number')
                    .max(100, 'Must be not be more than 100')
            }),
            sku: Yup.number().when('type', {
                is: value => value === 'Count Split',
                then: Yup.number().required('You must enter a value')
            }),
            split: Yup.object({
                customer: Yup.number().when('type', {
                    is: value => value === 'Count Split',
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be a positive number')
                }),
                charity: Yup.number().when('type', {
                    is: value => value === 'Count Split',
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be a positive number')
                }),
                collector: Yup.number().when('type', {
                    is: value => value === 'Count Split',
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be a positive number')
                }),
                processor: Yup.number().when('type', {
                    is: value => value === 'Count Split',
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be a positive number')
                })
                /*driver: Yup.number().when('type', {
                    is: value => value === 'Count Split',
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be a positive number')
                })*/
            }),
            skuType: Yup.string().oneOf(skuTypes),
            tax: Yup.string()
                .nullable()
                .required('You must select a tax group')
        }),
        onSubmit: values => {
            let valuesCopy = _.cloneDeep(values);
            if (valuesCopy.type === 'Percent Fee') {
                valuesCopy.value /= 100;
                delete valuesCopy.split;
            } else {
                delete valuesCopy.value;
            }
            onSubmit(valuesCopy);
        }
    });

    const ratesForCommodity = _.filter(_.get(rates, 'rates', []), { skuType: formik.values.skuType });
    return (
        <Dialog open={open}>
            <DialogContent>
                {getSwitch(theme, 'disabled', 'Disabled', formik)}
                {getSwitch(theme, 'default', 'Default', formik)}
                {getSelect(theme, 'skuType', 'Commodity', skuTypes, formik)}
                {getTextInput(theme, 'description', 'Description', formik)}
                {getSelect(theme, 'type', 'Type', ['Percent Fee', 'Count Split'], formik)}
                <Collapse in={_.get(formik, 'values.type') === 'Percent Fee'}>
                    {/*'Percentage taken from customer deposits for customers and donations. Collectors are paid an even split with a 5% full refund promo fee if applicable. Drivers are paid standard wage/commission'*/}
                    {getTextInput(theme, 'value', 'Percent Fee', formik, 'number', {}, {})}
                </Collapse>
                <Collapse in={_.get(formik, 'values.type') === 'Count Split'}>
                    <TextField
                        {...formik.getFieldProps('sku')}
                        label="Sku"
                        variant="outlined"
                        fullWidth
                        error={_.get(formik.touched, 'sku', false) && _.get(formik.errors, 'sku', false) ? true : null}
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        select
                    >
                        {ratesForCommodity.map(rate => {
                            return (
                                <MenuItem value={_.get(rate, 'sku')} key={_.get(rate, 'sku')}>
                                    <Typography inline>
                                        {rate.description} {rate.label && `(${rate.label})`} -
                                    </Typography>{' '}
                                    <Typography inline color="primary">
                                        {-rate.value}¢
                                    </Typography>{' '}
                                    <Typography color="textSecondary" inline>
                                        {rate.sku}
                                    </Typography>
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    {getTextInput(theme, 'split.customer', 'Customer Split (¢)', formik, 'number')}
                    {getTextInput(theme, 'split.charity', 'Charity Donation Split (¢)', formik, 'number')}
                    {getTextInput(theme, 'split.collector', 'Transporter Split (¢)', formik, 'number')}
                    {getTextInput(theme, 'split.processor', 'Processor Split (¢)', formik, 'number')}
                    {/*getTextInput(theme, 'split.driver', 'Driver Split (¢)', formik, 'number')*/}
                </Collapse>

                {getSelect(theme, 'tax', 'Tax Group', taxGroups, formik, null, '_id', 'name')}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading} onClick={formik.handleSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ServiceFeesForm);
