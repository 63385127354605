import React, { useState } from 'react';
import _ from 'lodash';

import { MenuItem, TextField, colors, withMobileDialog, withTheme } from '@material-ui/core';

import { _bulk, _commodity, _user } from 'std';

import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';

function EditAdjustmentReasonDialog({
    theme,
    fullScreen,
    open,
    bulk,
    onClose,
    adjustmentConfig,
    handleUpdateAdjustmentReason,
    addEmptySession
}) {
    const [adjustmentReason, setAdjustmentReason] = useState(_.get(bulk, 'adjustmentReason', ''));
    const adjustmentReasons = _.get(adjustmentConfig, 'reasons', []);

    return (
        <Dialog open={open} maxWidth="sm" fullScreen={fullScreen} fullWidth>
            <DialogTitle style={{ paddingBottom: 8 }}>
                {_bulk.isAdjustment(bulk) && (
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        value={adjustmentReason}
                        onChange={e => setAdjustmentReason(e.target.value)}
                        label="Adjustment Reason"
                        style={{ marginBottom: theme.spacing.unit * 2 }}
                        data-cy="bulk-counter-adjustment-reason-select"
                    >
                        {adjustmentReasons.map((reason, idx) => (
                            <MenuItem data-cy={`bulk-counter-adjustment-reason-select-${idx}`} value={reason}>
                                {reason}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </DialogTitle>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    data-cy="bulk-counter-bags-submit"
                    color="primary"
                    onClick={async () => {
                        if (_bulk.isAdjustment(bulk)) {
                            handleUpdateAdjustmentReason(adjustmentReason);
                        }
                        if (adjustmentReason === 'Payment Processed') {
                            if (_.get(bulk, 'countingSessions', []).length === 0) await addEmptySession();
                        }
                        onClose();
                    }}
                    disabled={_.isEmpty(adjustmentReason)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EditAdjustmentReasonDialog));
