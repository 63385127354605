import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import InputWithConfirmButtons from 'components/CustomInputs/InputWithConfirmButtons';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withMobileDialog,
    withTheme,
    Icon,
    IconButton,
    TextField,
    Divider,
    Typography,
    FormControlLabel,
    Switch,
    Collapse
} from '@material-ui/core';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { _commodity } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CommodityPickupFieldsDialog(props) {
    const { open, commodity, onClose, onSubmit, theme, fullScreen } = props;

    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const [commodityPickupsEnabled, setCommodityPickupsEnabled] = useState(
        _.get(commodity, 'commodityPickupsEnabled', false)
    );
    const [collectorPickupFields, setCollectorPickupFields] = useState(_.get(commodity, 'collectorPickupFields', []));
    const [intuitCustomerRef, setIntuitCustomerRef] = useState(_.get(commodity, 'intuit.customerRef', ''));
    const [intuitItemRef, setIntuitItemRef] = useState(_.get(commodity, 'intuit.itemRef', ''));

    const [newFieldValues, setNewFieldValues] = useState({});

    const handleNewFieldChanged = e => {
        const newFieldValuesCopy = _.cloneDeep(newFieldValues);
        newFieldValuesCopy[e.target.name] = e.target.value;
        setNewFieldValues(newFieldValuesCopy);
    };

    const handleAddField = () => {
        const collectorPickupFieldsCopy = _.cloneDeep(collectorPickupFields);
        collectorPickupFieldsCopy.push(newFieldValues);
        setCollectorPickupFields(collectorPickupFieldsCopy);
        setNewFieldValues({});
    };

    const handleUpdateDescription = (value, i) => {
        const collectorPickupFieldsCopy = _.cloneDeep(collectorPickupFields);
        collectorPickupFieldsCopy[i].description = value;
        setCollectorPickupFields(collectorPickupFieldsCopy);
    };

    const handleUpdateUnit = (value, i) => {
        const collectorPickupFieldsCopy = _.cloneDeep(collectorPickupFields);
        collectorPickupFieldsCopy[i].unit = value;
        setCollectorPickupFields(collectorPickupFieldsCopy);
    };

    const handleRemoveField = i => {
        const collectorPickupFieldsCopy = _.cloneDeep(collectorPickupFields);
        collectorPickupFieldsCopy.splice(i, 1);
        setCollectorPickupFields(collectorPickupFieldsCopy);
    };

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Commodity Pickup Config</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit }}>
                    <FormControlLabel
                        control={
                            <Switch
                                data-cy="commodity-pickups-enable-toggle"
                                checked={commodityPickupsEnabled}
                                onChange={e => setCommodityPickupsEnabled(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Commodity Pickups Enabled"
                        style={{ marginBottom: theme.spacing.unit }}
                    />

                    <Collapse in={commodityPickupsEnabled}>
                        {(_commodity.isSplitCompleted(commodity) || _commodity.isAcceptUnitsCompleted(commodity)) && (
                            <>
                                <Typography variant="h6" style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    Invoicing
                                </Typography>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        value={intuitCustomerRef}
                                        onChange={e => setIntuitCustomerRef(e.target.value)}
                                        label="Quickbooks Customer Id"
                                        variant="outlined"
                                        style={{
                                            marginBottom: theme.spacing.unit * 2,
                                            marginRight: theme.spacing.unit
                                        }}
                                        fullWidth
                                    />
                                    <TextField
                                        value={intuitItemRef}
                                        onChange={e => setIntuitItemRef(e.target.value)}
                                        label="Quickbooks Product/Service Id"
                                        variant="outlined"
                                        style={{ marginBottom: theme.spacing.unit * 2 }}
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                        <Typography variant="h6" style={{ marginBottom: theme.spacing.unit * 2 }}>
                            Commodity Pickup Fields
                        </Typography>
                        {collectorPickupFields.map((pickupField, i) => (
                            <div
                                style={{ display: 'flex', marginBottom: theme.spacing.unit * 2, alignItems: 'center' }}
                                key={i}
                            >
                                <TextField
                                    value={_.get(pickupField, 'description', '')}
                                    onChange={e => handleUpdateDescription(e.target.value, i)}
                                    label="Description"
                                    variant="outlined"
                                />
                                <TextField
                                    value={_.get(pickupField, 'unit', '')}
                                    onChange={e => handleUpdateUnit(e.target.value, i)}
                                    label="Units (optional)"
                                    variant="outlined"
                                    allowEmptyString
                                />
                                <IconButton
                                    onClick={() =>
                                        warnAction(() => handleRemoveField(i), 'Are you sure you want to delete this?')
                                    }
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </div>
                        ))}
                        <Divider style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 4 }} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                data-cy="commodity-pickup-field-description-input"
                                style={{ marginRight: theme.spacing.unit }}
                                value={_.get(newFieldValues, 'description', '')}
                                name="description"
                                onChange={handleNewFieldChanged}
                                label="Description"
                                variant="outlined"
                                fullWidth
                            />
                            <TextField
                                data-cy="commodity-pickup-field-units-input"
                                style={{ marginRight: theme.spacing.unit }}
                                value={_.get(newFieldValues, 'unit', '')}
                                onChange={handleNewFieldChanged}
                                name="unit"
                                label="Units (optional)"
                                variant="outlined"
                                fullWidth
                            />
                            <IconButton
                                data-cy="commodity-pickup-field-add-button"
                                color="primary"
                                onClick={handleAddField}
                                disabled={_.isEmpty(_.get(newFieldValues, 'description'))}
                            >
                                <Icon>add_circle_outline</Icon>
                            </IconButton>
                        </div>
                    </Collapse>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    data-cy="commodity-pickup-config-submit-button"
                    onClick={() =>
                        onSubmit(
                            _.isEmpty(_.get(newFieldValues, 'description'))
                                ? collectorPickupFields
                                : [...collectorPickupFields, newFieldValues],
                            intuitCustomerRef,
                            intuitItemRef,
                            commodityPickupsEnabled
                        )
                    }
                    disabled={
                        _.isEmpty((intuitCustomerRef || '').toString()) &&
                        _.isEmpty((intuitItemRef || '').toString()) &&
                        commodityPickupsEnabled &&
                        (_commodity.isSplitCompleted(commodity) || _commodity.isAcceptUnitsCompleted(commodity))
                    }
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(CommodityPickupFieldsDialog));
