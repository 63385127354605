import React, { useContext } from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import ReactJson from 'react-json-view';

import { withTheme } from '@material-ui/core/styles';
import {
    Button,
    Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    OutlinedInput,
    Typography,
    Paper
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { getSwitch, getTextInput } from '../../../components/CRUDTables/helperFunctions';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { isProductionEnv } from 'utils/misc';

const Integrations = ({
    theme,
    onSnackbar,
    integration,
    formLoading,
    formOpen,
    handleFormOpen,
    saveIntegration,
    onInfoDialog,
    permissionsDescriptions
}) => {
    const { lang } = useContext(LocalizationContext);
    const integrationPermissions = {
        setKantechPasscode: _.get(integration, 'permissions.setKantechPasscode', false),
        porCreate: _.get(integration, 'permissions.porCreate', false),
        porSubmit: _.get(integration, 'permissions.porSubmit', false),
        incomingRedemptions: _.get(integration, 'permissions.incomingRedemptions', false),
        incomingChequeRedemptions: _.get(integration, 'permissions.incomingChequeRedemptions', false),
        incomingLabels: _.get(integration, 'permissions.incomingLabels', false),
        outgoingCounts: _.get(integration, 'permissions.outgoingCounts', false),
        outgoingAccountStatus: _.get(integration, 'permissions.outgoingAccountStatus', false),
        outgoingAccountDetails: _.get(integration, 'permissions.outgoingAccountDetails', false),
        outgoingRedemptions: _.get(integration, 'permissions.outgoingRedemptions', false),
        outgoingChequeRedemptions: _.get(integration, 'permissions.outgoingChequeRedemptions', false),
        outgoingInventory: _.get(integration, 'permissions.outgoingInventory', false),
        outgoingImages: _.get(integration, 'permissions.outgoingImages', false),
        sendRegistrationDetails: _.get(integration, 'permissions.sendRegistrationDetails', false),
        // sendUpdateDetails: _.get(integration, 'permissions.sendUpdateDetails', false),
        updateLegacyPassword: _.get(integration, 'permissions.updateLegacyPassword', false),
        verifyLoginDetails: _.get(integration, 'permissions.verifyLoginDetails', false),
        getAccessDoorCode: _.get(integration, 'permissions.getAccessDoorCode', false),
        validateId: _.get(integration, 'permissions.validateId', false),
        getUser: _.get(integration, 'permissions.getUser', false),
        pollTOMRASessions: _.get(integration, 'permissions.pollTOMRASessions', false),
        getDropLocations: _.get(integration, 'permissions.getDropLocations', false),
        getBagStatus: _.get(integration, 'permissions.getBagStatus', false),
        outgoingDropLocations: _.get(integration, 'permissions.outgoingDropLocations', false),
        scanQRCode: _.get(integration, 'permissions.scanQRCode', false)
    };
    const permissionTypes = Object.keys(integrationPermissions);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: _.get(integration, '_id'),
            name: _.get(integration, 'name', ''),
            description: _.get(integration, 'description', ''),
            active: _.get(integration, 'active', true),
            logoURL: _.get(integration, 'logoURL', ''),
            integrationURL: _.get(integration, 'integrationURL', ''),
            accessAccount: _.get(integration, 'accessAccount', ''),
            newIP: '',
            allowedIPs: _.get(integration, 'allowedIPs', []),
            history: _.get(integration, 'history', []),
            outgoingXAPIKey: _.get(integration, 'outgoingXAPIKey', ''),
            outgoingAuthContentType: _.get(integration, 'outgoingAuthContentType', 'application/x-www-form-urlencoded'),
            outgoingAuthType: _.get(integration, 'outgoingAuthType', 'Default'),
            incomingCountsFormat: _.get(integration, 'incomingCountsFormat', 'DEFAULT'),
            lostBagSku: _.get(integration, 'lostBagSku', 9300),
            adjustmentRate: _.get(integration, 'adjustmentRate', 90010),
            bounceInvalidBags: _.get(integration, 'bounceInvalidBags', false),
            outgoingAuthParams: _.get(integration, 'outgoingAuthParams', ''),
            outgoingTokenRequired: _.get(integration, 'outgoingTokenRequired', false),
            tomraWebhookSecret: _.get(integration, 'tomraWebhookSecret', ''),
            outgoingUsername: _.get(integration, 'outgoingUsername', ''),
            outgoingPassword: _.get(integration, 'outgoingPassword', ''),
            // scanQRCodeBody: _.get(integration, 'scanQRCodeBody', {}),
            tokenPath: _.get(integration, 'tokenPath', ''),
            // type: _.get(integration, 'type', 'NONE'),
            testEndpoint: _.get(integration, 'endpoints.testEndpoint', ''),
            outgoingCounts: _.get(integration, 'endpoints.outgoingCounts', ''),
            outgoingAccountStatus: _.get(integration, 'endpoints.outgoingAccountStatus', ''),
            outgoingAccountDetails: _.get(integration, 'endpoints.outgoingAccountDetails', ''),
            pollTOMRASessions: _.get(integration, 'endpoints.pollTOMRASessions', ''),
            outgoingRedemptions: _.get(integration, 'endpoints.outgoingRedemptions', ''),
            outgoingChequeRedemptions: _.get(integration, 'endpoints.outgoingChequeRedemptions', ''),
            outgoingImages: _.get(integration, 'endpoints.outgoingImages', ''),
            outgoingDropLocations: _.get(integration, 'endpoints.outgoingDropLocations', ''),
            sendRegistrationDetails: _.get(integration, 'endpoints.sendRegistrationDetails', ''),
            getAccessDoorCode: _.get(integration, 'endpoints.getAccessDoorCode', ''),
            // sendUpdateDetails: _.get(integration, 'endpoints.sendUpdateDetails', ''),
            updateLegacyPassword: _.get(integration, 'endpoints.updateLegacyPassword', ''),
            verifyLoginDetails: _.get(integration, 'endpoints.verifyLoginDetails', ''),
            scanQRCode: _.get(integration, 'endpoints.scanQRCode', ''),
            permissions: integrationPermissions,
            forceIPCheck: _.get(integration, 'forceIPCheck', true),
            // createPrintedLabelOnPORCreate: _.get(integration, 'createPrintedLabelOnPORCreate', false),
            // createScannedLabelOnPORCreate: _.get(integration, 'createScannedLabelOnPORCreate', false),
            // addBagtagListToOrder: _.get(integration, 'addBagtagListToOrder', false),
            qrTokenPrefix: _.get(integration, 'qrTokenPrefix', '')
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(18)
                .required('You must enter a name'),
            description: Yup.string().max(32),
            accessAccount: Yup.string().email(),
            integrationURL: Yup.string()
                .url('This must be a valid url')
                .required('You must enter an integration URL'),
            qrTokenPrefix: Yup.string().when('permissions.scanQRCode', {
                is: value => value === true,
                then: Yup.string().required('You must enter a token prefix')
            })
        }),
        onSubmit: async values => {
            const updatedValues = _.cloneDeep(values);
            updatedValues.endpoints = {
                testEndpoint: values.testEndpoint,
                outgoingCounts: values.outgoingCounts,
                pollTOMRASessions: values.pollTOMRASessions,
                outgoingRedemptions: values.outgoingRedemptions,
                outgoingAccountStatus: values.outgoingAccountStatus,
                outgoingAccountDetails: values.outgoingAccountDetails,
                outgoingChequeRedemptions: values.outgoingChequeRedemptions,
                outgoingImages: values.outgoingImages,
                outgoingDropLocations: values.outgoingDropLocations,
                sendRegistrationDetails: values.sendRegistrationDetails,
                // sendUpdateDetails: values.sendUpdateDetails,
                updateLegacyPassword: values.updateLegacyPassword,
                verifyLoginDetails: values.verifyLoginDetails,
                getAccessDoorCode: values.getAccessDoorCode,
                scanQRCode: values.scanQRCode
            };

            await saveIntegration(updatedValues);
        }
    });

    const handleEditIP = (updateIP, add = false) => {
        let ips = _.clone(formik.values.allowedIPs);

        if (add) {
            let regexIP = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
            if (ips.includes(updateIP)) {
                onSnackbar('You cannot enter a duplicate IP', 'error');
                return;
            }
            // if (!regexIP.test(updateIP)) {
            //     onSnackbar('The IP you entered is not a valid IP', 'error');
            //     return;
            // }

            ips.push(updateIP);
        } else {
            ips = ips.filter(ip => ip !== updateIP);
        }

        formik.setFieldValue('newIP', '');
        formik.setFieldValue('allowedIPs', ips);
    };

    const handlePermissionsChanges = selectedPermissions => {
        const updatedPermissions = {
            setKantechPasscode: selectedPermissions.includes('setKantechPasscode'),
            porCreate: selectedPermissions.includes('porCreate'),
            porSubmit: selectedPermissions.includes('porSubmit'),
            incomingRedemptions: selectedPermissions.includes('incomingRedemptions'),
            incomingChequeRedemptions: selectedPermissions.includes('incomingChequeRedemptions'),
            incomingLabels: selectedPermissions.includes('incomingLabels'),
            outgoingCounts: selectedPermissions.includes('outgoingCounts'),
            pollTOMRASessions: selectedPermissions.includes('pollTOMRASessions'),
            outgoingRedemptions: selectedPermissions.includes('outgoingRedemptions'),
            outgoingChequeRedemptions: selectedPermissions.includes('outgoingChequeRedemptions'),
            outgoingInventory: selectedPermissions.includes('outgoingInventory'),
            outgoingAccountStatus: selectedPermissions.includes('outgoingAccountStatus'),
            outgoingAccountDetails: selectedPermissions.includes('outgoingAccountDetails'),
            outgoingImages: selectedPermissions.includes('outgoingImages'),
            sendRegistrationDetails: selectedPermissions.includes('sendRegistrationDetails'),
            sendUpdateDetails: selectedPermissions.includes('sendUpdateDetails'),
            updateLegacyPassword: selectedPermissions.includes('updateLegacyPassword'),
            verifyLoginDetails: selectedPermissions.includes('verifyLoginDetails'),
            getAccessDoorCode: selectedPermissions.includes('getAccessDoorCode'),
            validateId: selectedPermissions.includes('validateId'),
            getUser: selectedPermissions.includes('getUser'),
            getDropLocations: selectedPermissions.includes('getDropLocations'),
            getBagStatus: selectedPermissions.includes('getBagStatus'),
            outgoingDropLocations: selectedPermissions.includes('outgoingDropLocations'),
            scanQRCode: selectedPermissions.includes('scanQRCode')
        };

        formik.setFieldValue('permissions', updatedPermissions);
    };

    // const handleScanQRCodeBody = e => {
    //     console.log({ e });
    //     formik.setFieldValue('scanQRCodeBody', e);
    // };

    return (
        <Dialog open={formOpen}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {formik.values._id ? 'Update' : 'Create'} {getSwitch(theme, 'active', 'Enabled', formik)}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit }}>
                    <Typography variant="subtitle2">Integration Info</Typography>
                    {getTextInput(theme, 'name', 'Name', formik)}
                    {getTextInput(theme, 'description', 'Description', formik)}
                    {getTextInput(theme, 'logoURL', 'Logo URL', formik)}
                    {getTextInput(theme, 'integrationURL', 'Integration URL', formik, 'text', {
                        endAdornment: (
                            <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                <Icon onClick={() => onInfoDialog(true, 'Integration URL')}>info</Icon>
                            </InputAdornment>
                        )
                    })}
                </div>

                <div style={{ marginTop: theme.spacing.unit * 4 }}>
                    <Typography variant="subtitle2">Incoming Auth</Typography>

                    {getTextInput(theme, 'accessAccount', 'Access Account', formik, 'text', {
                        endAdornment: (
                            <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                <Icon onClick={() => onInfoDialog(true, 'Access Account')}>info</Icon>
                            </InputAdornment>
                        )
                    })}
                    {getSwitch(theme, 'forceIPCheck', 'IP Verification Required', formik)}
                    {formik.values.forceIPCheck &&
                        getTextInput(theme, 'newIP', 'Allowed IPs', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => handleEditIP(formik.values.newIP, true)}>add_circle</Icon>
                                </InputAdornment>
                            )
                        })}

                    <div style={{ marginTop: theme.spacing.unit }}>
                        {formik.values.allowedIPs.map(ip => {
                            return (
                                <Chip
                                    key={`ip-chip-${ip}`}
                                    label={ip}
                                    style={{ margin: theme.spacing.unit / 2 }}
                                    onDelete={() => handleEditIP(ip, false)}
                                    deleteIcon={
                                        //needed for adding a cypress tag to the button
                                        <Icon data-cy={`ip-remove-${ip}`}>cancel</Icon>
                                    }
                                />
                            );
                        })}
                    </div>
                </div>

                <div style={{ marginTop: theme.spacing.unit * 4 }}>
                    <Typography variant="subtitle2">Outgoing Auth</Typography>
                    {getTextInput(theme, 'outgoingXAPIKey', 'X-API-Key', formik, 'password', {
                        endAdornment: (
                            <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                <Icon onClick={() => onInfoDialog(true, 'X-API-Key')}>info</Icon>
                            </InputAdornment>
                        )
                    })}
                    {getSwitch(theme, 'outgoingTokenRequired', 'Outgoing Token Required', formik)}
                    {formik.values.outgoingTokenRequired &&
                        getTextInput(theme, 'tokenPath', 'Token Endpoint', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Token Endpoint')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.outgoingTokenRequired &&
                        getTextInput(theme, 'testEndpoint', 'POST Test (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Test')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.outgoingTokenRequired &&
                        getTextInput(
                            theme,
                            'outgoingAuthContentType',
                            'Content Type',
                            formik,
                            'text',
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'Content Type')}>info</Icon>
                                    </InputAdornment>
                                )
                            },
                            {},
                            null,
                            true
                        )}
                    {formik.values.outgoingTokenRequired && (
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                            <InputLabel variant="outlined">{'Authorization Type'}</InputLabel>
                            <Select
                                {...formik.getFieldProps('outgoingAuthType')}
                                value={formik.values.outgoingAuthType}
                                fullWidth
                                onChange={e => formik.setFieldValue('outgoingAuthType', e.target.value)}
                                input={
                                    <OutlinedInput
                                        labelWidth={'Authorization Type'.length * theme.spacing.unit}
                                        data-cy={`auth-type-input`}
                                    />
                                }
                            >
                                <MenuItem value={'Default'} key={'default'}>
                                    Default (Send user/pass as part of body)
                                </MenuItem>
                                <MenuItem value={'Basic'} key={'default'}>
                                    Basic (Uses Basic Authorization header)
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {formik.values.outgoingTokenRequired &&
                        getTextInput(theme, 'outgoingUsername', 'Username', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Outgoing Username')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.outgoingTokenRequired && (
                        <TextField
                            {...formik.getFieldProps('outgoingPassword')}
                            fullWidth
                            label="Password"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            variant="outlined"
                            type="password"
                            helperText={''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'Outgoing Password')}>info</Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}

                    {formik.values.outgoingTokenRequired &&
                        getTextInput(theme, 'outgoingAuthParams', 'Other Parameters', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Other Parameters')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                </div>

                <div style={{ marginTop: theme.spacing.unit * 4 }}>
                    <Typography variant="subtitle2">Enabled Functionality</Typography>
                    {
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                            <InputLabel variant="outlined">{'Functions'}</InputLabel>
                            <Select
                                {...formik.getFieldProps('permissions')}
                                value={_.filter(permissionTypes, permType => formik.values.permissions[permType]) || []}
                                fullWidth
                                multiple={true}
                                onChange={e => handlePermissionsChanges(e.target.value)}
                                input={
                                    <OutlinedInput
                                        labelWidth={'Functions'.length * theme.spacing.unit}
                                        data-cy={`${_.kebabCase('permissions')}-input`}
                                    />
                                }
                            >
                                {permissionTypes.map((selectableValue, idx) => (
                                    <MenuItem value={selectableValue} key={idx}>
                                        {permissionsDescriptions[selectableValue] || selectableValue}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                    {formik.values.permissions.outgoingCounts && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Counts
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingCounts &&
                        getTextInput(theme, 'outgoingCounts', 'POST Counts (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Counts')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {(formik.values.permissions.outgoingRedemptions ||
                        formik.values.permissions.outgoingChequeRedemptions) && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Redemptions
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingRedemptions &&
                        getTextInput(theme, 'outgoingRedemptions', 'POST Redemptions (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Redemptions')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.outgoingChequeRedemptions &&
                        getTextInput(
                            theme,
                            'outgoingChequeRedemptions',
                            'POST Cheque Redemptions (outgoing)',
                            formik,
                            'text',
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'POST Cheque Redemptions')}>info</Icon>
                                    </InputAdornment>
                                )
                            }
                        )}
                    {formik.values.permissions.outgoingImages && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Images
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingImages &&
                        getTextInput(theme, 'outgoingImages', 'POST Images (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Images')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.sendRegistrationDetails && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Customer Details
                        </Typography>
                    )}
                    {formik.values.permissions.sendRegistrationDetails &&
                        getTextInput(theme, 'sendRegistrationDetails', 'POST Register (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Register')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {/*formik.values.permissions.sendUpdateDetails &&
                        getTextInput(theme, 'sendUpdateDetails', 'POST Update (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Update')}>info</Icon>
                                </InputAdornment>
                            )
                        })*/}
                    {/* {formik.values.permissions.verifyLoginDetails && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Login Details (Verification)
                        </Typography>
                    )} */}
                    {formik.values.permissions.verifyLoginDetails &&
                        getTextInput(theme, 'verifyLoginDetails', 'POST Login (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Login')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.updateLegacyPassword &&
                        getTextInput(
                            theme,
                            'updateLegacyPassword',
                            'POST Update Legacy Password (outgoing)',
                            formik,
                            'text',
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'POST Update Legacy Password')}>
                                            info
                                        </Icon>
                                    </InputAdornment>
                                )
                            }
                        )}
                    {formik.values.permissions.getAccessDoorCode && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Access Door Code Requests (outgoing)
                        </Typography>
                    )}
                    {formik.values.permissions.getAccessDoorCode &&
                        getTextInput(
                            theme,
                            'getAccessDoorCode',
                            'POST Get Access Door Code (outgoing)',
                            formik,
                            'text',
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'POST Get Access Door Code')}>
                                            info
                                        </Icon>
                                    </InputAdornment>
                                )
                            }
                        )}
                    {formik.values.permissions.pollTOMRASessions && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            TOMRA Polling
                        </Typography>
                    )}
                    {formik.values.permissions.pollTOMRASessions &&
                        getTextInput(theme, 'pollTOMRASessions', 'Poll TOMRA Sessions', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Poll TOMRA Sessions')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.pollTOMRASessions && (
                        <TextField
                            fullWidth
                            label="Poll TOMRA Sessions Freq."
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            variant="outlined"
                            helperText={''}
                            disabled={true}
                            value={isProductionEnv ? 'Every 15 Minutes' : 'Every Minute'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'Poll TOMRA Sessions Freq.')}>
                                            info
                                        </Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    {formik.values.permissions.scanQRCode && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Data on QR Scan
                        </Typography>
                    )}
                    {formik.values.permissions.scanQRCode &&
                        getTextInput(theme, 'qrTokenPrefix', 'Token Prefix', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Token Prefix')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.scanQRCode &&
                        getTextInput(theme, 'scanQRCode', 'POST QR Scan (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'Scan QR Code')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.outgoingAccountStatus && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Account Status
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingAccountStatus &&
                        getTextInput(theme, 'outgoingAccountStatus', 'POST Account Status (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Status')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.outgoingAccountDetails && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Account Details
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingAccountDetails &&
                        getTextInput(
                            theme,
                            'outgoingAccountDetails',
                            'POST Account Details (outgoing)',
                            formik,
                            'text',
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'POST Details')}>info</Icon>
                                    </InputAdornment>
                                )
                            }
                        )}

                    {/* {formik.values.permissions.porCreate && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Internal Order Creation
                        </Typography>
                    )}
                    {formik.values.permissions.porCreate &&
                        getSwitch(theme, 'createPrintedLabelOnPORCreate', 'Create Printed Label on POR Create', formik)}
                    {formik.values.permissions.porCreate &&
                        getSwitch(theme, 'createScannedLabelOnPORCreate', 'Create Scanned Label on POR Create', formik)}
                    {formik.values.permissions.porCreate &&
                        getSwitch(theme, 'addBagtagListToOrder', 'Add Expected Bagtag List to Order', formik)} */}

                    {(formik.values.permissions.porSubmit || formik.values.permissions.pollTOMRASessions) && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Incoming Counts
                        </Typography>
                    )}

                    {formik.values.permissions.porSubmit && (
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                            <InputLabel variant="outlined">{'Incoming Counts Format'}</InputLabel>
                            <Select
                                {...formik.getFieldProps('incomingCountsFormat')}
                                value={formik.values.incomingCountsFormat}
                                fullWidth
                                onChange={e => formik.setFieldValue('incomingCountsFormat', e.target.value)}
                                input={
                                    <OutlinedInput
                                        labelWidth={'Incoming Counts Format'.length * theme.spacing.unit}
                                        data-cy={`auth-type-input`}
                                    />
                                }
                            >
                                <MenuItem value={'TOMRA'} key={'default'}>
                                    TOMRA
                                </MenuItem>
                                <MenuItem value={'EXPRESS'} key={'default'}>
                                    Express
                                </MenuItem>
                                <MenuItem value={'DEFAULT'} key={'default'}>
                                    System Default
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {formik.values.permissions.porSubmit &&
                        formik.values.incomingCountsFormat === 'TOMRA' &&
                        getTextInput(theme, 'tomraWebhookSecret', 'TOMRA Webhook Secret', formik, 'password', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'TOMRA Webhook Secret')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {formik.values.permissions.porSubmit && formik.values.incomingCountsFormat === 'EXPRESS' && (
                        <>
                            {getTextInput(theme, 'lostBagSku', 'Incoming Lost Bag SKU', formik, 'text', {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'Lost Bag SKU')}>info</Icon>
                                    </InputAdornment>
                                )
                            })}
                            {getTextInput(theme, 'adjustmentRate', 'Internal Adjustment SKU', formik, 'text', {
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ cursor: 'pointer', color: colors.grey[500] }}
                                    >
                                        <Icon onClick={() => onInfoDialog(true, 'Adjustment SKU')}>info</Icon>
                                    </InputAdornment>
                                )
                            })}
                        </>
                    )}

                    {(formik.values.permissions.porSubmit || formik.values.permissions.pollTOMRASessions) &&
                        getSwitch(theme, 'bounceInvalidBags', 'Bounce Invalid Bagtags', formik)}

                    {formik.values.permissions.outgoingDropLocations && (
                        <Typography variant="subtitle2" style={{ marginTop: theme.spacing.unit * 2 }}>
                            Outgoing Drop Locations
                        </Typography>
                    )}
                    {formik.values.permissions.outgoingDropLocations &&
                        getTextInput(theme, 'outgoingDropLocations', 'POST Locations (outgoing)', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer', color: colors.grey[500] }}>
                                    <Icon onClick={() => onInfoDialog(true, 'POST Locations')}>info</Icon>
                                </InputAdornment>
                            )
                        })}
                    {/* {formik.values.permissions.scanQRCode && (
                        <FormControl
                            variant="outlined"
                            style={{ marginTop: theme.spacing.unit * 2, display: 'flex', flexDirection: 'column' }}
                        >
                            <InputLabel
                                shrink
                                htmlFor="json-input"
                                style={{
                                    marginBottom: theme.spacing.unit,
                                    backgroundColor: theme.palette.background.paper
                                }}
                            >
                                POST QR Body
                            </InputLabel>
                            <Paper
                                id="json-input"
                                variant="outlined"
                                elevation={0}
                                style={{
                                    border: `1px solid rgba(0,0,0,0.24)`,
                                    borderRadius: theme.shape.borderRadius,
                                    padding: theme.spacing.unit * 2,
                                    overflow: 'auto'
                                }}
                            >
                                <ReactJson
                                    src={formik.values.scanQRCodeBody}
                                    onEdit={handleScanQRCodeBody}
                                    onAdd={handleScanQRCodeBody}
                                    onDelete={handleScanQRCodeBody}
                                />
                            </Paper>
                        </FormControl>
                    )} */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleFormOpen(false)} disabled={formLoading}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" disabled={formLoading} onClick={formik.handleSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTheme()(Integrations);
