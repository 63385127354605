import React, { useMemo, useEffect, useContext } from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core';

import { useState } from 'react';

import { TableRow, TableCell, Paper, Grid, Menu, IconButton, Icon, Button, TextField } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import HttpContext from 'utils/contexts/HttpContext';
import { openWindow } from 'helpers/windowHelper';
import { convertToImportName, deviceHelper } from 'utils/misc';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { loc } from 'localizations/localizationHandler';
import CollectorInventoryWidget from './Components/CollectorInventoryWidget';

function Inventory({ theme, history, operator }) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [allCollectors, setAllCollectors] = useState([]);
    const [commodities, setCommodities] = useState([]);

    useEffect(() => {
        (async () => {
            const [resCommodities, resCollectors] = await Promise.all([
                http.getJSON('/commodities/getAllCommodities'),
                http.getJSON('/collectors')
            ]);
            if (resCommodities.ok) {
                setCommodities(_.get(resCommodities, 'data.collectionData', []));
            }
            if (resCollectors.ok) {
                setAllCollectors(_.get(resCollectors, 'data.collectors', []));
            }
        })();
    }, []);

    return (
        <CollectorInventoryWidget
            collectors={allCollectors}
            commodities={commodities}
            http={http}
            theme={theme}
            onSnackbar={onSnackbar}
            showEmptyInventory={true}
        />
    );
}

export default withTheme()(Inventory);
