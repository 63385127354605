import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _user, _time, _commodity, _collector } from 'std';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import InlineChip from 'components/Chips/InlineChip';

import GenericDialog from 'components/Dialogs/GenericDialog';

import {
    withTheme,
    TableRow,
    TableCell,
    colors,
    IconButton,
    Icon,
    Tooltip,
    Grid,
    Paper,
    Menu,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Button,
    MenuItem,
    TextField,
    Checkbox,
    ListItemText,
    Switch,
    Typography,
    Avatar,
    LinearProgress,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress
} from '@material-ui/core';
import OperatorContext from 'utils/contexts/OperatorContext';
import { uppercaseFirstLetter, formatAsCurrency } from 'utils/misc';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import CommodityPickupDialog from 'components/CommodityPickupDialog';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import useCommodityPickupFilters from './hooks/useCommodityPickupFilters';

import ImageViewer from 'components/ImageUploads/ImageViewer';

import TrailerLabelDialog from './TrailerLabelDialog';
import useTrailerLabelDialog from './TrailerLabelDialog/hooks/useTrailerLabelDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import useGetJSON from 'utils/hooks/useGetJSON';
import bulkHelper from 'helpers/bulkHelper';

function CommodityPickups(props) {
    const { lang } = useContext(LocalizationContext);
    const { theme, collector } = props;

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);

    const [stats, setStats] = useState(null);

    const [payloadDialogOpen, setPayloadDialogOpen] = useState(false);
    const [commodityPickupSelected, setCommodityPickupSelected] = useState(null);

    //const [idsChecked, setIdsChecked] = useState([]);
    const [commodityPickupsChecked, setCommodityPickupsChecked] = useState([]);
    const [commodityToEdit, setcommodityToEdit] = useState(null);
    const [statusToEdit, setStatusToEdit] = useState(null);

    const tzCommodityPickupSelected = _.get(
        commodityPickupSelected,
        'collection.timezone',
        process.env.REACT_APP_REGION_TIMEZONE
    );

    const [loading, setLoading] = useState(false);
    const [statsLoading, setStatsLoading] = useState(true);
    const [tabSelected, setTabSelected] = useState('Incomplete');

    const operator = useContext(OperatorContext);
    const warnAction = useContext(ConfirmDialogContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [commodityPickupDialogOpen, setCommodityPickupDialogOpen] = useState(false);
    const [editingCommodityPickup, setEditingCommodityPickup] = useState(false);

    const [images, setImages] = useState([]);
    const [imageDialogOpen, setImageDialogOpen] = useState(false);

    const handleViewImage = imageURLs => {
        setImages(imageURLs);
        setImageDialogOpen(true);
    };

    let headers = [];
    if (_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) {
        headers = [
            { name: 'Actions', key: 'Actions', locKey: null },
            { name: 'Status', key: 'Status', locKey: null },
            { name: 'Commodity', key: 'Commodity', locKey: null },
            { name: 'Trailer Number', key: 'Trailer Number', locKey: null },
            { name: 'Bottle Depot', key: 'Bottle Depot', locKey: null },
            { name: 'Reference Number', key: 'Reference Number', locKey: null },
            { name: 'Date Requested^', key: 'Date Requested', locKey: null },
            { name: 'Collection Period^', key: 'Collection Period', locKey: null },
            { name: 'Total Items', key: 'Total Items', locKey: null },
            { name: 'Associated Orders', key: 'Associated Orders', locKey: null },
            { name: 'Associated Bags/Items', key: 'Associated Bags/Items', locKey: null },
            { name: 'Payment Total', key: 'Payment Total', locKey: null },
            { name: 'Processor Payment', key: 'Processor Payment', locKey: null },
            { name: 'Driver Payment', key: 'Driver Payment', locKey: null },
            { name: 'Customer Payment', key: 'Customer Payment', locKey: null },
            { name: 'Charity Payment', key: 'Charity Payment', locKey: null },
            { name: 'Payload', key: 'Payload', locKey: null }
        ];
    } else {
        headers = [
            { name: 'Actions', key: 'Actions', locKey: null },
            { name: 'Status', key: 'Status', locKey: null },
            { name: 'Commodity', key: 'Commodity', locKey: null },
            { name: 'Trailer Number', key: 'Trailer Number', locKey: null },
            { name: 'Reference Number', key: 'Reference Number', locKey: null },
            { name: 'Date Requested^', key: 'Date Requested', locKey: null },
            { name: 'Collection Period^', key: 'Collection Period', locKey: null },
            { name: 'Total Items', key: 'Total Items', locKey: null },
            { name: 'Associated Orders', key: 'Associated Orders', locKey: null },
            { name: 'Associated Bags/Items', key: 'Associated Bags/Items', locKey: null },
            { name: 'Payload', key: 'Payload', locKey: null }
        ];
    }
    const {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allCommodities,
        commoditiesSelected,
        setCommoditiesSelected,
        filtersLoading,
        handleAllFilterCollectors,
        handleAllFilterCommodities
    } = useCommodityPickupFilters(_user.isCollectorAdmin(operator) ? collector : null);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards
        // handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment(new Date(2018, 0, 1))
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day'),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'allTime'
    });

    const {
        loading: tableLoading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/commodityPickups?${getCommodityPickupQuery({
            tabSelected,
            operator,
            collector,
            collectorsSelected,
            commoditiesSelected,
            startDate,
            endDate
        })}`,
        key: 'commodityPickups',
        headers: headers.map(h => h.name)
    });

    const {
        open: trailerLabelDialogOpen,
        trailerLabel,
        handleOpen: handleOpenTrailerLabelDialog,
        handleClose: handleCloseTrailerLabelDialog,
        handleLabelChange: handleChangeLabel
    } = useTrailerLabelDialog();

    const {
        loading: openBulkBreakdownLoading,
        error: openBulkBreakdownLoadingLoadingErr,
        data: openBulkBreakdown = {},
        refresh: refreshOpenBulkBreakdown
    } = useGetJSON(`/bulks/openBulksBreakdown?collectors=${allCollectors.map(c => c._id)}`, 'openBulksBreakdown');

    const handleOpenCompleteDialog = () => {
        setCommodityPickupSelected(null);
        setCommodityPickupDialogOpen(true);
        setEditingCommodityPickup(false);
    };

    const handleLoadStats = async () => {
        setStatsLoading(true);
        const res = await http.getJSON(
            `/commodityPickups/stats?${getCommodityPickupQuery({
                tabSelected,
                operator,
                collector,
                collectorsSelected,
                commoditiesSelected,
                startDate,
                endDate
            })}`
        );
        if (res.ok) {
            setStats(res.data);
        }
        setStatsLoading(false);
    };

    const handlePayloadDialogOpen = commodityPickup => {
        setPayloadDialogOpen(true);
        setCommodityPickupSelected(commodityPickup);
    };

    const handleUpdateCommodityPickupDialog = commodityPickup => {
        setCommodityPickupSelected(commodityPickup);
        setCommodityPickupDialogOpen(true);
        setEditingCommodityPickup(true);
    };

    /*const handleUpdateCommodityPickup = async (fieldValues, trailerLabel) => {
        setLoading(true);
        const res = await http.post(
            `/commodityPickups/${commodityPickupSelected._id}/update`,
            { fieldValues, trailerLabel },
            true
        );
        if (res.ok) {
            onSnackbar('Successfully updated commodity pickup');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };*/

    const handleDeleteCommodityPickup = async commodityPickup => {
        setLoading(true);
        const res = await http.post(`/commodityPickups/${commodityPickup._id}/delete`, {}, true);
        if (res.ok) {
            onSnackbar('Requested pickup successfully deleted');
            getData();
            handleLoadStats();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleCreateComodityPickup = async ({
        status,
        fieldValues,
        trailerLabel,
        commodityId,
        collectorId,
        startDate,
        endDate
    }) => {
        setLoading(true);
        const res = await http.post(
            `/commodityPickups/createCommodityPickup`,
            {
                status,
                commodity: commodityId,
                collector: collectorId,
                startDate,
                endDate,
                fieldValues,
                trailerLabel
            },
            true
        );
        if (res.ok) {
            onSnackbar('Successfully created commodity pickup');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
            refreshOpenBulkBreakdown();
            setcommodityToEdit(null);
            setStatusToEdit(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleUpdateCommodityPickup = async ({
        status,
        fieldValues,
        trailerLabel,
        commodityId,
        collectorId,
        startDate,
        endDate
    }) => {
        setLoading(true);
        let _ids;
        if (editingCommodityPickup) {
            _ids = [_.get(commodityPickupSelected, '_id')];
        } else {
            _ids = commodityPickupsChecked.map(c => c._id);
        }
        const res = await http.post(
            `/commodityPickups/processCommodityPickups`,
            {
                _ids,
                status,
                commodity: commodityId,
                collector: collectorId,
                startDate,
                endDate,
                fieldValues,
                trailerLabel
            },
            true
        );
        if (res.ok) {
            onSnackbar('Successfully completed commodity pickups');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
            refreshOpenBulkBreakdown();
            setCommodityPickupsChecked([]);
            setcommodityToEdit(null);
            setStatusToEdit(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleCommodityPickupChecked = (e, commodityPickup) => {
        const commodityPickupsCheckedCopy = _.cloneDeep(commodityPickupsChecked);
        if (e.target.checked) {
            commodityPickupsCheckedCopy.push(commodityPickup);
        } else {
            const i = _.findIndex(commodityPickupsChecked, { _id: commodityPickup._id });
            if (i >= 0) {
                commodityPickupsCheckedCopy.splice(i, 1);
            }
        }
        setCommodityPickupsChecked(commodityPickupsCheckedCopy);

        if (_.isEmpty(commodityPickupsCheckedCopy)) {
            setcommodityToEdit(null);
            setStatusToEdit(null);
        } else if (_.get(commodityToEdit, '_id') !== _.get(commodityPickup, 'commodity._id')) {
            setcommodityToEdit(commodityPickup.commodity);
            setStatusToEdit(commodityPickup.status);
        }
    };

    const handleSubmitTrailerLabel = async () => {
        setLoading(true);
        const idsChecked = commodityPickupsChecked.map(c => c._id);
        const res = await http.post(`/commodityPickups/updateTrailerLabels`, { _ids: idsChecked, trailerLabel }, true);
        if (res.ok) {
            onSnackbar('Successfully updated commodity pickups');
            handleCloseTrailerLabelDialog();
            getData();
            handleLoadStats();
            setCommodityPickupsChecked([]);
            setcommodityToEdit(null);
            setStatusToEdit(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleUndoCommodityPickup = async commodityPickup => {
        const previousState = _.get(commodityPickup, 'previousState');
        if (_.isEmpty(previousState)) {
            onSnackbar('Cannot undo the last transaction, the last transaction is unknown', 'error');
            return;
        }

        setLoading(true);

        let res;
        if (_.get(previousState, 'nonExistent', false)) {
            res = await http.post(`/commodityPickups/${commodityPickup._id}/delete`, {}, true);
        } else {
            let _ids = [_.get(commodityPickup, '_id')];
            res = await http.post(
                `/commodityPickups/processCommodityPickups`,
                {
                    ...previousState,
                    startDate: _.get(previousState, 'collectionPeriod.startDate'),
                    endDate: _.get(previousState, 'collectionPeriod.endDate'),
                    fieldValues: _.get(previousState, 'payload', []),
                    nonExistent: false,
                    _ids
                },
                true
            );
        }
        if (res.ok) {
            onSnackbar('Successfully undid commodity pickups');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
            refreshOpenBulkBreakdown();
            setCommodityPickupsChecked([]);
            setcommodityToEdit(null);
            setStatusToEdit(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const renderRow = (commodityPickup, filteredHeaders, i) => {
        const timezone = _.get(commodityPickup, 'collection.timezone', process.env.REACT_APP_REGION_TIMEZONE);
        const startDateFormatted = formatDate(_.get(commodityPickup, 'collectionPeriod.startDate'), timezone);
        const endDateFormatted = formatDate(_.get(commodityPickup, 'collectionPeriod.endDate'), timezone);
        const createdAtFormatted = formatDate(_.get(commodityPickup, 'createdAt'), timezone);

        /*const olderThan4Weeks = moment(_.get(commodityPickup, 'createdAt'))
            .tz(timezone)
            .isBefore(
                moment()
                    .tz(timezone)
                    .subtract(4, 'weeks')
            );*/

        const payloadFormatted = _(commodityPickup.payload)
            .filter(p => p.quantity > 0)
            .map((p, i) => (
                <InlineChip
                    key={i}
                    style={{ margin: theme.spacing.unit * 0.5 }}
                    color={colors.grey[500]}
                    textColor={'white'}
                    text={
                        _.isEmpty(p.unit)
                            ? `${p.description}: ${_.round(p.quantity, 2)}`
                            : `${p.description}: ${_.round(p.quantity, 2)} ${p.unit}`
                    }
                />
            ))
            .value();

        const bulks = _.get(commodityPickup, 'bulks', []);
        const commodityPickupInvoiced =
            _.some(bulks, bulk => {
                const invoice = _.get(bulk, 'invoice');
                return (!_.isNil(invoice) && !_.isEmpty(invoice)) || bulkHelper.isRedeemed(bulk);
            }) && !_.isEmpty(bulks);
        const outOfSystemCollectorPaymentInvoiced =
            _.get(commodityPickup, 'outOfSystemCollectorPaymentTotal', 0) <= 0 ||
            !_.isNil(_.get(commodityPickup, 'outOfSystemCollectorPaymentInvoice'));

        const invoiced = commodityPickupInvoiced && outOfSystemCollectorPaymentInvoiced;

        const payloadImages = _.get(commodityPickup, 'images');

        const commodityMatchesCommodityToEdit =
            _.isNil(commodityToEdit) ||
            (!_.isNil(commodityToEdit) && _.get(commodityPickup, 'commodity._id') === _.get(commodityToEdit, '_id'));

        const statusMatchesStatusToEdit =
            _.isNil(statusToEdit) || (!_.isNil(statusToEdit) && _.get(commodityPickup, 'status') === statusToEdit);

        const multipleSelectionAllowed = _.isNil(commodityToEdit) || _commodity.isSplitCompleted(commodityToEdit);

        return (
            <TableRow key={commodityPickup._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {_.get(commodityPickup, 'processing', false) ? (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={22} thickness={4.8} />{' '}
                                <span style={{ marginLeft: theme.spacing.unit }}>Processing...</span>
                            </span>
                        ) : (
                            <>
                                {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                    <Checkbox
                                        disabled={
                                            invoiced ||
                                            !commodityMatchesCommodityToEdit ||
                                            !statusMatchesStatusToEdit ||
                                            (!multipleSelectionAllowed &&
                                                _.get(_.first(commodityPickupsChecked), '_id') !== commodityPickup._id)
                                        }
                                        checked={
                                            !_.isNil(_.find(commodityPickupsChecked, { _id: commodityPickup._id }))
                                        }
                                        onClick={e => handleCommodityPickupChecked(e, commodityPickup)}
                                        data-cy={`commodity-pickup-selection-box-${i}`}
                                    />
                                )}
                                <Tooltip title="Undo" disableFocusListener disableTouchListener>
                                    <IconButton
                                        onClick={() => handleUndoCommodityPickup(commodityPickup)}
                                        disabled={
                                            invoiced ||
                                            !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator))
                                        }
                                        data-cy={`commodity-pickup-undo-btn-${i}`}
                                    >
                                        <Icon>undo</Icon>
                                        {invoiced && <Icon>lock</Icon>}
                                    </IconButton>
                                </Tooltip>
                                {/*<Tooltip title="Edit" disableFocusListener disableTouchListener>
                                    <IconButton
                                        onClick={() => handleUpdateCommodityPickupDialog(commodityPickup)}
                                        disabled={
                                            invoiced ||
                                            !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator))
                                        }
                                        data-cy={`commodity-pickup-edit-btn-${i}`}
                                    >
                                        <Icon>edit</Icon>
                                        {invoiced && <Icon>lock</Icon>}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" disableFocusListener disableTouchListener disabled={invoiced}>
                                    <IconButton
                                        data-cy={`commodity-pickup-delete-btn-${i}`}
                                        disabled={
                                            invoiced ||
                                            !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator))
                                        }
                                        onClick={() => {
                                            warnAction(
                                                () => handleDeleteCommodityPickup(commodityPickup),
                                                `Are you sure you want to delete this?`
                                            );
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </Tooltip>*/}
                                <Tooltip title="View" disableFocusListener disableTouchListener>
                                    <IconButton
                                        onClick={() => handlePayloadDialogOpen(commodityPickup)}
                                        data-cy={`commodity-pickup-collector-payload-btn-${i}`}
                                    >
                                        <Icon>visibility</Icon>
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </TableCell>
                )}
                {filteredHeaders.includes('Status') && (
                    <TableCell>
                        <InlineChip
                            style={{ margin: theme.spacing.unit * 0.5 }}
                            color={
                                _.get(commodityPickup, 'status', '') === 'Requested'
                                    ? colors.blueGrey[500]
                                    : _.get(commodityPickup, 'status', '') === 'Collected'
                                    ? colors.blue[500]
                                    : colors.green[500]
                            }
                            textColor={'white'}
                            text={_.get(commodityPickup, 'status', '')}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Commodity') && (
                    <TableCell>
                        <InlineChip
                            style={{ margin: theme.spacing.unit * 0.5 }}
                            color={_.get(commodityPickup, 'commodity.color')}
                            textColor={'white'}
                            text={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <MDIcon
                                        path={_.get(allIcons, _.get(commodityPickup, 'commodity.iconName'))}
                                        size={0.65}
                                        color="white"
                                        style={{ marginRight: theme.spacing.unit * 0.5 }}
                                    />
                                    {_.get(commodityPickup, 'commodity.skuType', '')}
                                </span>
                            }
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Trailer Number') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>{_.get(commodityPickup, 'trailerLabel', '')}</TableCell>
                )}
                {headers.map(h => h.name).includes('Bottle Depot') && filteredHeaders.includes('Bottle Depot') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {_.get(commodityPickup, 'collector.name', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Reference Number') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {_.get(commodityPickup, 'collectionId')} - {_.get(commodityPickup, 'collectionLabel')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Requested^') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>{createdAtFormatted}</TableCell>
                )}
                {filteredHeaders.includes('Collection Period^') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {startDateFormatted} - {endDateFormatted}
                    </TableCell>
                )}

                {filteredHeaders.includes('Total Items') &&
                    (_.get(commodityPickup, 'status', '') !== 'Processed' ? (
                        <TableCell />
                    ) : (
                        <TableCell>
                            {_.sumBy(_.get(commodityPickup, 'payload', []), 'quantity')}{' '}
                            {_.get(commodityPickup, 'commodity.skuType', '') === 'clothing' ? 'lbs' : 'items'}
                        </TableCell>
                    ))}
                {filteredHeaders.includes('Associated Orders') && (
                    <TableCell>
                        {_.get(commodityPickup, 'status', '') !== 'Requested' &&
                        !_.isNil(_.get(commodityPickup, 'bulks'))
                            ? _.get(commodityPickup, 'bulks', []).length
                            : ''}
                    </TableCell>
                )}
                {filteredHeaders.includes('Associated Bags/Items') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'commodityAmountTotal')) &&
                            `${_.get(commodityPickup, 'commodityAmountTotal', '')} ${_.get(
                                commodityPickup,
                                `commodity.${lang}.unit`,
                                ''
                            )}`}
                    </TableCell>
                )}

                {filteredHeaders.includes('Payment Total') && (
                    <TableCell data-cy={`commodity-pickup-payment-total-${i}`}>
                        {!_.isNil(_.get(commodityPickup, 'paymentTotal')) &&
                            formatAsCurrency(_.get(commodityPickup, 'paymentTotal'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Processor Payment') && (
                    <TableCell data-cy={`commodity-pickup-collector-payment-total-${i}`}>
                        {!_.isNil(_.get(commodityPickup, 'collectorPaymentTotal')) &&
                            formatAsCurrency(_.get(commodityPickup, 'collectorPaymentTotal'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Driver Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'driverSplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'driverSplit'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Customer Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'customerSplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'customerSplit'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Charity Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'charitySplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'charitySplit'))}
                    </TableCell>
                )}

                {filteredHeaders.includes('Payload') && (
                    <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    width: '350px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'noWrap'
                                }}
                            >
                                {payloadFormatted}
                            </div>
                        </div>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return children;
        }
    };

    const handleSubmit = e => {
        handleLoadStats();
        getData();

        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    /*
    {
        skuType:{
            collectorAmounts:{
                "5e7fe47798452c72f59a6a6b": {
                    "totalBulks": 1,
                    "totalCommodityAmount": 3,
                    "earliestDate": "2024-05-01T22:56:20.823Z",
                    "lastDate": "2024-05-01T22:56:20.823Z"
                }
            }
        }
    }

    [
        {
            collector: {
            },
            openBulks:[
                {
                    commodity,
                    "totalCommodityAmount": 3,
                    "earliestDate": "2024-05-01T22:56:20.823Z",
                    "lastDate": "2024-05-01T22:56:20.823Z"
                }
            ]
        }
    ]
    */

    const openBulksByCollector = useMemo(() => {
        return allCollectors
            .map(collector => {
                const collectorId = _.get(collector, '_id');
                const openBulksForCollector = Object.keys(openBulkBreakdown)
                    .map(skuType => {
                        const commodity = _.find(allCommodities, { skuType });
                        const valuesForCollector = _.get(
                            openBulkBreakdown,
                            `${skuType}.collectorAmounts.${collectorId}`
                        );
                        if (!_.isNil(commodity) && !_.isNil(valuesForCollector)) {
                            return {
                                commodity,
                                ...valuesForCollector
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter(entry => !_.isNil(entry));
                return {
                    collector,
                    openBulks: openBulksForCollector
                };
            })
            .filter(entry => !_.isEmpty(_.get(entry, 'openBulks')));
    }, [openBulkBreakdown, allCollectors, allCommodities]);

    useEffect(() => {
        if (!filtersLoading) {
            handleLoadStats();
        }
    }, [collectorsSelected, commoditiesSelected, filtersLoading, tabSelected]);

    useEffect(() => {
        if (tabSelected === 'Incomplete') {
            handleChangeRowsPerPage({
                target: {
                    value: 50
                }
            });
        } else {
            handleChangeRowsPerPage({
                target: {
                    value: 10
                }
            });
        }
    }, [tabSelected]);

    useEffect(() => {
        let interval = null;

        const commodityPickupProcessing = _.some(filteredData, commodityPickup => {
            return _.get(commodityPickup, 'processing', false);
        });
        if (commodityPickupProcessing) {
            interval = setInterval(() => {
                getData();
            }, 5000);
        }

        return () => {
            if (!_.isNil(interval)) {
                clearInterval(interval);
            }
        };
    }, [filteredData]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonGroup
                            value={tabSelected}
                            exclusive
                            onChange={(e, toggleType) => {
                                if (!_.isNil(toggleType)) {
                                    setTabSelected(toggleType);
                                }
                            }}
                        >
                            <ToggleButton value="Incomplete">Incomplete</ToggleButton>
                            <ToggleButton value="Complete">Complete</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Grid>
            </Grid>
            <div
                style={{
                    padding: theme.spacing.unit,
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2
                }}
            >
                {openBulkBreakdownLoading && <LinearProgress />}
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        height: '100%'
                    }}
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: theme.spacing.unit }}>
                        {openBulksByCollector.map((collectorEntry, i) => (
                            <InlineChip
                                key={i}
                                style={{ margin: theme.spacing.unit * 0.5 }}
                                color={colors.blue[500]}
                                textColor={'white'}
                                text={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flex: 1,
                                            padding: theme.spacing.unit * 0.5
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            textAlign="center"
                                            style={{ marginRight: theme.spacing.unit }}
                                        >
                                            {_.get(collectorEntry, 'collector.code')}
                                        </Typography>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {_.get(collectorEntry, 'openBulks', []).map((openBulksForCommodity, i) => {
                                                const earliestDate = _.get(openBulksForCommodity, 'earliestDate');
                                                const lastDate = _.get(openBulksForCommodity, 'lastDate');
                                                const earliestDateFormatted = _.isNil(earliestDate)
                                                    ? 'N/A'
                                                    : moment(earliestDate).format('MMM D');
                                                const lastDateFormatted = _.isNil(lastDate)
                                                    ? 'N/A'
                                                    : moment(lastDate).format('MMM D');
                                                return _.get(openBulksForCommodity, 'totalCommodityAmount', 0) ===
                                                    0 ? null : (
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                        key={i}
                                                    >
                                                        <MDIcon
                                                            path={_.get(
                                                                allIcons,
                                                                _.get(openBulksForCommodity, 'commodity.iconName')
                                                            )}
                                                            size={0.65}
                                                            color="white"
                                                            style={{ marginRight: theme.spacing.unit * 0.5 }}
                                                        />
                                                        <div>
                                                            {_.get(openBulksForCommodity, 'totalCommodityAmount', 0)}{' '}
                                                            {_.get(openBulksForCommodity, 'commodity.skuType', '') ===
                                                            'clothing'
                                                                ? 'bags'
                                                                : 'items'}{' '}
                                                            | {earliestDateFormatted} - {lastDateFormatted}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            {renderSearch(
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {tabSelected === 'Complete' && (
                                            <DateRangePicker
                                                timezone={timezone}
                                                startDate={startDate}
                                                endDate={endDate}
                                                window={dateWindow}
                                                disabled={loading}
                                                handlePrevious={handleGoBackwards}
                                                handleNext={handleGoForwards}
                                                handleChangeStartDate={handleChangeStartDate}
                                                handleChangeEndDate={handleChangeEndDate}
                                            />
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {(_user.isSystemAdmin(operator) ||
                                            _user.isInternalRole(operator) ||
                                            _.get(operator, 'collectors', []).length > 1) && (
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setCollectorsSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={collectorsSelected}
                                                    renderValue={s => `${s.length} Processors`}
                                                    data-cy="commodity-pickups-collector-select"
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(false)}
                                                            data-cy="commodity-pickups-collector-select-none"
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allCollectors.map(({ _id, name }) => (
                                                        <MenuItem
                                                            disabled={loading}
                                                            key={_id}
                                                            value={_id}
                                                            data-cy={`commodity-pickups-collector-select-${_.kebabCase(
                                                                name
                                                            )}`}
                                                        >
                                                            <Checkbox checked={collectorsSelected.includes(_id)} />
                                                            <ListItemText>{name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        <FormControl>
                                            <Select
                                                multiple
                                                disabled={loading}
                                                onChange={e => setCommoditiesSelected(e.target.value)}
                                                style={{
                                                    maxWidth: '250px',
                                                    margin: theme.spacing.unit
                                                }}
                                                value={commoditiesSelected}
                                                renderValue={s => `${s.length} Commodities`}
                                                data-cy="commodity-pickups-commodity-select"
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <Button
                                                        disabled={loading}
                                                        onClick={handleAllFilterCommodities(true)}
                                                    >
                                                        All
                                                    </Button>
                                                    <Button
                                                        disabled={loading}
                                                        onClick={handleAllFilterCommodities(false)}
                                                        data-cy="commodity-pickups-commodity-select-none"
                                                    >
                                                        None
                                                    </Button>
                                                </div>
                                                {allCommodities.map(({ _id, skuType }) => (
                                                    <MenuItem
                                                        disabled={loading}
                                                        key={_id}
                                                        value={_id}
                                                        data-cy={`commodity-pickups-commodity-select-${_.kebabCase(
                                                            skuType
                                                        )}`}
                                                    >
                                                        <Checkbox checked={commoditiesSelected.includes(_id)} />
                                                        <ListItemText style={{ textTransform: 'capitalize' }}>
                                                            {skuType}
                                                        </ListItemText>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                            <Button
                                                color="secondary"
                                                disabled={loading}
                                                variant="outlined"
                                                onClick={handleOpenCompleteDialog}
                                                data-cy="commodity-pickup-completion-btn"
                                            >
                                                {_.isEmpty(commodityPickupsChecked) ? 'Create' : 'Edit'}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {collapseSearch && (
                                <div style={{ textAlign: 'right' }}>
                                    <IconButton onClick={handleFiltersOpen()} data-cy="commodity-pickups-filters-btn">
                                        <Icon>filter_list</Icon>
                                    </IconButton>
                                </div>
                            )}
                        </form>
                    </div>
                </Paper>
            </div>
            <DashBoardTable
                title={'Requested Pickups'}
                loading={loading || tableLoading || filtersLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={
                    <FormControl style={inputStyles}>
                        <InputLabel htmlFor="collection" variant="outlined">
                            Column Filters
                        </InputLabel>
                        <Select
                            variant="outlined"
                            multiple
                            value={filteredHeaders}
                            onChange={handleChangeFilterHeaders}
                            style={{ width: 250 }}
                            input={<OutlinedInput labelWidth={140} />}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                            </div>
                            {headers.map(({ name, locKey }) => (
                                <MenuItem key={name} value={name}>
                                    {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            >
                {filteredData &&
                    filteredData.map((commodityPickup, i) => {
                        return renderRow(commodityPickup, filteredHeaders, i);
                    })}
            </DashBoardTable>
            <GenericDialog
                open={payloadDialogOpen}
                onClose={() => setPayloadDialogOpen(false)}
                title={`${uppercaseFirstLetter(_.get(commodityPickupSelected, 'commodity.skuType', ''))} Pickup`}
                message={
                    <>
                        <div>
                            <b>Reference Number:</b> {_.get(commodityPickupSelected, 'collectionId', '')} -{' '}
                            {_.get(commodityPickupSelected, 'collectionLabel', '')}
                        </div>
                        <div style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <b>Collection Period:</b>{' '}
                            {formatDate(
                                _.get(commodityPickupSelected, 'collectionPeriod.startDate'),
                                tzCommodityPickupSelected
                            )}{' '}
                            -{' '}
                            {formatDate(
                                _.get(commodityPickupSelected, 'collectionPeriod.endDate'),
                                tzCommodityPickupSelected
                            )}
                        </div>

                        <ImageViewer
                            displayImages={_.get(commodityPickupSelected, 'imagesOnRequest', [])}
                            disableDelete={true}
                            scrollerStyles={{
                                maxWidth: 420,
                                overflowX: 'auto',
                                marginBottom: theme.spacing.unit
                            }}
                        />

                        <div style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <b>Comments:</b>
                            <br /> <Typography>{_.get(commodityPickupSelected, 'commentsOnRequest', '')}</Typography>
                            <Typography>{_.get(commodityPickupSelected, 'commentsOnCollection', '')}</Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>
                                <b>Payload:</b>
                            </span>
                            {_(_.get(commodityPickupSelected, 'payload', []))
                                .filter(p => p.quantity > 0)
                                .map((p, i) => (
                                    <span key={i} data-cy={`commodity-pickup-payload-${_.kebabCase(p.description)}`}>
                                        {p.description}: {p.quantity} {p.unit || ''}
                                    </span>
                                ))
                                .value()}
                        </div>
                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                            Total Items: {_.sumBy(_.get(commodityPickupSelected, 'payload', []), 'quantity')}
                        </div>
                    </>
                }
            />
            {commodityPickupDialogOpen && (
                <CommodityPickupDialog
                    open={commodityPickupDialogOpen}
                    collectors={allCollectors}
                    commodities={allCommodities}
                    initialCommodity={
                        editingCommodityPickup ? _.get(commodityPickupSelected, 'commodity') : commodityToEdit
                    }
                    initialStatus={editingCommodityPickup ? _.get(commodityPickupSelected, 'status') : statusToEdit}
                    //commodityPickup={editingCommodityPickup ? commodityPickupSelected : _.first()}
                    commodityPickups={editingCommodityPickup ? [commodityPickupSelected] : commodityPickupsChecked}
                    loading={loading || tableLoading}
                    onClose={() => setCommodityPickupDialogOpen(false)}
                    onSubmit={
                        _.isEmpty(commodityPickupsChecked) ? handleCreateComodityPickup : handleUpdateCommodityPickup
                    }
                />
            )}

            <Dialog open={imageDialogOpen} fullWidth onClose={() => setImageDialogOpen(false)}>
                <ImageViewer
                    displayImages={images}
                    disableDelete={true}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
            <TrailerLabelDialog
                open={trailerLabelDialogOpen}
                trailerLabel={trailerLabel}
                onClose={handleCloseTrailerLabelDialog}
                onLabelChange={handleChangeLabel}
                onSubmit={handleSubmitTrailerLabel}
            />
        </>
    );
}

export default withTheme()(CommodityPickups);

function formatDate(date, timezone) {
    return !_.isNil(date)
        ? moment(date)
              .tz(timezone)
              .format('MMM D, YYYY')
        : 'N/A';
}

function getCommodityPickupQuery({
    tabSelected,
    operator,
    collector,
    collectorsSelected,
    commoditiesSelected,
    startDate,
    endDate
}) {
    if (tabSelected === 'Incomplete') {
        return `collectors=${
            !_user.isSystemAdmin(operator) &&
            !_user.isInternalRole(operator) &&
            _.get(operator, 'collectors', []).length <= 1
                ? collector._id
                : collectorsSelected
        }&commodities=${commoditiesSelected}&status=${
            tabSelected === 'Incomplete' ? ['Requested', 'Collected'] : ['Processed']
        }`;
    } else {
        return `collectors=${
            !_user.isSystemAdmin(operator) &&
            !_user.isInternalRole(operator) &&
            _.get(operator, 'collectors', []).length <= 1
                ? collector._id
                : collectorsSelected
        }&commodities=${commoditiesSelected}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&status=${
            tabSelected === 'Incomplete' ? ['Requested', 'Collected'] : ['Processed']
        }`;
    }
}
