import React, { useState, useMemo, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';

import useGetJSON from 'utils/hooks/useGetJSON';
import { formatAsCurrency, downloadObjectAsCSV } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CreateInvoiceDialog(props) {
    const { theme, http, open, onClose, onSnackbar, refreshInvoices } = props;
    const { lang } = useContext(LocalizationContext);

    const { loading: collectorsAvailableLoading, data: collectorsAvailable } = useGetJSON(
        '/collectors',
        'collectors',
        []
    );

    const [collectorIdSelected, setCollectorIdSelected] = useState('');
    const collectorSelected = useMemo(() => _.find(collectorsAvailable, c => c._id === collectorIdSelected), [
        collectorIdSelected,
        collectorsAvailable
    ]);

    const { date: dateSelected, handleChangeDate: setDateSelected } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: moment(),
        initToNull: false
    });

    const [loadingData, setLoadingData] = useState(false);
    const [invoiceLines, setInvoiceLines] = useState([]);
    const [totalAmount, setTotalAmount] = useState(null); // in cents
    const [report, setReport] = useState(null);
    const [depositBulkIds, setDepositBulkIds] = useState([]);
    const [csfBulkIds, setCSFBulkIds] = useState([]);
    const [commodityPickups, setCommodityPickups] = useState([]);
    const [cashOutRedemptions, setCashOutRedemptions] = useState([]);
    const [driverRedemptions, setDriverRedemptions] = useState([]);
    const [driverTips, setDriverTips] = useState([]);

    const handleCollector = _id => {
        setCollectorIdSelected(_id);
        setInvoiceLines([]);
        setTotalAmount(null);
        setReport(null);
        setDepositBulkIds([]);
        setCSFBulkIds([]);
        setCommodityPickups([]);
        setCashOutRedemptions([]);
        setDriverRedemptions([]);
        setDriverTips([]);
    };

    const handleFetchData = async () => {
        setLoadingData(true);

        const res = await http.postJSON(`/quickbooks/prepareInvoice`, {
            collector_id: collectorIdSelected,
            date: dateSelected.toDate()
        });
        if (res.ok) {
            setInvoiceLines(res.data.invoiceLines);
            setTotalAmount(res.data.totalAmount);
            setReport(res.data.report);
            setDepositBulkIds(res.data.depositBulkIds);
            setCSFBulkIds(res.data.csfBulkIds);
            setCommodityPickups(res.data.commodityPickups);
            setCashOutRedemptions(res.data.cashOutRedemptions);
            setDriverRedemptions(res.data.driverRedemptions);
            setDriverTips(res.data.tips);
        }

        setLoadingData(false);
    };

    const handleDownloadCSV = async () => {
        await downloadObjectAsCSV(
            report,
            `Invoice report ${collectorSelected.name} ${moment(dateSelected).format('YY-MM-DD')}`
        );
    };

    const handleSubmit = async () => {
        setLoadingData(true);

        const res = await http.post('/quickbooks/createInvoice', {
            invoiceLines: invoiceLines,
            collector_id: collectorIdSelected,
            depositBulkIds: depositBulkIds,
            csfBulkIds: csfBulkIds,
            commodityPickupIds: commodityPickups.map(commodityPickup => commodityPickup._id),
            cashOutRedemptions: cashOutRedemptions.map(redemption => redemption._id),
            driverRedemptions: driverRedemptions.map(redemption => redemption._id),
            driverTips: driverTips.map(tip => tip._id)
        });
        if (res.ok) {
            onSnackbar('Invoice created successfully.');
            refreshInvoices();
            onClose();

            downloadObjectAsCSV(
                report,
                `Invoice report ${collectorSelected.name} ${moment(dateSelected).format('YY-MM-DD')}`
            );
        }

        setLoadingData(false);
    };

    const inProgress = collectorsAvailableLoading || loadingData;
    const intuitConfigNotSet = !_.isNil(collectorSelected) && _.isNil(collectorSelected.intuit);

    return (
        <Dialog fullWidth open={open} onClose={inProgress ? () => null : onClose} maxWidth="md">
            <DialogTitle>Create invoice</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The algorithm will get all counted uninvoiced bulks that were picked up prior to and including
                    selected date and generate a corresponding invoice in Quickbooks. It will{' '}
                    <span style={{ fontWeight: 500, color: theme.palette.text.primary }}>not</span> send the invoice.
                    Use Quickbooks to further modify and send invoice to the client.
                </DialogContentText>

                <div style={{ width: '100%', maxWidth: 320 }}>
                    <FormControl
                        fullWidth
                        data-cy="create-invoice-dialog-collector-select"
                        style={{ marginTop: theme.spacing.unit }}
                    >
                        <InputLabel htmlFor="collectorIdSelected">Client</InputLabel>
                        <Select
                            inputProps={{ id: 'collectorIdSelected', name: 'collectorIdSelected' }}
                            value={collectorIdSelected}
                            onChange={e => handleCollector(e.target.value)}
                        >
                            {collectorsAvailable.map(collector => {
                                return (
                                    <MenuItem
                                        key={collector._id}
                                        value={collector._id}
                                        data-cy={`create-invoice-${collector.name
                                            .replace(/\s/g, '-')
                                            .toLowerCase()}-li`}
                                    >
                                        {collector.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                        <DatePicker
                            timezone={process.env.REACT_APP_REGION_TIMEZONE}
                            disableFuture
                            disabled={_.isEmpty(collectorIdSelected)}
                            label="Date"
                            name="dateSelected"
                            format="DD MMM YYYY"
                            value={dateSelected}
                            onChange={date => setDateSelected(date)}
                        />
                    </FormControl>
                </div>

                <div
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        marginLeft: theme.spacing.unit * -0.5,
                        marginRight: theme.spacing.unit * -0.5
                    }}
                >
                    <Button
                        data-cy="create-invoice-dialog-fetch-data"
                        disabled={_.isEmpty(collectorIdSelected)}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}
                        onClick={handleFetchData}
                    >
                        Fetch data
                    </Button>
                    <Button
                        disabled={_.isNil(report)}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}
                        onClick={handleDownloadCSV}
                    >
                        {loc('taxReceipts25', lang)}
                    </Button>
                </div>

                <Table style={{ marginTop: theme.spacing.unit * 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="dense">#</TableCell>
                            <TableCell padding="dense">Description</TableCell>
                            <TableCell padding="dense" align="right">
                                Qty
                            </TableCell>
                            <TableCell padding="dense" align="right">
                                Rate
                            </TableCell>
                            <TableCell padding="dense" align="right">
                                Amount
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceLines.map((line, i) => {
                            return (
                                <TableRow key={line.index}>
                                    <TableCell padding="dense">{line.index}</TableCell>
                                    <TableCell padding="dense">{line.description}</TableCell>
                                    <TableCell
                                        padding="dense"
                                        data-cy={`create-invoice-dialog-line-qty-${i}`}
                                        align="right"
                                    >
                                        {!_.isNil(line.quantity) && line.quantity.toLocaleString()}
                                    </TableCell>
                                    <TableCell
                                        data-cy={`create-invoice-dialog-line-rate-${i}`}
                                        align="right"
                                        style={{
                                            color:
                                                (!_.isNil(line.afterTaxRate) ? line.afterTaxRate : line.afterTaxAmount) < 0
                                                    ? colors.green[500]
                                                    : null
                                        }}
                                        padding="dense"
                                    >
                                        {(
                                            (!_.isNil(line.afterTaxRate) ? line.afterTaxRate : line.afterTaxAmount) /
                                            100 || 0
                                        ).toLocaleString('en-CA', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'CAD'
                                        })}
                                    </TableCell>
                                    <TableCell
                                        data-cy={`create-invoice-dialog-line-amount-${i}`}
                                        align="right"
                                        style={{ color: line.afterTaxAmount < 0 ? colors.green[500] : null }}
                                        padding="dense"
                                    >
                                        {formatAsCurrency(line.afterTaxAmount)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: theme.spacing.unit * 3 }}>
                    Total amount:{' '}
                    <span
                        style={{ fontWeight: 500, color: theme.palette.text.primary }}
                        data-cy="create-invoice-dialog-total"
                    >
                        {formatAsCurrency(totalAmount)}
                    </span>
                </Typography>

                {intuitConfigNotSet && (
                    <Typography
                        variant="body1"
                        style={{ marginTop: theme.spacing.unit * 2, color: theme.palette.error.main }}
                    >
                        Unable to create invoice because the selected collector does not have its Intuit configuration
                        set.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    disabled={_.isEmpty(invoiceLines) || inProgress || intuitConfigNotSet}
                    onClick={handleSubmit}
                >
                    Create
                </Button>
            </DialogActions>
            {inProgress && <LinearProgress />}
        </Dialog>
    );
}

export default withTheme()(CreateInvoiceDialog);
