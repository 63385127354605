import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Table from 'components/Table/Table';

import useTable from 'components/Table/useTable';

import {
    TableRow,
    TableCell,
    colors,
    Icon,
    IconButton,
    Grid,
    Paper,
    MenuItem,
    FormControl,
    Select,
    Button,
    withTheme
} from '@material-ui/core';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';

import { _time } from 'std';

import { getCustomerName, formatAsCurrency, downloadObjectAsCSV } from 'utils/misc';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { Clipboard } from '@capacitor/clipboard';
import HttpContext from 'utils/contexts/HttpContext';

const headers = [
    { name: 'Actions', locKey: null },
    { name: 'E-mail', locKey: null },
    { name: 'Name', locKey: null },
    { name: 'Date Requested', locKey: null },
    { name: 'Amount', locKey: null },
    { name: 'Date Completed', locKey: null },
    { name: 'Type', locKey: null },
    { name: 'Method', locKey: null },
    { name: 'Question', locKey: null },
    { name: 'Passphrase', locKey: null }
];

function CompletedRedemptionTable(props) {
    const { onInfoDialog, redemptionOptions, theme } = props;

    const disableInAppRedemptionCancel = _.get(redemptionOptions, 'disableInAppRedemptionCancel', false);
    const hideEtransferPassphrase = _.get(redemptionOptions, 'hideEtransferPassphrase', false);

    if (hideEtransferPassphrase) {
        const index = headers.findIndex(header => header.name === 'Passphrase');
        if (index !== -1) {
            headers.splice(index, 1);
        }
    }
    const displayedHeaders = disableInAppRedemptionCancel ? headers.slice(1) : headers;

    const [tabFilter, setTabFilter] = useState('all');

    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);

    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/redemptions/completed?types=${getTypesForTab(tabFilter)}&paymentModels=${getPaymentsModelsForTab(
            tabFilter
        )}`,
        key: 'redemptions',
        headers: displayedHeaders.map(h => h.name)
    });

    const handleReverseRedemption = async redemption => {
        const res = await http.post('/redemptions/reject', { redemptionIds: [redemption._id] });
        if (res.ok) {
            onSnackbar('Successfully reversed redemption');
            getData();
        }
    };

    const handleCopy = string => {
        try {
            onSnackbar(`Copied ${string} to your clipboard!`, 'success', 1000);
            //Remove $ from sting
            if (string[0] === '$') {
                string = string.substring(1);
            }
            Clipboard.write({ string });
        } catch (err) {
            onSnackbar('Unable to copy value to clipboard', 'error', 1000);
        }
    };

    const handleDownloadRedemptions = async () => {
        const res = await http.getJSON(
            `/redemptions/completed?types=${getTypesForTab(tabFilter)}&paymentModels=${getPaymentsModelsForTab(
                tabFilter
            )}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&searchTerm=${search}&page_size=${1000000}`
        );

        if (res.ok) {
            const csvRows = [];
            const redemptions = _.get(res, 'data.redemptions', []);
            redemptions.forEach(redemption => {
                let method = '';
                //let action = redemption.type === 'Driver' ? addInfoButton(redemption) : '';
                switch (redemption.paymentModel) {
                    case 'INTERAC_ETRANSFERS':
                        method = loc('redemption4', lang);
                        break;
                    case 'CHEQUE':
                        method = loc('redemption5', lang);
                        break;
                    case 'CASH':
                        method = loc('redemption7', lang);
                        break;
                    case 'BANK_TRANSFER':
                        method = loc('redemption6', lang);
                        break;
                    default:
                        break;
                }

                csvRows.push({
                    'E-mail': _.get(redemption, 'email'),
                    Name:
                        redemption.type === 'Customer' || redemption.type === 'Driver'
                            ? getCustomerName(redemption.claimant)
                            : redemption.charity.name,
                    'Date Requested': moment(redemption.createdAt)
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .format('LT MMM Do, YYYY'),
                    Amount: formatAsCurrency(redemption.amount - redemption.fee),
                    'Date Completed': moment(_.get(redemption, 'completionDate', 'N/A'))
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .format('LT MMM Do, YYYY'),
                    Type: redemption.type,
                    Method: method,
                    Question: _.get(redemption, 'question')
                });
            });

            try {
                await downloadObjectAsCSV(csvRows, `completed_redemptions_${moment().format('YYYY-MM-DD')}`);
            } catch (err) {
                console.log(err);
            }
        } else {
            onSnackbar('Error downloading redemptions', 'error');
        }
    };

    const addCopyButton = string => (
        <span onClick={() => handleCopy(string)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {string}
        </span>
    );

    const addInfoButton = breakdown => (
        <IconButton onClick={() => onInfoDialog(breakdown)}>
            <Icon>info</Icon>
        </IconButton>
    );

    const renderRow = (redemption, filteredHeaders) => {
        let action = (
            <div style={{ display: 'flex', width: '100%' }}>
                <IconButton
                    onClick={() =>
                        warnAction(
                            async () => await handleReverseRedemption(redemption),
                            'Are you sure you want to reverse this redemption?'
                        )
                    }
                >
                    <Icon>undo</Icon>
                </IconButton>
                {redemption.type === 'Driver' ? addInfoButton(redemption) : ''}
            </div>
        );

        let method;
        //let action = redemption.type === 'Driver' ? addInfoButton(redemption) : '';
        switch (redemption.paymentModel) {
            case 'INTERAC_ETRANSFERS':
                method = (
                    <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                        {loc('redemption4', lang)}
                    </span>
                );
                break;
            case 'CHEQUE':
                method = (
                    <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                        {loc('redemption5', lang)}
                    </span>
                );
                break;
            case 'CASH':
                method = <span style={{ color: colors.green[500], fontWeight: 500 }}>{loc('redemption7', lang)}</span>;
                break;
            case 'BANK_TRANSFER':
                method = <span style={{ color: colors.orange[500], fontWeight: 500 }}>{loc('redemption6', lang)}</span>;
                break;
            default:
                break;
        }

        return (
            <TableRow key={redemption._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {action}
                    </TableCell>
                )}
                {filteredHeaders.includes('E-mail') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {addCopyButton(_.get(redemption, 'email'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Name') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {addCopyButton(
                            redemption.type === 'Customer' || redemption.type === 'Driver'
                                ? getCustomerName(redemption.claimant)
                                : redemption.charity.name
                        )}
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Requested') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {moment(redemption.createdAt)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Amount') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {addCopyButton(formatAsCurrency(redemption.amount - redemption.fee))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Completed') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {moment(_.get(redemption, 'completionDate', 'N/A'))
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Type') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {redemption.type}
                    </TableCell>
                )}
                {filteredHeaders.includes('Method') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {method}
                    </TableCell>
                )}
                {filteredHeaders.includes('Question') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {addCopyButton(_.get(redemption, 'question'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Passphrase') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {addCopyButton(_.get(redemption, 'passphrase'))}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        value={tabFilter}
                                        disabled={loading}
                                        onChange={e => setTabFilter(e.target.value)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="e-transfer">E-Transfer</MenuItem>
                                        <MenuItem value="cheque">Cheque</MenuItem>
                                        <MenuItem value="bank transfer">Bank Transfer</MenuItem>
                                        <MenuItem value="cash">Cash</MenuItem>
                                        <MenuItem value="driver">Driver</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                dataCy="completed-redemptions-table"
                title="Completed"
                loading={loading}
                headers={displayedHeaders}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                cellStyle={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                customAction={
                    <Button
                        disabled={loading}
                        color="primary"
                        style={{ marginRight: theme.spacing.unit }}
                        onClick={handleDownloadRedemptions}
                    >
                        <Icon>get_app</Icon>
                    </Button>
                }
            >
                {filteredData &&
                    filteredData.map(data => {
                        const rowData = hideEtransferPassphrase ? { ...data, passphrase: undefined } : data;
                        return renderRow(rowData, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(CompletedRedemptionTable);

function getTypesForTab(tabFilter) {
    switch (tabFilter) {
        case 'all':
            return ['Customer', 'Charity', 'Driver'];
        case 'e-transfer':
            return ['Customer', 'Charity'];
        case 'cheque':
            return ['Customer', 'Charity'];
        case 'bank transfer':
            return ['Customer', 'Charity'];
        case 'cash':
            return ['Customer', 'Charity'];
        case 'driver':
            return ['Driver'];
        default:
            return ['Customer', 'Charity', 'Driver'];
    }
}

function getPaymentsModelsForTab(tabFilter) {
    switch (tabFilter) {
        case 'all':
            return ['INTERAC_ETRANSFERS', 'CHEQUE', 'BANK_TRANSFER', 'CASH'];
        case 'e-transfer':
            return ['INTERAC_ETRANSFERS'];
        case 'cheque':
            return ['CHEQUE'];
        case 'bank transfer':
            return ['BANK_TRANSFER'];
        case 'cash':
            return ['CASH'];
        case 'driver':
            return ['INTERAC_ETRANSFERS', 'CHEQUE', 'BANK_TRANSFER', 'CASH'];
        default:
            return ['INTERAC_ETRANSFERS', 'CHEQUE', 'BANK_TRANSFER', 'CASH'];
    }
}
