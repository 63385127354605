import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { deviceHelper, isProductionEnv, downloadObjectAsCSV } from 'utils/misc';

import HttpContext from 'utils/contexts/HttpContext';

function DebugOverlay({ bytesSent, bytesReceived, debugHistory, clearHistory, getEventSources }) {
    const http = useContext(HttpContext);

    const [showDebug, setShowDebug] = useState(false);

    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    const [shiftDown, setShiftDown] = useState(false);

    const mouseMoveEventHandler = event => {
        setMouseX(event.clientX);
        setMouseY(event.clientY);
    };

    const keyDownEventHandler = event => {
        if (event.code === 'Backquote' && shiftDown) {
            setShowDebug(!showDebug);
        }
        if (event.code === 'ShiftLeft' && !shiftDown) {
            setShiftDown(true);
        }
    };

    const keyUpEventHandler = event => {
        if (event.code === 'ShiftLeft') {
            setShiftDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', keyUpEventHandler);
        document.addEventListener('keydown', keyDownEventHandler);
        document.addEventListener('mousemove', mouseMoveEventHandler);

        return () => {
            document.removeEventListener('keyup', keyUpEventHandler);
            document.removeEventListener('keydown', keyDownEventHandler);
            document.removeEventListener('mousemove', mouseMoveEventHandler);
        };
    });

    const formatBytes = bytes => {
        if (bytes === 0) {
            return '? B';
        } else if (bytes >= 1000) {
            return `${bytes / 1000} KB`;
        } else if (bytes >= 1000000) {
            return `${bytes / 1000000} MB`;
        } else if (bytes >= 1000000000) {
            return `too much`;
        }
        return `${bytes} B`;
    };

    const runTestEndpoint = async () => {
        const res = await http.postJSON(`/endpoint/to/test/here`, {});

        if (res.ok) {
            console.log({ res });
        }
    };

    const runDebugScript = async () => {
        const res = await http.getJSON(`/system/runDebugScript`);

        if (res.ok) {
            console.log({ res });
            await downloadObjectAsCSV(res.data, `Script Report`);
        }
    };

    if (!showDebug || deviceHelper.isNativeApp() || isProductionEnv) return null;

    const headerStyle = {
        color: 'black',
        margin: '4px',
        fontSize: '12px',
        wordBreak: 'break-all'
    };

    const valueStyle = {
        color: 'red'
    };

    return (
        <div
            style={{
                backgroundColor: 'lightgrey',
                opacity: 0.85,
                color: 'black',
                minHeight: '100px',
                height: 'auto',
                width: '350px',
                position: 'fixed',
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                padding: '20px'
            }}
        >
            <div
                onClick={() => setShowDebug(false)}
                style={{
                    ...headerStyle,
                    cursor: 'pointer',
                    color: 'blue',
                    position: 'fixed',
                    marginRight: '14px',
                    right: 0
                }}
            >
                [X]
            </div>
            <div style={headerStyle}>
                Mouse X: <span style={valueStyle}>{mouseX}</span>, Mouse Y: <span style={valueStyle}>{mouseY}</span>
            </div>
            <div style={headerStyle}>
                Data Sent: <span style={valueStyle}>{bytesSent === 0 ? 0 : formatBytes(bytesSent)}</span>
            </div>
            <div style={headerStyle}>
                Data Received: <span style={valueStyle}>{formatBytes(bytesReceived)}</span>
            </div>
            {/* <div style={headerStyle}>Initial Render Time: </div> */}
            {/* <div style={headerStyle}>Total Render Time: </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...headerStyle, textDecoration: 'underline' }}>Console </div>

                <div style={{ display: 'flex' }}>
                    <div onClick={runTestEndpoint} style={{ ...headerStyle, color: 'blue', cursor: 'pointer' }}>
                        Endpoint{' '}
                    </div>
                    <div onClick={runDebugScript} style={{ ...headerStyle, color: 'blue', cursor: 'pointer' }}>
                        Script{' '}
                    </div>
                    <div onClick={getEventSources} style={{ ...headerStyle, color: 'blue', cursor: 'pointer' }}>
                        Events{' '}
                    </div>
                    {debugHistory.length > 0 && (
                        <div onClick={clearHistory} style={{ ...headerStyle, color: 'blue', cursor: 'pointer' }}>
                            Clear{' '}
                        </div>
                    )}
                </div>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                {debugHistory.map(item => {
                    const type = item.type;
                    const url = item.url;
                    const size = formatBytes(item.size);
                    const startTime = item.startTime;
                    const endTime = item.endTime;
                    const text = item.text;

                    if (type === 'event-sources') {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={headerStyle}>{text}</div>
                            </div>
                        );
                    }

                    if (type === 'http-get') {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={headerStyle}>GET {url}</div>
                                <div
                                    style={{
                                        ...headerStyle,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: '8px',
                                        marginTop: '2px'
                                    }}
                                >
                                    <span style={valueStyle}>
                                        {size}, Start: {moment(startTime).format('ss:SSS')}, End:{' '}
                                        {moment(endTime).format('ss:SSS')}, {endTime - startTime} ms
                                    </span>{' '}
                                </div>
                            </div>
                        );
                    }

                    if (type === 'http-post') {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={headerStyle}>POST {url}</div>
                                <div
                                    style={{
                                        ...headerStyle,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: '8px',
                                        marginTop: '2px'
                                    }}
                                >
                                    <span style={valueStyle}>
                                        {size}, Start: {moment(startTime).format('ss:SSS')}, End:{' '}
                                        {moment(endTime).format('ss:SSS')}, {endTime - startTime} ms
                                    </span>{' '}
                                </div>
                            </div>
                        );
                    }

                    return null;
                })}
            </div>
        </div>
    );
}

export default DebugOverlay;
