import React, { Component } from 'react';
import SwipeableViews from 'resources/temp_packages/SwipeableViews';
import { autoPlay } from 'react-swipeable-views-utils';

import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Chip, Icon } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { deviceHelper } from 'utils/misc';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class FeaturedCharityWidget extends Component {
    static contextType = LocalizationContext;
    state = {
        charityIndex: 0
    };

    handleCharityIndex = charityIndex => {
        this.setState({ charityIndex });
    };

    render() {
        const {
            theme,
            elevated,
            charities,
            customer,
            onCharityShareDialogOpen,
            shareDialogEnabled,
            width,
            onNativeShare,
            environmentalStats,
            subject,
            message
        } = this.props;
        const { lang } = this.context;
        const { charityIndex } = this.state;
        const statsMessage = loc('environmentalStats1', lang, {
            landfillStat: environmentalStats.landfill.toLocaleString(undefined, {
                maximumSignificantDigits: 3
            }),
            energyStat: environmentalStats.energy.toLocaleString(undefined, {
                maximumSignificantDigits: 3
            }),
            emissionStat: environmentalStats.emissions.toLocaleString(undefined, {
                maximumSignificantDigits: 3
            }),
            brand: process.env.REACT_APP_BRAND_NAME
        });
        const shareMessage = environmentalStats.emissions > 0 ? statsMessage : message;

        const orgs = charities.map(charity => (
            <List key={charity._id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ListItem
                    disableGutters
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={charity.logo}
                        alt="Charity logo"
                        style={{ width: 32 * 2, maxHeight: 32, objectFit: 'contain' }}
                    />
                    <ListItemText
                        primary={charity.name}
                        secondary={`Featured ${terms.ORGANIZATION_NAME.toLowerCase()}`}
                        primaryTypographyProps={{ noWrap: true, style: { lineHeight: 1.25 } }}
                        secondaryTypographyProps={{ noWrap: true, style: { lineHeight: 1.25 } }}
                    />
                </ListItem>
            </List>
        ));

        if (_.isEmpty(charities)) {
            return null;
        }

        return (
            <Paper
                elevation={elevated ? 4 : 2}
                style={{ margin: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit / 2 }}
            >
                {!_.isEmpty(charities) && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <AutoPlaySwipeableViews
                            index={charityIndex}
                            ignoreNativeScroll
                            interval={5000}
                            style={{
                                paddingLeft: theme.spacing.unit * 2,
                                paddingRight: 0,
                                paddingTop: theme.spacing.unit,
                                paddingBottom: 0,
                                maxWidth: '100%',
                                width: '100%' // Ensure it takes up the full width of the parent
                            }}
                            onChangeIndex={this.handleCharityIndex}
                        >
                            {orgs}
                        </AutoPlaySwipeableViews>
                        <Button
                            data-cy="featured-charity-widget-donate-button"
                            variant="text"
                            color="primary"
                            style={{
                                color: 'white',
                                backgroundColor: theme.palette.primary[500],
                                borderRadius: theme.spacing.unit,
                                margin: `${theme.spacing.unit}px 0`,
                                width: `calc(100% - ${theme.spacing.unit * 4}px)`
                            }}
                            onClick={this.props.onCharityInfoDialog(true, charities[charityIndex])}
                        >
                            {loc('dashboardUpdate1', this.context.lang)}
                        </Button>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        padding: theme.spacing.unit,
                        width: '100%',
                        boxSizing: 'border-box'
                    }}
                >
                    {!_.isNil(customer.charities) &&
                        !_.isEmpty(customer.charities) &&
                        shareDialogEnabled &&
                        customer.charities.map(charity => {
                            return (
                                <Chip
                                    label={
                                        <span style={{ color: 'white' }}>
                                            {loc('labels6', this.context.lang, { charity: charity.name })}
                                        </span>
                                    }
                                    icon={<Icon style={{ color: 'white' }}>share</Icon>}
                                    style={{
                                        backgroundColor: colors.lightBlue[500],
                                        margin: theme.spacing.unit,
                                        maxWidth: `calc(100% - ${theme.spacing.unit * 2}px)`,
                                        flexGrow: 1,
                                        textAlign: 'center',
                                        borderRadius: theme.spacing.unit
                                    }}
                                    onClick={
                                        deviceHelper.isNativeApp() || !_.isNil(window.navigator.share)
                                            ? () =>
                                                  onNativeShare(
                                                      subject,
                                                      shareMessage,
                                                      `${process.env.REACT_APP_ORIGIN_URL}/${charity.url}`
                                                  )
                                            : null
                                    }
                                />
                            );
                        })}
                </div>
            </Paper>
        );
    }
}

export default withTheme()(FeaturedCharityWidget);
