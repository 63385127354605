import React, { useState } from 'react';
import _ from 'lodash';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import { FormControl, Dialog, DialogActions, DialogContent, Button, TextField, Grid } from '@material-ui/core';

import 'react-simple-keyboard/build/css/index.css';

function EditNoteDialog({ theme, open, onClose, handleSubmit, bulk }) {
    const [note, setNote] = useState(_.get(bulk, 'referenceNote', ''));
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                <FormControl fullWidth>
                    <TextField
                        label={'Reference Note'}
                        value={note}
                        onChange={e => {
                            setNote(e.target.value);
                        }}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{}}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onClose}>
                    cancel
                </Button>
                <Button
                    color="primary"
                    size="small"
                    disabled={_.isEmpty(note)}
                    onClick={() => {
                        handleSubmit(note);
                    }}
                >
                    save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EditNoteDialog));
