import React, { useContext } from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme,
    DialogTitle
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function VerifyEmailDialog(props) {
    const { open, handleClose, onVerifyEmail, onResendVerificationEmail, isAdmin, customer, theme } = props;
    const warnAction = useContext(ConfirmDialogContext);

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle>{loc('dashboardUpdate10', lang)}</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
                <DialogContentText>{loc('dashboardUpdate11', lang, { email: customer.email })}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{loc('dismiss', lang)}</Button>
                <Button style={{ marginLeft: theme.spacing.unit }} onClick={onResendVerificationEmail} color="primary">
                    {loc('dashboardUpdate7', lang)}
                </Button>
                {isAdmin && (
                    <Button
                        color="primary"
                        style={{ marginLeft: theme.spacing.unit }}
                        onClick={() =>
                            warnAction(
                                () => onVerifyEmail(),
                                `Are you sure you want to set ${_.get(
                                    customer,
                                    'name.first',
                                    'this customer'
                                )}'s email (${customer.email}) as verified?`
                            )
                        }
                    >
                        Verify Email
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(VerifyEmailDialog);
