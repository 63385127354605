import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';

import {
    convertToImportName,
    DriverCommentMarkdownHelpertext,
    formatAsCurrency,
    getStateAbbreviationFromFullName,
    isAUSRegion
} from 'utils/misc';
import * as userHelper from 'helpers/userHelper';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import AddressDropdown from 'components/RecurringFeesDialog/AddressDropdown';

import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import * as allIcons from '@mdi/js';

import {
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    MenuItem,
    Button,
    IconButton,
    Divider,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    Grid,
    InputAdornment,
    Icon,
    TextField,
    DialogActions,
    Grow,
    colors,
    Switch,
    FormControlLabel,
    Typography,
    Collapse,
    OutlinedInput
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiDelete, mdiPencil } from '@mdi/js';

import useGetJSON from 'utils/hooks/useGetJSON';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';
import { MAX_NUM_OF_RECYCLING_BAGS } from '../../../../constants';
import { useMemo } from 'react';

function ManageDeliveriesDialog(props) {
    const {
        theme,
        open,
        http,
        auth,
        fullScreen,
        customer,
        reload,
        onClose,
        onSnackbar,
        pendingStops,
        transporters,
        location,
        commodities,
        note,
        requestedCommodities
    } = props;
    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);
    const filteredCommodities = useMemo(() => {
        let list = [];
        commodities.forEach(commodity => {
            if (
                commodity.enableCustomerOrders
                // _.get(commodity, 'locationType', []).includes(location.locationType) &&
                // (!isAUSRegion() ||
                //     _.isNil(location.province) ||
                //     _.get(commodity, 'state', []).includes(getStateAbbreviationFromFullName(location.province)))
            ) {
                list.push({
                    _id: commodity._id,
                    name: _.get(commodity, `payloadInputName.${lang}`),
                    icon: _.get(allIcons, commodity.iconName),
                    iconColor: commodity.color,
                    units: _.get(commodity, `units.${lang}`)
                });
            }
            _.get(commodity, 'subCommodities', []).forEach(subCommodity => {
                if (
                    subCommodity.enableCustomerOrders
                    // _.get(subCommodity, 'locationType', []).includes(location.locationType) &&
                    // (!isAUSRegion() ||
                    //     _.isNil(location.province) ||
                    //     _.get(subCommodity, 'state', []).includes(getStateAbbreviationFromFullName(location.province)))
                ) {
                    list.push({
                        _id: subCommodity._id,
                        name: _.get(subCommodity, `name.${lang}`),
                        icon: _.get(allIcons, convertToImportName(subCommodity.iconName)),
                        iconColor: subCommodity.color,
                        units: _.get(commodity, `units.${lang}`),
                        isSubCommodity: true
                    });
                }
            });
        });
        return list;
    }, [commodities]);
    // form stat
    const [inProgress, setInProgress] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [comment, setComment] = useState(getDefaultComment(requestedCommodities, filteredCommodities));
    const [transporter, setTransporter] = useState('');
    const [selectedCommodities, setSelectedCommodities] = useState(requestedCommodities);
    const [showInput, setShowInput] = useState(false);
    const [date, setDate] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day')
    );
    const [selectedStop, setSelectedStop] = useState(undefined);

    const {
        data: { collectionData: fees = [] }
    } = useGetJSON(`/fees/getAllEnabledFees`);

    const handleSelectStop = stop => {
        setSelectedStop(stop);
        setComment(stop.comment);
        setDate(moment(stop.date).tz(process.env.REACT_APP_REGION_TIMEZONE));
        setTransporter(_.get(stop, 'trip.transporterCollector', stop.collector));
        setSelectedCommodities(stop.commoditiesForDelivery);
        setShowInput(true);
    };

    const handleCommodity = e => {
        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSelection = _.cloneDeep(selectedCommodities);

        const commodity = _.find(filteredCommodities, c => _.get(c, '_id') === path);

        let selectedCommodity = _.find(updatedSelection, c => _.get(c, '_id') === path);

        if (!commodity) return;

        if (selectedCommodity) {
            selectedCommodity.amount = value;
        } else {
            updatedSelection.push({
                _id: commodity._id,
                amount: value,
                isSubCommodity: commodity.isSubCommodity
            });
        }
        _.remove(updatedSelection, c => c.amount <= 0);
        setComment(getDefaultComment(updatedSelection, filteredCommodities));
        setSelectedCommodities(updatedSelection);
        const collector = _.find(transporters, t => t._id.toString() === transporter);
        if (!_.isNil(collector) && !transporterHasInventoryForSelection(collector, updatedSelection)) {
            setTransporter(null);
        }
    };

    const handleCancelEdit = () => {
        setShowInput(false);
        // if (_.isNil(selectedStop)) {
        //     return;
        // }
        setSelectedStop(undefined);
        setComment('');
        setDate(
            moment()
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .startOf('day')
        );
    };

    const handleCreateStop = async () => {
        setInProgress(true);

        const res = await http.post(
            '/stops/create',
            {
                date,
                stopName: `Drop off for ${_user.getNameFull(customer)}`,
                location,
                comment,
                stopDuration: 1200,
                stopPayAmount: 0,
                frequency: 0,
                stopIcon: 'trash-can',
                stopIconColor: 'black',
                collector_id: transporter,
                commoditiesForDelivery: selectedCommodities
            },
            true
        );
        if (res.ok) {
            onSnackbar('Succesfully created stop');
            setComment('');
            setTransporter(null);

            reload();
        }

        setShowInput(false);
        setInProgress(false);
    };

    const handleUpdateStop = async stop_id => {
        setInProgress(true);

        const res = await http.post(`/stops/${stop_id}/update`, {
            _id: stop_id,
            comment,
            date,
            collector_id: transporter,
            commoditiesForDelivery: selectedCommodities
        });

        if (res.ok) {
            onSnackbar('Successfully updated stop');
            setSelectedStop(undefined);
            setComment('');

            reload();
        }

        setSelectedStop(undefined);
        setShowInput(false);
        setInProgress(false);
    };

    const handleRemoveStop = async stop => {
        setInProgress(true);

        const res = await http.post(`/stops/${stop._id}/delete`, {});

        if (res.ok) {
            onSnackbar('Successfully removed stop ');
            setSelectedStop(undefined);
            reload();
        }

        setInProgress(false);
    };
    return (
        <>
            <Dialog fullScreen={fullScreen} fullWidth open={open}>
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    Bookings at Location
                </DialogTitlePrimary>
                <DialogContent>
                    <>
                        {!_.isNil(note) && (
                            <DialogContentText
                                style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                            >
                                {note}
                            </DialogContentText>
                        )}
                        <DialogContentText
                            style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                        >
                            To book a delivery for this address, select the date, transporter, and commodities to drop
                            off.
                        </DialogContentText>
                        <Collapse in={!showInput}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: '100%', marginTop: theme.spacing.unit }}
                                onClick={() => setShowInput(true)}
                            >
                                <Icon>add_circle</Icon> Add
                            </Button>
                        </Collapse>
                        <Collapse in={showInput}>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            variant="outlined"
                                            timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                            value={date}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Icon>date_range</Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={date => setDate(date)}
                                            disablePast
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        data-cy="create-edit-trip-dialog-collector-select"
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                    >
                                        <InputLabel htmlFor="transporterCollector" variant="outlined" shrink>
                                            Transporter
                                        </InputLabel>
                                        <Select
                                            value={transporter}
                                            onChange={e => {
                                                setTransporter(e.target.value);
                                            }}
                                            inputProps={{
                                                id: 'transporterCollector',
                                                name: 'transporterCollector'
                                            }}
                                            input={
                                                <OutlinedInput
                                                    name="transporterCollector"
                                                    id="transporterCollector"
                                                    labelWidth={'Transporter'.length * theme.spacing.unit}
                                                    notched
                                                />
                                            }
                                        >
                                            {_.filter(transporters, t =>
                                                transporterHasInventoryForSelection(t, selectedCommodities)
                                            ).map(collector => {
                                                return (
                                                    <MenuItem
                                                        key={collector._id}
                                                        value={collector._id}
                                                        data-cy={`create-edit-trip-transporterCollector-${collector.name
                                                            .replace(/\s*\W*\s+/g, '-')
                                                            .toLowerCase()}-li`}
                                                    >
                                                        {collector.name}{' '}
                                                        {_.keys(collector.inventory).map((commodity_id, i, array) => {
                                                            const commodity = _.find(
                                                                filteredCommodities,
                                                                c => c._id.toString() === commodity_id
                                                            );
                                                            if (
                                                                _.isNil(commodity) ||
                                                                _.get(collector.inventory, commodity_id, 0) <= 0
                                                            ) {
                                                                return <></>;
                                                            }
                                                            return (
                                                                <>
                                                                    <MDIcon
                                                                        path={commodity.icon}
                                                                        size={1}
                                                                        color={commodity.iconColor}
                                                                    />{' '}
                                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                                        x
                                                                        {_.get(
                                                                            collector.inventory,
                                                                            commodity_id,
                                                                            'N/A'
                                                                        )}
                                                                        {i < array.length - 1 && ','}{' '}
                                                                    </span>
                                                                </>
                                                            );
                                                        })}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    {_.isEmpty(transporter) && (
                                        <Typography
                                            style={{
                                                marginTop: theme.spacing.unit / 2,
                                                color: theme.palette.error.main,
                                                marginLeft: 5
                                            }}
                                        >
                                            <Icon
                                                style={{
                                                    marginRight: theme.spacing.unit / 2,
                                                    lineHeight: 1.25,
                                                    color: theme.palette.error.main
                                                }}
                                            >
                                                warning
                                            </Icon>{' '}
                                            No transporter selected!
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {filteredCommodities.map((commodity, i) => {
                                        const selection = _.find(selectedCommodities, c => c._id === commodity._id) || {
                                            _id: commodity._id,
                                            amount: 0,
                                            isSubCommodity: commodity.isSubCommodity
                                        };
                                        const selectionError =
                                            selection.amount < 0 || selection.amount > MAX_NUM_OF_RECYCLING_BAGS;
                                        return (
                                            <FormControl
                                                fullWidth
                                                style={{
                                                    marginBottom: 0
                                                }}
                                                key={commodity._id}
                                            >
                                                <NumberOfBagsInput
                                                    required={false}
                                                    startAdornmentIcon={commodity.icon}
                                                    startAdornmentIconColor={commodity.iconColor}
                                                    componentValue={selection.amount}
                                                    componentLabel={commodity.name}
                                                    componentName={commodity._id}
                                                    componentHelperText={
                                                        selectionError
                                                            ? loc('commodityRequestDialogError1', lang, {
                                                                  min: 0,
                                                                  max: MAX_NUM_OF_RECYCLING_BAGS
                                                              })
                                                            : ''
                                                    }
                                                    componentError={selectionError}
                                                    // extraAdornments={
                                                    //     <InputAdornment position="end" style={{ height: '100%' }}>
                                                    //         {commodity.units}
                                                    //     </InputAdornment>
                                                    // }
                                                    onBags={handleCommodity}
                                                />
                                            </FormControl>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={comment}
                                            onChange={e => setComment(e.target.value)}
                                            multiline
                                            label={'Directions'}
                                            variant="outlined"
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{ 'data-cy': `bottle-drive-comment-input` }}
                                            FormHelperTextProps={{
                                                'data-cy': `bottle-drive-comment-input-helper-txt`
                                            }}
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showPreview}
                                                onChange={() => setShowPreview(!showPreview)}
                                            />
                                        }
                                        label="Preview Markdown"
                                    />
                                    {showPreview && <DriverCommentMarkdownHelpertext source={comment} theme={theme} />}
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Button
                                    // data-cy="recurring-fee-dialog-cancel-update-btn" TODO: add cypress test
                                    style={{ marginTop: theme.spacing.unit }}
                                    disabled={inProgress}
                                    size="small"
                                    variant="outlined"
                                    onClick={handleCancelEdit}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-cy="recurring-fee-dialog-apply-btn"
                                    style={{ marginTop: theme.spacing.unit }}
                                    disabled={inProgress || _.isEmpty(transporter)}
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        _.isNil(selectedStop)
                                            ? handleCreateStop
                                            : () => handleUpdateStop(selectedStop._id)
                                    }
                                >
                                    {_.isNil(selectedStop) ? 'Create stop' : 'Update stop'}
                                </Button>
                            </DialogActions>
                        </Collapse>

                        <Divider style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }} />
                    </>

                    {_.isNil(pendingStops) || _.isEmpty(pendingStops) ? (
                        <DialogContentText>There are no pending stops booked at this location</DialogContentText>
                    ) : (
                        <List>
                            {pendingStops.map((stop, index) => {
                                const collector = _.find(
                                    transporters,
                                    t =>
                                        t._id.toString() ===
                                        _.get(stop, 'trip.transporterCollector', stop.collector).toString()
                                );
                                return (
                                    <ListItem
                                        key={index}
                                        style={{
                                            marginTop: theme.spacing.unit,
                                            backgroundColor: theme.palette.background.paper,
                                            border: `2px solid ${
                                                _.get(selectedStop, '_id') === stop._id
                                                    ? colors.green[400]
                                                    : theme.palette.grey[400]
                                            }`,
                                            borderRadius: theme.shape.borderRadius,
                                            height: 'auto',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        <ListItemText
                                            style={{ paddingRight: theme.spacing.unit * 8 }}
                                            primary={[
                                                <div>
                                                    <MDIcon
                                                        path={_.get(allIcons, convertToImportName(stop.stopIcon))}
                                                        size={1}
                                                        color={stop.stopIconColor}
                                                    />
                                                    {stop.stopName} {!_.isNil(collector) && `(${collector.code})`}
                                                </div>,
                                                <div>{locDate(stop.date, 'DD MMM YYYY', lang)} </div>,
                                                <div>{_.get(stop, 'location.description')}</div>
                                            ]}
                                            secondary={`${stop.comment && `Comment: ${stop.comment}`} `}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                disabled={inProgress || _.get(selectedStop, '_id') === stop._id}
                                                onClick={() => {
                                                    handleSelectStop(stop);
                                                }}
                                            >
                                                <MDIcon
                                                    path={mdiPencil}
                                                    size={1}
                                                    title="Edit stop"
                                                    color={
                                                        _.get(selectedStop, '_id') === stop._id
                                                            ? colors.green[400]
                                                            : theme.palette.text.secondary
                                                    }
                                                />
                                            </IconButton>
                                            <IconButton
                                                disabled={inProgress}
                                                onClick={() => {
                                                    warnAction(() => {
                                                        handleRemoveStop(stop);
                                                    }, 'Are you sure you want to delete this stop?');
                                                }}
                                            >
                                                <MDIcon
                                                    path={mdiDelete}
                                                    size={1}
                                                    title="Remove stop"
                                                    color={theme.palette.text.secondary}
                                                />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ManageDeliveriesDialog));

function transporterHasInventoryForSelection(collector, selectedCommodities) {
    if (_.isEmpty(selectedCommodities)) {
        return true;
    } else {
        let hasInventory = true;
        selectedCommodities.forEach(commodity => {
            if (_.get(collector.inventory, commodity._id) < commodity.amount) {
                hasInventory = false;
            }
        });
        return hasInventory;
    }
}
function getDefaultComment(selectedCommodities, commodities) {
    let string = 'Drop off for customer:';
    selectedCommodities.forEach(item => {
        const commodity = _.find(commodities, c => c._id.toString() === item._id.toString());
        if (!_.isNil(commodity) && item.amount > 0) {
            string += `\n* ${_.get(commodity, 'name', 'Item')} x ${item.amount}`;
        }
    });
    return string;
}
