import React, { useState } from 'react';

import _ from 'lodash';

import { Chip, Typography, Icon, colors, withTheme } from '@material-ui/core';

import { loc, locDate } from 'localizations/localizationHandler';

import { truncateBagtag } from 'utils/misc';

import MDIcon from '@mdi/react';
import { mdiTagArrowDown, mdiLabel } from '@mdi/js';
import { getCustomerName } from '../../../utils/misc';

function Bagtags({ customer, bagtags, sessions, collector, lang, theme, showEmptyBagTag = true }) {
    const customerId = customer.uniqueID;
    const sessionsWithValidLabels = _.filter(sessions, session => session.bagtag && session.bagtag != customerId);

    const allUniqueBagTags = _.uniqBy(bagtags, 'bagtag');

    if (!_.isEmpty(sessionsWithValidLabels)) {
        return (
            <ShowHideBagTagList theme={theme} lang={lang} bagTagObj={sessionsWithValidLabels} collector={collector} />
        );
    } else {
        return (
            <ShowHideBagTagList
                theme={theme}
                lang={lang}
                bagTagObj={allUniqueBagTags}
                customerId={customerId}
                showEmptyBagTag={showEmptyBagTag}
                collector={collector}
            />
        );
    }
}

export default withTheme()(Bagtags);

function ShowHideBagTagList({ theme, lang, bagTagObj, customerId, showEmptyBagTag, collector }) {
    const [showMore, setShowMore] = useState(true);

    return (
        <>
            {bagTagObj.map((obj, i) => {
                if (!showMore && i > 0) {
                    return <></>;
                }

                if (!showEmptyBagTag) {
                    if (_.isEmpty(obj.bagtag)) {
                        return <></>;
                    }
                }

                var isCustomerLabel = truncateBagtag(obj.bagtag) === customerId;

                if (isCustomerLabel) {
                    return (
                        <div
                            style={{
                                height: 'auto',
                                width: '100%',
                                margin: theme.spacing.unit * 0.5,
                                padding: theme.spacing.unit / 2,
                                backgroundColor: '#e0e0e0',
                                borderRadius: '16px',
                                overflow: 'auto'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <MDIcon
                                    path={mdiLabel}
                                    size={0.9}
                                    color={theme.palette.text.secondary}
                                    style={{ margin: theme.spacing.unit / 2 }}
                                />
                                <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                    {truncateBagtag(obj.bagtag)}
                                </Typography>
                            </div>
                        </div>
                    );
                }

                return (
                    <div
                        style={{
                            height: 'auto',
                            width: '100%',
                            margin: theme.spacing.unit * 0.5,
                            padding: theme.spacing.unit / 2,
                            backgroundColor: '#e0e0e0',
                            borderRadius: '16px',
                            overflow: 'auto'
                        }}
                    >
                        <BagTagWithDate
                            theme={theme}
                            lang={lang}
                            bagTagObj={obj}
                            isCustomerLabel={isCustomerLabel}
                            collector={collector}
                        />
                    </div>
                );
            })}

            {bagTagObj.length > 1 && !showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(true)}
                >
                    {loc('showAll', lang)}
                </Typography>
            )}
            {bagTagObj.length > 1 && showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(false)}
                >
                    {loc('showLess', lang)}
                </Typography>
            )}
        </>
    );
}

function BagTagWithDate({ theme, lang, bagTagObj, collector }) {
    const { bagtag, dateCounted, operator } = bagTagObj;
    // const counterName = getCustomerName(operator);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDIcon
                    path={mdiTagArrowDown}
                    size={0.8}
                    color={theme.palette.text.secondary}
                    style={{ margin: theme.spacing.unit / 2 }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>{truncateBagtag(bagtag)}</Typography>
                    {dateCounted && (
                        <Typography style={{ fontSize: '11px', lineHeight: 1.2 }}>
                            {loc('processedOn', lang)}: {locDate(dateCounted, 'MMM D, YYYY hh:mm A', lang)}
                        </Typography>
                    )}
                </div>
            </div>

            {dateCounted && (
                <span
                    style={{
                        marginRight: theme.spacing.unit,
                        marginLeft: theme.spacing.unit
                    }}
                >
                    <Icon style={{ color: colors.green[500] }}>check_circle</Icon>
                </span>
            )}
        </div>
    );
}
