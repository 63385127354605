import React, { Fragment, useEffect, useState } from 'react';

import _ from 'lodash';

import { BOTTLE_DRIVE } from 'constants.js';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withTheme } from '@material-ui/core/styles';

import { _pickup, _zone, _commodity } from 'std';

import PayloadInput from 'components/InputComponents/PayloadInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { useContext } from 'react';
import BinRequestDialog from '../BinRequestDialog';
import { colors, Icon, Typography } from '@material-ui/core';

import { getStateAbbreviationFromFullName, isAUSRegion, isMXDRegion } from 'utils/misc';
import CommodityRequestDialog from 'components/CommodityRequestDialog';
import { useMemo } from 'react';

const Payload = props => {
    const {
        theme,
        pickupForm,
        formErrors,
        inProgress,
        customer,
        toggleHelpDialog,
        onNext,
        onBack,
        onPayload,
        onSubPayloads,
        handleRequestBinChange,
        handleBinRequestMessageChange,
        commoditiesAvailable = [],
        http,
        onSnackbar,
        auth
    } = props;

    const [binRequestDialogOpen, setBinRequestDialogOpen] = useState(false);
    const [pendingCommodityRequest, setPendingCommodityRequest] = useState(null);

    const { lang } = useContext(LocalizationContext);

    const { payload, subPayloads, zone, binRequested, binRequestMessage } = pickupForm;

    const payloadAccepted = _.get(zone, 'payloadAccepted', commoditiesAvailable.filter(c => c.unserviced));

    const formContainsErrors = _.some(payloadAccepted, commodity =>
        _.get(formErrors, `${_commodity.getSkuType(commodity)}.fail`, false)
    );

    const binRequestMessageError = _.get(formErrors, 'binRequestMessage');

    // const commoditiesAvailableForRequest = useMemo(() => {
    //     return !_.isNil(
    //         _.find(
    //             commoditiesAvailable,
    //             c =>
    //                 (c.enableCustomerOrders &&
    //                     _.get(c, 'locationType', []).includes(pickupForm.pickupType) &&
    //                     (!isAUSRegion() ||
    //                         _.isNil(_.get(pickupForm, 'location.province')) ||
    //                         _.get(c, 'state', []).includes(
    //                             getStateAbbreviationFromFullName(_.get(pickupForm, 'location.province'))
    //                         ))) ||
    //                 !_.isNil(
    //                     _.find(
    //                         _.get(c, 'subCommodities', []),
    //                         sc =>
    //                             sc.enableCustomerOrders &&
    //                             _.get(sc, 'locationType', []).includes(pickupForm.pickupType) &&
    //                             (!isAUSRegion() ||
    //                                 _.isNil(_.get(pickupForm, 'location.province')) ||
    //                                 _.get(sc, 'state', []).includes(
    //                                     getStateAbbreviationFromFullName(_.get(pickupForm, 'location.province'))
    //                                 ))
    //                     )
    //                 )
    //         )
    //     );
    // }, [commoditiesAvailable]);

    const handleSubmitCommodityRequest = async selection => {
        const res = await http.postJSON(
            `/commodityRequests/submitRequest/${customer._id}`,
            {
                commodities: selection,
                location: pickupForm.location
            },
            true
        );
        if (res.ok) {
            getPendingCommodityRequest();
            // setPendingCommodityRequest(res.data.pendingRequest);
            setBinRequestDialogOpen(false);
            onSnackbar(loc('commodityRequestDialog5', lang));
        } else {
            onSnackbar(loc('commodityRequestDialogError2', lang), 'error');
        }
    };

    const handleCancelCommodityRequest = async () => {
        const res = await http.post('/commodityRequests/' + pendingCommodityRequest._id + '/cancel');
        if (res.ok) {
            getPendingCommodityRequest();
            setBinRequestDialogOpen(false);
        }
    };

    const getPendingCommodityRequest = async () => {
        const res = await http.getJSON(
            `/commodityRequests/getPendingCommodityRequestByCustomerAndLocation?customer_id=${
                customer._id
            }&place_id=${_.get(pickupForm, 'location.place_id')}`
        );
        if (res.ok) {
            setPendingCommodityRequest(res.data.pendingRequest);
        }
    };

    useEffect(() => {
        getPendingCommodityRequest();
    }, []);

    return (
        <Fragment>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog25', lang)} iconName="list_alt" />
                <PayloadInput
                    payload={payload}
                    subPayloads={subPayloads}
                    onPayload={onPayload}
                    onSubPayloads={onSubPayloads}
                    formErrors={formErrors}
                    toggleHelpDialog={toggleHelpDialog}
                    payloadAccepted={payloadAccepted}
                    payloadRequired={_zone.getPayloadRequired(zone)}
                    adminView={_.get(customer, 'adminView')}
                    auth={auth}
                    lowVolumeContainerThreshold={props.lowVolumeContainerThreshold}
                    minPickupFeeEnabled={props.minPickupFeeEnabled}
                />
                <DialogContentText style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit / 2 }}>
                    {loc('pickupDialog34a', lang)}
                    <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                        {customer.uniqueID}
                    </strong>
                    {loc('pickupDialog34b', lang)}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: theme.spacing.unit * 2
                        }}
                    >
                        {/* {commoditiesAvailableForRequest && (
                            <div>
                                <Button variant="outlined" onClick={() => setBinRequestDialogOpen(true)}>
                                    {loc('commodityRequestDialog1', lang)}
                                </Button>
                                {!_.isNil(pendingCommodityRequest) && (
                                    <Typography variant="caption" style={{ color: colors.amber[700] }}>
                                        {loc('commodityRequestDialog7', lang)}
                                    </Typography>
                                )}
                            </div>
                        )} */}
                        {/* {!isMXDRegion() && _pickup.isCommercial(pickupForm) && (
                            <Button variant="outlined" onClick={() => setBinRequestDialogOpen(true)}>
                                <Icon
                                    style={{
                                        fontSize: '24px',
                                        marginRight: theme.spacing.unit / 2,
                                        color: binRequested ? colors.green[400] : colors.grey[700]
                                    }}
                                >
                                    {binRequested ? 'mark_email_read' : 'delete_outline'}
                                </Icon>
                                {binRequested ? loc('binRequest6', lang) : loc('binRequest5', lang)}
                            </Button>
                        )} */}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button color="primary" onClick={onBack}>
                            {loc('back', lang)}
                        </Button>
                        <Button
                            color="primary"
                            disabled={
                                formContainsErrors ||
                                (_pickup.payloadIsEmpty(payload) && _pickup.subPayloadsAreEmpty(subPayloads))
                            }
                            onClick={onNext}
                            data-cy="3-pickupdialog-next-button"
                        >
                            {loc('next', lang)}
                        </Button>
                    </div>
                </div>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
            {/* <BinRequestDialog
                open={binRequestDialogOpen}
                onClose={() => setBinRequestDialogOpen(false)}
                handleRequestBinChange={handleRequestBinChange}
                handleBinRequestMessageChange={handleBinRequestMessageChange}
                binRequestMessage={binRequestMessage}
                binRequestMessageError={binRequestMessageError}
            /> */}

            <CommodityRequestDialog
                open={binRequestDialogOpen}
                onClose={() => setBinRequestDialogOpen(false)}
                theme={theme}
                loading={false}
                onSubmit={handleSubmitCommodityRequest}
                location={pickupForm.location}
                locationType={pickupForm.pickupType}
                http={http}
                fullScreen={false}
                pendingRequest={pendingCommodityRequest}
                onCancel={handleCancelCommodityRequest}
            />
        </Fragment>
    );
};

export default withTheme()(Payload);
