import React, { useState, useContext, useEffect } from 'react';
import * as terms from 'localizations/terms';
import _ from 'lodash';

import VERSION_FRONT from 'VERSION';

import {
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
    Tabs,
    Tab,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const tabTitles = {
    residential: 'Residential',
    commercial: 'Commercial & Business',
    charity: 'Charities',
    fundraising: 'Fundraising & Bottle Drives',
    bottledepot: 'Bottle Depot',
    mobilerecycling: 'Mobile Recycling'
};

const FAQ = ({ open, fullScreen, http, onClose, theme, tabIndex, useFallbackData }) => {
    const { lang } = useContext(LocalizationContext);

    const [groupings, setGroupings] = useState({ residential: [], charity: [], bottleDepot: [] });
    const [page, setPage] = useState('residential');
    const [tab, setTab] = useState(0);

    const handleChangePage = e => {
        setPage(e.target.value);
    };
    useEffect(() => {
        if (tabIndex === 1 || tabIndex === 3 || tabIndex === 5) {
            setPage('charity');
        }
    }, [tabIndex]);

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const getData = async () => {
        let faqsEndpoint = `/faqs/${lang}`;
        if (useFallbackData) {
            const version = VERSION_FRONT.replaceAll('.', '-');
            faqsEndpoint = `/faqs/fallback/${lang}/${version}`;
        }

        const res = await http.getJSON(faqsEndpoint, true, true);

        if (res.ok) {
            const data = res.data.faqs;
            const { residential, commercial, charity, fundraising, bottledepot, mobilerecycling } = data;

            const updatedGroupings = _.cloneDeep(groupings);

            if (residential) {
                updatedGroupings.residential.push({ residential });
            }
            if (commercial) {
                updatedGroupings.residential.push({ commercial });
            }
            if (charity) {
                updatedGroupings.charity.push({ charity });
            }
            if (fundraising) {
                updatedGroupings.charity.push({ fundraising });
            }
            if (bottledepot && JSON.parse(process.env.REACT_APP_GET_RECYCLING_SOFTWARE_OPTION_ENABLED)) {
                updatedGroupings.bottleDepot.push({ bottledepot });
            }
            if (mobilerecycling && JSON.parse(process.env.REACT_APP_GET_RECYCLING_SOFTWARE_OPTION_ENABLED)) {
                updatedGroupings.bottleDepot.push({ mobilerecycling });
            }

            setGroupings(updatedGroupings);
        }
    };

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [lang, open]);

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
            <DialogTitlePrimary>
                <span style={{ marginRight: theme.spacing.unit * 2, marginBottom: theme.spacing.unit }}>FAQ:</span>
                <Select
                    value={page}
                    onChange={handleChangePage}
                    style={{
                        maxWidth: '100%',
                        paddingLeft: theme.spacing.unit * 2,
                        color: 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)'
                    }}
                    data-cy="faq-category-select"
                >
                    {Object.keys(groupings).map((key, index) => {
                        const value = groupings[key];
                        if (_.isEmpty(value)) {
                            return null;
                        }

                        return (
                            <MenuItem
                                data-cy={`faq-category-select-${index}`}
                                key={key}
                                value={key}
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                                {loc(`faq-${key}`, lang, { organizationName: terms.ORGANIZATION_NAME })}
                            </MenuItem>
                        );
                    })}
                </Select>
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                <Tabs value={tab} indicatorColor="primary" textColor="primary" centered onChange={handleChangeTab}>
                    {groupings[page].map((obj, index) => {
                        const key = Object.keys(obj)[0];
                        return (
                            <Tab
                                data-cy={`faq-tab-select-${index}`}
                                key={key}
                                label={loc(`faq-tab-${_.kebabCase(tabTitles[key])}`, lang, {
                                    organizationName: terms.ORGANIZATION_NAME
                                })}
                            />
                        );
                    })}
                </Tabs>
                {groupings[page] &&
                    groupings[page][tab] &&
                    Object.values(groupings[page][tab])[0].map(({ q, a }, index) => {
                        return (
                            <ExpansionPanel data-cy={`expand-faq-${index}`} key={index}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography data-cy={`faq-${index}-question`} variant="subtitle2">
                                        {q}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography
                                        data-cy={`faq-${index}-answer`}
                                        dangerouslySetInnerHTML={{ __html: a }}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })}
            </DialogContent>
            <DialogActions>
                <Button data-cy="faq-close-button" color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(FAQ));
