import React, { useContext } from 'react';
import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function VerifyEmailWidget(props) {
    const { theme, onVerifyEmail, isAdmin, onResendVerificationEmail, customer } = props;

    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    return (
        <React.Fragment>
            <Paper
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
                    background: colors.red[500]
                }}
            >
                <Typography style={{ color: theme.palette.common.white }}>
                    {process.env.REACT_APP_REGION_EXT === 'EXP'
                        ? loc('dashboardUpdate9', lang, { email: customer.email })
                        : loc('dashboardUpdate6', lang, { email: customer.email })}
                </Typography>
                <Button
                    variant="outlined"
                    style={{ marginTop: theme.spacing.unit * 2, color: 'white', borderColor: 'white' }}
                    onClick={onResendVerificationEmail}
                >
                    {loc('dashboardUpdate7', lang)}
                </Button>
                {isAdmin && (
                    <Button
                        variant="outlined"
                        style={{
                            marginTop: theme.spacing.unit * 2,
                            marginLeft: theme.spacing.unit,
                            color: 'white',
                            borderColor: 'white'
                        }}
                        onClick={() =>
                            warnAction(
                                () => onVerifyEmail(),
                                `Are you sure you want to set ${_.get(
                                    customer,
                                    'name.first',
                                    'this customer'
                                )}'s email (${customer.email}) as verified?`
                            )
                        }
                    >
                        Verify Email
                    </Button>
                )}
            </Paper>
        </React.Fragment>
    );
}

export default withTheme()(VerifyEmailWidget);
