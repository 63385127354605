import React from 'react';

import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import CharityDeselectDialog from 'components/CharityDeselectDialog/CharityDeselectDialog';
import { useContext } from 'react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { isEXPRegion, isCONRegion } from 'utils/misc';

import ExpIcon from 'icons/ExpIcon';
import conIcon from 'icons/con-get-started-icon.png';
import sarIcon from 'icons/rw-icon.png';

import { Icon as MDIcon } from '@mdi/react';
import { mdiHeart, mdiHeartOff } from '@mdi/js';
import { colors } from '@material-ui/core';
import FakeLink from 'components/FakeLink/FakeLink';

function SchedulePickupSubwidget(props) {
    const {
        theme,
        clicksAllowed,
        customer,
        charities,
        charityPreferred,
        http,
        onPickupDialog,
        setCharitySelectDialogOpen,
        onReloadCustomer,
        charityEnabled
    } = props;
    const [showCharityDeselectDialog, setShowCharityDeselectDialog] = React.useState(false);
    const [charity, setCharity] = React.useState(null);

    const { lang } = useContext(LocalizationContext);

    React.useEffect(() => {
        getCharityPreferred();
    }, []);
    React.useEffect(() => {
        getCharityPreferred();
    }, [charityPreferred]);

    const handleClearCharity = async () => {
        let res = await http.post(`/users/${customer._id}/clearCharitySelected`);
        if (res.ok) {
            onReloadCustomer();
            setShowCharityDeselectDialog(false);
            getCharityPreferred();
        }
    };

    const getCharityPreferred = async () => {
        const res = await http.getJSON(`/users/getCharitySelected/${customer._id}`);
        if (res.ok) {
            let newCharityPreferred = _.get(res, 'data.charitySelected', null);
            setCharity(_.find(charities, charity => charity._id === newCharityPreferred));
        }
    };

    return (
        <>
            <div
                id="schedule-pickup-subwidget"
                data-cy="schedule-pickup-subwidget"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <CharityDeselectDialog
                    open={showCharityDeselectDialog}
                    onConfirm={() => {
                        handleClearCharity();
                    }}
                    onCancel={() => {
                        setShowCharityDeselectDialog(false);
                    }}
                    text={
                        customer.lockDonationPreference
                            ? loc('donationPreferenceLockedMessage', lang, {
                                  brandname: process.env.REACT_APP_BRAND_NAME
                              })
                            : 'Your current donation preference is ' +
                              _.get(charity, 'name', '') +
                              ', would you like to clear your donation preference?'
                    }
                    lockDonationPreference={customer.lockDonationPreference}
                />
                <div
                    style={{
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        overflow: 'hidden'
                    }}
                >
                    <table style={{ borderSpacing: '0px 5px', tableLayout: 'fixed', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    {clicksAllowed ? (
                                        <Typography onClick={onPickupDialog(true)}>
                                            <a
                                                data-cy="schedule-pickup-subwidget-a"
                                                href="#/"
                                                style={{ color: theme.palette.linkColor }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                {loc('pickupWidgets13', lang)}
                                            </a>
                                        </Typography>
                                    ) : (
                                        <Typography color="textSecondary">{loc('pickupWidgets14', lang)}</Typography>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="caption">
                                        {loc('pickupWidgets2', lang, { id: _.get(customer, 'uniqueID') })}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        borderLeft: '1px solid ' + theme.palette.text.hint,
                        paddingLeft: theme.spacing.unit,
                        paddingRight: theme.spacing.unit
                    }}
                >
                    <IconButton disabled={!clicksAllowed} onClick={onPickupDialog(true)}>
                        {isEXPRegion() && <ExpIcon theme={theme} width={'24px'} height={'24px'} />}
                        {isCONRegion() && (
                            <img style={{ width: '24px', height: '24px' }} src={conIcon} alt="Consignaction Logo" />
                        )}
                        {process.env.REACT_APP_REGION_EXT_DEMO === 'SAR' && (
                            <img style={{ width: '24px', height: '24px' }} src={sarIcon} alt="Recyclewise Logo" />
                        )}
                        {!isEXPRegion() && !isCONRegion() && !(process.env.REACT_APP_REGION_EXT_DEMO === 'SAR') && (
                            <Icon>local_shipping</Icon>
                        )}
                    </IconButton>
                </div>
            </div>
            {charityEnabled && _.isNil(charity) && (
                <div
                    style={{
                        verticalAlign: 'middle',
                        display: 'flex',
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <span style={{ width: '25px' }}>
                        <MDIcon path={mdiHeartOff} size={0.8} color={colors.grey[500]} />
                    </span>
                    <span
                        style={{
                            marginLeft: theme.spacing.unit,
                            marginRight: theme.spacing.unit
                        }}
                    >
                        <Typography variant="caption" noWrap>
                            <FakeLink
                                theme={theme}
                                text={isEXPRegion() ? loc('pickupWidgets34Exp', lang) : loc('pickupWidgets34', lang)}
                                onClickEvent={() => setCharitySelectDialogOpen(true)}
                                color={theme.palette.primary.main}
                                data-cy="select-charity"
                            />
                        </Typography>
                    </span>
                </div>
            )}
            {charityEnabled && !_.isNil(charity) && (
                <div
                    style={{
                        verticalAlign: 'middle',
                        display: 'flex',
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <span style={{ width: '25px' }}>
                        <MDIcon path={mdiHeart} size={0.8} color={colors.red[300]} />
                    </span>
                    <span
                        style={{
                            maxWidth: '75%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginLeft: theme.spacing.unit,
                            marginRight: theme.spacing.unit
                        }}
                    >
                        <Typography variant="caption" noWrap>
                            {loc('pickupWidgets19', lang, { charity: _.get(charity, 'name', '') })}
                        </Typography>
                    </span>
                    <span style={{ width: '65px' }}>
                        <Typography variant="caption" noWrap>
                            <FakeLink
                                theme={theme}
                                text={loc('remove', lang)}
                                onClickEvent={() => setShowCharityDeselectDialog(true)}
                                color={theme.palette.primary.main}
                                data-cy="deselect-charity"
                            />
                        </Typography>
                    </span>
                </div>
            )}
        </>
    );
}

export default withTheme()(SchedulePickupSubwidget);
