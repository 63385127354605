import React, { useState, useEffect, useContext } from 'react';
import { startCase, isEmpty, isNil } from 'lodash';
import { _time } from 'std';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTACT_EMAIL_OPTIONS, DROP_OFF_TYPES, SHARE_AND_FEEDBACK_TYPES } from 'constants.js';

import { EditorState, convertToRaw } from 'draft-js';
import RichTextEditor, { initializeEditorState } from '../../../components/RichTextEditor';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import MdiIcon from '@mdi/react';
import * as allMdiIcons from '@mdi/js';
import { withTheme } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';
import {
    withWidth,
    Card,
    CardHeader,
    CardContent,
    Icon,
    Paper,
    Grid,
    Button,
    Typography,
    IconButton,
    Collapse,
    CircularProgress,
    TextField,
    Divider,
    colors,
    InputAdornment,
    Switch,
    Select,
    OutlinedInput,
    MenuItem,
    Menu,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    FormControl,
    Tooltip,
    Chip,
    Checkbox
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { getSwitch, getTextInput } from 'components/CRUDTables/helperFunctions';
import StringListInput from 'components/CustomInputs/StringListInput';
import InputWithConfirmButtons from 'components/CustomInputs/InputWithConfirmButtons';
import { loc } from 'localizations/localizationHandler';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SocialShareInput from 'components/CRUDTables/Inputs/SocialShareInput';
import ObjectTextInput from 'components/CRUDTables/Inputs/ObjectTextInput';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ComplaintsForm from 'components/CRUDTables/Forms/ComplaintsForm';
import useComplaintsForm from '../Configuration/hooks/useComplaintsForm';
import CustomWrapper from '../BulkCounter/CustomWrapper';
import { getQuickDropName, isAUSRegion, isCONRegion, isEXPRegion } from 'utils/misc';
import SecurityQuestionsInput from '../../../components/CustomInputs/SecurityQuestionsInput';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useRef } from 'react';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { validate } from 'utils/validate';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import DOMPurify from 'dompurify';

const InformationForms = ({ theme, collectors, width, pickupsEnabled }) => {
    const headerRef = useRef(null);
    const emailFormRef = useRef(null);
    const [headerWidth, setHeaderWidth] = useState(width);
    const isSmallScreen = isWidthDown('md', width);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);

    const [informationFormsConfigLoading, setInformationFormsConfigLoading] = useState();
    const [localization, setLocalization] = useState({});
    const [otherConfigs, setOtherConfigs] = useState({});

    const [renderScreen, setRenderScreen] = useState(0);
    const [selectedView, setSelectedView] = useState('How It Works');
    const [showMenu, setShowMenu] = useState(true);
    const [menuEl, setMenuEl] = useState(null);

    const [faqView, setFaqView] = useState('residential');
    const [shareAndFeedbackView, setShareAndFeedbackView] = useState(SHARE_AND_FEEDBACK_TYPES[0].key);
    const [technologyView, setTechnologyView] = useState(DROP_OFF_TYPES[0].value);
    const [howItWorksView, setHowItWorksView] = useState('how-it-works-0');

    const [editorStateTermsEN, setEditorStateTermsEN] = useState(EditorState.createEmpty());
    const [editorStatePrivacyEN, setEditorStatePrivacyEN] = useState(EditorState.createEmpty());

    const [editorStateTermsFR, setEditorStateTermsFR] = useState(EditorState.createEmpty());
    const [editorStatePrivacyFR, setEditorStatePrivacyFR] = useState(EditorState.createEmpty());

    const [isLoading, setIsLoading] = useState(false);

    const onSnackbar = useContext(SnackbarContext);

    const loadInformationFormsConfig = async () => {
        setInformationFormsConfigLoading(true);
        const res = await http.getJSON(`/system/allLocalizations/`);
        if (res.ok) {
            const newLocalization = convertIcons(_.cloneDeep(res.data.localization), 'kebab');
            setLocalization(newLocalization);
            setOtherConfigs(res.data.otherConfigs);

            var enTermContents = _.get(res.data.localization, `en.informationForms.terms`, '');
            initializeEditorState(enTermContents, setEditorStateTermsEN);

            var enPrivacyContents = _.get(res.data.localization, `en.informationForms.privacy`, '');
            initializeEditorState(enPrivacyContents, setEditorStatePrivacyEN);

            var frTermContents = _.get(res.data.localization, `fr.informationForms.terms`, '');
            initializeEditorState(frTermContents, setEditorStateTermsFR);

            var frnPrivacyContents = _.get(res.data.localization, `fr.informationForms.privacy`, '');
            initializeEditorState(frnPrivacyContents, setEditorStatePrivacyFR);
        }
        setInformationFormsConfigLoading(false);
    };

    //convert mdiIcons between mdiCamelCase and kebab-case
    const convertIcons = (localization, format) => {
        if (_.isNil(localization)) {
            return localization;
        }

        Object.keys(localization).forEach(lang => {
            const informationForms = localization[lang] && localization[lang].informationForms;
            const quickdropInstructions = localization[lang] && localization[lang].quickdropInstructions;
            const convertIconFormat = icon => {
                if (format === 'kebab') {
                    return icon.startsWith('mdi') ? _.kebabCase(icon.substring(3)) : _.kebabCase(icon);
                } else if (format === 'camel') {
                    return icon.startsWith('mdi') ? _.camelCase(`${icon}`) : _.camelCase(`mdi-${icon}`);
                }
                return icon;
            };

            if (informationForms && Array.isArray(informationForms.howItWorks)) {
                informationForms.howItWorks.forEach(type => {
                    if (Array.isArray(type.steps)) {
                        type.steps.forEach(step => {
                            step.icon = _.isNil(step.icon) ? '' : convertIconFormat(step.icon);
                        });
                    }
                });
            }

            if (!_.isNil(quickdropInstructions)) {
                Object.keys(quickdropInstructions).forEach(key => {
                    const instruction = quickdropInstructions[key];
                    if (Array.isArray(instruction)) {
                        instruction.forEach(step => {
                            step.icon = _.isNil(step.icon) ? '' : convertIconFormat(step.icon);
                        });
                    }
                });
            }
        });

        return localization;
    };

    const {
        initialObj,
        complaintType,
        complaintFormOpen,
        setComplaintFormOpen,
        handleOpenComplaintsForm
    } = useComplaintsForm(_.get(otherConfigs, `complaints`, {}));

    const securityQuestionError = question => {
        if (question.length > 40) {
            return true;
        }
        const specialCharacters = ['-', '<', '>', '%', '{', '}', '[', ']', '&', '#', '\\', '“'];
        if (_.some(specialCharacters, char => question.includes(char))) {
            return true;
        }

        const specialStrings = ['http:', 'https:', 'javascript', 'function', 'return', 'www'];
        if (_.some(specialStrings, string => question.includes(string))) {
            return true;
        }
        return false;
    };

    const securityQuestionErrorMessage = question => {
        if (question.length > 40) {
            return 'This must be 40 characters or less';
        }
        const specialCharacters = ['-', '<', '>', '%', '{', '}', '[', ']', '&', '#', '\\', '"'];
        for (let char of specialCharacters) {
            if (question.includes(char)) return `The character ${char} is not allowed.`;
        }

        const specialStrings = ['http:', 'https:', 'javascript', 'function', 'return', 'www'];
        for (let specialString of specialStrings) {
            if (question.includes(specialString)) return `The word "${specialString}" is not allowed.`;
        }
        return false;
    };

    const handleRerender = () => {
        setRenderScreen(renderScreen === 0 ? 1 : 0);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...otherConfigs,
            localization: {
                en: {
                    promoCodeCustomer: _.get(localization, `en.promoCodeCustomer`),
                    ratingDialogText: _.get(localization, `en.ratingDialogText`),
                    CRUDDefaults: _.get(localization, `en.CRUDDefaults`),
                    ratesPageConfig: _.get(localization, `en.ratesPageConfig`),
                    complaints: _.get(localization, `en.complaints`),
                    quickdropInstructions: _.get(localization, `en.quickdropInstructions`),
                    informationForms: _.get(localization, `en.informationForms`)
                },
                fr: {
                    promoCodeCustomer: _.get(localization, `fr.promoCodeCustomer`),
                    ratingDialogText: _.get(localization, `fr.ratingDialogText`),
                    CRUDDefaults: _.get(localization, `fr.CRUDDefaults`),
                    ratesPageConfig: _.get(localization, `fr.ratesPageConfig`),
                    complaints: _.get(localization, `fr.complaints`),
                    quickdropInstructions: _.get(localization, `fr.quickdropInstructions`),
                    informationForms: _.get(localization, `fr.informationForms`)
                }
            }
        },
        onSubmit: async values => {
            setIsLoading(true);

            _.set(
                formik.values,
                `localization.en.informationForms.terms`,
                convertToRaw(editorStateTermsEN.getCurrentContent())
            );

            _.set(
                formik.values,
                `localization.en.informationForms.privacy`,
                convertToRaw(editorStatePrivacyEN.getCurrentContent())
            );

            _.set(
                formik.values,
                `localization.fr.informationForms.terms`,
                convertToRaw(editorStateTermsFR.getCurrentContent())
            );

            _.set(
                formik.values,
                `localization.fr.informationForms.privacy`,
                convertToRaw(editorStatePrivacyFR.getCurrentContent())
            );

            const newLocalization = convertIcons(_.cloneDeep(values.localization), 'camel');

            const localizationRes = await http.postJSON(
                '/system/updateLocalizations',
                {
                    localization: newLocalization,
                    otherConfigs: _.pick(values, ['registration', 'redemptionOptions', 'complaints', 'emails'])
                },
                true
            );
            if (localizationRes.ok) {
                onSnackbar('Values updated successfully');
                const res = await http.getJSON(`/system/allLocalizations/`);
                if (res.ok) {
                    const newLocalization = convertIcons(_.cloneDeep(res.data.localization), 'kebab');
                    formik.setFieldValue(`localization`, newLocalization);
                    setLocalization(newLocalization);
                    setOtherConfigs(res.data.otherConfigs);
                }
            } else {
                onSnackbar('Failed to update localizations.', 'error');
            }

            setIsLoading(false);
        }
    });

    const setFormikDirty = () => {
        // add a custom field with a boolean value to mark general dirtyness
        formik.setFieldValue('hasUnsavedChanges', true);
    };

    const handleFormikSave = () => {
        // since we're saving remove our general dirty field
        formik.setFieldValue('hasUnsavedChanges', undefined);
        formik.handleSubmit();
    };

    useEffect(() => {
        loadInformationFormsConfig();
        formik.handleReset();
    }, []);

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        const newWidth = headerRef.current.offsetWidth;
        setHeaderWidth(newWidth);
    }, [width]);

    const handleRecoverGPSOption = () => {
        let length = _.get(localization, 'en.complaints.driverComplaints.options', []).length;
        let DefaultGPSOption = {
            code: 'D6',
            label: 'GPS location inaccurate',
            suggestions: [
                'Please confirm your address and send pin location using your favorite maps application to {{serviceEmail}}.',
                'The driver had difficulties locating your location due to a google maps GPS error.'
            ],
            emailToCustomer: true
        };
        formik.setFieldValue(`localization.en.complaints.driverComplaints.options.[${length}]`, DefaultGPSOption);
        formik.setFieldValue(`localization.fr.complaints.driverComplaints.options.[${length}]`, DefaultGPSOption);
        formik.handleSubmit();
    };

    const isMissingGPSOption =
        _.get(localization, 'en.complaints.driverComplaints.options', []).findIndex(option => option.code === 'D6') ===
        -1;

    const handleDragEndCustomer = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.customerComplaints.resolutions`);
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, resolution => resolution.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(formik.values, `localization.${lang}.complaints.customerComplaints.resolutions`, newComplaints);
    };
    const handleDragEndCounter = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.counterComplaints.options`);
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, option => option.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(formik.values, `localization.${lang}.complaints.counterComplaints.options`, newComplaints);
    };
    const handleDragEndDriver = ({ draggableId, destination }) => {
        let complaintsArray = _.get(formik.values, `localization.${lang}.complaints.driverComplaints.options`);
        if (!destination) return;
        let newComplaints = _.filter(complaintsArray, option => option.code !== draggableId);
        const complaintMoved = _.find(complaintsArray, { code: draggableId });
        newComplaints.splice(destination.index, 0, complaintMoved);
        _.set(formik.values, `localization.${lang}.complaints.driverComplaints.options`, newComplaints);
    };

    const handleDeleteHowItWorksStep = (typeIndex, stepIndex) => {
        warnAction(() => {
            const howItWorksCopy = _.cloneDeep(
                _.get(formik.values, `localization[${lang}].informationForms.howItWorks[${typeIndex}].steps`, [])
            );
            if (_.isNil(howItWorksCopy) || _.isEmpty(howItWorksCopy)) {
                return;
            }
            howItWorksCopy.splice(stepIndex, 1);
            _.set(
                formik.values,
                `localization[${lang}].informationForms.howItWorks[${typeIndex}].steps`,
                howItWorksCopy
            );
            setLocalization(formik.values.localization);
            setFormikDirty();
        }, 'Are you sure you want to delete this?');
    };

    const handleAddHowItWorksStep = (typeIndex, step) => {
        const howItWorksCopy = _.cloneDeep(
            _.get(formik.values, `localization[${lang}].informationForms.howItWorks[${typeIndex}].steps`, [])
        );
        if (_.isNil(howItWorksCopy)) {
            return;
        }
        howItWorksCopy.push(step);
        _.set(formik.values, `localization[${lang}].informationForms.howItWorks[${typeIndex}].steps`, howItWorksCopy);
        setLocalization(formik.values.localization);
        setFormikDirty();
        handleRerender();
    };

    const handleDeleteFaq = (type, i) => {
        warnAction(() => {
            const faqCopy = _.cloneDeep(
                _.get(formik.values, `localization[${lang}].informationForms.faq[${type}]`, [])
            );
            if (_.isNil(faqCopy) || _.isEmpty(faqCopy)) {
                return;
            }
            faqCopy.splice(i, 1);
            _.set(formik.values, `localization[${lang}].informationForms.faq[${type}]`, faqCopy);
            setLocalization(formik.values.localization);
            setFormikDirty();
        }, 'Are you sure you want to delete this?');
    };

    const handleAddFaq = (type, values) => {
        const faqCopy = _.cloneDeep(_.get(formik.values, `localization[${lang}].informationForms.faq[${type}]`, []));
        if (_.isNil(faqCopy)) {
            return;
        }
        faqCopy.push(values);
        _.set(formik.values, `localization[${lang}].informationForms.faq[${type}]`, faqCopy);
        setLocalization(formik.values.localization);
        setFormikDirty();
        handleRerender();
    };

    const handleDeleteInstruction = (type, i) => {
        warnAction(() => {
            const quickdropInstructionsCopy = _.cloneDeep(
                _.get(formik.values, `localization[${lang}].quickdropInstructions[${type}]`, [])
            );
            if (_.isNil(quickdropInstructionsCopy) || _.isEmpty(quickdropInstructionsCopy)) {
                return;
            }
            quickdropInstructionsCopy.splice(i, 1);
            _.set(formik.values, `localization[${lang}].quickdropInstructions[${type}]`, quickdropInstructionsCopy);
            setLocalization(formik.values.localization);
            setFormikDirty();
        }, 'Are you sure you want to delete this?');
    };

    const handleAddInstruction = (type, values) => {
        const quickdropInstructionsCopy = _.cloneDeep(
            _.get(formik.values, `localization[${lang}].quickdropInstructions[${type}]`, [])
        );
        if (_.isNil(quickdropInstructionsCopy)) {
            return;
        }
        quickdropInstructionsCopy.push(values);
        _.set(formik.values, `localization[${lang}].quickdropInstructions[${type}]`, quickdropInstructionsCopy);
        setLocalization(formik.values.localization);
        setFormikDirty();
        handleRerender();
    };

    const handleAddContactReason = reason => {
        let contactCopy = _.cloneDeep(_.get(formik.values, `localization[${lang}].informationForms.contact`, []));
        let lastCode = contactCopy[contactCopy.length - 1].code;
        let newCodeNum = Number(lastCode.replace('CONTACT', ''));
        contactCopy.push({
            ...reason,
            code: `CONTACT${newCodeNum + 1}`
        });
        _.set(formik.values, `localization[${lang}].informationForms.contact`, contactCopy);
        setLocalization(formik.values.localization);
        setFormikDirty();
        handleRerender();
    };

    const handleDeleteContactReason = index => {
        warnAction(() => {
            const contactCopy = _.cloneDeep(_.get(formik.values, `localization[${lang}].informationForms.contact`, []));
            contactCopy.splice(index, 1);
            _.set(formik.values, `localization[${lang}].informationForms.contact`, contactCopy);
            setLocalization(formik.values.localization);
            setFormikDirty();
        }, 'Are you sure you want to delete this?');
    };

    const mdiIconsUrl = 'https://pictogrammers.com/library/mdi/';

    const handleMenu = open => e => {
        if (open) {
            if (isSmallScreen) {
                setMenuEl(e.currentTarget);
            }
            setShowMenu(true);
        } else {
            setMenuEl(null);
            setShowMenu(false);
        }
    };

    const menuItemsList = {
        howItWorks: {
            icon: 'mdiInformation',
            title: 'How It Works'
        },
        faq: {
            icon: 'mdiFrequentlyAskedQuestions',
            title: 'FAQ'
        },
        contactUs: {
            icon: 'mdiCardAccountPhone',
            title: 'Contact Us'
        },
        shareAndFeedbackDialog: {
            icon: 'mdiShareVariant',
            title: 'Share And Feedback Dialog'
        },
        technologyInstructions: {
            icon: 'mdiListBox',
            title: 'Technology Instructions'
        },
        registration: {
            icon: 'mdiAccountPlus',
            title: 'Registration'
        },
        redemptionOptions: {
            icon: 'mdiWalletGiftcard',
            title: 'Redemption Options'
        },
        complaints: {
            icon: 'mdiCommentAlert',
            title: 'Complaints'
        },
        terms: {
            icon: 'mdiFileSign',
            title: 'Terms'
        },
        privacy: {
            icon: 'mdiIncognitoCircle',
            title: 'Privacy'
        }
    };

    const infoMenuItems = (
        <Paper style={{ height: '100%', overflowY: 'auto' }}>
            <ListItem data-cy={'system-information-editor-menu'}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: `${theme.palette.text.primary}`
                    }}
                >
                    Information Editor
                </Typography>
            </ListItem>
            {_.values(menuItemsList).map(menuItem => (
                <IconListItem
                    theme={theme}
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    isSubmitting={formik.isSubmitting}
                    isValid={formik.isValid}
                    menuItem={menuItem}
                />
            ))}
        </Paper>
    );

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {!isSmallScreen && (
                <div
                    style={{
                        width: 400
                    }}
                >
                    {infoMenuItems}
                </div>
            )}

            <div
                ref={headerRef}
                style={{ width: '100%', padding: theme.spacing.unit * 2, height: '100%', overflowY: 'scroll' }}
            >
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        zIndex: 10,
                        boxShadow: theme.shadows[2],
                        width: _.isNil(headerRef.current) ? headerWidth - 50 : headerRef.current.offsetWidth - 50
                    }}
                >
                    <Typography variant="h5">
                        {isSmallScreen && (
                            <IconButton onClick={handleMenu(true)} data-cy="information-forms-menu-button">
                                <MdiIcon path={allMdiIcons['mdiMenu']} size={0.7} color={theme.palette.text.primary} />
                            </IconButton>
                        )}{' '}
                        {selectedView}
                    </Typography>
                    {isSmallScreen && (
                        <Menu
                            open={showMenu && isSmallScreen && !_.isNil(menuEl)}
                            anchorEl={menuEl}
                            onClose={handleMenu(false)}
                        >
                            {infoMenuItems}
                        </Menu>
                    )}
                    <div>
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={(e, lang) => {
                                if (!_.isEmpty(lang)) setLang(lang);
                            }}
                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: theme.spacing.unit }}
                        >
                            <ToggleButton style={{ flexGrow: 1 }} value="en" data-cy={`language-toggle-en`}>
                                English
                            </ToggleButton>
                            <ToggleButton style={{ flexGrow: 1 }} value="fr" data-cy={`language-toggle-fr`}>
                                French
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {formik.dirty && (
                            <Typography variant="body2" style={{ color: 'red', marginTop: theme.spacing.unit }}>
                                There are unsaved changes
                            </Typography>
                        )}
                    </div>

                    <Button
                        data-cy={`information-editor-submit`}
                        color="primary"
                        onClick={handleFormikSave}
                        disabled={isLoading}
                        variant="contained"
                    >
                        Save
                        {isLoading && <CircularProgress size={16} />}
                    </Button>
                </Paper>
                <div
                    style={{
                        paddingTop: theme.spacing.unit,
                        marginTop: formik.dirty ? 75 : 50,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    {informationFormsConfigLoading ? (
                        <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                            <div
                                style={{
                                    margin: theme.spacing.unit * 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress />
                            </div>
                        </Paper>
                    ) : (
                        <>
                            {selectedView === menuItemsList.howItWorks.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            paddingTop: theme.spacing.unit * 2,
                                            paddingBottom: theme.spacing.unit * 2,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <ToggleButtonGroup
                                            value={howItWorksView}
                                            exclusive
                                            onChange={(e, view) => {
                                                if (!_.isEmpty(view)) setHowItWorksView(view);
                                            }}
                                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                                        >
                                            {_.get(
                                                formik.values,
                                                `localization.${lang}.informationForms.howItWorks`,
                                                []
                                            ).map((item, i) => {
                                                return (
                                                    <ToggleButton
                                                        style={{ flexGrow: 1, fontSize: 12 }}
                                                        value={`how-it-works-${i}`}
                                                    >
                                                        {item.type}
                                                    </ToggleButton>
                                                );
                                            })}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    {_.get(formik.values, `localization.${lang}.informationForms.howItWorks`, []).map(
                                        (item, typeIndex) => {
                                            return (
                                                howItWorksView === `how-it-works-${typeIndex}` && (
                                                    <Grid
                                                        container
                                                        spacing={theme.spacing.unit}
                                                        key={`how-it-works-${typeIndex}-${item.steps.length}`}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ marginBottom: 0, paddingBottom: 0 }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    marginTop: theme.spacing.unit
                                                                }}
                                                            >
                                                                {item.type}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: theme.spacing.unit * 2
                                                                }}
                                                            />
                                                        </Grid>
                                                        {item.steps.map((step, stepIndex) => (
                                                            <HowItWorksConfigUpdateWidget
                                                                formik={formik}
                                                                typeIndex={typeIndex}
                                                                stepIndex={stepIndex}
                                                                theme={theme}
                                                                onDeleteHowItWorksStep={handleDeleteHowItWorksStep}
                                                                onSearch={() => {
                                                                    window.open(mdiIconsUrl);
                                                                }}
                                                                lang={lang}
                                                            />
                                                        ))}
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                style={{
                                                                    marginTop: theme.spacing.unit * 2,
                                                                    marginBottom: theme.spacing.unit * 2
                                                                }}
                                                            />
                                                        </Grid>
                                                        <HowItWorksConfigAddWidget
                                                            typeIndex={typeIndex}
                                                            theme={theme}
                                                            onAddHowItWorksStep={handleAddHowItWorksStep}
                                                            onSearch={() => {
                                                                window.open(mdiIconsUrl);
                                                            }}
                                                            lang={lang}
                                                        />
                                                    </Grid>
                                                )
                                            );
                                        }
                                    )}
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.faq.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            paddingTop: theme.spacing.unit * 2,
                                            paddingBottom: theme.spacing.unit * 2,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <ToggleButtonGroup
                                            value={faqView}
                                            exclusive
                                            onChange={(e, view) => {
                                                if (!_.isEmpty(view)) setFaqView(view);
                                            }}
                                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                                        >
                                            {Object.keys(
                                                _.get(formik.values, `localization[${lang}].informationForms.faq`, {})
                                            ).map(faqType => (
                                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value={faqType}>
                                                    {loc(getLocalizationStringForFaqType(faqType), lang)}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Grid>

                                    {Object.keys(
                                        _.get(formik.values, `localization[${lang}].informationForms.faq`, {})
                                    ).map(faqType => {
                                        return (
                                            faqView === faqType && (
                                                <FaqConfigWidget
                                                    data-cy="information-forms-faq-config-update-widget"
                                                    theme={theme}
                                                    title={loc(getLocalizationStringForFaqType(faqType), lang)}
                                                    type={faqType}
                                                    formik={formik}
                                                    onAddFaq={handleAddFaq}
                                                    onDeleteFaq={handleDeleteFaq}
                                                    lang={lang}
                                                    key={`faq-${faqType}-${
                                                        _.get(
                                                            localization,
                                                            `${lang}.informationForms.faq.${faqType}`,
                                                            []
                                                        ).length
                                                    }`}
                                                />
                                            )
                                        );
                                    })}
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.contactUs.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginTop: theme.spacing.unit
                                            }}
                                        >
                                            {'Contact Us'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider
                                            style={{
                                                marginTop: 0,
                                                marginBottom: theme.spacing.unit * 2
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={theme.spacing.unit}
                                        key={`contact-${_.get(localization, 'en.informationforms.contact', []).length}`}
                                    >
                                        {_.get(formik.values, `localization[${lang}].informationForms.contact`, []).map(
                                            (reason, index) => {
                                                return (
                                                    <ContactConfigUpdateWidget
                                                        theme={theme}
                                                        formik={formik}
                                                        onDeleteContactReason={handleDeleteContactReason}
                                                        index={index}
                                                        lang={lang}
                                                    />
                                                );
                                            }
                                        )}
                                        <Grid item xs={12}>
                                            <Divider
                                                style={{
                                                    marginTop: theme.spacing.unit * 2,
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                            />
                                        </Grid>
                                        <ContactConfigAddWidget
                                            lang={lang}
                                            onAddContactReason={handleAddContactReason}
                                            theme={theme}
                                        />
                                    </Grid>
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.shareAndFeedbackDialog.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            paddingTop: theme.spacing.unit * 2,
                                            paddingBottom: theme.spacing.unit * 2,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <ToggleButtonGroup
                                            value={shareAndFeedbackView}
                                            exclusive
                                            onChange={(e, view) => {
                                                if (!_.isEmpty(view)) setShareAndFeedbackView(view);
                                            }}
                                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                                        >
                                            {SHARE_AND_FEEDBACK_TYPES.map(type => (
                                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value={type.key}>
                                                    {type.value}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    {SHARE_AND_FEEDBACK_TYPES.map(type => (
                                        <>
                                            {shareAndFeedbackView === type.key && (
                                                <>
                                                    <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                        <Typography
                                                            variant="h6"
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                        >
                                                            {type.value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                marginTop: 0,
                                                                marginBottom: theme.spacing.unit * 2
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    ))}
                                    {shareAndFeedbackView === 'shareAndFeedback-shareScreen' && (
                                        <>
                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item xs={12}>
                                                    <SocialShareInput
                                                        path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_DEFAULT`}
                                                        formik={formik}
                                                        headerText="Customer default share message"
                                                        isExpanded={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SocialShareInput
                                                        path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_RECYCLED`}
                                                        formik={formik}
                                                        headerText="Customer pickup completed share message"
                                                        isExpanded={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SocialShareInput
                                                        path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_CUSTOMER_DONATED`}
                                                        formik={formik}
                                                        headerText="Customer pickup donated share message"
                                                        isExpanded={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SocialShareInput
                                                        path={`localization.${lang}.CRUDDefaults.regions.social.MSG_SOCIAL_ORGANIZATION_DEFAULT`}
                                                        formik={formik}
                                                        headerText="Charity share message"
                                                        isExpanded={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    {shareAndFeedbackView === 'shareAndFeedback-referralScreen' && (
                                        <>
                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.title`,
                                                        'Referral Dialog Title',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Referral Dialog Title',
                                                            'Title of customer referral dialog',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.text`,
                                                        'Referral Dialog Text',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Referral Dialog Text',
                                                            'Text of customer referral dialog',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.codeDisplayLabel`,
                                                        'Referral Promo Label',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Referral Promo Label',
                                                            'Label for displaying referral promo code to customer',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.referralURLDisplayLabel`,
                                                        'Referral Url Label',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Referral Url Label',
                                                            'Label for displaying referral link to customer',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.primaryActionLabel`,
                                                        'Invite Label',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Invite Label',
                                                            'Label for displaying social media invite options to customer',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.rulesDialogHandle`,
                                                        'Rules Dialog Label',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Rules Dialog Label',
                                                            'Label for button to open referral rules dialog',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.rulesTitle`,
                                                        'Rules Dialog Title',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Rules Dialog Title',
                                                            'Title of dialog explaining the rules of customer referrals',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.promoCodeCustomer.rules`,
                                                        'Rules Dialog Text',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Rules Dialog Text',
                                                            'Text of dialog explaining the rules of customer referrals',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    {shareAndFeedbackView === 'shareAndFeedback-ratingScreen' && (
                                        <>
                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.rateUsTitle`,
                                                        'No Ratings Title',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'No Ratings Title',
                                                            'Title of rating dialog displayed when customer has never rated us before',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.thanksForRatingTitle`,
                                                        'Just Rated Us Title',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Just Rated Us Title',
                                                            'Title of rating dialog displayed when customer has just submitted a rating',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.ratedUsBeforeTitle`,
                                                        'Rated Us Before Title',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Rated Us Before Title',
                                                            'Title of rating dialog displayed when customer has previously submitted a rating',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.rateUsContent`,
                                                        'No Ratings Content',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'No Ratings Content',
                                                            'Content of rating dialog displayed when customer has never rated us before',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.thanksForRatingContent`,
                                                        'Just Rated Us Content',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Just Rated Us Content',
                                                            'Content of rating dialog displayed when customer has just submitted a rating',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    {getTextInput(
                                                        theme,
                                                        `localization.${lang}.ratingDialogText.ratedUsBeforeContent`,
                                                        'Rated Us Before Content',
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            'Rated Us Before Content',
                                                            'Content of rating dialog displayed when customer has previously submitted a rating',
                                                            theme
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.technologyInstructions.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            paddingTop: theme.spacing.unit * 2,
                                            paddingBottom: theme.spacing.unit * 2,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <ToggleButtonGroup
                                            value={technologyView}
                                            exclusive
                                            onChange={(e, view) => {
                                                if (!_.isEmpty(view)) setTechnologyView(view);
                                            }}
                                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                                        >
                                            {DROP_OFF_TYPES.map(dropOffType => (
                                                <ToggleButton
                                                    style={{ flexGrow: 1, fontSize: 12 }}
                                                    value={dropOffType.value}
                                                >
                                                    {dropOffType.value}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    {DROP_OFF_TYPES.map(dropOffType => (
                                        <>
                                            {technologyView === dropOffType.value && (
                                                <Grid
                                                    container
                                                    spacing={theme.spacing.unit}
                                                    key={`quickdrop-instructions-${
                                                        _.get(
                                                            localization,
                                                            `${lang}.quickdropInstructions.${
                                                                dropOffType.localizationName
                                                            }`,
                                                            []
                                                        ).length
                                                    }`}
                                                >
                                                    <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                        <Typography
                                                            variant="h6"
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                        >
                                                            {dropOffType.value}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                marginTop: 0,
                                                                marginBottom: theme.spacing.unit * 2
                                                            }}
                                                        />
                                                    </Grid>
                                                    {_.get(
                                                        localization,
                                                        `${lang}.quickdropInstructions.${dropOffType.localizationName}`,
                                                        []
                                                    ).map((faq, i) => (
                                                        <QuickDropInstructionsAddUpdateWidget
                                                            formik={formik}
                                                            type={dropOffType.localizationName}
                                                            index={i}
                                                            theme={theme}
                                                            onDeleteInstruction={handleDeleteInstruction}
                                                            onSearch={() => {
                                                                window.open(mdiIconsUrl);
                                                            }}
                                                            lang={lang}
                                                        />
                                                    ))}
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                marginTop: theme.spacing.unit * 2,
                                                                marginBottom: theme.spacing.unit * 2
                                                            }}
                                                        />
                                                    </Grid>
                                                    <QuickDropInstructionsAddUpdateWidget
                                                        type={dropOffType.localizationName}
                                                        theme={theme}
                                                        onAddInstruction={handleAddInstruction}
                                                        onSearch={() => {
                                                            window.open(mdiIconsUrl);
                                                        }}
                                                        lang={lang}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.registration.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid item xs={12}>
                                        {getTextInput(
                                            theme,
                                            `registration.tagline.${lang}`,
                                            'Registration Tagline',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Registration Tagline',
                                                'Tagline shown above registration options on landing page',
                                                theme
                                            )
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StringListInput
                                            label={`Registration Security Questions (${lang})`}
                                            values={_.get(formik.values, `registration.securityQuestions.${lang}`)}
                                            onChange={values =>
                                                formik.setFieldValue(`registration.securityQuestions.${lang}`, values)
                                            }
                                            error={securityQuestionError}
                                            errorMessage={securityQuestionErrorMessage}
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            style={{ margin: 0, padding: 0 }}
                                            useButton={true}
                                            infoText={
                                                <div>
                                                    <div>
                                                        List of available security questions for customer registration
                                                    </div>
                                                    <div>Questions must follow these rules:</div>
                                                    <ul style={{ marginTop: 0 }}>
                                                        <li>Maximum 40 characters</li>
                                                        <li>Cannot contain the following</li>
                                                        <ul>
                                                            <li>These characters: {`- < > % { } [ ] & # \\ “`}</li>
                                                            <li>
                                                                These words: http:, https:, javascript, function,
                                                                return, www
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            }
                                        />
                                    </Grid>
                                    <CustomWrapper title="Tooltips">
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.address.${lang}`,
                                                    'Address Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Address Tooltip',
                                                        'Customer registration tooltip for address',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.name.${lang}`,
                                                    'Name Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Name Tooltip',
                                                        'Customer registration tooltip for name',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.email.${lang}`,
                                                    'Email Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Email Tooltip',
                                                        'Customer registration tooltip for email',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.phone.${lang}`,
                                                    'Phone Number Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Phone Number Tooltip',
                                                        'Customer registration tooltip for phone number',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.doingBusinessAs.${lang}`,
                                                    'Doing Business As Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Doing Business As Tooltip',
                                                        'Customer registration tooltip for doing business as',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    `registration.tooltips.registeredBusinessName.${lang}`,
                                                    'Registered Business Name Tooltip',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Registered Business Name Tooltip',
                                                        'Customer registration tooltip for registered business name',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.redemptionOptions.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid item xs={12}>
                                        <SecurityQuestionsInput
                                            label={`E-transfer Security Questions (${lang})`}
                                            values={_.get(formik.values, `redemptionOptions.securityQuestions.${lang}`)}
                                            onChange={values =>
                                                formik.setFieldValue(
                                                    `redemptionOptions.securityQuestions.${lang}`,
                                                    values
                                                )
                                            }
                                            error={securityQuestionError}
                                            errorMessage={securityQuestionErrorMessage}
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            infoText={
                                                <div>
                                                    <div>
                                                        List of available security questions for customers to redeem via
                                                        e-transfer
                                                    </div>
                                                    <div>Questions must follow these rules:</div>
                                                    <ul style={{ marginTop: 0 }}>
                                                        <li>Maximum 40 characters</li>
                                                        <li>Cannot contain the following</li>
                                                        <ul>
                                                            <li>These characters: {`- < > % { } [ ] & # \\ “`}</li>
                                                            <li>
                                                                These words: http:, https:, javascript, function,
                                                                return, www
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            }
                                        />
                                    </Grid>
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.complaints.title && (
                                <FormsWidget theme={theme} formik={formik}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Customer Complaints
                                        </Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                                Add Resolution
                                            </Typography>
                                            <IconButton
                                                onClick={() => handleOpenComplaintsForm('Customer')}
                                                disabled={complaintFormOpen}
                                                color="primary"
                                            >
                                                <Icon>add_circle_outline</Icon>
                                            </IconButton>
                                        </div>
                                        <DragDropContext onDragEnd={handleDragEndCustomer}>
                                            <Droppable droppableId="ratingPlatformList">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}>
                                                        {_.get(
                                                            formik.values,
                                                            `localization[${lang}].complaints.customerComplaints.resolutions`,
                                                            []
                                                        ).map((resolution, idx, arr) => (
                                                            <Draggable
                                                                draggableId={resolution.code}
                                                                index={idx}
                                                                key={resolution.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid item md={12} xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.customerComplaints.resolutions`,
                                                                                        _.get(
                                                                                            formik,
                                                                                            'values.complaints.customerComplaints.resolutions',
                                                                                            arr
                                                                                        ).filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.customerComplaints.resolutions`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.customerComplaints.resolutions`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                resolution.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.customerComplaints.resolutions[${idx}]`}
                                                                                togglePath={`complaints.customerComplaints.resolutions[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.customerComplaints.resolutions[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                headerText={_.get(
                                                                                    localization,
                                                                                    `[${lang}].complaints.customerComplaints.resolutions[${idx}].label`,
                                                                                    'N/A'
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}

                                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Counter Complaints
                                        </Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                                Add Complaint
                                            </Typography>
                                            <IconButton
                                                onClick={() => handleOpenComplaintsForm('Counter')}
                                                disabled={
                                                    complaintFormOpen ||
                                                    _.get(
                                                        formik.values,
                                                        `localization[${lang}].complaints.counterComplaints.optionsReadOnly`,
                                                        false
                                                    )
                                                }
                                                color="primary"
                                            >
                                                <Icon>add_circle_outline</Icon>
                                            </IconButton>
                                        </div>
                                        <DragDropContext onDragEnd={handleDragEndCounter}>
                                            <Droppable droppableId="ratingPlatformList">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}>
                                                        {_.get(
                                                            formik.values,
                                                            `localization[${lang}].complaints.counterComplaints.options`,
                                                            []
                                                        ).map((option, idx, arr) => (
                                                            <Draggable
                                                                draggableId={option.code}
                                                                index={idx}
                                                                key={option.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        // style={provided.draggableProps.style}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.counterComplaints.options`,
                                                                                        _.get(
                                                                                            formik,
                                                                                            'values.complaints.counterComplaints.options',
                                                                                            arr
                                                                                        ).filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.counterComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.counterComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.counterComplaints.options[${idx}]`}
                                                                                togglePath={`complaints.counterComplaints.options[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.counterComplaints.options[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                type="counter"
                                                                                headerText={_.get(
                                                                                    localization,
                                                                                    `[${lang}].complaints.counterComplaints.options[${idx}].label`,
                                                                                    'N/A'
                                                                                )}
                                                                                disableEditing={_.get(
                                                                                    formik.values,
                                                                                    `localization[${lang}].complaints.counterComplaints.optionsReadOnly`,
                                                                                    false
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}

                                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Driver Complaints
                                        </Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                                Add Complaint
                                            </Typography>
                                            <IconButton
                                                onClick={() => handleOpenComplaintsForm('Driver')}
                                                disabled={complaintFormOpen}
                                                color="primary"
                                            >
                                                <Icon>add_circle_outline</Icon>
                                            </IconButton>
                                            {isMissingGPSOption && (
                                                <Button
                                                    onClick={handleRecoverGPSOption}
                                                    disabled={complaintFormOpen}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Recover GPS Option
                                                </Button>
                                            )}
                                        </div>
                                        <DragDropContext onDragEnd={handleDragEndDriver}>
                                            <Droppable droppableId="ratingPlatformList">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}>
                                                        {_.get(
                                                            formik.values,
                                                            `localization[${lang}].complaints.driverComplaints.options`,
                                                            []
                                                        ).map((option, idx, arr) => (
                                                            <Draggable
                                                                draggableId={option.code}
                                                                index={idx}
                                                                key={option.code}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        // style={provided.draggableProps.style}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <ObjectTextInput
                                                                                key={idx}
                                                                                onDeleteOption={() => {
                                                                                    formik.setFieldValue(
                                                                                        `complaints.driverComplaints.options`,
                                                                                        _.get(
                                                                                            formik,
                                                                                            'values.complaints.driverComplaints.options',
                                                                                            arr
                                                                                        ).filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.en.complaints.driverComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `localization.fr.complaints.driverComplaints.options`,
                                                                                        arr.filter(
                                                                                            item =>
                                                                                                item.code !==
                                                                                                option.code
                                                                                        )
                                                                                    );
                                                                                    formik.handleSubmit();
                                                                                }}
                                                                                path={`localization.${lang}.complaints.driverComplaints.options[${idx}]`}
                                                                                togglePath={`complaints.driverComplaints.options[${idx}]`}
                                                                                formik={formik}
                                                                                obj={_.get(
                                                                                    localization,
                                                                                    `${lang}.complaints.driverComplaints.options[${idx}]`,
                                                                                    {}
                                                                                )}
                                                                                headerText={_.get(
                                                                                    localization,
                                                                                    `[${lang}].complaints.driverComplaints.options[${idx}].label`,
                                                                                    'N/A'
                                                                                )}
                                                                                type="driver"
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}

                                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid>
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.terms.title && (
                                <FormsWidget
                                    theme={theme}
                                    formik={formik}
                                    cyString={`localization-${lang}-information-forms-terms-input`}
                                >
                                    {lang === 'en' ? (
                                        <RichTextEditor
                                            editorState={editorStateTermsEN}
                                            setEditorState={setEditorStateTermsEN}
                                        />
                                    ) : (
                                        <RichTextEditor
                                            editorState={editorStateTermsFR}
                                            setEditorState={setEditorStateTermsFR}
                                        />
                                    )}
                                </FormsWidget>
                            )}
                            {selectedView === menuItemsList.privacy.title && (
                                <FormsWidget
                                    theme={theme}
                                    formik={formik}
                                    cyString={`localization-${lang}-information-forms-privacy-input`}
                                >
                                    {lang === 'en' ? (
                                        <RichTextEditor
                                            editorState={editorStatePrivacyEN}
                                            setEditorState={setEditorStatePrivacyEN}
                                        />
                                    ) : (
                                        <RichTextEditor
                                            editorState={editorStatePrivacyFR}
                                            setEditorState={setEditorStatePrivacyFR}
                                        />
                                    )}
                                </FormsWidget>
                            )}
                            <ComplaintsForm
                                lang={lang}
                                initialObj={initialObj}
                                open={complaintFormOpen}
                                onClose={() => setComplaintFormOpen(false)}
                                complaintType={complaintType}
                                onSetField={formik.setFieldValue}
                                onConfigSubmit={formik.handleSubmit}
                                complaintsConfig={_.get(formik, `values.complaints`, {})}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withWidth()(withTheme()(InformationForms));

function IconListItem(props) {
    const { theme, selectedView, setSelectedView, isSubmitting, isValid, menuItem } = props;

    const icon = menuItem.icon;
    const title = menuItem.title;

    useEffect(() => {
        if (!isSubmitting && !isValid) {
            setSelectedView(title);
        }
    }, [isSubmitting]);

    return (
        <MenuItem
            dense
            onClick={() => setSelectedView(title)}
            selected={selectedView === title}
            style={{ marginLeft: theme.spacing.unit }}
            data-cy={`information-forms-menu-item-${_.camelCase(title)}`}
        >
            <ListItemIcon>
                <MdiIcon
                    path={allMdiIcons[icon]}
                    size={1}
                    color={'grey'}
                    style={{
                        alignSelf: 'flex-end'
                    }}
                />
            </ListItemIcon>
            <ListItemText
                inset
                primary={
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block',
                            paddingRight: 0,
                            color: `${theme.palette.text.primary}`
                        }}
                    >
                        {title}
                    </span>
                }
            />
        </MenuItem>
    );
}

function ContactConfigAddWidget(props) {
    const { theme, lang, onAddContactReason } = props;
    const [message, setMessage] = useState('');
    const [isPickupIssue, setIsPickupIssue] = useState(false);
    const [isCountsIssue, setIsCountsIssue] = useState(false);
    const [isPaymentsIssue, setIsPaymentsIssue] = useState(false);
    const [toEmail, setToEmail] = useState(CONTACT_EMAIL_OPTIONS[0]);
    const [expanded, setExpanded] = useState(false);

    const onAdd = () => {
        onAddContactReason({
            message,
            isPickupIssue,
            isCountsIssue,
            isPaymentsIssue,
            toEmail
        });
        setMessage('');
        setIsPickupIssue(false);
        setIsCountsIssue(false);
        setIsPaymentsIssue(false);
        setToEmail(CONTACT_EMAIL_OPTIONS[0]);
    };
    return (
        <Card
            style={{
                width: '100%',
                margin: theme.spacing.unit,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)'
            }}
            data-cy="information-forms-contact-add-widget"
        >
            <CardHeader
                titleTypographyProps={{ variant: 'body1' }}
                title={
                    <div>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: theme.spacing.unit
                            }}
                        >
                            Add New
                        </Typography>
                    </div>
                }
                action={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            data-cy={`information-forms-contact-add-expand`}
                            onClick={() => setExpanded(!expanded)}
                        >
                            <Icon>{expanded ? 'remove_circle_outline' : 'add_circle_outline'}</Icon>
                        </IconButton>
                    </span>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ marginRight: theme.spacing.unit }}
                                value={message}
                                name="reason"
                                onChange={e => setMessage(e.target.value)}
                                label={loc('reason', lang)}
                                variant="outlined"
                                fullWidth
                                data-cy={`information-forms-contact-reason-input`}
                            />
                        </Grid>
                        <Grid item sm={2} xs={6}>
                            <FormControl fullWidth>
                                <InputLabel variant="outlined">{loc('contactEmail', lang)}</InputLabel>
                                <Select
                                    value={toEmail}
                                    onChange={e => setToEmail(e.target.value)}
                                    input={
                                        <OutlinedInput
                                            labelWidth={loc('contactEmail', lang).length * theme.spacing.unit}
                                            name="contactEmailAdd"
                                        />
                                    }
                                    style={{
                                        minWidth: 168
                                    }}
                                    data-cy={`information-forms-contact-email-select`}
                                >
                                    {CONTACT_EMAIL_OPTIONS.map((email, i) => {
                                        return (
                                            <MenuItem
                                                data-cy={`information-forms-contact-email-select-${i}`}
                                                value={email.toLowerCase()}
                                            >
                                                {email}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={10}>
                            <Typography variant="caption" style={{ paddingLeft: theme.spacing.unit }}>
                                {loc('issueType', lang)}
                            </Typography>
                            <div style={{ display: 'inline-flex', paddingLeft: theme.spacing.unit }}>
                                <Typography variant="body2">
                                    <Switch
                                        name="isPickupIssue"
                                        checked={isPickupIssue}
                                        onChange={e => setIsPickupIssue(e.target.checked)}
                                        color="primary"
                                        style={{ padding: 0, margin: 0, height: '25px' }}
                                    />
                                    Pickup
                                </Typography>
                                <Typography variant="body2">
                                    <Switch
                                        name="isCountsIssue"
                                        checked={isCountsIssue}
                                        onChange={e => setIsCountsIssue(e.target.checked)}
                                        color="primary"
                                        style={{ padding: 0, margin: 0, height: '25px' }}
                                    />
                                    Counts
                                </Typography>
                                <Typography variant="body2">
                                    <Switch
                                        name="isPaymentsIssue"
                                        checked={isPaymentsIssue}
                                        onChange={e => setIsPaymentsIssue(e.target.checked)}
                                        color="primary"
                                        style={{ padding: 0, margin: 0, height: '25px' }}
                                    />
                                    Payments
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <Button
                                data-cy={`information-forms-contact-submit`}
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                disabled={_.isEmpty(message)}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
}

function ContactConfigUpdateWidget(props) {
    const { theme, formik, onDeleteContactReason, index, lang } = props;
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid item xs={12}>
            <Card
                data-cy="information-forms-contact-config-update-widget"
                style={{
                    margin: theme.spacing.unit,
                    boxShadow: 'none',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }}
            >
                <CardHeader
                    titleTypographyProps={{ variant: 'body1' }}
                    title={
                        expanded
                            ? getTextInput(
                                  theme,
                                  `localization.${lang}.informationForms.contact[${index}].message`,
                                  loc('reason', lang),
                                  formik,
                                  'text'
                              )
                            : _.get(
                                  formik.values,
                                  `localization.${lang}.informationForms.contact[${index}].message`,
                                  ''
                              )
                    }
                    action={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                data-cy={`information-forms-contact-${index}-delete`}
                                onClick={() => onDeleteContactReason(index)}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                            <IconButton
                                data-cy={`information-forms-contact-${index}-expand`}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        </span>
                    }
                />
                <Collapse in={expanded}>
                    <CardContent style={{ display: 'inline-flex' }}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel variant="outlined">{loc('contactEmail', lang)}</InputLabel>
                                <Select
                                    value={_.get(
                                        formik.values,
                                        `localization.${lang}.informationForms.contact[${index}].toEmail`,
                                        ''
                                    )}
                                    onChange={e =>
                                        _.set(
                                            formik.values,
                                            `localization.${lang}.informationForms.contact[${index}].toEmail`,
                                            e.target.value
                                        )
                                    }
                                    input={
                                        <OutlinedInput
                                            labelWidth={loc('contactEmail', lang).length * theme.spacing.unit}
                                            name="contactEmail"
                                        />
                                    }
                                    style={{
                                        minWidth: 168
                                    }}
                                    data-cy={`information-forms-contact-${index}-email-select`}
                                >
                                    {CONTACT_EMAIL_OPTIONS.map((email, i) => {
                                        return (
                                            <MenuItem
                                                data-cy={`information-forms-contact-${index}-email-select-${i}`}
                                                value={email.toLowerCase()}
                                            >
                                                {email}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ marginLeft: '18px', display: 'flex', flexDirection: 'row' }}>
                            {getSwitch(
                                theme,
                                `localization.${lang}.informationForms.contact[${index}].isPickupIssue`,
                                'Pickups Issue',
                                formik
                            )}
                            {getSwitch(
                                theme,
                                `localization.${lang}.informationForms.contact[${index}].isCountsIssue`,
                                'Counts Issue',
                                formik
                            )}
                            {getSwitch(
                                theme,
                                `localization.${lang}.informationForms.contact[${index}].isPaymentsIssue`,
                                'Payments Issue',
                                formik
                            )}
                        </div>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

function FaqConfigWidget(props) {
    const { formik, type, theme, onDeleteFaq, onAddFaq, lang, key, title } = props;
    return (
        <Grid container spacing={theme.spacing.unit} key={key}>
            <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Typography
                    variant="h6"
                    style={{
                        marginTop: theme.spacing.unit
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginTop: 0, marginBottom: theme.spacing.unit * 2 }} />
            </Grid>
            {_.get(formik.values, `localization.${lang}.informationForms.faq.${type}`, []).map((faq, i) => (
                <FaqConfigAddUpdateWidget
                    formik={formik}
                    type={type}
                    index={i}
                    theme={theme}
                    onDeleteFaq={onDeleteFaq}
                    lang={lang}
                />
            ))}
            <Grid item xs={12}>
                <Divider style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }} />
            </Grid>
            <FaqConfigAddUpdateWidget type={type} theme={theme} onAddFaq={onAddFaq} lang={lang} />
        </Grid>
    );
}

function FaqConfigAddUpdateWidget(props) {
    const { formik, type, index, theme, onAddFaq, onDeleteFaq, lang } = props;

    const [newAnswer, setNewAnswer] = useState('');
    const [newQuestion, setNewQuestion] = useState('');

    const onAdd = () => {
        onAddFaq(type, { q: newQuestion, a: newAnswer });
        setNewAnswer('');
        setNewQuestion('');
    };

    return _.isNil(index) ? (
        <Grid item xs={12}>
            <CustomWrapper title="Add New" data-cy="information-forms-faq-config-add-widget">
                <Grid container spacing={theme.spacing.unit} style={{ marginTop: theme.spacing.unit }}>
                    <Grid item xs={3}>
                        <TextField
                            style={{ marginRight: theme.spacing.unit }}
                            value={newQuestion}
                            name="question"
                            onChange={e => setNewQuestion(e.target.value)}
                            label={'New ' + loc('question', lang)}
                            variant="outlined"
                            fullWidth
                            data-cy="information-forms-faq-question-input"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            style={{ marginRight: theme.spacing.unit }}
                            value={newAnswer}
                            onChange={e => setNewAnswer(e.target.value)}
                            name="answer"
                            label={'New ' + loc('answer', lang)}
                            variant="outlined"
                            fullWidth
                            data-cy="information-forms-faq-answer-input"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onAdd}
                            disabled={_.isEmpty(newQuestion) || _.isEmpty(newAnswer)}
                            data-cy="information-forms-faq-submit"
                            style={{ marginTop: theme.spacing.unit }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CustomWrapper>
        </Grid>
    ) : (
        <>
            <Grid item xs={3} data-cy="information-forms-faq-config-update-widget">
                {getTextInput(
                    theme,
                    `localization.${lang}.informationForms.faq.${type}[${index}].q`,
                    loc('question', lang),
                    formik
                )}
            </Grid>
            <Grid item xs={8}>
                {getTextInput(
                    theme,
                    `localization.${lang}.informationForms.faq.${type}[${index}].a`,
                    loc('answer', lang),
                    formik,
                    'text'
                )}
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={() => onDeleteFaq(type, index)} data-cy={`information-forms-faq-${index}-delete`}>
                    <Icon>delete</Icon>
                </IconButton>
            </Grid>
        </>
    );
}

function QuickDropInstructionsAddUpdateWidget(props) {
    const { formik, type, index, theme, onAddInstruction, onDeleteInstruction, onSearch, lang } = props;

    const [newIcon, setNewIcon] = useState('');
    const [newPrimary, setNewPrimary] = useState('');
    const [newSecondary, setNewSecondary] = useState('');

    const onAdd = () => {
        onAddInstruction(type, { icon: newIcon, primary: newPrimary, secondary: newSecondary });
        setNewIcon('');
        setNewPrimary('');
        setNewSecondary('');
    };

    const getMdiIcon = (typeIndex, stepIndex) => {
        let icon = _.get(
            formik.values,
            `localization.${lang}.quickdropInstructions.${typeIndex}[${stepIndex}].icon`,
            ''
        );
        icon = _.isNil(icon) || _.isEmpty(icon) ? 'mdiCircle' : icon;

        if (icon.startsWith('mdi')) {
            icon = _.camelCase(`${icon}`);
        } else {
            icon = _.camelCase(`mdi-${icon}`);
        }
        return allMdiIcons[icon];
    };

    return _.isNil(index) ? (
        <Grid item xs={12}>
            <CustomWrapper title={'Add New'} data-cy="information-editor-quickdrop-instructions-config-add-widget">
                <Grid container spacing={theme.spacing.unit} style={{ marginTop: theme.spacing.unit }}>
                    <Grid item xs={3}>
                        <div style={{ display: 'flex' }}>
                            <MdiIcon
                                path={allMdiIcons[newIcon ? newIcon : 'mdiDotsHorizontalCircle']}
                                size={'100%'}
                                color={theme.palette.primary.main}
                                style={{
                                    maxWidth: '50px',
                                    maxHeight: '50px',
                                    marginRight: theme.spacing.unit,
                                    marginTop: _.isNil(index) ? '' : theme.spacing.unit * 2
                                }}
                            />
                            <TextField
                                style={{}}
                                value={newIcon}
                                name="icon"
                                label={'New ' + loc('icon', lang)}
                                variant="outlined"
                                onChange={e => setNewIcon(e.target.value)}
                                data-cy="information-editor-quickdrop-instructions-icon-input"
                            />
                            <IconButton
                                style={{
                                    marginTop: _.isNil(index) ? '' : theme.spacing.unit * 2,
                                    marginRight: theme.spacing.unit,
                                    padding: '2px',
                                    height: '100%'
                                }}
                                onClick={onSearch}
                            >
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            style={{ marginRight: theme.spacing.unit }}
                            value={newPrimary}
                            name="primary"
                            onChange={e => setNewPrimary(e.target.value)}
                            label={'New ' + loc('primaryText', lang)}
                            variant="outlined"
                            fullWidth
                            data-cy="information-editor-quickdrop-instructions-primary-input"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ marginRight: theme.spacing.unit }}
                            value={newSecondary}
                            onChange={e => setNewSecondary(e.target.value)}
                            name="secondary"
                            label={'New ' + loc('secondaryText', lang)}
                            variant="outlined"
                            fullWidth
                            data-cy="information-editor-quickdrop-instructions-secondary-input"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onAdd}
                            disabled={_.isEmpty(newPrimary) || _.isEmpty(newSecondary)}
                            data-cy="information-editor-quickdrop-instructions-submit"
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CustomWrapper>
        </Grid>
    ) : (
        <>
            <Grid item xs={3} data-cy="information-editor-quickdrop-instructions-config-update-widget">
                <div style={{ display: 'flex' }}>
                    <MdiIcon
                        path={getMdiIcon(type, index)}
                        size={'100%'}
                        color={theme.palette.primary.main}
                        style={{
                            maxWidth: '50px',
                            maxHeight: '50px',
                            marginRight: theme.spacing.unit,
                            marginTop: _.isNil(index) ? '' : theme.spacing.unit * 2
                        }}
                    />
                    {getTextInput(
                        theme,
                        `localization.${lang}.quickdropInstructions.${type}[${index}].icon`,
                        loc('icon', lang),
                        formik
                    )}
                    <IconButton
                        style={{
                            marginTop: _.isNil(index) ? '' : theme.spacing.unit * 2,
                            marginRight: theme.spacing.unit,
                            padding: '2px',
                            height: '100%'
                        }}
                        onClick={onSearch}
                    >
                        <SearchIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={2}>
                {getTextInput(
                    theme,
                    `localization.${lang}.quickdropInstructions.${type}[${index}].primary`,
                    loc('primaryText', lang),
                    formik
                )}
            </Grid>
            <Grid item xs={6}>
                {getTextInput(
                    theme,
                    `localization.${lang}.quickdropInstructions.${type}[${index}].secondary`,
                    loc('secondaryText', lang),
                    formik,
                    'text'
                )}
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={() => onDeleteInstruction(type, index)}
                    data-cy={`information-editor-quickdrop-instructions-${index}-delete`}
                >
                    <Icon>delete</Icon>
                </IconButton>
            </Grid>
        </>
    );
}

function HowItWorksConfigUpdateWidget(props) {
    const { formik, typeIndex, stepIndex, theme, onDeleteHowItWorksStep, onSearch, lang } = props;

    const [expanded, setExpanded] = useState(false);

    const [instructionValues, setInstructionValues] = useState(
        _.get(
            formik.values,
            `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].instructions`,
            []
        )
    );

    const handleChangeInstructionValues = newValues => {
        setInstructionValues(newValues);
        _.set(
            formik.values,
            `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].instructions`,
            newValues
        );
    };

    const getMdiIcon = (typeIndex, stepIndex) => {
        let icon = _.get(
            formik.values,
            `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].icon`,
            ''
        );

        if (icon.startsWith('mdi')) {
            icon = _.camelCase(`${icon}`);
        } else {
            icon = _.camelCase(`mdi-${icon}`);
        }
        return allMdiIcons[icon];
    };

    return (
        <Grid item xs={12}>
            <Card
                style={{
                    margin: theme.spacing.unit,
                    boxShadow: 'none',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }}
                data-cy="information-forms-how-it-works-config-update-widget"
            >
                <CardHeader
                    titleTypographyProps={{ variant: 'body1' }}
                    title={
                        expanded ? (
                            <div style={{ display: 'flex' }}>
                                <MdiIcon
                                    path={getMdiIcon(typeIndex, stepIndex)}
                                    size={'100%'}
                                    color={theme.palette.primary.main}
                                    style={{
                                        maxWidth: '50px',
                                        maxHeight: '50px',
                                        marginRight: theme.spacing.unit,
                                        marginTop: theme.spacing.unit * 2
                                    }}
                                />

                                {getTextInput(
                                    theme,
                                    `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].icon`,
                                    loc('icon', lang),
                                    formik,
                                    'text',
                                    { marginRight: 0, flex: 0.7 }
                                    // {},
                                    // null,
                                    // false,
                                    // e => onUpdateHowItWorksStep(typeIndex, stepIndex, 'icon', e.target.value)
                                )}
                                <IconButton
                                    style={{
                                        marginTop: theme.spacing.unit * 3,
                                        marginLeft: theme.spacing.unit,
                                        marginRight: theme.spacing.unit,
                                        padding: '2px',
                                        height: '100%'
                                    }}
                                    onClick={onSearch}
                                >
                                    <SearchIcon />
                                </IconButton>
                                {getTextInput(
                                    theme,
                                    `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].title`,
                                    loc('title', lang),
                                    formik
                                )}
                            </div>
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <MdiIcon
                                    path={getMdiIcon(typeIndex, stepIndex)}
                                    size={'100%'}
                                    color={theme.palette.primary.main}
                                    style={{
                                        maxWidth: '50px',
                                        maxHeight: '50px',
                                        marginRight: theme.spacing.unit
                                    }}
                                />{' '}
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginTop: theme.spacing.unit
                                    }}
                                >
                                    {_.get(
                                        formik.values,
                                        `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].title`,
                                        ''
                                    )}
                                </Typography>
                            </div>
                        )
                    }
                    action={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                data-cy={`information-forms-how-it-works-${stepIndex}-delete`}
                                onClick={() => onDeleteHowItWorksStep(typeIndex, stepIndex)}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                            <IconButton
                                data-cy={`information-forms-how-it-works-${stepIndex}-expand`}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        </span>
                    }
                />
                <Collapse in={expanded}>
                    <CardContent>
                        {getTextInput(
                            theme,
                            `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].text`,
                            loc('growthPostInfo7', lang),
                            formik
                        )}

                        <StringListInput
                            InputProps={{
                                variant: 'outlined'
                            }}
                            label="Instructions (Optional)"
                            values={instructionValues}
                            onChange={handleChangeInstructionValues}
                            error={() => {}}
                            errorMessage=""
                            longStringFormat
                        />
                        {getSwitch(
                            theme,
                            `localization.${lang}.informationForms.howItWorks[${typeIndex}].steps[${stepIndex}].hideFromRegisteredUsers`,
                            'Hide from Registered Users',
                            formik
                        )}
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

function HowItWorksConfigAddWidget(props) {
    const { typeIndex, stepIndex, theme, onAddHowItWorksStep, onSearch, lang } = props;

    const [newTitle, setNewTitle] = useState('');
    const [newText, setNewText] = useState('');
    const [newIcon, setNewIcon] = useState('');
    const [newInstructions, setNewInstructions] = useState([]);
    const [newHideFrom, setNewHideFrom] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const onAdd = () => {
        onAddHowItWorksStep(typeIndex, {
            title: newTitle,
            text: newText,
            icon: newIcon,
            instructions: newInstructions,
            hideFromRegisteredUsers: newHideFrom
        });
        setNewTitle('');
        setNewText('');
        setNewIcon('');
        setNewInstructions([]);
    };

    const convertToIconExportName = icon => {
        return icon.startsWith('mdi') ? _.camelCase(`${icon}`) : _.camelCase(`mdi-${icon}`);
    };

    return (
        <Card
            style={{
                width: '100%',
                margin: theme.spacing.unit,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)'
            }}
            data-cy="information-forms-how-it-works-config-add-widget"
        >
            <CardHeader
                titleTypographyProps={{ variant: 'body1' }}
                title={
                    <div>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: theme.spacing.unit
                            }}
                        >
                            Add New
                        </Typography>
                    </div>
                }
                action={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            data-cy={`information-forms-how-it-works-add-expand`}
                            onClick={() => setExpanded(!expanded)}
                        >
                            <Icon>{expanded ? 'remove_circle_outline' : 'add_circle_outline'}</Icon>
                        </IconButton>
                    </span>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs={6} style={{ marginBottom: theme.spacing.unit }}>
                            <div style={{ display: 'flex' }}>
                                <MdiIcon
                                    path={
                                        allMdiIcons[
                                            newIcon ? convertToIconExportName(newIcon) : 'mdiDotsHorizontalCircle'
                                        ]
                                    }
                                    color={theme.palette.primary.main}
                                    style={{
                                        maxWidth: '50px',
                                        maxHeight: '50px',
                                        minWidth: '50px',
                                        minHeight: '50px',
                                        marginRight: theme.spacing.unit
                                    }}
                                />
                                <TextField
                                    style={{ marginRight: 0, flex: 1 }}
                                    name="icon"
                                    label="Icon"
                                    variant="outlined"
                                    value={newIcon}
                                    onChange={e => setNewIcon(e.target.value)}
                                    data-cy="information-forms-how-it-works-icon-input"
                                />
                                <IconButton
                                    style={{
                                        marginTop: theme.spacing.unit * 2,
                                        marginLeft: theme.spacing.unit,
                                        marginRight: theme.spacing.unit,
                                        padding: '2px',
                                        height: '100%'
                                    }}
                                    onClick={onSearch}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={6} style={{ marginBottom: theme.spacing.unit }}>
                            <TextField
                                style={{ marginRight: theme.spacing.unit }}
                                name="title"
                                label={loc('title', lang)}
                                variant="outlined"
                                value={newTitle}
                                onChange={e => setNewTitle(e.target.value)}
                                fullWidth
                                data-cy="information-forms-how-it-works-title-input"
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 0 }}>
                            <TextField
                                style={{ marginRight: theme.spacing.unit }}
                                name="text"
                                label={loc('growthPostInfo7', lang)}
                                variant="outlined"
                                value={newText}
                                onChange={e => setNewText(e.target.value)}
                                fullWidth
                                data-cy="information-forms-how-it-works-text-input"
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 0, marginBottom: 0 }}>
                            <StringListInput
                                InputProps={{
                                    variant: 'outlined'
                                }}
                                label="Instructions (Optional)"
                                values={newInstructions}
                                onChange={values => setNewInstructions(values)}
                                error={() => {}}
                                errorMessage=""
                                style={{ marginTop: 0 }}
                            />
                        </Grid>
                        <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={newHideFrom}
                                        onChange={event => setNewHideFrom(event.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Hide from Registered Users"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={1} style={{ marginBottom: 0 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    marginTop: theme.spacing.unit * 3
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    disabled={_.isEmpty(newTitle) || _.isEmpty(newText) || isEmpty(newIcon)}
                                    data-cy="information-forms-how-it-works-submit"
                                >
                                    Add
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
}

function FormsWidget(props) {
    const { children, theme, cyString } = props;
    return (
        <Paper
            style={{
                padding: theme.spacing.unit * 2,
                margin: theme.spacing.unit / 2,
                marginTop: theme.spacing.unit * 2
            }}
        >
            <Grid container spacing={theme.spacing.unit} data-cy={cyString ? cyString : null}>
                {children}
            </Grid>
        </Paper>
    );
}

function getInfoIcon(title, infoText, theme) {
    return {
        endAdornment: (
            <InputAdornment position="end">
                {!_.isEmpty(infoText) && (
                    <Tooltip
                        title={infoText}
                        data-cy={`config-${_.kebabCase(title)}-info-text`}
                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                    >
                        <Icon data-cy={`config-${_.kebabCase(title)}-info-icon`}>info</Icon>
                    </Tooltip>
                )}
            </InputAdornment>
        )
    };
}

function getLocalizationStringForFaqType(faqType) {
    switch (faqType) {
        case 'residential':
            return 'faq-tab-residential';
        case 'commercial':
            return 'faq-tab-commercial-business';
        case 'charity':
            return 'faq-tab-charities';
        case 'fundraising':
            return 'faq-tab-fundraising-bottle-drives';
        case 'bottledepot':
            return 'faq-tab-bottle-depot';
        case 'mobilerecycling':
            return 'faq-tab-mobile-recycling';
        default:
            return '';
    }
}
