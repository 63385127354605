import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { withTheme } from '@material-ui/core/styles';

import { Button, Icon, IconButton, LinearProgress, Tooltip } from '@material-ui/core';

import { mdiCodeJson } from '@mdi/js';
import MDIcon from '@mdi/react';

import CustomDebugDialog from '../CustomDebugDialog/CustomDebugDialog';
import DatePicker from '../DateTimePickersTz/DatePicker';

import CustomTable from './CustomTable';

function CRUDTable(props) {
    const {
        operator,
        columns,
        data,
        editForm,
        headerContent,
        headerRightContent,
        renderExtraActions,
        documentIsDisabled,
        documentNotEditable,
        defaultRowsPerPage,
        startDateFilterStats,
        setStartDateFilterStats,
        endDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        selectedDocument,
        setSelectedDocument,
        editDialogOpen,
        setEditDialogOpen,
        activeOrderBy,
        setActiveOrderBy,
        activeOrder,
        setActiveOrder,
        enabledHeaderText,
        disabledHeaderText,
        defaultColumnFilters,
        hideHeaderBar,
        hideDateFilters,
        hideAddButton,
        hideEditButton,
        hideViewJSON,
        loading,
        theme,
        nonCrudCustomFunction,
        initialCollectorFilter = null,
        footerContent,
        collectorTypeFilter,
        showEmptyTable
    } = props;

    const [debugDialogOpen, setDebugDialogOpen] = useState(false);
    const [debugDialogJSON, setDebugDialogJSON] = useState({});

    const [inactiveOrderBy, setInactiveOrderBy] = useState(null);
    const [inactiveOrder, setInactiveOrder] = useState('asc');

    const onDebugDialogClose = () => {
        setDebugDialogOpen(false);
    };

    const onViewJSON = document => {
        setDebugDialogJSON(document);
        setDebugDialogOpen(true);
    };

    const onCreate = () => {
        setSelectedDocument(null);
        setEditDialogOpen(true);
    };

    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
    };

    const activeDocuments = _.filter(data, document => !documentIsDisabled(document));
    const disabledDocuments = _.filter(data, document => documentIsDisabled(document));

    const renderActions = document => {
        return (
            <>
                {!hideViewJSON && (operator.isAllowedToDebug || JSON.parse(process.env.REACT_APP_DEBUG)) && (
                    <Tooltip title="View JSON" disableFocusListener disableTouchListener>
                        <IconButton
                            onClick={() => {
                                onViewJSON(document);
                            }}
                        >
                            <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                        </IconButton>
                    </Tooltip>
                )}
                {!hideEditButton && (
                    <Tooltip title="Edit" disableFocusListener disableTouchListener>
                        <IconButton
                            onClick={() => {
                                onEditRow(document);
                            }}
                            disabled={!_.isNil(documentNotEditable) ? documentNotEditable(document) : false}
                            data-cy="crud-table-edit-button"
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                )}

                {!_.isNil(renderExtraActions) && renderExtraActions(document)}
            </>
        );
    };

    const actionsColumnIsEmpty =
        !(operator.isAllowedToDebug || JSON.parse(process.env.REACT_APP_DEBUG)) &&
        hideEditButton &&
        _.isNil(renderExtraActions);
    return (
        <>
            <Grid
                container
                style={{ width: '100%', padding: theme.spacing.unit, margin: 0 }}
                spacing={theme.spacing.unit * 2}
            >
                <Grid item xs={12}>
                    {(loading || (nonCrudCustomFunction && nonCrudCustomFunction.loading)) && <LinearProgress />}
                    {!hideHeaderBar && (
                        <Paper style={{ width: '100%', paddingTop: theme.spacing.unit, margin: 0 }}>
                            <Grid container spacing={theme.spacing.unit} style={{ padding: theme.spacing.unit }}>
                                <Grid item xs={12} md={6}>
                                    {!hideDateFilters && (
                                        <>
                                            <DatePicker
                                                timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                                label="Start Date"
                                                disableFuture
                                                format="DD MMM YYYY Z"
                                                value={startDateFilterStats}
                                                style={{ margin: theme.spacing.unit }}
                                                onChange={date => setStartDateFilterStats(moment(date).startOf('day'))}
                                                error={dateFilterStatsErrorMessage !== ''}
                                                helperText={dateFilterStatsErrorMessage}
                                            />
                                            <DatePicker
                                                timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                                label="End Date"
                                                type="end"
                                                disableFuture
                                                format="DD MMM YYYY Z"
                                                value={endDateFilterStats}
                                                style={{ margin: theme.spacing.unit }}
                                                onChange={date => setEndDateFilterStats(moment(date).endOf('day'))}
                                                error={dateFilterStatsErrorMessage !== ''}
                                                helperText={dateFilterStatsErrorMessage}
                                            />
                                        </>
                                    )}

                                    {headerContent && (
                                        <span style={{ display: 'inline-block', margin: theme.spacing.unit }}>
                                            {headerContent}
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            flexWrap: 'wrap',
                                            margin: 0
                                        }}
                                    >
                                        {nonCrudCustomFunction && (
                                            <Button
                                                style={{ margin: theme.spacing.unit }}
                                                variant="outlined"
                                                onClick={() => nonCrudCustomFunction.method()}
                                                color="primary"
                                                data-cy="crud-table-custom"
                                                disabled={nonCrudCustomFunction.loading}
                                            >
                                                <Icon style={{ marginRight: theme.spacing.unit }}>
                                                    {nonCrudCustomFunction.btnMaterialIcon}
                                                </Icon>
                                                {nonCrudCustomFunction.btnLabel}
                                            </Button>
                                        )}
                                        {!hideAddButton && (
                                            <Button
                                                style={{ margin: theme.spacing.unit }}
                                                variant="outlined"
                                                onClick={onCreate}
                                                color="primary"
                                                data-cy="crud-table-add-new"
                                            >
                                                <Icon style={{ marginRight: theme.spacing.unit }}>
                                                    add_circle_outline
                                                </Icon>
                                                Add New
                                            </Button>
                                        )}

                                        {headerRightContent && (
                                            <span style={{ display: 'inline-block' }}>{headerRightContent}</span>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Grid>
                {(activeDocuments.length > 0 || showEmptyTable) && (
                    <Grid item xs={12}>
                        <Paper style={{ width: '100%', paddingTop: theme.spacing.unit, margin: 0 }}>
                            <CustomTable
                                headerText={enabledHeaderText}
                                data={activeDocuments}
                                columns={columns}
                                defaultRowsPerPage={defaultRowsPerPage}
                                orderBy={activeOrderBy}
                                order={activeOrder}
                                onOrderByChange={orderBy => setActiveOrderBy(orderBy)}
                                onOrderChange={order => setActiveOrder(order)}
                                renderActions={actionsColumnIsEmpty ? null : renderActions}
                                defaultColumnsToShow={defaultColumnFilters}
                                initialCollectorFilter={initialCollectorFilter}
                                footerContent={footerContent}
                                collectorTypeFilter={collectorTypeFilter}
                            />
                        </Paper>
                    </Grid>
                )}
                {disabledDocuments.length > 0 && (
                    <Grid item xs={12}>
                        <Paper style={{ width: '100%', paddingTop: theme.spacing.unit, margin: 0 }}>
                            <CustomTable
                                headerText={disabledHeaderText}
                                data={disabledDocuments}
                                columns={columns}
                                defaultRowsPerPage={defaultRowsPerPage}
                                orderBy={inactiveOrderBy}
                                order={inactiveOrder}
                                onOrderByChange={orderBy => setInactiveOrderBy(orderBy)}
                                onOrderChange={order => setInactiveOrder(order)}
                                renderActions={actionsColumnIsEmpty ? null : renderActions}
                                defaultColumnsToShow={defaultColumnFilters}
                                initialCollectorFilter={initialCollectorFilter}
                                collectorTypeFilter={collectorTypeFilter}
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={onDebugDialogClose} />
            {editDialogOpen && editForm /*need to recreate element each time opened to properly initialize variables*/}
        </>
    );
}

export default withTheme()(CRUDTable);
