import React, { useState } from 'react';
import _ from 'lodash';

import { Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import CommodityTable from 'components/CRUDTables/CommodityTable';
import ServiceFeesTable from 'components/CRUDTables/ServiceFeesTable';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

function Commodities(props) {
    const { theme } = props;
    const [tabSelected, setTabSelected] = useState('commodities');
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonGroup
                            value={tabSelected}
                            exclusive
                            onChange={(e, toggleType) => {
                                if (!_.isNil(toggleType)) {
                                    setTabSelected(toggleType);
                                }
                            }}
                        >
                            <ToggleButton value="commodities">Commodities</ToggleButton>
                            <ToggleButton value="serviceFees">Service Fees</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Grid>
            </Grid>
            {tabSelected === 'commodities' && <CommodityTable {...props} />}
            {tabSelected === 'serviceFees' && <ServiceFeesTable {...props} />}
        </>
    );
}

export default withTheme()(Commodities);
