import React, { useState, useEffect, useContext } from 'react';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import _ from 'lodash';
import moment from 'moment-timezone';

import { deviceHelper } from 'utils/misc';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    withTheme,
    Button,
    Icon,
    TextField,
    colors,
    DialogTitle,
    Chip,
    LinearProgress
} from '@material-ui/core';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions } from '@material-ui/core';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import charityTaskHelper from 'helpers/charityTaskHelper';
import * as terms from 'localizations/terms';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function CharityTaskEditDialog(props) {
    const {
        operator,
        history,
        charity,
        open,
        onClose,
        handleVerifyTask,
        handleRejectTask,
        handleUndoApproveTaskWithoutSubmission,
        charityTaskConfig,
        loading,
        theme,
        fullScreen
    } = props;

    const { lang } = useContext(LocalizationContext);

    const promoTaskId = _.get(charityTaskConfig, 'promoTaskId');

    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [selectedCharityTask, setSelectedCharityTask] = useState(null);
    const [tasksToShow, setTasksToShow] = useState([]);
    const [expansionPanelStates, setExpansionPanelStates] = useState([]);

    useEffect(() => {
        const showOnlyNeedsVerification = _.some(charity.charityTasks, charityTask =>
            charityTaskHelper.needsVerification(charityTask)
        );

        const charityTasks = _.filter(charity.charityTasks, charityTask => {
            if (charityTask.task.notCompletable && _.get(charityTask, 'task._id') !== promoTaskId) return false;

            if (showOnlyNeedsVerification) {
                return charityTaskHelper.needsVerification(charityTask);
            } else {
                return true;
            }
        });

        setTasksToShow(charityTasks.map(charityTask => charityTask.task._id));
        setExpansionPanelStates(charityTasks.map(() => false));
    }, [open]);

    const charityTasks = _.filter(charity.charityTasks, charityTask =>
        _.some(tasksToShow, taskToShow => taskToShow === charityTask.task._id)
    );

    const handleExpansion = (expanded, index) => {
        let expansionPanelStatesCopy = _.cloneDeep(expansionPanelStates);
        expansionPanelStatesCopy[index] = expanded;
        setExpansionPanelStates(expansionPanelStatesCopy);
    };

    const handleApproveButtonClicked = async (charityTask, index) => {
        await handleVerifyTask(charityTask);
        handleExpansion(false, index);
    };

    const handleUndoButtonClicked = async (charityTask, index) => {
        await handleUndoApproveTaskWithoutSubmission(charityTask);
        handleExpansion(false, index);
    };

    const handleRejectButtonClicked = charityTask => {
        setSelectedCharityTask(charityTask);
        setRejectDialogOpen(true);
    };

    const handleReject = async () => {
        const charityTaskIndex = _.findIndex(
            charityTasks,
            charityTask => charityTask.task._id === selectedCharityTask.task._id
        );
        setRejectDialogOpen(false);
        setRejectionReason('');

        await handleRejectTask(selectedCharityTask, rejectionReason);
        handleExpansion(false, charityTaskIndex);
    };

    const handleGoToPromoScreen = () => {
        const URL = `/operators/${operator._id}/promos?fastTrackCharity=${_.get(charity, '_id')}`;
        if (!deviceHelper.isNativeApp()) {
            window.open(URL, '_system');
        } else {
            history.push(URL);
        }
    };

    const charityTaskList = charityTasks.filter(task => !_.get(task, 'task.hidden')).map((charityTask, index) => {
        let lastSubmissionStatusText = null;
        let charityTaskStatusIcon = null;
        let taskApprovedWithoutSubmission =
            _.get(charityTask, 'lastSubmission.reviewed', false) &&
            _.get(charityTask, 'lastSubmission.dateSubmitted', null) === null;

        if (!charityTask.lastSubmission) {
            lastSubmissionStatusText = <span style={{ color: colors.grey[500] }}>Not Submitted Yet</span>;
        } else if (!charityTask.lastSubmission.reviewed) {
            charityTaskStatusIcon = <Icon style={{ color: colors.orange[500] }}>rate_review</Icon>;
            lastSubmissionStatusText = <span style={{ color: colors.orange[500] }}>Needs Verification</span>;
        } else if (charityTask.lastSubmission.rejected) {
            lastSubmissionStatusText = <span style={{ color: colors.red[500] }}>{loc('growthGeneral3', lang)}</span>;
        } else if (charityTask.task.notSubmitable) {
            lastSubmissionStatusText = <span style={{ color: colors.green[500] }}>Automatically Verified</span>;
        } else if (taskApprovedWithoutSubmission) {
            lastSubmissionStatusText = <span style={{ color: colors.green[500] }}>Approved Without Submission</span>;
        } else {
            lastSubmissionStatusText = <span style={{ color: colors.green[500] }}>Verified</span>;
        }

        let charityTasksCompletionStatus, charityTaskCompletionStatusIcon, expirationDateDisplay;
        if (
            charityTaskHelper.isComplete(charityTask) ||
            (_.get(charityTask, 'task._id') === promoTaskId &&
                _.get(charityTask.lastSubmission, 'reviewed') &&
                !_.get(charityTask.lastSubmission, 'rejected'))
        ) {
            charityTasksCompletionStatus = 'Completed';
            charityTaskCompletionStatusIcon = <Icon style={{ color: colors.green[500] }}>check_circle</Icon>;
            expirationDateDisplay = (
                <Chip
                    style={{ marginTop: theme.spacing.unit }}
                    color="primary"
                    label={`Expires on ${moment(charityTask.dateExpires).format('MMM DD, YYYY')}`}
                />
            );
        } else if (charityTaskHelper.isExpired(charityTask)) {
            charityTasksCompletionStatus = loc('growthGeneral4', lang);
            charityTaskCompletionStatusIcon = <Icon style={{ color: colors.red[500] }}>event</Icon>;
            expirationDateDisplay = (
                <Chip
                    style={{ marginTop: theme.spacing.unit }}
                    color="secondary"
                    label={`Expired on ${moment(charityTask.dateExpires).format('MMM DD, YYYY')}`}
                />
            );
        } else {
            charityTasksCompletionStatus = 'Not Completed';
            charityTaskCompletionStatusIcon = <Icon style={{ color: colors.grey[500] }}>check_circle</Icon>;
            expirationDateDisplay = <></>;
        }

        const rejectBtnDisabled =
            !charityTask.lastSubmission ||
            (charityTask.lastSubmission && _.get(charityTask, 'lastSubmission.rejected'));

        const cypressTagTaskName = charityTask.task[lang].shortTitle.replace(/[ ,.]/g, '');

        const verificationType = _.get(charityTask, 'lastSubmission.verificationType', '');
        const verificationUrl = _.get(charityTask, 'lastSubmission.verificationUrl', '');
        const verificationImages = _.get(charityTask, 'lastSubmission.verificationImages', []);

        const showValidationDataDisplay =
            (verificationType !== 'photo' && !_.isEmpty(verificationUrl)) ||
            (verificationType === 'photo' && !_.isEmpty(verificationImages));

        const validationDataDisplay =
            verificationType === 'photo' ? (
                <ImageViewer
                    data-cy="charity-task-edit-dialog-verification-img"
                    displayImages={verificationImages}
                    disableDelete={true}
                    innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            ) : (
                <div data-cy={`charity-task-edit-dialog-verification-link-${cypressTagTaskName}`}>
                    <Typography style={{ display: 'inline-block', marginRight: theme.spacing.unit }}>
                        Submitted Link:
                    </Typography>
                    <ExternalLink text={verificationUrl} url={verificationUrl} />
                </div>
            );

        return (
            <ExpansionPanel
                key={charityTask.task._id}
                expanded={expansionPanelStates[index]}
                onChange={(event, expanded) => handleExpansion(expanded, index)}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                        data-cy={`charity-task-edit-dialog-${cypressTagTaskName}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <span style={{ marginRight: theme.spacing.unit * 2 }}>{charityTask.task.en.title}</span>
                        <span>
                            <span style={{ marginRight: theme.spacing.unit * 2 }}>{charityTaskStatusIcon}</span>
                            {(!_.get(charityTask, 'task.notCompletable', false) ||
                                _.get(charityTask, 'task._id') === promoTaskId) && (
                                    <span>{charityTaskCompletionStatusIcon}</span>
                                )}
                        </span>
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block' }}>
                    {!_.get(charityTask, 'task.notCompletable', false) && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography variant="h6">
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {charityTaskCompletionStatusIcon}
                                    <span style={{ marginLeft: theme.spacing.unit }}>
                                        {charityTasksCompletionStatus}
                                    </span>
                                </span>
                            </Typography>
                            {expirationDateDisplay}
                        </div>
                    )}
                    <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                        Latest Submission
                    </Typography>
                    <Typography data-cy={`charity-task-edit-dialog-${cypressTagTaskName}-status`}>
                        {loc('status', lang)}: {lastSubmissionStatusText}
                    </Typography>
                    {_.get(charityTask, 'lastSubission.dateSubmitted', null) && (
                        <Typography>
                            {loc('growthGeneral6', lang)}{' '}
                            {moment(charityTask.lastSubmission.dateSubmitted).format('MMM DD, YYYY')}
                        </Typography>
                    )}
                    {_.get(charityTask, 'lastSubmission.rejectionReason', false) && (
                        <Typography>Rejection Reason: {charityTask.lastSubmission.rejectionReason}</Typography>
                    )}
                    {showValidationDataDisplay && <>{validationDataDisplay}</>}
                    {_.get(charityTask, 'task._id') === promoTaskId && (
                        <Typography
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: theme.palette.linkColor
                            }}
                            onClick={handleGoToPromoScreen}
                        >
                            Create a promo code for this {terms.ORGANIZATION_NAME.toLowerCase()} to approve this
                            submission.
                        </Typography>
                    )}
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                    {_.get(charityTask, 'task._id') !== promoTaskId && (
                        <Button
                            data-cy={`charity-task-edit-dialog-approve-${cypressTagTaskName}`}
                            color="primary"
                            disabled={loading}
                            style={{ marginRight: theme.spacing.unit }}
                            onClick={() => handleApproveButtonClicked(charityTask, index)}
                        >
                            Approve
                        </Button>
                    )}
                    {taskApprovedWithoutSubmission ? (
                        <Button
                            color="secondary"
                            disabled={rejectBtnDisabled || loading}
                            onClick={() => handleUndoButtonClicked(charityTask, index)}
                        >
                            Undo approve
                        </Button>
                    ) : (
                        <Button
                            data-cy={`charity-task-edit-dialog-reject-${cypressTagTaskName}`}
                            color="secondary"
                            disabled={rejectBtnDisabled || loading}
                            onClick={() => handleRejectButtonClicked(charityTask)}
                        >
                            Reject
                        </Button>
                    )}
                </ExpansionPanelActions>
                {loading && <LinearProgress />}
            </ExpansionPanel>
        );
    });

    return (
        <>
            <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="Dialog" fullScreen={fullScreen}>
                <DialogTitle>Charity: {charity.name}</DialogTitle>
                <DialogContent style={{ paddingTop: theme.spacing.unit }}>{charityTaskList}</DialogContent>
                <DialogActions>
                    <Button color="default" onClick={onClose} data-cy="charity-task-edit-dialog-close">
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)}>
                <DialogTitle>Reject Submission</DialogTitle>
                <DialogContent>
                    <TextField
                        data-cy="charity-task-edit-dialog-rejection-reason"
                        label="Enter Reason"
                        variant="outlined"
                        margin="normal"
                        multiline
                        fullWidth
                        onChange={event => setRejectionReason(event.target.value)}
                        value={rejectionReason}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={() => setRejectDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!rejectionReason}
                        onClick={handleReject}
                        data-cy="charity-task-edit-dialog-reject"
                    >
                        {loc('submit', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withMobileDialog()(withTheme()(CharityTaskEditDialog));
