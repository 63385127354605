import React, { useState } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton, Icon, colors, withStyles } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import OTCGroupForm from './Forms/OTCGroupForm';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import { useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import { _commodity } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

const phoneNumberGroupings = process.env.REACT_APP_PHONE_NUMBER_GROUPING.split(' ');
const styles = theme => ({
    badgeOk: {
        backgroundColor: colors.green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

function OTCGroupTable(props) {
    const { theme, operator, onSnackbar, setGroupFilter, handleToggleTableTab } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const OTCGroupEndpoints = {
        getEndPoint: '/getAllExternalGroups',
        getEditEndPoint: _id => `/updateExternalGroup/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalGroup' // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const {
        data: allOTCGroups,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints: OTCGroupEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const OTClocationEndpoints = {
        getEndPoint: '/getAllExternalLocations',
        getEditEndPoint: _id => `/updateExternalLocation/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalLocation' // url to add new documents
    };
    const { data: allOTCLocations } = useCRUD({
        endpoints: OTClocationEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        {
            key: 'name',
            header: 'Name'
        },
        { key: 'email', header: 'Email' },
        {
            key: 'phone',
            header: 'Phone',
            formatValue: value => {
                if (value)
                    return value.replace(
                        new RegExp(
                            `(\\d{${phoneNumberGroupings[0]}})(\\d{${phoneNumberGroupings[1]}})(\\d{${phoneNumberGroupings[2]}})`
                        ),
                        process.env.REACT_APP_PHONE_NUMBER_FORMAT
                    );
                return value;
            }
        },
        {
            key: 'defaultIcon',
            header: 'Icon',
            formatValue: (value, rowData) => (
                <MDIcon
                    path={_.get(allIcons, _.camelCase(`mdi-${value}`))}
                    size={1}
                    color={
                        _.isNil(colors[_.get(rowData, 'defaultColor', 'black')])
                            ? _.get(rowData, 'defaultColor', 'black')
                            : colors[_.get(rowData, 'defaultColor', 'black')][500]
                    }
                />
            )
        },
        { key: 'accessCode', header: 'Access Code' }
    ];
    const handleDeleteOTCGroup = async OTCGroupId => {
        const res = await http.post(`/deleteExternalGroup`, { _id: OTCGroupId });
        if (res.ok) {
            onSnackbar('Group Removed');
            handleReloadData();
        }
    };

    const defaultColumnFilters = columns.map(c => c.key);
    const editForm = (
        <OTCGroupForm
            OTCGroup={selectedDocument}
            allOTCGroup={allOTCGroups}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            operator={operator}
        />
    );
    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
    };

    const renderExtraActions = document => {
        return (
            <>
                <Tooltip title="Edit">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            onEditRow(document);
                        }}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View locations">
                    <IconButton
                        data-cy={`view-button`}
                        onClick={e => {
                            setGroupFilter(_.get(document, '_id', ''));
                            handleToggleTableTab('location');
                        }}
                    >
                        <Icon>visibility</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            for (let i = 0; i < allOTCLocations.length; i++) {
                                const groupId = _.get(allOTCLocations[i], 'group._id');
                                if (groupId === _.get(document, '_id')) {
                                    onSnackbar('Cannot delete groups with existing locations', 'error');
                                    return;
                                }
                            }

                            warnAction(() => {
                                handleDeleteOTCGroup(_.get(document, '_id'));
                            }, 'Are you sure you want to delete this group?');
                        }}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={allOTCGroups}
                editForm={editForm}
                documentIsDisabled={g => _.get(g, 'disabled', false)}
                defaultRowsPerPage={10}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'OTC Groups'}
                loading={loading}
                renderExtraActions={renderExtraActions}
                hideDateFilters
                defaultColumnFilters={defaultColumnFilters}
                hideEditButton={true}
                hideViewJSON={true}
                showEmptyTable
            />
        </>
    );
}

export default withStyles(styles)(withTheme()(OTCGroupTable));
