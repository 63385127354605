import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { _time, _commodity } from 'std';
import moment from 'moment-timezone';

import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import {
    withMobileDialog,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    Grid
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { downloadObjectAsCSV, getQuickDropName, getWalkInName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

const VolumeReportDialog = ({
    http,
    customer,
    theme,
    fullScreen,
    commodities,
    open,
    onClose,
    reloadCustomer,
    onSnackbar,
    taxGroups
}) => {
    const { lang } = useContext(LocalizationContext);

    const allTaxes = useMemo(() => {
        return _.flatten(_.map(taxGroups, taxGroup => _.get(taxGroup, 'taxes', [])));
    }, [taxGroups]);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfMonth(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const skuTypesForStats = _.filter(commodities, { includeContainersInStats: true }).map(commodity =>
        _commodity.getSkuType(commodity)
    );

    const handleDownload = async () => {
        const res = await http.postJSON('/bulks/getVolumeReportBulks', {
            startDate,
            endDate,
            skuTypes: skuTypesForStats,
            customerID: _.get(customer, 'uniqueID', '')
        });

        if (res.ok) {
            const { volumeReportBulkLists } = res.data;

            let rowSpan = 0;
            for (let bulkList of volumeReportBulkLists) {
                if (bulkList.length + 2 > rowSpan) {
                    rowSpan = bulkList.length + 2;
                }
            }

            const dateRange = `${moment(startDate).format('MM-DD-YYYY')} to ${moment(endDate).format('MM-DD-YYYY')}`;

            const rows = [
                [
                    padArray(['Commercial customer report'], rowSpan, ''),
                    padArray(['Date range', dateRange], rowSpan, ''),
                    padArray([], rowSpan, ''),
                    padArray([], rowSpan, '')
                ]
            ];

            for (let bulkList of volumeReportBulkLists) {
                if (_.isEmpty(bulkList)) {
                    continue;
                }

                let completedBulksGroupedByLocation = _.groupBy(bulkList, bulk => {
                    if (!_.isNil(bulk.pickup)) {
                        return _.get(bulk, 'pickup.location.description', '')
                            .trim()
                            .toLowerCase();
                    } else {
                        // for drop and go
                        return _.get(bulk, 'collector.location.description', '')
                            .trim()
                            .toLowerCase();
                    }
                });

                const firstBulk = _.first(bulkList);
                const ownerName = `${_.get(firstBulk, 'owner.name.first')} ${_.get(firstBulk, 'owner.name.last')}`;
                const customerName = `${_.get(customer, 'name.first')} ${_.get(customer, 'name.last')}`;
                let reportName = firstBulk && firstBulk.owner ? ownerName : customerName;
                // reportName += ` (${_.get(firstBulk, 'owner._id', customer._id)})`;
                let arrayToConvert = [padArray(['Customer name', reportName], rowSpan, '')];

                // if more than 1 location, show breakdown;
                const locations = Object.keys(completedBulksGroupedByLocation);
                const hasMultipleLocations = locations.length > 1;

                const depositTaxGroup = _.get(customer, 'depositTaxGroup', null);
                const displayDepositTax = _.get(customer, 'displayDepositTax', false);

                let overallNumOfBins = 0,
                    overallPickups = 0,
                    overallNumBags = 0,
                    overallVolume = 0,
                    overallWeight = 0,
                    overallValue = 0,
                    overallDeposits = 0,
                    overallTaxOnDeposits = 0,
                    overallFees = 0,
                    overallTaxOnFees = 0;

                for (let location in completedBulksGroupedByLocation) {
                    let {
                        pickupDatesArr,
                        numBagsArr,
                        numOfBinsArr,
                        totalContainersArr,
                        totalWeightArr,
                        totalValuesArr,
                        depositsArr,
                        depositTaxArr,
                        feesArr,
                        feeTaxArr,
                        bulkTypeArr,
                        materialQuantities,
                        totalNumOfBins,
                        totalPickups,
                        totalNumBags,
                        totalVolume,
                        totalWeight,
                        totalValue,
                        totalDeposits,
                        totalTaxOnDeposits,
                        totalFees,
                        totalTaxOnFees
                    } = getReportData(
                        completedBulksGroupedByLocation[location],
                        timezone,
                        rowSpan,
                        location,
                        depositTaxGroup,
                        displayDepositTax,
                        allTaxes
                    );

                    numOfBinsArr.push(totalNumOfBins);
                    pickupDatesArr.push('');
                    numBagsArr.push(totalNumBags);
                    totalContainersArr.push(totalVolume);
                    totalWeightArr.push(totalWeight);
                    depositsArr.push(totalDeposits);
                    depositTaxArr.push(totalTaxOnDeposits);
                    totalValuesArr.push(totalValue);
                    feesArr.push(totalFees);
                    feeTaxArr.push(totalTaxOnFees);
                    bulkTypeArr.push('TOTALS');

                    pickupDatesArr.unshift('Date');
                    bulkTypeArr.unshift('Type');

                    const dataForLocation = [padArray(['Address', location], rowSpan, ''), pickupDatesArr, bulkTypeArr];

                    if (!_.every(numBagsArr, val => !val)) {
                        numBagsArr.unshift('No. bags');
                        dataForLocation.push(numBagsArr);
                    }

                    if (!_.every(numOfBinsArr, val => !val)) {
                        numOfBinsArr.unshift('No. bins');
                        dataForLocation.push(numOfBinsArr);
                    }

                    totalContainersArr.unshift('Total containers');
                    dataForLocation.push(...materialQuantities);
                    dataForLocation.push(totalContainersArr);

                    if (totalWeight > 0) {
                        totalWeightArr.unshift('Total weight (kg)');
                        dataForLocation.push(totalWeightArr);
                    }

                    if (!_.every(depositsArr, val => !val)) {
                        depositsArr.unshift('Deposits ($)');
                        dataForLocation.push(_.map(depositsArr, val => (_.isNumber(val) ? formatCents(val) : val)));

                        if (displayDepositTax && !_.every(depositTaxArr, val => !val)) {
                            depositTaxArr.unshift('Deposits Tax component ($)');
                            dataForLocation.push(
                                _.map(depositTaxArr, val => (_.isNumber(val) ? formatCents(val) : val))
                            );
                        }
                    }

                    if (!_.every(feesArr, val => !val)) {
                        feesArr.unshift('Fees ($)');
                        dataForLocation.push(_.map(feesArr, val => (_.isNumber(val) ? formatCents(val) : val)));

                        if (!_.every(feeTaxArr, val => !val)) {
                            feeTaxArr.unshift('Fees Tax Component ($)');
                            dataForLocation.push(_.map(feeTaxArr, val => (_.isNumber(val) ? formatCents(val) : val)));
                        }
                    }

                    totalValuesArr.unshift('Total Refund ($)');
                    dataForLocation.push(_.map(totalValuesArr, val => (_.isNumber(val) ? formatCents(val) : val)));

                    if (hasMultipleLocations) {
                        overallNumOfBins += totalNumOfBins;
                        overallPickups += totalPickups;
                        overallNumBags += totalNumBags;
                        overallVolume += totalVolume;
                        overallWeight += totalWeight;
                        overallValue += totalValue;
                        overallDeposits += totalDeposits;
                        overallTaxOnDeposits += totalTaxOnDeposits;
                        overallFees += totalFees;
                        overallTaxOnFees += totalTaxOnFees;
                    }

                    arrayToConvert = arrayToConvert.concat(dataForLocation);

                    arrayToConvert.push(padArray([], rowSpan, ''));
                }
                if (hasMultipleLocations) {
                    const overallData = [
                        padArray(['Customer Totals'], rowSpan, ''),
                        padArray(['Total no. pick ups', overallPickups], rowSpan, ''),
                        padArray(['Total no. bags', overallNumBags], rowSpan, ''),
                        padArray(['Total no. bins', overallNumOfBins], rowSpan, ''),
                        padArray(['Total volume', overallVolume], rowSpan, ''),
                        padArray(['Total weight (kg)', overallWeight], rowSpan, ''),
                        padArray(['Total Deposits ($)', formatCents(overallDeposits)], rowSpan, ''),
                        padArray(['Total Fees ($)', formatCents(overallFees)], rowSpan, ''),
                        padArray(['Total Fee Tax ($)', formatCents(overallTaxOnFees)], rowSpan, ''),
                        padArray(['Total Refund ($)', formatCents(overallValue)], rowSpan, '')
                    ];

                    if (displayDepositTax) {
                        // Add total deposit tax line after Total Deposits
                        overallData.splice(
                            7,
                            0,
                            padArray(['Total Deposit Tax', formatCents(overallTaxOnDeposits)], rowSpan, '')
                        );
                    }

                    arrayToConvert = arrayToConvert.concat(overallData);
                }

                arrayToConvert.push(padArray([], rowSpan, '')); // empty row
                arrayToConvert.push(padArray([], rowSpan, '')); // empty row

                rows.push(arrayToConvert);
            }

            downloadCSV(rows.flat());
        }
    };

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                // fullWidth
                open={open}
                onClose={onClose}
            >
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    {loc('volumeReport', lang)}
                </DialogTitlePrimary>
                <DialogContent>
                    <DialogContentText
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    >
                        {loc('volumeReport1', lang)}
                    </DialogContentText>
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        {loc('cancel', lang)}
                    </Button>
                    <Button color="primary" onClick={handleDownload} data-cy="volume-report-dialog-download">
                        {loc('download', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(VolumeReportDialog));

const padArray = (arr, len, fill) => {
    return arr.concat(Array(len).fill(fill)).slice(0, len);
};

function formatCents(cents) {
    return ((cents || 0) / 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

const downloadCSV = async arrayToConvert => {
    const date = moment().format('YYYY-MM-DD');
    const fileName = `volume_report_${date}`;
    try {
        await downloadObjectAsCSV(arrayToConvert, fileName, { header: false });
    } catch (err) {
        console.log(err);
    }
};

const getReportData = (bulks, timezone, rowSpan, location, depositTaxGroup, displayDepositTax, allTaxes) => {
    let quantitiesDict = {},
        pickupDatesArr = [],
        bulkTypeArr = [],
        numBagsArr = [],
        totalContainersArr = [],
        totalWeightArr = [],
        totalValuesArr = [],
        numOfBinsArr = [],
        depositsArr = [],
        depositTaxArr = [],
        feesArr = [],
        feeTaxArr = [];

    let deposits = 0,
        depositTax = 0,
        fees = 0,
        feeTax = 0;

    // use counts.items for quantities, ledger.lines for dollar values and commoditiesProcessed for bag counts
    bulks.forEach(
        (
            {
                bulkType,
                ledger,
                counts,
                commoditiesProcessed,
                commodityAmount,
                datePickedUp,
                customFees,
                commodityUOM = '',
                rates,
                commoditiesProcessedBreakdown
            },
            bulkIdx
        ) => {
            pickupDatesArr.push(
                moment(datePickedUp)
                    .tz(timezone)
                    .format('MM-DD-YYYY')
            );

            // if commodityUOM is bins don't count it again as bags??
            if (!(commodityUOM.toLowerCase() === 'bin' || commodityUOM.toLowerCase().includes('bin'))) {
                numBagsArr.push(commoditiesProcessed || commodityAmount);
            }

            let ratesBySku = _.get(rates, 'rates', []).reduce((ratesObj, rate) => {
                let sku = _.get(rate, 'sku');
                if (sku) {
                    return {
                        ...ratesObj,
                        [sku]: rate
                    };
                }
                return ratesObj;
            }, {});

            const countTotalsForBulk = _(counts)
                .flatMapDeep(count => _.get(count, 'items', []))
                .groupBy(
                    ({ materialType, size, sku }) =>
                        `${
                            _.get(ratesBySku, `${sku}.description`)
                                ? _.get(ratesBySku, `${sku}.description`)
                                : materialType
                        } ${_.isNil(size) || !_.get(ratesBySku, `${sku}.label`) ? '' : size}`
                )
                .map((groupArr, id) => {
                    const countSum = _.sumBy(groupArr, 'quantity');
                    const sku = _.get(groupArr, '0.sku', '');
                    const weight = _.get(ratesBySku, `${sku}.weight`, 0);
                    const weightSum = weight * countSum;

                    return {
                        material: id.trim(),
                        countSum,
                        weightSum
                    };
                })
                .value();

            let valueTotal = 0;
            let depositsBulk = 0,
                depositsTaxBulk = 0,
                feesBulk = 0,
                feesTaxBulk = 0;

            const lines = _.get(ledger, 'lines', []);

            lines.forEach(line => {
                valueTotal += line.amount;

                if (line.amount < 0) {
                    depositsBulk += Math.abs(line.amount);
                    deposits += Math.abs(line.amount);
                } else {
                    feesBulk += Math.abs(line.amount);
                    fees += Math.abs(line.amount);
                }
            });
            let depositTaxAmounts = [],
                feeTaxAmounts = [];
            if (!_.isNil(depositTaxGroup) && displayDepositTax) {
                _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
                    let currentAmountForTax = _.find(depositTaxAmounts, { _id: tax._id });
                    if (_.isNil(currentAmountForTax)) {
                        currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                        depositTaxAmounts.push(currentAmountForTax);
                    }
                    const taxType = _.get(tax, 'configuration.type', 'included');
                    if (taxType === 'included') {
                        const beforeTaxAmount = _.round(depositsBulk / (1 + _.get(tax, 'configuration.value', 0)));

                        const taxAmount = depositsBulk - beforeTaxAmount;
                        currentAmountForTax.amount += taxAmount;
                    } else {
                        //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
                    }
                });

                // _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
                //     let currentAmountForTax = _.find(feeTaxAmounts, { _id: tax._id });
                //     if (_.isNil(currentAmountForTax)) {
                //         currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                //         feeTaxAmounts.push(currentAmountForTax);
                //     }
                //     const taxType = _.get(tax, 'configuration.type', 'included');
                //     if (taxType === 'included') {
                //         const beforeTaxAmount = _.round(feesBulk / (1 + _.get(tax, 'configuration.value', 0)));

                //         const taxAmount = feesBulk - beforeTaxAmount;
                //         currentAmountForTax.amount += taxAmount;
                //     } else {
                //         //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
                //     }
                // });
            }

            // const taxAmounts = [];
            lines.forEach(line => {
                const taxForLine = _.find(allTaxes, tax => _.get(line, 'description', '').includes(_.get(tax, 'name')));
                if (!_.isNil(taxForLine)) {
                    let taxAmountForTax = _.find(feeTaxAmounts, { taxName: _.get(taxForLine, 'name') });
                    if (_.isNil(taxAmountForTax)) {
                        taxAmountForTax = { amount: 0, taxName: _.get(taxForLine, 'name') };
                        feeTaxAmounts.push(taxAmountForTax);
                    }

                    taxAmountForTax.amount += line.amount;
                }
            });

            depositsTaxBulk = depositTaxAmounts.reduce((sum, depositTax) => {
                return sum + _.get(depositTax, 'amount', 0);
            }, 0);
            depositTax += depositsTaxBulk;
            feesTaxBulk = feeTaxAmounts.reduce((sum, tax) => {
                return sum + _.get(tax, 'amount', 0);
            }, 0);
            feeTax += feesTaxBulk;

            let containerTotal = 0,
                weightTotal = 0;

            countTotalsForBulk.forEach(({ material, countSum, weightSum }) => {
                const value = Math.abs(
                    _.get(
                        _.find(lines, function(ln) {
                            return ln.description
                                .toLowerCase()
                                .trim()
                                .includes(material.toLowerCase());
                        }),
                        'amount',
                        0
                    )
                );

                containerTotal += countSum;
                weightTotal += weightSum;

                if (material in quantitiesDict) {
                    quantitiesDict[material].push(countSum);
                } else {
                    let newMaterialQuantitiesArr = new Array(bulkIdx).fill(0);
                    newMaterialQuantitiesArr.push(countSum);
                    quantitiesDict[material] = newMaterialQuantitiesArr;
                }
            });

            const numOfBinsForBulk = getNumOfBinsFromSubCommodities(
                _.filter(commoditiesProcessedBreakdown, c => c.isSubCommodity)
            );

            let bulkTypeName = _.capitalize(bulkType);
            if (bulkType === 'inhouse') {
                bulkTypeName = getQuickDropName('en');
            } else if (bulkType === 'walk-in') {
                bulkTypeName = getWalkInName('en');
            }

            bulkTypeArr.push(bulkTypeName);
            numOfBinsArr.push(numOfBinsForBulk);
            totalContainersArr.push(containerTotal);
            totalWeightArr.push(weightTotal);
            totalValuesArr.push(-1 * valueTotal);
            depositsArr.push(depositsBulk);
            depositTaxArr.push(depositsTaxBulk);
            feesArr.push(feesBulk);
            feeTaxArr.push(feesTaxBulk);

            // fill in the commodities not picked up in this bulk with zero for quantity
            for (var material in quantitiesDict) {
                if (quantitiesDict[material].length <= bulkIdx) {
                    quantitiesDict[material].push(0);
                }
            }
            // const numOfBinsForBulk = getNumOfBinsFromCustomFees(customFees);
            // numOfBinsArr.push(numOfBinsForBulk);
        }
    );

    let materialQuantities = [];
    for (var material in quantitiesDict) {
        const containerCounts = quantitiesDict[material];
        const totalContainers = containerCounts.reduce((curr, total) => total + curr);

        materialQuantities.push([material, ...quantitiesDict[material], totalContainers]);
    }

    const totalPickups = bulks.length;
    const totalNumBags = _.sum(numBagsArr);
    const totalVolume = _.sum(totalContainersArr);
    const totalWeight = _.sum(totalWeightArr);
    const totalValue = _.sum(totalValuesArr);
    const totalNumOfBins = _.sum(numOfBinsArr);

    // totalValuesArr = totalValuesArr.map(val => formatCents(val));

    return {
        pickupDatesArr,
        bulkTypeArr,
        numBagsArr,
        numOfBinsArr,
        totalContainersArr,
        totalWeightArr,
        totalValuesArr,
        depositsArr,
        depositTaxArr,
        feesArr,
        feeTaxArr,
        materialQuantities,
        totalNumOfBins,
        totalPickups,
        totalNumBags,
        totalVolume,
        totalWeight,
        totalValue,
        totalDeposits: deposits,
        totalTaxOnDeposits: depositTax,
        totalFees: fees,
        totalTaxOnFees: feeTax
    };
};

function getNumOfBinsFromCustomFees(customFees = []) {
    let numOfBins = customFees.reduce((sum, fee) => {
        if (fee.binSwapRequired === true) {
            return sum + 1;
        } else {
            return sum;
        }
    }, 0);

    return numOfBins;
}

function getNumOfBinsFromSubCommodities(commoditiesProcessedBreakdown) {
    let numOfBins = 0;
    commoditiesProcessedBreakdown.forEach(c => {
        if (
            c.isSubCommodity &&
            (_.get(c, 'subCommodity.units.en', '').toLowerCase() === 'bin' ||
                _.get(c, 'subCommodity.units.en', '')
                    .toLowerCase()
                    .includes('bin'))
        ) {
            numOfBins += c.amount;
        }
    });
    return numOfBins;
}
