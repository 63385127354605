import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Menu from '@material-ui/core/Menu';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ContactForm(props) {
    const { theme, fullScreen, specification, open, http, onClose, onSubmitSuccess } = props;

    const { lang } = useContext(LocalizationContext);

    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [contactReasons, setContactReasons] = useState([]);

    const handleSubmit = async () => {
        const reasonData = _.find(contactReasons, contactReason => contactReason.code === reason);

        let toEmail = 'service';
        //Sign Up For Depot Software contact us button doesn't have a reason field
        if (!_.isNil(reasonData)) {
            toEmail = reasonData.toEmail;
        }
        // const isPaymentsIssue = _.get(reasonData, 'isPaymentsIssue', false);

        // if (isPaymentsIssue) {
        //     toEmail = 'payments'; // Etransfer problem
        // }
        let res = await http.post('/contact', { reason, message, email, name, toEmail });
        if (res.ok) {
            onSubmitSuccess();

            setMessage('');
            setEmail('');
            setName('');
        }
    };

    useEffect(() => {
        async function loadContactDetails() {
            let res = await http.getJSON('/contact');
            if (res.ok) {
                const reasons = res.data.contact;
                if (specification !== 'BOTTLE_DEPOT' && specification !== 'HELP_CENTER') {
                    setContactReasons(reasons);
                }

                if (specification === 'BOTTLE_DEPOT') {
                    setReason('Bottle Depot from Registration page');
                    setContactReasons([]);
                } else if (specification === 'HELP_CENTER') {
                    setReason('Query from Registration Help Center');
                    setContactReasons([]);
                } else if (specification === 'OTC') {
                    setReason('Query from external OTC portal');
                    setContactReasons([]);
                }
            }
        }
        if (open) {
            loadContactDetails();
        }
    }, [open]);

    return (
        <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {loc('contactUs', lang)}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                {_.isNil(specification) && (
                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                        <InputLabel htmlFor="reason">
                            {process.env.REACT_APP_REGION_EXT === 'EXP' ? loc('labels19', lang) : loc('labels2', lang)}
                        </InputLabel>
                        <Select
                            data-cy="contact-reason-select"
                            value={reason}
                            input={<Input name="reason" id="reason" />}
                            onChange={e => setReason(e.target.value)}
                        >
                            {contactReasons.map((reason, idx) => {
                                return (
                                    <MenuItem data-cy={`contact-reason-select-${idx}`} key={idx} value={reason.code}>
                                        {reason.message}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
                {(specification === 'BOTTLE_DEPOT' || specification === 'HELP_CENTER' || specification === 'OTC') && (
                    <React.Fragment>
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                            <TextField
                                label={loc('email', lang)}
                                value={email}
                                name="email"
                                multiline
                                onChange={e => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                            <TextField
                                label={loc('labels3', lang)}
                                value={name}
                                name="name"
                                multiline
                                onChange={e => setName(e.target.value)}
                            />
                        </FormControl>
                    </React.Fragment>
                )}
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                    <TextField
                        label={
                            process.env.REACT_APP_REGION_EXT === 'EXP' ? loc('labels20', lang) : loc('labels4', lang)
                        }
                        value={message}
                        name="message"
                        multiline
                        onChange={e => setMessage(e.target.value)}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Hidden smUp>
                    <Button data-cy="contact-close-button" onClick={onClose} color="primary">
                        {loc('close', lang)}
                    </Button>
                </Hidden>
                <Button
                    color="primary"
                    disabled={_.isEmpty(reason) || (_.isEmpty(message) && _.isEmpty(email) && _.isEmpty(name))}
                    onClick={handleSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ContactForm));
