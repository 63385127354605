import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    InputAdornment,
    DialogActions,
    Button,
    withTheme,
    withMobileDialog,
    Typography,
    FormControl,
    colors
} from '@material-ui/core';
import * as allIcons from '@mdi/js';

import { MAX_NUM_OF_RECYCLING_BAGS } from 'constants.js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { getStateAbbreviationFromFullName, isAUSRegion } from 'utils/misc';
import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';

function CommodityRequestDialog(props) {
    const {
        open,
        loading,
        onClose,
        onSubmit,
        onCancel,
        theme,
        http,
        pendingRequest = null,
        locationType,
        location
    } = props;
    const { lang } = useContext(LocalizationContext);

    const [filteredCommodities, setFilteredCommodities] = useState([]);
    const [selectedCommodities, setSelectedCommodities] = useState(_.get(pendingRequest, 'commodities', []));

    const noCommoditiesRequested = _.isNil(_.find(selectedCommodities, commodity => commodity.amount > 0));
    const commodityRequestError = !_.isNil(
        _.find(selectedCommodities, commodity => commodity.amount > 99 || commodity.amount < 0)
    );

    const getCommodities = async () => {
        const res = await http.getJSON('/allEnabledCommodities');
        if (res.ok) {
            const allCommodities = res.data.commodities;
            let trackedCommodities = [];
            allCommodities.forEach(commodity => {
                if (
                    commodity.enableCustomerOrders &&
                    _.get(commodity, 'locationType', []).includes(locationType) &&
                    (!isAUSRegion() ||
                        _.isNil(location.province) ||
                        _.get(commodity, 'state', []).includes(getStateAbbreviationFromFullName(location.province)))
                ) {
                    trackedCommodities.push({
                        _id: commodity._id,
                        name: _.get(commodity, `payloadInputName.${lang}`),
                        iconName: _.get(allIcons, commodity.iconName),
                        iconColor: commodity.color,
                        units: _.get(commodity, `units.${lang}`)
                    });
                }
                _.get(commodity, 'subCommodities', []).forEach(subCommodity => {
                    if (
                        subCommodity.enableCustomerOrders &&
                        _.get(subCommodity, 'locationType', []).includes(locationType) &&
                        (!isAUSRegion() ||
                            _.isNil(location.province) ||
                            _.get(subCommodity, 'state', []).includes(
                                getStateAbbreviationFromFullName(location.province)
                            ))
                    ) {
                        trackedCommodities.push({
                            _id: subCommodity._id,
                            name: _.get(subCommodity, `name.${lang}`),
                            iconName: _.get(allIcons, convertToImportName(subCommodity.iconName)),
                            iconColor: subCommodity.color,
                            units: _.get(commodity, `units.${lang}`),
                            isSubCommodity: true
                        });
                    }
                });
            });
            setFilteredCommodities(trackedCommodities);
        }
    };

    const handleCommodity = e => {
        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSelection = _.cloneDeep(selectedCommodities);

        const commodity = _.find(filteredCommodities, c => _.get(c, '_id') === path);

        let selectedCommodity = _.find(updatedSelection, c => _.get(c, '_id') === path);

        if (!commodity) return;

        if (selectedCommodity) {
            selectedCommodity.amount = value;
        } else {
            updatedSelection.push({
                _id: commodity._id,
                amount: value,
                isSubCommodity: commodity.isSubCommodity
            });
        }

        setSelectedCommodities(updatedSelection);
    };

    useEffect(() => {
        getCommodities();
    }, [location, locationType]);

    useEffect(() => {
        setSelectedCommodities(_.get(pendingRequest, 'commodities', []));
    }, [pendingRequest]);

    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>
                {loc('commodityRequestDialog1', lang)}
                <Typography variant="caption">*{loc('commodityRequestDialog2', lang)}</Typography>
            </DialogTitle>
            <DialogContent style={{ paddingTop: theme.spacing.unit * 2 }}>
                <DialogContentText>
                    {filteredCommodities.map((commodity, i) => {
                        const selection = _.find(selectedCommodities, c => c._id === commodity._id) || {
                            _id: commodity._id,
                            amount: 0,
                            isSubCommodity: commodity.isSubCommodity
                        };
                        const selectionError = selection.amount < 0 || selection.amount > MAX_NUM_OF_RECYCLING_BAGS;
                        return (
                            <FormControl
                                fullWidth
                                style={{
                                    marginBottom: i === filteredCommodities.length - 1 ? 0 : theme.spacing.unit * 2
                                }}
                                key={commodity._id}
                            >
                                <NumberOfBagsInput
                                    required={false}
                                    startAdornmentIcon={commodity.iconName}
                                    startAdornmentIconColor={commodity.iconColor}
                                    componentValue={selection.amount}
                                    componentLabel={commodity.name}
                                    componentName={commodity._id}
                                    componentHelperText={
                                        selectionError
                                            ? loc('commodityRequestDialogError1', lang, {
                                                  min: 0,
                                                  max: MAX_NUM_OF_RECYCLING_BAGS
                                              })
                                            : ''
                                    }
                                    componentError={selectionError}
                                    // extraAdornments={
                                    //     <InputAdornment position="end" style={{ height: '100%' }}>
                                    //         {commodity.units}
                                    //     </InputAdornment>
                                    // }
                                    onBags={handleCommodity}
                                />
                            </FormControl>
                        );
                    })}
                </DialogContentText>
                {!_.isNil(pendingRequest) && (
                    <Typography variant="body2" style={{ color: colors.amber[700], textAlign: 'right' }}>
                        {loc('commodityRequestDialog3', lang)}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose} data-cy="commodity-request-dialog-close">
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(_.filter(selectedCommodities, c => c.amount > 0))}
                    disabled={noCommoditiesRequested || loading || commodityRequestError}
                    data-cy="commodity-request-dialog-submit"
                >
                    {_.isNil(pendingRequest) ? loc('request', lang) : loc('commodityRequestDialog4', lang)}
                </Button>
                {!_.isNil(pendingRequest) && (
                    <Button color="secondary" onClick={onCancel} data-cy="commodity-request-dialog-cancel">
                        {loc('accountSettings36', lang)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CommodityRequestDialog));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
