import React, { useState } from 'react';
import _ from 'lodash';

function useQuestionnaireForm(props) {
    const { questionnaire } = props;

    const [responses, setResponses] = useState([]);
    const [touchedInputs, setTouchedInputs] = useState([]);
    const [overrideTouched, setOverrideTouched] = useState(false);

    let allQuestions;
    if (_.isArray(questionnaire)) {
        allQuestions = _.flatten(_.map(questionnaire, questionnaire => _.get(questionnaire, 'questions', [])));
    } else {
        allQuestions = _.get(questionnaire, 'questions', []);
    }

    const formIncomplete = _.some(allQuestions, question => {
        let currentReponseToQuestion = _.find(responses, { questionId: question._id });
        if (_.isNil(currentReponseToQuestion) || _.isEmpty(_.get(currentReponseToQuestion, 'responseSelected', '')))
            return true;
        if (
            _.get(currentReponseToQuestion, 'responseSelected', '') === 'OTHER' &&
            _.isEmpty(_.get(currentReponseToQuestion, 'customResponse', ''))
        )
            return true;
        return false;
    });

    const handleResponseSelected = (questionnaire, question, response) => {
        const updatedResponses = _.cloneDeep(responses);
        let currentReponseToQuestion = _.find(updatedResponses, { questionId: question._id });
        if (_.isNil(currentReponseToQuestion)) {
            currentReponseToQuestion = {
                questionnaireId: questionnaire._id,
                questionId: question._id,
                responseSelected: response
            };
            updatedResponses.push(currentReponseToQuestion);
        } else {
            currentReponseToQuestion.responseSelected = response;
        }
        setResponses(updatedResponses);

        const updatedTouchedInputs = _.cloneDeep(touchedInputs);
        let currentTouchedState = _.find(updatedTouchedInputs, { questionId: question._id });
        if (_.isNil(currentTouchedState)) {
            currentTouchedState = {
                questionId: question._id,
                mainInputTouched: true,
                otherInputTouched: false
            };
            updatedTouchedInputs.push(currentTouchedState);
        } else {
            currentTouchedState.mainInputTouched = true;
        }
        setTouchedInputs(updatedTouchedInputs);
    };

    const handleCustomReponseChanged = (questionnaire, question, customResponse) => {
        const updatedResponses = _.cloneDeep(responses);
        let currentReponseToQuestion = _.find(updatedResponses, { questionId: question._id });
        if (_.isNil(currentReponseToQuestion)) {
            currentReponseToQuestion = {
                questionnaireId: questionnaire._id,
                questionId: question._id,
                responseSelected: 'OTHER',
                customResponse
            };
            updatedResponses.push(currentReponseToQuestion);
        } else {
            currentReponseToQuestion.customResponse = customResponse;
        }
        setResponses(updatedResponses);

        const updatedTouchedInputs = _.cloneDeep(touchedInputs);
        let currentTouchedState = _.find(updatedTouchedInputs, { questionId: question._id });
        if (_.isNil(currentTouchedState)) {
            currentTouchedState = {
                questionId: question._id,
                mainInputTouched: false,
                otherInputTouched: true
            };
            updatedTouchedInputs.push(currentTouchedState);
        } else {
            currentTouchedState.otherInputTouched = true;
        }
        setTouchedInputs(updatedTouchedInputs);
    };

    const handleOverrideTouched = value => {
        setOverrideTouched(value);
    };

    return {
        responses,
        formIncomplete,
        touchedInputs,
        overrideTouched,
        handleResponseSelected,
        handleCustomReponseChanged,
        handleOverrideTouched
    };
}

export default useQuestionnaireForm;
