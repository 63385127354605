import React, { useState, useMemo, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import { downloadObjectAsCSV } from 'utils/misc';
import HttpContext from 'utils/contexts/HttpContext';

function JournalReport(props) {
    const { theme, operator } = props;

    const http = useContext(HttpContext);

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(
        moment('2019-01-01')
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day')
    );
    const [endDate, setEndDate] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .endOf('day')
    );
    const [depositsReceivable, setDepositsReceivable] = useState('');
    const [customerLiability, setCustomerLiability] = useState('');
    const [serviceIncome, setServiceIncome] = useState('');
    const [taxLiability, setTaxLiability] = useState('');
    const [redemptionsPayment, setRedemptionsPayment] = useState('');
    const [adSpend, setAdSpend] = useState('');
    const [referrals, setReferrals] = useState('');
    const [serviceFees, setServiceFees] = useState('');

    const handleDownloadCSV = async () => {
        setLoading(true);

        const res = await http.getJSON(
            `/journalEntries?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        );
        if (res.ok) {
            const journalEntries = res.data.journalEntries;
            const report = generateCsvData(journalEntries, {
                depositsReceivable,
                customerLiability,
                serviceIncome,
                taxLiability,
                redemptionsPayment,
                adSpend,
                referrals,
                serviceFees
            });
            await downloadObjectAsCSV(
                report,
                `journal_report_${moment(startDate)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('YYYY-MM-DD')}_${moment(endDate)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('YYYY-MM-DD')}`
            );
        }

        setLoading(false);
    };

    const formControlStyle = {
        width: `calc(50% - ${theme.spacing.unit}px)`,
        marginLeft: theme.spacing.unit * 0.5,
        marginRight: theme.spacing.unit * 0.5,
        marginTop: theme.spacing.unit * 2
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/system/configuration/intuitAccounts');
            if (res.ok) {
                const accounts = res.data.accounts;
                setDepositsReceivable(_.get(accounts, 'depositsReceivable.name'));
                setCustomerLiability(_.get(accounts, 'customerLiability.name'));
                setServiceIncome(_.get(accounts, 'serviceIncome.name'));
                setTaxLiability(_.get(accounts, 'taxLiability.name'));
                setRedemptionsPayment(_.get(accounts, 'redemptionsPayment.name'));
                setAdSpend(_.get(accounts, 'adSpend.name'));
                setReferrals(_.get(accounts, 'referrals.name'));
                setServiceFees(_.get(accounts, 'serviceFees.name'));
            }
        })();
    }, []);

    return (
        <div data-cy="Accounting" style={{ padding: theme.spacing.unit * 2 }}>
            <Paper style={{ marginTop: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                <Typography variant="h6">Journal Report</Typography>
                <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                    Download all financial transactions within specified period:{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {moment(startDate)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('YYYY-MM-DD HH:mm Z')}
                    </span>{' '}
                    to{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {moment(endDate)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('YYYY-MM-DD HH:mm Z')}
                    </span>
                </Typography>
                <Typography color="textSecondary">
                    Date range applies to when <span style={{ color: theme.palette.text.primary }}>containers</span>{' '}
                    were picked up and <span style={{ color: theme.palette.text.primary }}>adjustments</span> were
                    issued.
                </Typography>

                <Grid
                    container
                    style={{
                        marginLeft: -theme.spacing.unit * 0.5,
                        marginRight: -theme.spacing.unit * 0.5,
                        marginTop: theme.spacing.unit
                    }}
                >
                    <Grid item xs={12}>
                        <Grid item xs={12} lg={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <FormControl style={formControlStyle}>
                                <DatePicker
                                    disabled={loading}
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    label="Start date"
                                    name="date"
                                    format="MMMM Do, YYYY"
                                    value={startDate}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={date => setStartDate(date)}
                                />
                            </FormControl>
                            <FormControl style={formControlStyle}>
                                <DatePicker
                                    disabled={loading}
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    label="End date"
                                    name="date"
                                    format="MMMM Do, YYYY"
                                    value={endDate}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={date => setEndDate(date.endOf('day'))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12} style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}>
                        <Typography color="textSecondary">Enter account names for accounting purposes.</Typography>
                    </Grid> */}

                    <Grid item xs={12} lg={6}>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Deposits Receivable"
                                value={depositsReceivable}
                                onChange={e => setDepositsReceivable(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Customer Liability"
                                value={customerLiability}
                                onChange={e => setCustomerLiability(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Service Income"
                                value={serviceIncome}
                                onChange={e => setServiceIncome(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Tax Liability"
                                value={taxLiability}
                                onChange={e => setTaxLiability(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Redemptions Payment"
                                value={redemptionsPayment}
                                onChange={e => setRedemptionsPayment(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Ad Spend"
                                value={adSpend}
                                onChange={e => setAdSpend(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Referrals"
                                value={referrals}
                                onChange={e => setReferrals(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={formControlStyle}>
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                label="Service Fees"
                                value={serviceFees}
                                onChange={e => setServiceFees(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ marginTop: theme.spacing.unit * 2 }}
                    onClick={handleDownloadCSV}
                >
                    Download CSV
                </Button>
            </Paper>

            {loading && (
                <LinearProgress
                    style={{
                        borderBottomLeftRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius
                    }}
                />
            )}
        </div>
    );
}

export default withTheme()(JournalReport);

function generateCsvData(journalEntries, enteredAccountNames) {
    const csvData = [];
    for (let i = 0; i < journalEntries.length; i++) {
        const journalEntry = journalEntries[i];

        const lineNo = i + 1;
        const transactionDateFormatted = moment(journalEntry.transactionDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .format('MMM D, YYYY');

        for (let line of _.get(journalEntry, 'intuitJournalEntry.Line', [])) {
            const accountKey = _.get(line, 'JournalEntryLineDetail.AccountRef.value');
            csvData.push({
                'Line No': lineNo,
                'Transaction Date': transactionDateFormatted,
                Account: _.get(enteredAccountNames, accountKey, accountKey),
                Amount:
                    _.get(line, 'Amount', 0) *
                    (_.get(line, 'JournalEntryLineDetail.PostingType', 'Credit') === 'Credit' ? 1 : -1),
                Description: _.get(line, 'Description', '')
            });
        }
    }
    return csvData;
}
