import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { COMMODITY_COLORS } from 'constants.js';

import { _bulk, _collector, _user, _time } from 'std';
import { useHistory } from 'react-router-dom';

import { formatAsCurrency, deviceHelper } from 'utils/misc';
import bulkHelper from 'helpers/bulkHelper';

import {
    colors,
    Typography,
    Button,
    TableRow,
    TableCell,
    Icon,
    IconButton,
    TextField,
    Grid,
    Paper,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { withTheme } from '@material-ui/core/styles';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import ReceiversSelectDropdown from 'components/DropDownSelects/Receivers';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';
import BulkSkuTypeSelectionDropdown from 'components/BulkComponents/BulkSkuTypeSelectionDropdown';

import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';
import useReceiverDropDown from 'components/DropDownSelects/Receivers/hooks/useReceiverDropDown';
import useBulkSkuTypeSelectionDropdown from 'components/BulkComponents/hooks/useBulkSkuTypeSelectionDropdown';

import useQueryString from 'utils/hooks/useQueryString';

import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import { downloadObjectAsCSV, getCustomerName } from 'utils/misc';

const headers = [
    { name: 'Actions', locKey: null },
    { name: 'Amount', locKey: null },
    { name: 'Label', locKey: null },
    { name: 'Customer ID', locKey: null },
    { name: 'Status', locKey: null },
    { name: 'Date Received', locKey: null },
    { name: 'Date Completed', locKey: null },
    { name: 'Sku', locKey: null },
    { name: 'Type', locKey: null },
    { name: 'Collector', locKey: null },
    { name: 'Driver', locKey: null }
];

function BulksHistory({ theme, skus, collectors, receivers, initialCollector }) {
    const history = useHistory();
    const http = useContext(HttpContext);
    const operator = useContext(OperatorContext);

    const [customerID, setCustomerID] = useQueryString('customerID', '', true);
    const [label, setLabel] = useQueryString('label', '', true);
    const [lostAndFoundOnly, setLostAndFoundOnly] = useQueryString('lostAndFoundOnly', 'false', true);

    const [commodityColors, setCommodityColors] = useState({});

    const fetchCommodityColors = async () => {
        const res = await http.getJSON('/commodities/commodityColors');
        setCommodityColors(res.data);
    };

    const allCommodityColors = { ...COMMODITY_COLORS, ...commodityColors };

    const handleBulkCustomerIDChange = e => {
        const newValue = e.target.value;
        if (newValue.length < 5) {
            setCustomerID(
                newValue
                    .toString()
                    .replace(/\W/g, '')
                    .toUpperCase()
            );
        }
    };

    const handleBulkLabelChange = e => {
        const newValue = e.target.value;
        if (newValue.length < 24) {
            setLabel(
                newValue
                    .toString()
                    .replace(/\W/g, '')
                    .toUpperCase()
            );
        }
    };

    const { showAll: showAllCollectors, collector, handleChange: handleChangeCollectorFilter } = useCollectorDropDown({
        collectors,
        showAll: _user.isSystemAdmin || _user.isInternalRole,
        saveStateInURL: true,
        initialCollector: _user.isCollectorAdmin(operator) ? initialCollector : null
    });

    const filteredReceiversByCollector = filterReceiversByCollector(collector, receivers);

    const { receiver, handleChange: handleChangeReceiverFilter } = useReceiverDropDown({
        receivers: filteredReceiversByCollector,
        saveStateInURL: true
    });

    const { sku: skuTypeFilter, handleChange: handleSkuTypeFilterChange } = useBulkSkuTypeSelectionDropdown({
        skus: Object.keys(skus),
        saveStateInURL: true
    });

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({ url: '/bulks?completed=true', key: 'bulks', headers: headers.map(({ name }) => name) });

    const cellStyle = { whiteSpace: 'nowrap' };
    const filterStyle = {
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        maxWidth: 200,
        minWidth: 125,
        flex: '1 1 40%'
    };

    const handleViewCustomer = customer => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/customers/${_user.getId(customer)}`);
        } else {
            window.open(`/customers/${_user.getId(customer)}`, '_blank');
        }
    };

    const handleViewBulk = bulk => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`);
        } else {
            window.open(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`, '_blank');
        }
    };

    const renderRow = (bulk, filteredHeaders) => {
        if (_.isNil(bulk)) return null;
        const { _id, datePickedUp, dateCompleted } = bulk;

        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={cellStyle}>
                        {bulk.owner && (
                            <IconButton onClick={() => handleViewCustomer(bulk.owner)}>
                                <Icon>visibility</Icon>
                            </IconButton>
                        )}

                        <IconButton onClick={() => handleViewBulk(bulk)}>
                            <Icon>ballot</Icon>
                        </IconButton>
                    </TableCell>
                )}

                {filteredHeaders.includes('Amount') && (
                    <TableCell style={cellStyle}>{formatAsCurrency(_bulk.getTotalAmount(bulk))}</TableCell>
                )}

                {filteredHeaders.includes('Label') && (
                    <TableCell style={cellStyle}>{_bulk.getTruncatedLabel(bulk)}</TableCell>
                )}

                {filteredHeaders.includes('Customer ID') && (
                    <TableCell style={cellStyle}>{_bulk.getCustomerUniqueID(bulk)}</TableCell>
                )}

                {filteredHeaders.includes('Status') && (
                    <TableCell style={cellStyle}>{_.capitalize(_bulk.getStatus(bulk))}</TableCell>
                )}

                {filteredHeaders.includes('Date Received') && (
                    <TableCell style={cellStyle}>{moment(datePickedUp).format('LLL')}</TableCell>
                )}

                {filteredHeaders.includes('Date Completed') && (
                    <TableCell style={cellStyle}>{moment(dateCompleted).format('LLL')}</TableCell>
                )}

                {filteredHeaders.includes('Sku') && (
                    <TableCell style={cellStyle}>{_.capitalize(bulk.skuType)}</TableCell>
                )}

                {filteredHeaders.includes('Type') && (
                    <TableCell style={cellStyle}>
                        {_.capitalize(bulkHelper.getBulkTypeFormatted(bulk))}{' '}
                        {_.isNil(_.get(bulk, 'subBulkType')) ? '' : `(${_.upperCase(_.get(bulk, 'subBulkType'))})`}
                    </TableCell>
                )}

                {filteredHeaders.includes('Collector') && (
                    <TableCell style={{ ...cellStyle, color: bulk.hasDefaultCollector ? colors.red[500] : null }}>
                        {_collector.getName(bulk.collector)}
                    </TableCell>
                )}

                {filteredHeaders.includes('Driver') && (
                    <TableCell style={cellStyle}>{_user.getNameFull(bulk.receiver)}</TableCell>
                )}
            </TableRow>
        );
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const customSearch = (
        <>
            <TextField
                variant="outlined"
                label="Customer ID"
                value={customerID}
                onChange={handleBulkCustomerIDChange}
                style={filterStyle}
            />
            <TextField
                variant="outlined"
                label="Label"
                style={filterStyle}
                value={label}
                onChange={handleBulkLabelChange}
            />
            <BulkSkuTypeSelectionDropdown
                commodityColors={allCommodityColors}
                skus={skus}
                helperText="Commodity Type"
                labelCustomWidth={120}
                typeFilter={skuTypeFilter}
                handleChange={handleSkuTypeFilterChange}
                style={{
                    marginRight: theme.spacing.unit,
                    marginTop: theme.spacing.unit
                }}
            />
            <CollectorsSelectDropdown
                showAll={showAllCollectors}
                id="collector-dropdown"
                label="Processor"
                value={collector}
                collectors={collectors}
                cypress_id="trip-list-collector-dropdown"
                onChange={handleChangeCollectorFilter}
                style={filterStyle}
            />
            <ReceiversSelectDropdown
                id="receiver-dropdown"
                value={receiver || ''}
                receivers={filteredReceiversByCollector}
                cypress_id="trip-list-receiver-dropdown"
                onChange={handleChangeReceiverFilter}
                style={filterStyle}
            />
            <FormControl style={{ marginLeft: 5 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={lostAndFoundOnly === 'true'}
                            onChange={() => {
                                if (lostAndFoundOnly === 'true') setLostAndFoundOnly('false');
                                else setLostAndFoundOnly('true');
                            }}
                        />
                    }
                    label="lost and found"
                />
            </FormControl>
        </>
    );

    const handleDownload = async () => {
        const csvRows = [];

        filteredData.forEach(order => {
            const newRow = {
                Amount: formatAsCurrency(_bulk.getTotalAmount(order)),
                Label: _bulk.getTruncatedLabel(order),
                CustomerId: _.get(order, 'owner.uniqueID', ''),
                Status: _.capitalize(_bulk.getStatus(order)),
                DateReceived: null,
                DateCompleted: null,
                Sku: _.capitalize(order.skuType),
                Type: _.capitalize(bulkHelper.getBulkTypeFormatted(order)),
                Collector: _collector.getName(order.collector),
                CollectorCode: _.get(order, 'collector.code', ''),
                Driver: _user.getNameFull(order.receiver),
                CustomerName: getCustomerName(order.owner),
                CustomerEmail: _.get(order, 'owner.email', ''),
                CustomerAddress: _.get(order, 'owner.location.description', ''),
                counter: order.expressCollector || '',
                numBags: order.commoditiesProcessed
            };

            if (order.datePickedUp) {
                newRow.DateReceived = moment(order.datePickedUp);
            }

            if (order.dateCompleted) {
                newRow.DateCompleted = moment(order.dateCompleted);
            }

            csvRows.push(newRow);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `order_statistics_${date}`;
        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
    };

    const customAction = (
        <Button disabled={loading} color="primary" style={{ marginRight: theme.spacing.unit }} onClick={handleDownload}>
            <Icon>get_app</Icon>
        </Button>
    );

    const defaultCollectorWarning = () => {
        let totalDefaultCollectors = 0;
        for (let order of filteredData) {
            if (order.hasDefaultCollector) {
                totalDefaultCollectors++;
            }
        }

        if (!totalDefaultCollectors) return <></>;

        return (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: theme.spacing.unit * 4 }}>
                <Icon style={{ color: colors.yellow[800] }}>warning</Icon>
                <Typography style={{ marginLeft: theme.spacing.unit, color: colors.yellow[800] }}>
                    {`WARNING: ${totalDefaultCollectors} orders tied to default collector`}
                </Typography>
            </div>
        );
    };

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    useEffect(() => {
        fetchCommodityColors();
    }, []);

    return (
        <>
            <Grid>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        disabled={loading}
                                        value={dateWindow}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <Paper
                        style={{
                            padding: theme.spacing.unit * 2,
                            height: '100%'
                        }}
                    >
                        {' '}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="h5" style={{ minWidth: 175 }}>
                                Search Orders
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingLeft: theme.spacing.unit / 2,
                                    paddingRight: theme.spacing.unit / 2,
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    flex: '1'
                                }}
                            >
                                {customSearch}
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    variant="contained"
                                    data-cy="config-logs-search-button"
                                    type="submit"
                                    onClick={handleSearch}
                                >
                                    <Icon>search</Icon>
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Grid>
            <Table
                title="Orders"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                noSearch={true}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={customAction}
                headerContent={defaultCollectorWarning()}
            >
                {filteredData &&
                    filteredData.map(org => {
                        return renderRow(org, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(BulksHistory);

function filterReceiversByCollector(collector, receivers) {
    if (_.isNil(collector) || collector === 'all') {
        return receivers;
    } else {
        const filteredReceivers = _.filter(receivers, r => r.collector.toString() === collector.toString());
        if (_.isEmpty(filteredReceivers)) return [];
        return filteredReceivers;
    }
}
