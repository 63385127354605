import VERSION_FRONT from 'VERSION';

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { _user } from 'std';

import {
    deviceHelper,
    getCustomerName,
    profileNotComplete,
    emailIsVerified,
    isEXPRegion,
    getUserAccountStatus,
    isCONRegion
} from 'utils/misc';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import MDIcon from '@mdi/react';
import { withTheme } from '@material-ui/core/styles';

import { mdiSproutOutline, mdiDoor } from '@mdi/js';
import CharityTaskCompletionChip from '../../components/CustomerWidgets/CharityTaskCompletionChip';

import './DrawerContent.css';
import { Collapse, ListItemSecondaryAction, colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';
import { useHistory } from 'react-router-dom';
import ExpIcon from 'icons/ExpIcon';
import conIcon from 'icons/con-get-started-icon.png';
import sarIcon from 'icons/rw-icon.png';

function DrawerContent(props) {
    const {
        http,
        theme,
        drawerWidth,
        auth,
        homePath,
        location,
        customer,
        customerPages,
        charityPages,
        accounts,
        accountSelected,
        accountSelectedIndex,
        accountsAnchorEl,
        pickupPending,
        helpDialogEl,
        enableReferrals,
        highlightAccountSwitch,
        charity,
        nextPlatformToRateOn,
        onGrowthPlanDialog,
        lastInteractionHidden,
        shareAndFeedbackDialogConfig,
        rolePermissions,
        scanQREnabled,
        showQREnabled,
        showHelpButton,
        changingCharities,
        adminPermissions,
        handleAccessDoor
        // displayQRCode
        // allowRequestLabels
    } = props;
    const history = useHistory();

    const { lang, setLang } = useContext(LocalizationContext);

    const [collapseOpen, setCollapseOpen] = useState(null);

    const handleExpandCollapse = (collapseToOpen = null) => {
        setCollapseOpen(collapseToOpen === collapseOpen ? null : collapseToOpen);
    };

    const handleViewUser = _id => () => {
        if (!deviceHelper.isNativeApp()) {
            window.open(`/customers/${_id}`, '_blank');
        } else {
            // this.props.history.push(`/customers/${_id}/`);
            window.location.assign(`/customers/${_id}`);
        }
    };

    const MenuItemStyle = { paddingLeft: theme.spacing.unit * 3, paddingRight: theme.spacing.unit * 3 };
    const MenuItemExtraSpaceStyle = {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 2
    };

    const accountColors = {
        Customer: theme.palette.primary[500],
        Administrator: theme.palette.secondary[500]
    };

    const ratingEnabled = _.get(shareAndFeedbackDialogConfig, 'ratingScreen.enabled', false);

    const isSysAdminOrCsr = _user.isSystemAdmin(auth) || _user.isInternalRole(auth);

    const showSubscriptions = isSysAdminOrCsr || _user.isCollectorAdmin(auth);

    const showLastInteraction =
        !lastInteractionHidden && accountSelected === 'Customer' && auth.accountType !== 'Customer';

    // const isCommercialCustomer = !isSysAdminOrCsr && !_.isNil(_.get(customer, 'location.businessName'));

    const userAccountStatus = getUserAccountStatus(customer);

    const charityAccountSelected = accountSelected.includes('Administrator')
        ? _.find(_.get(customer, 'charities', []), c => c._id === accountSelected.split('-')[1])
        : { name: 'N/A' };

    const showVolumeReport = _.get(customer, 'volumeReportPreference', false);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', position: 'relative' }}>
                <ListItem
                    component="div"
                    button={
                        accounts.length > 1 &&
                        (!rolePermissions ||
                            (_.get(rolePermissions, 'accounts.charity') && _.get(rolePermissions, 'accounts.customer')))
                    }
                    style={{
                        marginBottom: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        paddingTop: 'max(16px, env(safe-area-inset-top, 16px))'
                    }}
                    onClick={props.onAccountsMenu(true)}
                >
                    <ListItemIcon>
                        <Avatar
                            style={{
                                width: 40,
                                height: 40,
                                backgroundColor: accountColors[accountSelected.split('-')[0]],
                                color: 'white'
                            }}
                        >
                            {accountSelected.includes('Administrator')
                                ? charityAccountSelected.name[0]
                                : _.get(customer, 'name.first[0]', '?')}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText
                        data-cy="drawer-content-customer-info"
                        inset
                        primary={
                            <>
                                <div
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {accountSelected.includes('Administrator')
                                        ? charityAccountSelected.name
                                        : getCustomerName(customer)}
                                </div>
                                {userAccountStatus !== 'Active' &&
                                    !(auth.accountType === 'Customer' && userAccountStatus === 'Under Review') && (
                                        <div style={{ color: colors.grey[500] }}>{userAccountStatus}</div>
                                    )}
                            </>
                        }
                        secondary={
                            accountSelected === 'Customer' ? (
                                <div>
                                    ID: {customer.uniqueID}{' '}
                                    {!_.isNil(customer.schemeID) && (
                                        <>
                                            <br /> SchemeID: {customer.schemeID}
                                        </>
                                    )}
                                </div>
                            ) : (
                                accountSelected.split('-')[0]
                            )
                        }
                        secondaryTypographyProps={{ noWrap: true }}
                        style={{ paddingLeft: 8, maxWidth: 180 }}
                    />
                    {accounts.length > 1 &&
                        (!rolePermissions ||
                            (_.get(rolePermissions, 'accounts.charity') &&
                                _.get(rolePermissions, 'accounts.customer'))) && (
                            <IconButton
                                className={highlightAccountSwitch ? 'pulse' : null}
                                data-cy="customersite-expand-button"
                                disableRipple
                            >
                                <Icon style={{ color: highlightAccountSwitch ? 'white' : 'inherit' }}>
                                    manage_accounts
                                </Icon>
                            </IconButton>
                        )}
                </ListItem>
                <MenuList style={{ overflowY: 'auto', width: drawerWidth }} className="appbar">
                    {accountSelected === 'Customer' && (
                        <div>
                            <MenuItem
                                data-cy="customersite-home-button"
                                component={Link}
                                to={homePath}
                                selected={location.pathname === homePath}
                                disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>home</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={customerPages[homePath]} />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-history-button"
                                component={Link}
                                to={homePath + '/history'}
                                selected={location.pathname === homePath + '/history'}
                                disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>history</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={customerPages[homePath + '/history']} />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-user-button"
                                component={Link}
                                to={homePath + '/profile'}
                                selected={location.pathname === homePath + '/profile'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>account_circle</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={customerPages[homePath + '/profile']} />
                                {customer.accountsWithAccess && !_.isEmpty(customer.accountsWithAccess) && (
                                    <ListItemSecondaryAction>
                                        <Icon
                                            style={{
                                                color: 'red',
                                                marginRight: theme.spacing.unit
                                            }}
                                            data-cy="viewing-access-alert-icon"
                                        >
                                            error
                                        </Icon>
                                    </ListItemSecondaryAction>
                                )}
                            </MenuItem>

                            {/* {allowRequestLabels && (
                                <MenuItem
                                    data-cy="customersite-qr-code-button"
                                    style={MenuItemStyle}
                                    onClick={props.onQRDialog(true)}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>print</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={isEXPRegion() ? loc('requestLabels', lang) : loc('printLabels', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )} */}
                        </div>
                    )}

                    {accountSelected.includes('Administrator') && !changingCharities && (
                        <div>
                            <MenuItem
                                data-cy="customersite-drawer-charity-dashboard-button"
                                component={Link}
                                to={homePath + '/charity'}
                                selected={location.pathname === homePath + '/charity'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>dashboard</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={charityPages[homePath + '/charity']} />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-history-button"
                                component={Link}
                                to={homePath + '/charityHistory'}
                                selected={location.pathname === homePath + '/charityHistory'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>history</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={charityPages[homePath + '/charityHistory']} />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-drawer-charity-profile-button"
                                component={Link}
                                to={homePath + '/charityProfile'}
                                selected={location.pathname === homePath + '/charityProfile'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>account_box</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={charityPages[homePath + '/charityProfile']} />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-drawer-charity-donations-button"
                                component={Link}
                                to={homePath + '/charityDonations'}
                                selected={location.pathname === homePath + '/charityDonations'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>volunteer_activism</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={charityPages[homePath + '/charityDonations']} />
                            </MenuItem>
                            {charity.taxReceiptsIssued && (
                                <MenuItem
                                    data-cy="customersite-drawer-charity-receipts-button"
                                    component={Link}
                                    to={homePath + '/charityReceipts'}
                                    selected={location.pathname === homePath + '/charityReceipts'}
                                    style={MenuItemStyle}
                                    onClick={props.onDrawer(false)}
                                >
                                    <ListItemIcon>
                                        <Icon>receipt</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={charityPages[homePath + '/charityReceipts']} />
                                </MenuItem>
                            )}
                            <MenuItem
                                data-cy="customersite-drawer-charity-subdivisions-button"
                                component={Link}
                                to={homePath + '/charitySubdivisions'}
                                selected={location.pathname === homePath + '/charitySubdivisions'}
                                style={MenuItemStyle}
                                onClick={props.onDrawer(false)}
                            >
                                <ListItemIcon>
                                    <Icon>groups</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={charityPages[homePath + '/charitySubdivisions']} />
                            </MenuItem>
                        </div>
                    )}

                    <div style={{ marginTop: theme.spacing.unit * 2 }} />
                    {accountSelected === 'Customer' && (
                        <div>
                            <MenuItem
                                data-cy="customersite-drawer-requestpickup-button"
                                style={MenuItemStyle}
                                disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                onClick={props.onPickupOpen(true)}
                            >
                                <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                    {isEXPRegion() && <ExpIcon theme={theme} width={'24px'} height={'24px'} />}
                                    {isCONRegion() && (
                                        <img
                                            style={{ width: '24px', height: '24px' }}
                                            src={conIcon}
                                            alt="Consignaction Logo"
                                        />
                                    )}
                                    {process.env.REACT_APP_REGION_EXT_DEMO === 'SAR' && (
                                        <img
                                            style={{ width: '24px', height: '24px' }}
                                            src={sarIcon}
                                            alt="Recyclewise Logo"
                                        />
                                    )}
                                    {!isEXPRegion() &&
                                        !isCONRegion() &&
                                        !(process.env.REACT_APP_REGION_EXT_DEMO === 'SAR') && (
                                            <Icon>local_shipping</Icon>
                                        )}
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={pickupPending ? loc('editPickup', lang) : loc('getStartedHere', lang)}
                                    primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                />
                            </MenuItem>
                            {(scanQREnabled || showQREnabled) && (
                                <MenuItem
                                    data-cy="customersite-qr-code-button"
                                    style={MenuItemStyle}
                                    onClick={props.onQRDialog(true)}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>qr_code_scanner</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={loc('scanQRCode', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )}
                            {/* {(_user.isCollectorAdmin(auth) ||
                                _user.isSystemAdmin(auth) ||
                                _user.isInternalRole(auth) ||
                                userHelper.hasSubscriptions(customer)) && (
                                <MenuItem
                                    data-cy="customersite-drawer-subscriptions-button"
                                    style={MenuItemStyle}
                                    disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                    onClick={props.onRecurringFeesOpen(true)}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>update</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={'Subscriptions'}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )} */}
                            <MenuItem
                                data-cy="customersite-drawer-requestredemption-button"
                                style={MenuItemStyle}
                                disabled={
                                    profileNotComplete(customer) ||
                                    !emailIsVerified(customer) ||
                                    customer.suspended ||
                                    (customer.adminView && !_.get(adminPermissions, 'canRedeemCustomerBalance', false))
                                }
                                onClick={props.onRedemptionDialog(true)}
                            >
                                <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                    <Icon>payment</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={isEXPRegion() ? loc('cashOutExp', lang) : loc('cashOut', lang)}
                                    primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                />
                            </MenuItem>
                            {isEXPRegion() && (
                                <MenuItem
                                    data-cy="drawer-accessDoor-button"
                                    style={MenuItemStyle}
                                    onClick={() => handleAccessDoor()}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <MDIcon
                                            path={mdiDoor}
                                            size={1}
                                            color={
                                                typeof theme.palette.linkColor === 'object'
                                                    ? 'rgba(0,0,0,0.54)'
                                                    : theme.palette.linkColor
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={loc('openAccessDoorStep', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )}
                            {enableReferrals && (
                                <MenuItem
                                    data-cy="customersite-drawer-rewards-button"
                                    style={MenuItemStyle}
                                    onClick={props.onCustomerReferralDialog}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>attach_money</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={loc('earnMoney', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )}
                            <MenuItem style={MenuItemStyle} onClick={props.onShare}>
                                <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                    <Icon>eco</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={loc('impactReport', lang)}
                                    primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                />
                            </MenuItem>
                            {/* {!lastInteractionHidden &&
                                accountSelected === 'Customer' &&
                                auth.accountType !== 'Customer' && (
                                    <MenuItem style={MenuItemStyle} onClick={props.onLastInteraction}>
                                        <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                            <Icon>touch_app</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            inset
                                            primary="Last Interaction"
                                            primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                        />
                                    </MenuItem>
                                )} */}
                            {!isSysAdminOrCsr && !isCONRegion() && showVolumeReport && (
                                <MenuItem
                                    style={MenuItemStyle}
                                    onClick={props.onVolumeReport(true)}
                                    data-cy="customersite-volume-report-button"
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>description</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={loc('volumeReport', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )}
                            {showHelpButton && !_.isNil(nextPlatformToRateOn) && ratingEnabled && (
                                <MenuItem
                                    data-cy="customersite-drawer-help-button"
                                    style={MenuItemStyle}
                                    aria-owns={helpDialogEl ? 'help-menu' : null}
                                    aria-haspopup="true"
                                    onClick={props.onHelpDialog}
                                >
                                    <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                        <Icon>help</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={loc('help', lang)}
                                        primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                    />
                                </MenuItem>
                            )}
                        </div>
                    )}

                    {accountSelected.includes('Administrator') && !changingCharities && (
                        <div>
                            {/* <MenuItem style={MenuItemExtraSpaceStyle} onClick={props.onReferralDialog(true)}>
                                <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                    <Icon>group_add</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={loc('emailOutDirectly', lang)}
                                    primaryTypographyProps={{
                                        style: { color: theme.palette.linkColor, whiteSpace: 'initial' }
                                    }}
                                />
                            </MenuItem> */}
                            <MenuItem
                                data-cy="customersite-drawer-requestcharityredemption-button"
                                style={MenuItemStyle}
                                disabled={
                                    profileNotComplete(customer) ||
                                    !emailIsVerified(customer) ||
                                    (customer.adminView && !_.get(adminPermissions, 'canRedeemCharityBalance', false))
                                }
                                onClick={props.onCharityRedemptionDialog(true)}
                            >
                                <ListItemIcon style={{ color: theme.palette.linkColor }}>
                                    <Icon>payment</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={isEXPRegion() ? loc('cashOutExp', lang) : loc('cashOut', lang)}
                                    primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                />
                            </MenuItem>
                            <MenuItem
                                data-cy="customersite-drawer-growthplan-button"
                                style={MenuItemStyle}
                                disabled={profileNotComplete(customer)}
                                onClick={onGrowthPlanDialog(true)}
                            >
                                <ListItemIcon>
                                    <MDIcon path={mdiSproutOutline} size={1} color={theme.palette.linkColor} />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={
                                        <>
                                            {loc('growthPlan', lang)}
                                            {charity.approved && (
                                                <>
                                                    <CharityTaskCompletionChip
                                                        charity={charity}
                                                        style={{
                                                            height: 25,
                                                            marginLeft: lang === 'en' ? theme.spacing.unit : lang,
                                                            marginBottom: lang === 'en' ? 0 : theme.spacing.unit / 2
                                                        }}
                                                        onCharityGrowthPlan={onGrowthPlanDialog}
                                                    />
                                                </>
                                            )}
                                        </>
                                    }
                                    primaryTypographyProps={{
                                        style: {
                                            color: theme.palette.linkColor,
                                            whiteSpace: lang === 'en' ? 'inherit' : 'initial'
                                        }
                                    }}
                                />
                            </MenuItem>
                        </div>
                    )}
                </MenuList>

                <div style={{ marginTop: 'auto', marginBottom: 8 }}>
                    <MenuList style={{ overflowY: 'auto', width: drawerWidth }}>
                        {auth.accountType === 'Customer' && !customer.adminView && (
                            <MenuItem style={MenuItemExtraSpaceStyle} onClick={props.onNightModeToggle}>
                                <ListItemIcon>
                                    <Icon>brightness_2</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={loc('nightMode', lang)} />
                            </MenuItem>
                        )}
                        {auth.accountType === 'Customer' && !customer.adminView && (
                            <MenuItem style={MenuItemStyle} onClick={props.logOut} data-cy="customersite-logout-button">
                                <ListItemIcon>
                                    <Icon>exit_to_app</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={loc('signOut', lang)} />
                            </MenuItem>
                        )}
                        {/* showSubscriptions is inclusive of showeUserPreferences */}

                        {(showSubscriptions || showLastInteraction) && (
                            <MenuItem
                                style={MenuItemExtraSpaceStyle}
                                onClick={() => handleExpandCollapse('admin-actions')}
                                data-cy="customersite-admin-actions-menu-button"
                            >
                                <ListItemIcon style={{ color: theme.palette.secondary.main }}>
                                    <Icon>settings</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary="Admin Actions" />
                            </MenuItem>
                        )}
                        <Collapse in={collapseOpen === 'admin-actions'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {showSubscriptions && (
                                    <MenuItem
                                        dense
                                        data-cy="customersite-drawer-subscriptions-button"
                                        style={MenuItemStyle}
                                        disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                        onClick={props.onRecurringFeesOpen(true)}
                                    >
                                        <ListItemText
                                            inset
                                            primary={'Subscriptions'}
                                            primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                        />
                                    </MenuItem>
                                )}
                                {showLastInteraction && (
                                    <MenuItem
                                        dense
                                        data-cy="customersite-last-interaction-button"
                                        style={MenuItemStyle}
                                        onClick={props.onLastInteraction}
                                    >
                                        <ListItemText
                                            inset
                                            primary="Last Interaction"
                                            primaryTypographyProps={{ style: { color: theme.palette.linkColor } }}
                                        />
                                    </MenuItem>
                                )}
                                {isSysAdminOrCsr && (
                                    <MenuItem
                                        dense
                                        style={MenuItemStyle}
                                        onClick={props.onPreferencesDialog(true)}
                                        data-cy="customersite-preference-button"
                                    >
                                        <ListItemText inset primary="User Preferences" />
                                    </MenuItem>
                                )}
                                {isSysAdminOrCsr && (
                                    <MenuItem
                                        dense
                                        style={MenuItemStyle}
                                        onClick={props.onVolumeReport(true)}
                                        data-cy="customersite-volume-report-button"
                                    >
                                        <ListItemText inset primary="Volume Report" />
                                    </MenuItem>
                                )}
                                {isSysAdminOrCsr && (
                                    <MenuItem
                                        dense
                                        style={MenuItemStyle}
                                        onClick={props.onOpenSystemHistory(true)}
                                        data-cy="customersite-open-system-hitory-button"
                                    >
                                        <ListItemText inset primary="System History" />
                                    </MenuItem>
                                )}
                                {isSysAdminOrCsr && (
                                    <MenuItem
                                        dense
                                        style={MenuItemStyle}
                                        onClick={props.onOpenIPConflict(true)}
                                        data-cy="customersite-open-IP-conflict-button"
                                    >
                                        <ListItemText inset primary="IP Conflicts" />
                                    </MenuItem>
                                )}
                            </List>
                        </Collapse>

                        {(auth.accountType !== 'Customer' || auth._id !== customer._id) && (
                            <MenuItem
                                style={MenuItemStyle}
                                onClick={props.onExitView}
                                data-cy="customersite-exit-view-button"
                            >
                                <ListItemIcon style={{ color: theme.palette.secondary.main }}>
                                    <Icon>home</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary="Exit view" />
                            </MenuItem>
                        )}
                    </MenuList>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography color="textSecondary" style={{ fontSize: 12 }}>
                            <span style={{ color: theme.palette.envColor[500] }}>
                                {process.env.REACT_APP_ENV !== 'PROD' ? process.env.REACT_APP_ENV : null}
                            </span>
                            <span style={{ margin: `0 ${theme.spacing.unit}px` }}>{VERSION_FRONT}</span>
                            <span style={{ color: theme.palette.text.disabled }}>
                                {process.env.REACT_APP_BUILD_VERSION}
                            </span>
                        </Typography>
                    </div>
                </div>

                <Popover
                    open={!_.isNil(accountsAnchorEl)}
                    anchorEl={accountsAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={props.onAccountsMenu(false)}
                >
                    <List style={{ width: drawerWidth }}>
                        {accounts.map((accountName, index) => {
                            const account = ['Customer', 'Administrator'].includes(accountName.split('-')[0])
                                ? customer
                                : _.find(customer.multipleAccountAccessList, a => a._id.toString() === accountName);
                            const charityAccount = accountName.includes('Administrator')
                                ? _.find(_.get(customer, 'charities', []), c => c._id === accountName.split('-')[1])
                                : { name: 'N/A' };
                            return (
                                <MenuItem
                                    data-cy={`${index}-customersite-account-button`}
                                    key={accountName}
                                    disabled={profileNotComplete(customer) || !emailIsVerified(customer)}
                                    selected={accountSelectedIndex === index}
                                    disableGutters={false}
                                    style={{ height: 'auto' }}
                                    onClick={
                                        ['Customer', 'Administrator'].includes(accountName.split('-')[0])
                                            ? props.onAccountsChange(index)
                                            : handleViewUser(_.get(account, '_id', ''))
                                    }
                                >
                                    <ListItemIcon>
                                        <Avatar
                                            className={
                                                highlightAccountSwitch && accountName === 'Customer' ? 'pulse' : null
                                            }
                                            style={{
                                                width: 40,
                                                height: 40,
                                                backgroundColor: accountColors[accountName.split('-')[0]],
                                                color: 'white'
                                            }}
                                        >
                                            {accountName.includes('Administrator') ? (
                                                charityAccount.name[0]
                                            ) : accountName === 'Customer' ? (
                                                _.get(customer, 'name.first[0]', '?')
                                            ) : (
                                                // : _.get(account, 'name.first[0]', '?')
                                                <Icon>visibility</Icon>
                                            )}
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            accountName.includes('Administrator') ? (
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {charityAccount.name}
                                                </div>
                                            ) : accountName === 'Customer' ? (
                                                getCustomerName(customer)
                                            ) : (
                                                getCustomerName(account)
                                            )
                                        }
                                        secondary={
                                            ['Customer', 'Administrator'].includes(accountName.split('-')[0])
                                                ? accountName.split('-')[0]
                                                : 'View Account'
                                        }
                                        style={{ paddingLeft: 8 }}
                                    />
                                </MenuItem>
                            );
                        })}
                    </List>
                </Popover>
            </div>
        </>
    );
}

export default withTheme()(DrawerContent);
