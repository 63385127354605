import _ from 'lodash';

const mustache = require('mustache');

/**
 * Pulls social messages from Region, if region unavailable then tries reading from Information Editor, else falls back to json files.
 */
export async function messageBuilder(
    http,
    type,
    data,
    customerRegion,
    url = process.env.REACT_APP_ORIGIN_URL,
    lang = process.env.REACT_APP_REGION_LANGUAGE
) {
    let jsonURL = null;
    let messageObject = {};

    if (!_.isNil(customerRegion) && !_.isNil(customerRegion.social)) {
        messageObject = customerRegion.social[type];
        jsonURL = _.get(customerRegion.social[type], 'url', null);
    } else {
        let configuration = {};

        const res = await http.getJSON(`/system/localizedSocials/` + lang);
        if (res.ok) {
            configuration = res.data.socials || {};
        } else {
            switch (process.env.REACT_APP_REGION_EXT) {
                case 'AUS':
                    configuration = require('./social-aus.json');
                    break;
                case 'STD':
                    configuration = require('./social-std.json');
                    break;
                case 'EXP':
                    configuration = require('./social-exp.json');
                    break;
                case 'MXD':
                    configuration = require('./social-mxd.json');
                    break;
                case 'CON':
                    configuration = require('./social-con.json');
                    break;
                default:
            }
        }

        messageObject = configuration[type];
        jsonURL = _.get(configuration[type], 'url', null);
    }

    return {
        //I don't think these two are used anymore
        //dialogTitle: mustache.render(messageObject.dialogTitle, data),
        //dialogDesc: mustache.render(messageObject.dialogDesc, data),
        subject: mustache.render(messageObject.subject, data),
        message: mustache.render(messageObject.message, data),
        hashtags: messageObject.hashtags,
        url: _.isNil(jsonURL) ? url : jsonURL
    };
}
