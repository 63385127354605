import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _user } from 'std';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import { useMemo } from 'react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { LinearProgress } from '@material-ui/core';
import { isInternalRole, isSystemAdmin } from 'std/helpers/user';
import { aboveETransferLimit } from 'utils/misc';

const Cashout = ({
    operator,
    customer,
    charity,
    collectors,
    handleBack,
    handleNext,
    theme,
    paymentModel,
    cashedOutBy,
    handleChange,
    handleChangeCashedOutBy,
    redemptions,
    redemptionOptions,
    redemptionType,
    balance,
    activeStep,
    canRedeemWithoutDonation,
    setPaymentModel,
    loading,
    disablingAccount,
    deletingAccount
}) => {
    const { lang } = useContext(LocalizationContext);
    const sysAdmin = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);
    const collAdmin = _user.isCollectorAdmin(operator);

    const {
        chequeMinimum,
        directDepositMinimum,
        eTransferMinimum,
        bankTransferMinimum,
        cashMinimum,
        etransferLimit,
        methods: { etransfer, cheque, directDeposit, bankTransfer, cash }
    } = redemptionOptions[redemptionType];

    const allowETransferRedemption =
        redemptionType === 'charity'
            ? _.get(charity, 'allowETransferRedemption', etransfer)
            : _.get(customer, 'allowETransferRedemption', etransfer);

    const allowChequeRedemption =
        redemptionType === 'charity'
            ? _.get(charity, 'allowChequeRedemption', cheque)
            : _.get(customer, 'allowChequeRedemption', cheque);

    const allowBankTransferRedemption =
        redemptionType === 'charity'
            ? _.get(charity, 'allowBankTransferRedemption', bankTransfer)
            : _.get(customer, 'allowBankTransferRedemption', bankTransfer);

    const allowCashRedemption =
        redemptionType === 'charity'
            ? _.get(charity, 'allowCashRedemption', cash)
            : _.get(customer, 'allowCashRedemption', cash);

    const noMethods = !etransfer && !cheque && !directDeposit && !collAdmin && !sysAdmin && !bankTransfer;

    const overridePaymentMethods =
        redemptionType === 'charity'
            ? _.get(charity, 'overridePaymentMethods', false)
            : _.get(customer, 'overridePaymentMethods', false);
    const bypassRedemptionMinimum =
        disablingAccount ||
        deletingAccount ||
        (redemptionType === 'charity'
            ? _.get(charity, 'bypassRedemptionMinimum', false)
            : _.get(customer, 'bypassRedemptionMinimum', false));

    const isAboveETransferLimit = aboveETransferLimit(balance, redemptions, redemptionOptions[redemptionType]);

    const getETransferText = () => {
        if (isAboveETransferLimit) {
            return loc('redemption28', lang, {
                limit: etransferLimit
            });
        }
        if (!bypassRedemptionMinimum && balance < parseInt((eTransferMinimum ? eTransferMinimum : 0) * 100)) {
            return loc('redemption29', lang, {
                limit: eTransferMinimum ? eTransferMinimum : 0
            });
        }

        return loc('redemption4', lang);
    };


    const errorForCashRedemption = paymentModel === 'CASH' && sysAdmin && _.isEmpty(cashedOutBy);

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption2', lang)} iconName="money" />

                {isAboveETransferLimit && (
                    <DialogContentText style={{ marginBottom: 5, fontSize: theme.typography.fontSize * 0.9 }}>
                        {loc('redemption68', lang)}
                    </DialogContentText>
                )}

                <DialogContentText>{loc('redemption3', lang)}</DialogContentText>
                <FormControl
                    data-cy="redemption-dialog-cashout-option"
                    fullWidth
                    style={{ marginTop: theme.spacing.unit }}
                >
                    <InputLabel variant="outlined" htmlFor="cashout">
                        {loc('redemption65', lang)}
                    </InputLabel>
                    <Select
                        disabled={loading}
                        value={paymentModel}
                        onChange={handleChange}
                        inputProps={{
                            name: 'cashout',
                            id: 'cashout'
                        }}
                        input={<OutlinedInput labelWidth={122} name="question" id="cashout" className="select-icon" />}
                        data-cy="redemption-dialog-cashout-select"
                    >
                        {!collAdmin &&
                            (!_.isNil(allowETransferRedemption) && overridePaymentMethods
                                ? allowETransferRedemption
                                : etransfer) &&
                            canRedeemWithoutDonation && (
                                <MenuItem
                                    data-cy={'etransfer-redemption-dialog-option'}
                                    key={'etransfer-redemption-dialog-option'}
                                    value="INTERAC_ETRANSFERS"
                                    disabled={
                                        isAboveETransferLimit ||
                                        (!bypassRedemptionMinimum &&
                                            balance < parseInt((eTransferMinimum ? eTransferMinimum : 0) * 100))
                                    }
                                >
                                    {getETransferText()}
                                </MenuItem>
                            )}
                        {!collAdmin &&
                            (!_.isNil(canRedeemWithoutDonation) && overridePaymentMethods
                                ? allowChequeRedemption
                                : cheque) &&
                            canRedeemWithoutDonation && (
                                <MenuItem
                                    data-cy={'cheque-redemption-dialog-option'}
                                    key={'cheque-redemption-dialog-option'}
                                    value="CHEQUE"
                                    disabled={
                                        !bypassRedemptionMinimum &&
                                        balance < parseInt((chequeMinimum ? chequeMinimum : 0) * 100)
                                    }
                                >
                                    {!bypassRedemptionMinimum &&
                                        balance < parseInt((chequeMinimum ? chequeMinimum : 0) * 100)
                                        ? loc('redemption39', lang, {
                                            minimum: chequeMinimum ? chequeMinimum : 0
                                        })
                                        : loc('redemption5', lang)}
                                </MenuItem>
                            )}
                        {!collAdmin && directDeposit && canRedeemWithoutDonation && (
                            <MenuItem data-cy={'direct-banking-redemption-dialog-option'} key={'direct-banking-redemption-dialog-option'} value="AUS_DIRECT_BANKING">
                                Australian Direct Banking
                            </MenuItem>
                        )}

                        {!collAdmin &&
                            (!_.isNil(allowCashRedemption) && overridePaymentMethods
                                ? allowBankTransferRedemption
                                : bankTransfer) &&
                            canRedeemWithoutDonation && (
                                <MenuItem
                                    data-cy={'bank-transfer-redemption-dialog-option'}
                                    key={'bank-transfer-redemption-dialog-option'}
                                    value="BANK_TRANSFER"
                                    disabled={
                                        !bypassRedemptionMinimum &&
                                        balance < parseInt((bankTransferMinimum ? bankTransferMinimum : 0) * 100)
                                    }
                                >
                                    {!bypassRedemptionMinimum &&
                                        balance < parseInt((bankTransferMinimum ? bankTransferMinimum : 0) * 100)
                                        ? loc('redemption61', lang, {
                                            minimum: bankTransferMinimum ? bankTransferMinimum : 0
                                        })
                                        : loc('redemption6', lang)}
                                </MenuItem>
                            )}

                        {(sysAdmin ||
                            collAdmin ||
                            (!_.isNil(allowCashRedemption) && overridePaymentMethods ? allowCashRedemption : cash)) && (
                                <MenuItem
                                    data-cy={'cash-out-redemption-dialog-option'}
                                    key={'cash-out-redemption-dialog-option'}
                                    value="CASH"
                                    disabled={
                                        !sysAdmin &&
                                        !_.get(customer, 'bypassRedemptionMinimum', false) &&
                                        balance < parseInt((cashMinimum ? cashMinimum : 0) * 100)
                                    }
                                >
                                    {!_.get(customer, 'bypassRedemptionMinimum', false) &&
                                        !sysAdmin &&
                                        balance < parseInt((cashMinimum ? cashMinimum : 0) * 100)
                                        ? loc('redemption49', lang, {
                                            minimum: cashMinimum ? cashMinimum : 0
                                        })
                                        : loc('redemption7', lang)}
                                </MenuItem>
                            )}
                        {noMethods && <MenuItem disabled>{loc('redemption52', lang)}</MenuItem>}
                    </Select>
                </FormControl>
                {paymentModel === 'CASH' && sysAdmin && (
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={loc('collector', lang)}
                        name="cashedOutBy"
                        disabled={loading || !_.isNil(_.get(customer, 'collector'))}
                        value={cashedOutBy}
                        onChange={handleChangeCashedOutBy}
                        select
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        SelectProps={{ className: 'select-icon' }}
                    >
                        {collectors.map(collector => (
                            <MenuItem value={collector._id} key={collector._id}>{collector.name}</MenuItem>
                        ))}
                    </TextField>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                {activeStep > 0 && (
                    <Button color="primary" onClick={handleBack}>
                        {loc('back', lang)}
                    </Button>
                )}
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    onClick={handleNext}
                    disabled={paymentModel === 'NONE' || errorForCashRedemption || loading}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </>
    );
};

export default Cashout;
